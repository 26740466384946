/* eslint-disable default-case */
import React, { useState, useEffect } from 'react'
import IconsPath from '../../assets/icons'
import ParentModal from '../../genericComponents/ParentModal'
import ListComponent from '../../genericComponents/ListComponent'
import { useHistory } from 'react-router-dom'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import { useDispatch, useSelector } from 'react-redux'
import NotificationModal from './NotificationModal'
import '../../styles/stylesheets/home/Header.scss'
import '../../styles/stylesheets/GenericComponents.scss'
import '../../styles/stylesheets/helper.scss'
import '../../styles/stylesheets/listComponent.scss'
import '../../styles/stylesheets/TableComponent.scss'
import GenericConfirmationModal from '../../genericComponents/GenericConfiramationModal'
import HelpAndSupportDropdownComponent from './HelpAndSupportDropdownComponent'
import HeaderStrip from './components/HeaderStrip'
import EscalationMatrixModal from './components/EscalationMatrixModal'
import ProfileSection from './profileSection/ProfileSection'
import {getNumberOfStrips} from '../../initialPhaseOperations/InitialAnnouncementModal'
import FullPageLoader from '../../loaders/FullPageLoader'

const SupplymintHeader = (props) => {
    const STATUS_CLASSNAME_MAP = {
        PENDING: "vsos-pending",
        COMPLETED: "vsos-approved",
        "IN-PROGRESS": "vsos-hold",
        ERROR: "vsos-error"
    };

    const [selectedModal, setSelectedModal] = useState(false);
    const [refreshListData, setRefreshListData] = useState(false);
    // State: To show and hide the help section dropdown
    const [helpAndSupportDropdown, setHelpAndSupportDropdown] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const generalStore = useSelector(state => state.generalStore);

    const [stripHeight, setStripHeight] = useState(getNumberOfStrips() || 0);
    const [loading, setLoading] = useState(false);
    const [trainingGuideData, setTrainingGuideData] = useState([])

    const OS = navigator?.userAgentData?.platform; // current operating system
    // Initial useEffect Function
    useEffect(() => {
        document.addEventListener("keydown", setHeaderShortcuts);

        return (() => {
            document.removeEventListener("keydown", setHeaderShortcuts, false);
        })
    }, [])

    useEffect(() => {
        if(generalStore?.["getSupplymintTraningGuidesData"]?.isSuccess) {
            setTrainingGuideData(generalStore?.getSupplymintTraningGuidesData?.data?.resource || []);
            setRefreshListData(true);
        }
    }, [generalStore?.["getSupplymintTraningGuidesData"]?.isSuccess]);

    useEffect(() => {
        setInterval(() => {
            setStripHeight(getNumberOfStrips())
        }, 400)
    }, [])

    useEffect(() => {
        console.log("Debug89");
        setRefreshListData(true);
    }, [generalStore?.["cancelDataExportModalData"]?.isSuccess]);


    useEffect(() => {
        setRefreshListData(false);
    }, [generalStore?.["getDataExportModalData"]?.isSuccess]);

    // Function to handle the shortcuts (keyboard)
    const setHeaderShortcuts = (e) => {
        const CTRL_OR_CMD = OS === "macOS" ? e.metaKey : e.ctrlKey;
        if (CTRL_OR_CMD && (e.key === "k" || e.key === "K")) {
            if (e.shiftKey) { handleChange("openGlobalSearchModal")() }
        }
    }

    // Close Modal function to close the current modal
    const closeModal = () => {
        setSelectedModal(false);
    }

    const handleChange = (type, data) => (e) => {
        console.log("Headc", helpAndSupportDropdown);
        // Function to handle changes inside the headers component
        switch (type) {
            case "openGlobalSearchModal": {
                setSelectedModal("globalSearch");
                break;
            }
            case "goToPage": {
                let temp = {
                    pathname: data.pageUrl?.replace("#", "")
                };
                if (data.pageUrl) {
                    history?.push(temp)
                }
                break;
            }
            case "openDataExportModal": {
                setSelectedModal("dataExportModal");
                break;
            }
            case "cancelItemExport": {
                dispatchHandler(dispatch, "cancelDataExportModalRequest", {
                    id: data?.id || "",
                    status: data?.status || "",
                })
                break;
            }
            case "downloadExportDataFile": {
                if (data?.file_url) {
                    window.open(data.file_url);
                }
                break;
            }
            case "helpAndSupportDropdown": {
                if (helpAndSupportDropdown) {
                    closeHelpModal();
                } else {
                    setHelpAndSupportDropdown(!helpAndSupportDropdown);
                    setTimeout(() => document.addEventListener("click", closeHelpModal), 0)
                }
                break;
            }
        }
    }

    const closeHelpModal = (e) => {
        setHelpAndSupportDropdown(false)
        setTimeout(() => document.removeEventListener("click", closeHelpModal), 0)
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "globalSearch":
            case "trainingGuidesModal":
            case "dataExportModal": {
                return ListComponent;
            }
            case "notificationModal": {
                return NotificationModal;
            }
            case "escalationMatrixModal": {
                return EscalationMatrixModal
            }
            case "confirmationModal": {
                return GenericConfirmationModal
            }
        }
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: closeModal
        }
        switch (selectedModal) {
            case "globalSearch": {
                return {
                    ...payload,
                    postitionProps: { top: 20, bottom: 0, left: 70, right: 0, transform: "none", position: "absolute", margin: 0, borderRadius: 3 },
                    dimensions: { width: "50vw", height: "70vh" },
                    animationType: "center",
                    removeTransformProperty: true,
                    modalMainClass: "global-search-modal",
                    // Label props
                    // label: {
                    //     content: "Global Search",
                    // },
                    // total records:
                    totalRecords: (states, functions) => {
                        return states?.listData?.length || 0;
                    },
                    // search props.
                    search: {
                        placeholder: "Type To Search",
                        isSearchIconRequire: true,
                        customSearchFunction: (listState, listFunctions) => {
                            const { listData, currentSearch } = listState;
                            return listData?.filter(item => item?.name.toLowerCase().includes(currentSearch?.toLowerCase()));
                        }

                    },

                    headerKeys: {
                        "pageSearch": {
                            value: "Page Search", // This can be a function also which can show the dynamic data
                            width: "50%" // This defines the width of the column
                        }
                    },

                    itemKeys: {
                        // each header can have sevral item Keys
                        "pageSearch": [
                            //  "pageName", 
                            (state, functions, data) => {
                                let { currentSearch } = state;
                                const parts = data?.["name"].split(new RegExp(`(${currentSearch})`, 'gi'));
                                return (
                                    <span className='global-search-custom-col'>
                                        {parts?.map((part, index) =>
                                            part.toLowerCase() === currentSearch?.toLowerCase() ? (
                                                <span key={index} style={{ fontWeight: 'bold', color: '#21314b' }}>
                                                    {part?.toUpperCase()}
                                                </span>
                                            ) : <span>
                                                {part[0] == " " && <span>&nbsp;</span>}
                                                {part?.toUpperCase()}
                                            </span>
                                        )}
                                        <IconsPath.GlobalSearchArrowIcon />
                                    </span>
                                );
                            },
                            // we can pass the function to show the pagePath
                            "pagePath",
                        ]
                    },

                    getListData: () => {
                        function parseSubModules(module) {
                            let pageData = [];
                            module?.subModules?.forEach(subModule => {
                                subModule.pagePath = module.pagePath + " > " + subModule.name;
                                if (subModule.isPage === 0 && subModule.subModules) {
                                    pageData = [...pageData, ...parseSubModules(subModule, pageData)]
                                }
                                else pageData.push(subModule);
                            });
                            return pageData || [];
                        }
                        let modules = JSON.parse(sessionStorage.getItem("modules"));
                        let pageData = [];
                        modules?.map(module => {
                            module.pagePath = module.name;
                            if (module.isPage === 0 && module.subModules) {
                                pageData = [...pageData, ...parseSubModules(module, pageData)];
                            }
                        })
                        return pageData;
                    },

                    submitHandler: (data) => {
                        handleChange("goToPage", data)();
                    }
                }
            }
            case "dataExportModal": {
                return {
                    ...payload,
                    postitionProps: { top: "15%", bottom: 0, left: "20%", right: 60, transform: "none", position: "absolute", margin: 0, borderRadius: 3 },
                    dimensions: { width: "60vw", height: "70vh" },
                    viewDataType: "table-view",
                    animationType: "center",
                    modalMainClass: "export-data-dropdown",
                    // Label props
                    label: {
                        content: "Data Export",
                    },
                    headerKeys: {
                        "fileName": {
                            value: "File Name", // This can be a function also which can show the dynamic data
                            width: "30%" // This defines the width of the column
                        },
                        "createdOn": {
                            value: "Created On", // This can be a function also which can show the dynamic data
                            width: "20%" // This defines the width of the column
                        },
                        "generatedOn": {
                            value: "Generated On", // This can be a function also which can show the dynamic data
                            width: "20%" // This defines the width of the column
                        },
                        "status": {
                            value: () => {
                                return (
                                    <div className='eddsc-refresh'>
                                        <label>Status</label>
                                        <button type='button' disabled={refreshListData} onClick={() => { setRefreshListData(true) }}><IconsPath.RefreshIcon /></button>
                                    </div>
                                )
                            }, // This can be a function also which can show the dynamic data
                            width: "20%" // This defines the width of the column
                        }
                    },
                    api: {
                        reduxState: "generalStore",
                        apiName: "getDataExportModal",
                        apiPayload: (params) => {
                            return {
                                "pageNo": params?.currentPage || 1,
                                "pageSize": 30,
                                "type": 1,
                                "search": "",
                                "filter": {},
                                "sortedBy": "",
                                "sortedIn": ""
                            }
                        },
                        getMaxPage: (states, fucntions, response) => {
                            return response?.resource?.maxPage
                        },
                    },
                    itemKeys: {
                        // each header can have sevral item Keys
                        "fileName": [
                            (state, functions, data) => {
                                return <div className='fileName-field'><IconsPath.DataExportFileIcon /><span className='mdlr-multiple-row'>{data.fileName}</span></div>;
                            },
                        ],
                        "createdOn": ["creation_time"],
                        "generatedOn": ["updation_time"],
                        "status": [
                            (state, functions, data) => {
                                return renderDataExportModalStatus(state, functions, data);
                            },
                        ]
                    },
                    getListData: (response) => {
                        return response?.resource?.data;
                    },
                    // renderCustomItems: {
                    //     "4": () => {
                    //         return (
                    //             <div className='edd-footer'>
                    //                 <button type='button' className='default-theme-btn'>View All</button>
                    //             </div>
                    //         )
                    //     }
                    // },
                    refreshData: refreshListData,
                }
            }
            case "trainingGuidesModal": {
                return {
                    postitionProps: { top: 0, bottom: 0, left: "auto", right: 0, transform: "none", position: "absolute", margin: 0, borderRadius: 0 },
                    dimensions: { width: "35vw", height: "100vh" },
                    animationType: "right",
                    closeModal: () => { setSelectedModal("") },
                    // search props.
                    search: {
                        placeholder: "Type To Search",
                        isSearchIconRequire: true,
                        customSearchFunction: (listState, listFunctions) => {
                            const { listData, currentSearch } = listState;
                            return listData?.filter(item => item?.displayName.toLowerCase().includes(currentSearch?.toLowerCase()));
                        }

                    },
                    headerKeys: {
                        "displayName": {},
                    },
                    itemKeys: {
                        "displayName": ["displayName"]
                    },
                    rowRightComponent: <IconsPath.VendorRedirectIcon />,
                    label: {
                        content: "Training Guides",
                        closeModal: () => { setSelectedModal("") }
                    },
                    submitHandler: (data) => {
                        data?.fileUrl && window.open(data?.fileUrl);
                        setSelectedModal(false);
                    },
                    getListData: () => {
                        return trainingGuideData
                    },
                    refreshData: refreshListData
                    // api: {
                    //     reduxState: "generalStore",
                    //     apiName: "getSupplymintTraningGuides",
                    //     apiPayload: (params) => {
                    //         return {
                    //         }
                    //     },
                    //     getMaxPage: (states, fucntions, response) => {
                    //         return 1;
                    //     },
                    // },
                    // getListData: (data) => data?.resource,
                }
            }
            case "notificationModal": {
                return {
                    postitionProps: { top: 0, bottom: 0, left: "auto", right: 0, transform: "none", position: "absolute", margin: 0, borderRadius: 0 },
                    dimensions: { width: "35vw", height: "100vh" },
                    animationType: "right",
                    closeModal: () => { setSelectedModal("") }
                }
            }
            case "confirmationModal": {
                return {
                    ...payload,
                    postitionProps: { top: "10%", bottom: 0, left: "0", right: 0 },
                    dimensions: { width: "50vw", height: "70vh" },
                    animationType: "center",
                    // Label props
                    labelComponent: 'Are you sure to perform the action',
                    buttons: [{
                        label: 'Confirm', onClick: () => console.log('confirm button is clicked')
                    },
                    {
                        label: 'Cancled', onClick: () => console.log('Cancled button is clicked')

                    }],
                    message: 'Hello buddy',
                    icon: IconsPath.AdminIconMenuIcon
                }
            }
            case "escalationMatrixModal": {
                return {
                    ...payload,
                    postitionProps: { top: "10%", left: "15%", right: 0, borderRadius: 0 },
                    dimensions: { width: "70vw", height: "50vh" },
                    animationType: "top",
                }
            }
        }
    }

    const renderDataExportModalStatus = (state, functions, data) => {
        return <div className='edd-status-col'>
            <label className={`vso-status ${STATUS_CLASSNAME_MAP?.[data?.status] || ""}`} >{data?.status}</label>
            {data.status === "IN-PROGRESS" ?
                <button className='edd-btn' onClick={handleChange("cancelItemExport", data)}>
                    <IconsPath.DataExportCancelActiveIcon />
                    <span className='generic-tooltip'>Cancel</span>
                </button>
                :
                <button className='edd-btn !cursor-not-allowed'>
                    <IconsPath.DataExportCancelDisabledIcon />
                    {/* <span className='generic-tooltip'>Cancel</span> */}
                </button>}
            {data.status == "ERROR" || data.status == "IN-PROGRESS" || data.status == "CANCELLED" ?
                <button>
                    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.875 11.875h11.25v1.25H1.875v-1.25zm6.25-3.643 3.794-3.794.884.883L7.5 10.625 2.197 5.322l.884-.885 3.794 3.794V1.25h1.25v6.982z" fill="#D8DAF5" />
                    </svg> </button> :
                <button onClick={handleChange("downloadExportDataFile", data)}><svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }}>
                    <path d="M1.875 11.875h11.25v1.25H1.875v-1.25zm6.25-3.643 3.794-3.794.884.883L7.5 10.625 2.197 5.322l.884-.885 3.794 3.794V1.25h1.25v6.982z" fill="#00CEB5" />
                </svg>
                </button>}
        </div>
    }

    return (
        <React.Fragment>
            <HeaderStrip />

            <div className={`header-outer top-${stripHeight}`}>
                <header className="header">
                    {/* Render the div fild for the global search  */}
                    <div className="hoh-left">
                        <button className="hohl-global-search" onClick={handleChange("openGlobalSearchModal")}>
                            <IconsPath.GlobalSearchIcon />
                            <input autoComplete="off" type="text" readOnly value="" placeholder={`Page Search... `} />
                            <span className="short-key-btn"> {OS === "macOS" ? "\u2318" : "Ctrl"}+Shift+K</span>
                        </button>
                    </div>
                    {/* Render the right side section of the headers  */}
                    <div className="hoh-right">
                        <ul className="hohr-menu">
                            {/* Render the help section */}
                            <li onClick={handleChange("helpAndSupportDropdown")} className="hohrm-list hohrm-btn-v2">
                                <IconsPath.HeaderHelpMenuIcon />
                                <span class="generic-tooltip">Help</span>
                            </li>
                            {/* Render the Help & Support Dropdown Modal here */}
                            {helpAndSupportDropdown && <HelpAndSupportDropdownComponent
                                setSelectedModal={setSelectedModal}
                                closeHelpModal={() => closeHelpModal()}
                                createNewTicket={props.createNewTicket}
                            />}
                            <li className="hohrm-list hohrm-btn" onClick={() => history.push('/excel-upload')}>
                                <IconsPath.UploadExcelIcon />
                                <span className={`generic-tooltip`}>Upload Excel</span>
                            </li>
                            <li className="hohrm-list hohrm-btn" onClick={handleChange("openDataExportModal")}><IconsPath.HeaderDataExportIcon />
                                <span class="generic-tooltip">Data Export</span>
                            </li>
                            <li className="hohrm-list hohrm-btn" onClick={() => { setSelectedModal("notificationModal") }}><IconsPath.HeaderNotificationsIcon />
                                <span class="generic-tooltip">Notifications</span>
                            </li>
                            <li className='hohrm-list p-rgt-0'>
                                {/* Render the profile section here. */}
                                <ProfileSection {...props} loading={loading} setLoading= {setLoading} />
                            </li>
                        </ul>


                    </div>
                </header>
            </div>

            <React.Fragment>
                {/* Render the Paren Modal to render all the modal components here */}
                {selectedModal && <ParentModal
                    getParentChildProps={getParentChildProps()}
                    childComponent={getChildComponent()}
                    closeModal={() => { setSelectedModal(false) }}
                />}

            </React.Fragment>
            {/* Set Loading to true and show the full page loader */}
            {loading && <>
                <FullPageLoader />
            </>}
        </React.Fragment>
    )
}

export default SupplymintHeader