import React from 'react'
import PerformanceDashFilters from './PerformanceDashFilters'
import { useSelector } from 'react-redux'
import Icons from '../../../assets/icons'
import DailySalesTabTableSkeleton from './skeletons/DailySalesTabTableSkeleton'
const PREV_YEAR = (new Date()).getFullYear() - 1;

const PRIMARY_FILTERS = [
    {
        entity: "generic_item_master_report",
        key: "hl1_name",
        value: "Product Category"
    }
]
const currentFilterKeys = ['hl1_name']
const DAILY_SALES_TRACKER_HEADERS_MAP = {
    group1: "Store Type",
    store_grade: "Store Grade",
    name1: "Store Name",
    asm: "ASM",
    city: "City",
    state1: "State",
    region: "Region",
    ds_sales_qty: "Sales Qty",
    ds_mrp_sales: "MRP Sales",
    ds_sales_value: "Net Sales",
    ds_nsv_sales: "NSV",
    ds_mtd_per: "MD %",
    ds_asp: "ASP",
    wk_sales_qty: "Sales Qty",
    wk_mrp_sales: "MRP Sales",
    wk_sales_value: "Net Sales",
    wk_nsv_sales: "NSV",
    wk_mtd_per: "MD %",
    wk_asp: "ASP",
    wtd_nob: "No Of Bills",
    wk_upt: "UPT",
    wk_atv: "ATV",
    last_wk_sales_qty: "LW Sales Qty",
    growth_wk_qty_per: "Growth %",
    last_wk_sales_value: "LW Sales Val",
    growth_wk_value_per: "Growth %",
    mtd_sales_qty: "Sales Qty",
    mtd_mrp_sales: "MRP Sales",
    mtd_sales_value: "Net Sales",
    mtd_nsv_sales: "NSV",
    mtd_mtd_per: "MD %",
    mtd_asp: "ASP",
    mtd_nob: "No Of Bills",
    mtd_upt: "UPT",
    mtd_atv: "ATV",
    last_mtd_sales_qty: `LY - MTD Qty - ${PREV_YEAR}`,
    growth_mtd_qty_per: "Growth %",
    last_mtd_sales_value: `LY - MTD Val - ${PREV_YEAR}`,
    growth_mtd_value_per: "Growth %",
    ytd_sales_qty: "Sales Qty",
    ytd_mrp_sales: "MRP Sales",
    ytd_sales_value: "Net Sales",
    ytd_nsv_sales: "NSV",
    ytd_mtd_per: "MD %",
    ytd_asp: "ASP",
    ytd_nob: "No Of Bills",
    ytd_upt: "UPT",
    ytd_atv: "ATV",
    last_ytd_sales_qty: `LYTD Qty - ${PREV_YEAR}`,
    growth_ytd_qty_per: "Growth %",
    last_ytd_sales_value: `LYTD Val - ${PREV_YEAR}`,
    growth_ytd_value_per: "Growth %"
};

const DailySalesTracker = (props) => {
    const { dashboardData } = props
    const digivendStore = useSelector((state) => state.digivendStore)

    const getDashboardData = (filtersData = {}) => {
        props.getDashboardData(filtersData)
    }

    return (
        <div className='daily-sales-tracking'>
            <PerformanceDashFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                applyFilter={getDashboardData}
                currentFilterKeys={currentFilterKeys}
            />

            {/* table */}
            {digivendStore?.getARSDashboardDataData?.isLoading ? (<DailySalesTabTableSkeleton />) : (
                <div role="tabpanel" className="tab-pane fade p-lr-12" id="dailySalesTracker">
                    <div className="vendor-gen-table vgtbg-fcfdfe sticky-seven-col">
                        <div className="manage-table">
                            <table className="table gen-main-table w-100" id="dailySalesTrackerTable">
                                <thead className="box-shadow-none">
                                    <tr className="vgt-header-top">
                                        <th class="vgtht-none"></th>
                                        <th class="vgtht-none"></th>
                                        <th class="vgtht-none"></th>
                                        <th class="vgtht-none"></th>
                                        <th class="vgtht-none" colspan="4"></th>
                                        <th class="vgtht-qty" colspan="6"><label>Daily Sales</label></th>
                                        <th class="vgtht-value" colspan="13"><label>WTD</label></th>
                                        <th class="vgtht-last" colspan="13"><label>MTD</label></th>
                                        <th class="vgtht-ytd" colspan="13"><label>YTD</label></th>
                                    </tr>
                                    <tr>
                                        <th className="fix-action-btn wid-40">
                                            <ul className="rab-refresh">
                                                <li className="rab-rinner" onClick={() => getDashboardData({})}>
                                                    <Icons.RefreshIcon className='m-0' />
                                                    <span className="generic-tooltip">Refresh</span>
                                                </li>
                                            </ul>
                                        </th>
                                        {Object.values(DAILY_SALES_TRACKER_HEADERS_MAP)?.map((value, index) =>
                                            <th key={index}><label>{value}</label></th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(dashboardData?.dsrDetails?.length) ?
                                        <React.Fragment>
                                            {dashboardData?.dsrDetails?.map((item, index) =>
                                                <React.Fragment>
                                                    <tr>
                                                        <td className="fix-action-btn width40">
                                                            <ul className="table-item-list">
                                                                <li className="til-inner"></li>
                                                            </ul>
                                                        </td>
                                                        {Object.keys(DAILY_SALES_TRACKER_HEADERS_MAP)?.map(key =>
                                                            <td align={["group1", "city", "state1", "region", "asm", "store_grade", "name1"]?.includes(key) ? "" : "right"}>
                                                                <label>{item[key]}</label>
                                                            </td>
                                                        )}
                                                    </tr>
                                                    {(dashboardData?.dsrDetails[index + 1] === undefined || item?.group1 !== dashboardData?.dsrDetails[index + 1]?.group1) &&
                                                        <tr className="vgttable-footer">
                                                            <td className="fix-action-btn width40">
                                                                <ul className="table-item-list">
                                                                    <li className="til-inner"></li>
                                                                </ul>
                                                            </td>
                                                            {Object.keys(DAILY_SALES_TRACKER_HEADERS_MAP)?.map(key =>
                                                                <td align={["group1", "city", "state1", "region", "asm", "store_grade", "name1"].includes(key) ? "" : "right"}>
                                                                    <label className={key === "group1" ? "bold" : ""}>{dashboardData?.dsrTotal?.[item?.group1]?.[key]}</label>
                                                                </td>
                                                            )}
                                                        </tr>}
                                                </React.Fragment>
                                            )}
                                            <tr className="vgt-tr-bg">
                                                <td className="fix-action-btn width40">
                                                    <ul className="table-item-list">
                                                        <li className="til-inner"></li>
                                                    </ul>
                                                </td>
                                                {Object.keys(DAILY_SALES_TRACKER_HEADERS_MAP)?.map(key =>
                                                    <td align={["group1", "city", "state1", "region", "asm", "store_grade", "name1"].includes(key) ? "" : "right"}>
                                                        <label className={key === "group1" ? "bold" : ""}>{dashboardData?.dsrTotal?.TOTAL?.[key]}</label>
                                                    </td>
                                                )}
                                            </tr>
                                        </React.Fragment> :
                                        <tr><td colSpan={100} align="center"><label>No data found!</label></td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>

    )
}

export default DailySalesTracker