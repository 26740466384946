import React, { Component } from 'react'
import moment from "moment";
import { DatePicker } from 'antd';
import GenericSearchModalNew from './GenericSearchModalNew';
import dayjs from 'dayjs';
import IconsPath from '../../../assets/icons/index'
import '../../../styles/stylesheets/Assortment/TipsAssortment.scss'
const { RangePicker } = DatePicker;

export default class CustomSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDropdown: false,
            inputStr: "",
            itemCodes: this.props.data,
            headerType: this.props.headerType,
            columnBy: this.props.columnBy,
            checkboxSpace: false,
            activeTab: this.props.actionBarConfig && this.props.actionBarConfig.filters && this.props.actionBarConfig.filters.isItemRequire ? "itemFilters" : "customFilters",
            // Item Filters 
            search: "",
            applyFilterEnabled: false,
            filterItems: { ...this.props.filterItems },
            checkedFilters: { ...this.props.appliedFilters },
            currentTab: "item",
            customHeaders: this.props.headersData.item && this.props.headersData.item.data && Object.keys(this.props.headersData.item.data).length === 0 ? [] : this.props.headersData.item && this.props.headersData.item.data && Object.keys(this.props.headersData.item.data["Custom Headers"]).length === 0 ? Object.keys(this.props.headersData.item.data["Default Headers"]) : this.props.headersData.item && this.props.headersData.item.data && Object.keys(this.props.headersData.item.data["Custom Headers"]),
            customHeadersType: this.props.headersData.item && this.props.headersData.item.data && this.props.headersData.item["Custom Headers"] === undefined || this.props.headersData.item && this.props.headersData.item.data && this.props.headersData.item["Custom Headers"].length === 0 ? "Default Headers" : "Custom Headers",
        };

        this.columnList = {
            pi: {
                indentNo: "Indent Number"
            },
            po: {
                orderNo: "Order Number",
                gensysOrderNo: "ERP Order Number"
            },
            openPo: {
                orderNumber: "PO Number"
            }
        };
    }

    changeTab = (tab) => {
        this.setState({
            activeTab: tab,
        });
    }

    componentDidMount() {
        this.setState(prevState => {
            let checkedFilters = { ...prevState.checkedFilters };
            Object.keys(prevState.checkedFilters).forEach(key => {
                if (key == "item") {
                    Object.keys(prevState.checkedFilters.item).forEach(key => {
                        if (this.props.headersData[this.state.currentTab].data.columnType[key] === "DATE" && checkedFilters[key].key || this.props.headersData[this.state.currentTab].data.columnType[key] === "NUMBER" || this.props.headersData[this.state.currentTab].data.columnType[key] === "TEXT") {
                            checkedFilters[key] = checkedFilters.item[key]
                        }
                        else if (this.props.headersData[this.state.currentTab].data.columnType[key] === "LOV" || this.props.headersData[this.state.currentTab].data.columnType[key] === "SEARCH") {
                            try {
                                checkedFilters[key] = checkedFilters.item[key].split(",");
                            }
                            catch (err) {
                                checkedFilters[key] = checkedFilters.item[key];
                            }
                        }
                    });
                }
                else {
                    if (prevState.filterItems.columnType[key] === "DATE" && checkedFilters[key].key) {
                        checkedFilters[key] = {
                            ...this.getDateFromTo(checkedFilters[key].key),
                            key: checkedFilters[key].key
                        };
                    }
                    else if (prevState.filterItems.columnType[key] === "LOV" || prevState.filterItems.columnType[key] === "SEARCH") {
                        try {
                            checkedFilters[key] = checkedFilters[key].split(",");
                        }
                        catch (err) {
                            checkedFilters[key] = checkedFilters[key];
                        }
                    }
                }
            });
            return { checkedFilters };
        }, this.handleApplyFilterButton);
    }

    handleApplyFilterButton = () => { //Enables the "Apply Filter" button if all the checked filters have a value corresponding to them.
        this.setState(prevState => {
            if (Object.keys(prevState?.checkedFilters)?.length === 0 || Object.values(prevState?.checkedFilters)?.some(value => value === "" || value?.length === 0 || value?.from === "" || value?.to === ""))
                return { applyFilterEnabled: false };
            else
                return { applyFilterEnabled: true };
        });
    }

    checkFilter = (key) => {
        if (Object.keys(this.state.checkedFilters).indexOf(key) === -1) {
            switch (this.state.filterItems.columnType[key] || this.props.headersData[this.state.currentTab].data.columnType[key]) {
                case "NUMBER": {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = {
                            from: "",
                            to: ""
                        };
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                    break;
                }
                case "LOV":
                case "SEARCH": {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = [];
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                    break;
                }
                default: {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = "";
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
            }
        }
        else {
            this.setState(prevState => {
                let checkedFilters = prevState.checkedFilters;
                delete checkedFilters[key];
                return { checkedFilters };
            }, this.handleApplyFilterButton);
        }
    }

    handleInput = (e, key) => {
        try {
            e.persist();
        }
        catch (err) { }

        switch (this.state.filterItems.columnType[key] || this.props.headersData[this.state.currentTab].data.columnType[key]) {
            case "NUMBER": {
                if (e.target.dataset.type === "from") {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key].from = e.target.value;
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key].to = e.target.value;
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
                break;
            }
            case "DATE": {
                if (e === null) {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = "";
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
                else if (["SM_DATE_TODAY_VALUE", "SM_DATE_YESTERDAY_VALUE", "SM_DATE_LAST7DAYS_VALUE", "SM_DATE_LASTWEEK_VALUE", "SM_DATE_THISMONTH_VALUE", "SM_DATE_LASTMONTH_VALUE"].includes(e)) {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = {
                            ...this.getDateFromTo(e),
                            key: e
                        };
                        return { checkedFilters, dateDropdown: "" };
                    }, this.handleApplyFilterButton);
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = {
                            from: moment(e[0].$d).format('YYYY-MM-DD'),
                            to: moment(e[1].$d).format('YYYY-MM-DD')
                        };
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
                break;
            }
            case "TIME": {
                if (e === null) {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = "";
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = { ...prevState.checkedFilters };
                        checkedFilters[key] = {
                            from: moment(e[0].$d).format('YYYY-MM-DD HH:mm'),
                            to: moment(e[1].$d).format('YYYY-MM-DD HH:mm')
                        };
                        return { checkedFilters };
                    }, this.handleApplyFilterButton);
                }
                break;
            }
            case "LOV": {
                this.setState(prevState => {
                    let checkedFilters = { ...prevState.checkedFilters }, index = checkedFilters[key].indexOf(e.target.dataset.value);
                    if (!~index) {
                        checkedFilters[key].push(e.target.dataset.value);
                    }
                    else {
                        checkedFilters[key].splice(index, 1);
                    }
                    return { checkedFilters };
                }, this.handleApplyFilterButton);
                break;
            }
            default: {
                this.setState(prevState => {
                    let checkedFilters = { ...prevState.checkedFilters };
                    checkedFilters[key] = e.target.dataset.value || (e.target.parentElement.dataset.value || (e.target.value || ""));
                    return { checkedFilters };
                }, this.handleApplyFilterButton);
            }
        }
    }

    selectItems = (field, items) => {
        this.setState(prevState => {
            let checkedFilters = { ...prevState.checkedFilters };
            checkedFilters[field.filterKey] = items;
            return { checkedFilters };
        }, this.handleApplyFilterButton);
    }

    getDateFromTo = key => {
        switch (key) {
            case "SM_DATE_TODAY_VALUE": {
                return {
                    from: moment().format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_YESTERDAY_VALUE": {
                return {
                    from: moment().subtract(1, "day").format('YYYY-MM-DD'),
                    to: moment().subtract(1, "day").format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LAST7DAYS_VALUE": {
                return {
                    from: moment().subtract(7, "days").format('YYYY-MM-DD'),
                    to: moment().subtract(1, "day").format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LASTWEEK_VALUE": {
                return {
                    from: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_THISMONTH_VALUE": {
                return {
                    from: moment().startOf('month').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LASTMONTH_VALUE": {
                return {
                    from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                };
            }
            default: {
                return {
                    from: "",
                    to: ""
                };
            }
        }
    }

    showTextDropdown = key => () => {
        this.setState({
            textDropdown: key
        }, () => setTimeout(() => document.addEventListener("click", this.hideTextDropdown), 0));
    }

    hideTextDropdown = () => {
        this.setState({
            textDropdown: ""
        }, () => setTimeout(() => document.removeEventListener("click", this.hideTextDropdown), 0));
    }

    showSelectDropdown = key => () => {
        this.setState({
            selectDropdown: key
        }, () => setTimeout(() => document.addEventListener("click", this.hideSelectDropdown), 0));
    }

    hideSelectDropdown = e => {
        let path = e.path || (e.composedPath && e.composedPath());
        if (!path.some(elem => elem.id == "genericFilterSelectDropdown")) {
            this.setState({
                selectDropdown: ""
            }, () => document.removeEventListener("click", this.hideSelectDropdown));
        }
    }

    renderExtraFooter = key => () => {
        return (
            <span className="date-picker-extra-footer">
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_TODAY_VALUE"} onChange={e => this.handleInput("SM_DATE_TODAY_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Today</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_YESTERDAY_VALUE"} onChange={e => this.handleInput("SM_DATE_YESTERDAY_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Yesterday</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LAST7DAYS_VALUE"} onChange={e => this.handleInput("SM_DATE_LAST7DAYS_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last 7 Days</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LASTWEEK_VALUE"} onChange={e => this.handleInput("SM_DATE_LASTWEEK_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last Week</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_THISMONTH_VALUE"} onChange={e => this.handleInput("SM_DATE_THISMONTH_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">This Month</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LASTMONTH_VALUE"} onChange={e => this.handleInput("SM_DATE_LASTMONTH_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last Month</span>
                </label>
            </span>
        );
    }

    applyFilterOnItemLevel = () => {
        let filter = {};
        let item = {};
        Object.keys(this.state.checkedFilters).map(key => {
            if (this.props.headersData.main.data.columnType[key] === "LOV" || this.props.headersData.main.data.columnType[key] === "SEARCH" || this.props.headersData.item.data.columnType[key] === "LOV" || this.props.headersData.item.data.columnType[key] === "SEARCH") {
                if (this.props.headersData.main.data[this.state.customHeadersType][key]) {
                    filter[key] = this.state.checkedFilters[key].join();
                }
                else if (this.props.headersData.item.data[this.state.customHeadersType][key]) {
                    item[key] = this.state.checkedFilters[key].join();
                }
            }
            else {
                if (this.props.headersData.main.data[this.state.customHeadersType][key]) {
                    filter[key] = this.state.checkedFilters[key]
                }
                else if (this.props.headersData.item.data[this.state.customHeadersType][key]) {
                    item[key] = this.state.checkedFilters[key]
                }
            }
        });
        this.props.close();
        this.props.applyFilter({ ...filter, item })();
    }

    handleCheckbox = () => {
        this.setState({
            checkboxSpace: !this.state.checkboxSpace
        })
    }

    openColumnDropdown = () => {
        this.setState({
            columnDropdown: !this.state.columnDropdown
        }, () => setTimeout(() => document.addEventListener('click', this.closeColumnDropdown), 0));
    }

    closeColumnDropdown = () => {
        this.setState({ columnDropdown: false }, () => {
            setTimeout(() => document.removeEventListener('click', this.closeColumnDropdown), 0);
        });
    }

    setColumnBy = columnBy => () => {
        this.setState({
            columnBy
        });
    }

    handleCustomSearchInput = e => {
        if (this.state.checkboxSpace) {
            let addItem = (e.type === "keydown" && (e.key === "Enter" || e.key === " ")) || e.target.value.includes(" ") || e.target.value.includes("\t") || e.target.value.includes(",");
            if (addItem) {
                this.addItemCode(e.target.value);
            }
            else {
                this.setState({
                    inputStr: e.target.value
                });
            }
        }
        else {
            let addItem = (e.type === "keydown" && (e.key === "Enter")) || e.target.value.includes("\t") || e.target.value.includes(",");
            if (addItem) {
                this.addItemCode(e.target.value);
            }
            else {
                this.setState({
                    inputStr: e.target.value
                });
            }
        }
    }

    addItemCode = inputStr => {
        let newItemCodes, newItemCodesFormatted = [];
        if (inputStr.includes(","))
            newItemCodes = inputStr.split(",");
        else
            if (this.state.checkboxSpace) {
                newItemCodes = inputStr.split(" ");
            }
            else {
                newItemCodes = inputStr.split(",");
            }
        newItemCodes.forEach(item => {
            let itemFormatted = item.trim()?.replace("\t", "");
            if (itemFormatted.length && this.state.itemCodes.indexOf(itemFormatted) == -1) {
                newItemCodesFormatted.push(itemFormatted);
            }
        });
        this.setState(prevState => ({
            itemCodes: [...prevState.itemCodes, ...newItemCodesFormatted],
            inputStr: ""
        }));
    }

    removeItemCode = item => () => {
        this.setState(prevState => {
            let itemCodes = [...prevState.itemCodes];
            itemCodes.splice(itemCodes.indexOf(item), 1);
            return {
                itemCodes
            };
        });
    }

    clearAll = () => {
        this.setState({
            itemCodes: []
        });
    }

    onSubmit = () => {
        this.props.submit(this.state.columnBy, this.state.itemCodes);
    }

    closeModal = () => {
        document.getElementById("reportModal")?.classList?.remove("from-center-in")
        document.getElementById("reportModal")?.classList?.add("from-center-out")
        setTimeout(() => {
            this.props.close();
        }, 250)
    }

    render() {
        return (
            <div className="modal">
                <div className="backdrop" onClick={this.closeModal}></div>
                <div id='reportModal' className="modal-content custom-search-modal from-center-in">
                    <div className="csm-head">
                        <div className="csmh-left">
                            {this.props.actionBarConfig.filters.isItemRequire ?
                                <h3>Custom Search & Item Filters</h3> :
                                <h3>Custom Search</h3>}
                        </div>
                        <div className="csmh-right">
                            <button type="button" className='generic-default-btn' onClick={this.closeModal}>Close</button>
                            {this.state.activeTab == "customFilters" && <button type="button" className={`csmhr-apply ${this.state.itemCodes.length == 0 || this.state.columnBy == '' ? "opacity04" : ""}`} disabled={this.state.itemCodes.length == 0 || this.state.columnBy == ''} onClick={this.onSubmit}>Apply Search</button>}
                            {this.state.activeTab == "itemFilters" && <button type="button" className={this.state.applyFilterEnabled ? "csmhr-apply" : "csmhr-apply opacity04"} disabled={!this.state.applyFilterEnabled} onClick={this.applyFilterOnItemLevel}>Apply Item Filter</button>}
                        </div>
                    </div>
                    {this.props.actionBarConfig.filters.isItemRequire ? <div class="subscription-tab st-new-tab">
                        <ul class="nav nav-tabs subscription-tab-list">
                            <li class={this.state.activeTab == "itemFilters" ? "nav-item active" : "nav-item"}><a class="nav-link st-btn" onClick={() => this.changeTab("itemFilters")}>Item Filters</a></li>
                            <li class={this.state.activeTab == "customFilters" ? "nav-item active" : "nav-item"}><a class="nav-link st-btn" onClick={() => this.changeTab("customFilters")}>Custom Search</a></li>
                            <li className='stl-clear-all'>
                                {this.state.activeTab == "itemFilters" ?
                                    <a class="nav-link st-btn" onClick={() => this.setState({ checkedFilters: {} }, this.handleApplyFilterButton)}>Clear all Filters</a> :
                                    <a class="nav-link st-btn" onClick={this.clearAll}>Clear all</a>}
                            </li>
                        </ul>
                    </div> : null}
                    {this.state.activeTab == "itemFilters" &&
                        <div className='item-filters'>
                            <div className="asnfb-head">
                                <span className="asnfb-left">Header</span><span className="asnfb-right">Value</span>
                            </div>
                            {this.props.actionBarConfig.filters.isItemRequire ?
                                <div className="asnfb-body">
                                    {this.state.customHeaders.length === 0 ?
                                        <span>No filters available!</span> :
                                        this.state.customHeaders.map(key => {
                                            if (this.props.headersData?.[this.state.currentTab]?.data?.[this.state.customHeadersType]?.[key]?.toLowerCase()?.includes(this.state.search.toLowerCase()) && this.props.headersData?.[this.state.currentTab]?.data?.columnType?.[key] !== "NOSORT") {
                                                return (
                                                    <div key={key} className="asnfb-item">
                                                        <span className="asnfbb-left">
                                                            <span className="asn-check">
                                                                <div className="checkSeperate checkBoxBorder">
                                                                    <label className="checkBoxLabel0">
                                                                        <input type="checkBox" onChange={() => this.checkFilter(key)} checked={this.state.checkedFilters[key] !== undefined} />
                                                                        {this.props.headersData[this.state.currentTab].data[this.state.customHeadersType][key]}
                                                                        <span className="checkmark1"></span>
                                                                    </label>
                                                                </div>
                                                            </span>
                                                        </span>
                                                        {this.state.checkedFilters[key] === undefined ?
                                                            <span className="asnfbb-right not-checked" onClick={() => this.checkFilter(key)}></span> :
                                                            this.props.headersData[this.state.currentTab].data.columnType[key] === "NUMBER" ?
                                                                <span className="asnfbb-right">
                                                                    <input
                                                                        type="number"
                                                                        data-type="from"
                                                                        id={key + "From"}
                                                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].from}
                                                                        placeholder="Enter From Value"
                                                                        onChange={(e) => this.handleInput(e, key)}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        data-type="to"
                                                                        className="asnfbbr-poquantity"
                                                                        id={key + "To"}
                                                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].to}
                                                                        placeholder="Enter To Value"
                                                                        onChange={(e) => this.handleInput(e, key)}
                                                                    />
                                                                </span> :

                                                                this.props.headersData[this.state.currentTab].data.columnType[key] === "DATE" ?
                                                                    <span className="asnfbb-right">
                                                                        <RangePicker
                                                                            format="YYYY-MM-DD"
                                                                            id={key}
                                                                            value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                                                            onChange={(e) => this.handleInput(e, key)}
                                                                            renderExtraFooter={this.renderExtraFooter(key)}
                                                                            onOpenChange={open => open ? this.setState({ dateDropdown: key }) : this.setState({ dateDropdown: "" })}
                                                                            open={this.state.dateDropdown === key}
                                                                        />
                                                                    </span> :

                                                                    this.props.headersData[this.state.currentTab].data.columnType[key] === "TIME" ?
                                                                        <span className="asnfbb-right">
                                                                            <RangePicker
                                                                                format="YYYY-MM-DD HH:mm"
                                                                                showTime={{ format: "HH:mm", defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')] }}
                                                                                id={key}
                                                                                value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                                                                onChange={(e) => this.handleInput(e, key)}
                                                                            />
                                                                        </span> :

                                                                        this.props.headersData[this.state.currentTab].data.columnType[key] === "LOV" ?
                                                                            <span className="asnfbb-right select" onClick={this.showSelectDropdown(key)}>
                                                                                <input
                                                                                    type="text"
                                                                                    id={key}
                                                                                    placeholder="Select Options"
                                                                                    value={this.state.checkedFilters[key].length > 1 ? `${this.state.checkedFilters[key][0]} + ${this.state.checkedFilters[key].length - 1}` : this.state.checkedFilters[key].length === 1 ? this.state.checkedFilters[key][0] : ""}
                                                                                    readOnly
                                                                                />
                                                                                <button className='asnfbbr-input-arrow' onClick={this.showTextDropdown(key)}>
                                                                                    <IconsPath.DownArrowNew />
                                                                                </button>
                                                                                {this.state.checkedFilters[key].length > 1 && <span className="asnfbbr-select-count"> + {this.state.checkedFilters[key].length - 1}</span>}

                                                                                {this.state.selectDropdown &&
                                                                                    <div id="genericFilterSelectDropdown" className="dropdown-menu-city1 gen-width-auto zi999">
                                                                                        <ul className="dropdown-menu-city-item">
                                                                                            {this.state.filterItems["Lov Headers"][key] && this.state.filterItems["Lov Headers"][key].map(item =>
                                                                                                <li key={item}>
                                                                                                    <label className="checkBoxLabel0">
                                                                                                        <input
                                                                                                            type="checkBox"
                                                                                                            checked={this.state.checkedFilters[key].indexOf(item) !== -1}
                                                                                                            onChange={(e) => this.handleInput(e, key)}
                                                                                                            data-value={item}
                                                                                                        />
                                                                                                        <span className="checkmark1"></span>
                                                                                                        {item}
                                                                                                    </label>
                                                                                                </li>
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>}
                                                                            </span> :

                                                                            this.props.headersData[this.state.currentTab].data.columnType[key] === "SEARCH" ?
                                                                                <span className="asnfbb-right search">
                                                                                    <GenericSearchModalNew
                                                                                        field={{
                                                                                            filterKey: key,
                                                                                            entity: this.props.headersData[this.state.currentTab].data.tableName[key],
                                                                                            key: this.props.headersData[this.state.currentTab].data.tableColumnMap[key],
                                                                                            value: this.props.headersData[this.state.currentTab].data[this.state.customHeadersType][key]
                                                                                        }}
                                                                                        emptyAttributes={true}
                                                                                        showLabel={false}
                                                                                        selectedItems={{ [this.props.headersData[this.state.currentTab].data.tableColumnMap[key]]: this.state.checkedFilters[key] }}
                                                                                        selectItems={this.selectItems}
                                                                                        {...this.props}
                                                                                    />
                                                                                </span> :

                                                                                <span className="asnfbb-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        id={key}
                                                                                        value={this.state.checkedFilters[key] === undefined ? "" : this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" ? "is Empty" : this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" ? "is Not Empty" : this.state.checkedFilters[key]}
                                                                                        placeholder="Enter Value"
                                                                                        onChange={(e) => this.handleInput(e, key)}
                                                                                        disabled={["SM_TEXT_EMPTY_VALUE", "SM_TEXT_NOT_EMPTY_VALUE"].includes(this.state.checkedFilters[key])}
                                                                                    />
                                                                                    <button className='asnfbbr-input-arrow' onClick={this.showTextDropdown(key)}>
                                                                                        <IconsPath.DownArrowNew />
                                                                                    </button>
                                                                                    {this.state.textDropdown == key &&
                                                                                        <span className="asnfbbr-inner">
                                                                                            <span className="asnfbbr-all-vendor">
                                                                                                <li data-value="" onClick={(e) => this.handleInput(e, key)}>
                                                                                                    <button>Custom</button>
                                                                                                    <span className="asnfbbrav-code">Type custom value</span>
                                                                                                </li>
                                                                                                <li data-value="SM_TEXT_EMPTY_VALUE" onClick={(e) => this.handleInput(e, key)}>
                                                                                                    <button>is Empty</button>
                                                                                                </li>
                                                                                                <li data-value="SM_TEXT_NOT_EMPTY_VALUE" onClick={(e) => this.handleInput(e, key)}>
                                                                                                    <button>is Not Empty</button>
                                                                                                </li>
                                                                                            </span>
                                                                                        </span>}
                                                                                </span>}
                                                    </div>
                                                )
                                            }
                                        })}
                                </div> : null}
                        </div>}
                    {this.state.activeTab == "customFilters" && <div className="csm-body">
                        <div className="csmb-top">
                            <div className="csmbt-left">
                                <div className="gen-custom-select">
                                    <button type="button" onClick={this.openColumnDropdown}>
                                        {this.props.columnList ? this.state.columnBy ? this.props.columnList[this.state.headerType][this.state.columnBy] : "Select Custom Search" : this.columnList[this.props.page][this.state.columnBy]}
                                        <svg width="8" height="4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 4 0 0h8L4 4z" fill="#21314B" />
                                        </svg>
                                    </button>
                                    {this.state.columnDropdown &&
                                        <div className="gcs-dropdown">
                                            <ul>
                                                {/* {Object.keys(this.props.columnList || this.columnList[this.props.page]).map(key =>
                                                <li key={key} onClick={this.setColumnBy(key)}>{this.props.columnList ? this.props.columnList[key] : this.columnList[this.props.page][key]}</li>
                                            )} */}
                                                {this.props.columnList ? this.props.columnList[this.state.headerType] === undefined || Object.keys(this.props.columnList[this.state.headerType]).length === 0 ?
                                                    <li>No filters available!</li> :
                                                    Object.keys(this.props.columnList[this.state.headerType]).map((key) => {
                                                        return (
                                                            <React.Fragment>
                                                                {this.props.columnList.columnType[key] === "LOV" || this.props.columnList.columnType[key] === "SEARCH" ?
                                                                    <li key={key} onClick={this.setColumnBy(key)}>
                                                                        {this.props.columnList[this.state.headerType][key]}
                                                                    </li> : null}
                                                            </React.Fragment>
                                                        )
                                                    }) :
                                                    Object.keys(this.columnList[this.props.page]).map(key =>
                                                        <li key={key} onClick={this.setColumnBy(key)}>{this.columnList[this.props.page][key]}</li>
                                                    )}
                                            </ul>
                                        </div>}
                                </div>
                                <div className="csmbtl-col">
                                    <p>Use Spacebar for Enter/Return</p>
                                    <label className="checkBoxLabel0">
                                        <input type="checkBox" checked={this.state.checkboxSpace} onChange={() => this.handleCheckbox()} />
                                        <span className="checkmark1"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="csmbt-right">
                                {this.props.actionBarConfig.filters.isItemRequire ? null : <button type="button" onClick={this.clearAll}>Clear All</button>}
                            </div>
                        </div>
                        <div className="csmb-bottom">
                            <div className="csmbb-type-area">
                                <div className="csmbbta-left">
                                    <input
                                        id="inputStr"
                                        value={this.state.inputStr}
                                        onChange={this.handleCustomSearchInput}
                                        onKeyDown={this.handleCustomSearchInput}
                                        autoComplete="off"
                                        type="text"
                                        disabled={this.state.columnBy == ""}
                                        placeholder={`Type ${this.state.columnBy == '' ? '' : this.props.columnList ? this.props.columnList[this.state.headerType][this.state.columnBy] : ""} or paste comma separated values...`}
                                    />
                                </div>
                                <div className="csmbbta-right">
                                    <p>Add multiple values by pressing {this.state.checkboxSpace && <React.Fragment><i>Space Bar</i> or </React.Fragment>}<i>Return/Enter</i> (&#9166;)</p>
                                </div>
                            </div>
                            <div className="csmbb-tags">
                                <h3>Total {this.props.columnList ? this.props.columnList[this.state.headerType][this.state.columnBy] : this.columnList[this.props.page][this.state.columnBy]}{this.state.columnBy ? 's' : null}: {this.state.itemCodes.length}</h3>
                                <div className="csmbbt-inner">
                                    {this.state.itemCodes.map(item =>
                                        <span className="csmbbti-tag">
                                            <span className="csmbbtit-text">{item}</span>
                                            <span className="csmbbtit-close" onClick={this.removeItemCode(item)}>&#x2715;</span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}