/* eslint-disable */
import React, { Component } from 'react'
import { changeKey } from '../../../helper/helperTableFunctions';
import Pagination from '../../../genericComponents/oldGenericComponents/tableHelperComponents/Pagination'
import '../../../styles/stylesheets/DigiVend/OrderRequest.scss'
import Icons from '../../../assets/icons';

export default class ImageDetailsTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prev: this.props.data.prePage,
            next: this.props.data.currPage + 1,
            current: this.props.data.currPage || 1,
            maxPage: this.props.data.maxPage || 1,
            jumpPage: this.props.data.currPage || 1,
            data: this.props.data.data,
            currentFilterPage: 0,
            maxFilterPage: 0,
            filters: {
                "icode": [],
                "size1": [],
                "color": [],
                "style_id": []
            },
            searchFilter: {
                "icode": "",
                "size1": "",
                "color": "",
                "style_id": "",
            },
            selectedFilters: {
                "icode": [],
                "size1": [],
                "color": [],
                "style_id": []
            },
            filterModal: {
                "icode": false,
                "size1": false,
                "color": false,
                "style_id": false
            },
            filterSearchType: {
                "icode": "Contains",
                "size1": 'Contains',
                "color": 'Contains',
                "style_id": 'Contains'
            },
        }
        this.payload = {
            "orderNumber": "",
            "shipmentAdviceCode": "",
            "setBarCode": "",
            "itemCode": "",
            "logisticNumber": "",
            "getBy": this.props.imageTrackingNumber,
            "search": "",
            "pageSize": 10,
            "pageNo": 1,
            "filter": {}
        }
        this.itemFilterMap = [
            {
                "value": "Icode",
                "key": "icode",
            },
            {
                "value": "Size",
                "key": "size1",
            },
            {
                "value": "Color",
                "key": "color",
            },
            {
                "value": "Design Number",
                "key": "style_id",
            },
        ];
        this.filterPayload = {
            "entity": "item",
            "key": "",
            "code": "",
            "search": "",
            "searchBy": "CONTAINS",
            "pageNo": "1",
            "moduleName": "",
            "dependentData": {
                "entity": "po_details",
                "dependentColumn": "icode",
                "coulmn": "item_code",
                "searchKey": "po_id",
                "searchValue": "",
            },
            "other_data": {}
        }
    }
    componentDidMount() {
        if (this.props.generalStore.getImageTrackingData.isSuccess && this.props.generalStore.getImageTrackingData.data.resource) {
            if (this.props.generalStore.getImageTrackingData.data.resource.data) {
                let data = this.props.generalStore.getImageTrackingData.data.resource.data;
                this.filterPayload["dependentData"]["searchValue"] = String(data[0].po_id)
            }
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.generalStore.getImageTrackingData.isSuccess && nextProps.generalStore.getImageTrackingData.data.resource) {
            let data;
            if (nextProps.generalStore.getImageTrackingData.data.resource.data) {
                data = nextProps.generalStore.getImageTrackingData.data.resource.data;
                data.map(item => {
                    item["imgIndex"] = 1;
                })
            }
            return {
                data: data,
                current: nextProps.generalStore.getImageTrackingData.data.resource.currPage || 1,
                maxPage: nextProps.generalStore.getImageTrackingData.data.resource.maxPage || 1,
                jumpPage: nextProps.generalStore.getImageTrackingData.data.resource.currPage || 1,
                prev: nextProps.generalStore.getImageTrackingData.data.resource.prePage,
                next: nextProps.generalStore.getImageTrackingData.data.resource.currPage + 1
            }
        }
        if (nextProps.generalStore?.getSearchFiltersData?.isLoading) {
            return { loading: true }
        }
        if (nextProps.generalStore?.getSearchFiltersData?.isError) {
            let filterModal = prevState.filterModal;
            Object.keys(filterModal).map(key => { filterModal[key] = false })
            return { loading: false, filterModal: filterModal }
        }
        if (nextProps.generalStore?.getSearchFiltersData?.isSuccess && nextProps.generalStore?.getSearchFiltersData?.data.resource) {
            // nextProps.getArsGenericFiltersClear();
            let filter = false;
            let filters = prevState.filters;
            Object.keys(prevState.filterModal).map(key => prevState.filterModal[key] ? filter = key : null)
            if (filter) {
                filters[filter] = nextProps.generalStore?.getSearchFiltersData?.data.resource.searchResult || []
                return {
                    currentFilterPage: nextProps.generalStore?.getSearchFiltersData?.data.resource.currPage,
                    maxFilterPage: nextProps.generalStore?.getSearchFiltersData?.data.resource.maxPage,
                    filters: filters,
                    loading: false
                }
            }
            return {
                loading: false
            }
        }

    }
    page = (e) => {
        this.payload["getBy"] = changeKey(this.props.imageTrackingNumber)
        if (this.payload["filter"] && Object.keys(this.payload["filter"]).some(key => this.payload["filter"][key] != "")) {
            this.paylaod["type"] = 2;
        }
        else {
            this.payload["type"] = 1;
            this.payload["filter"] = {};
        }
        if (e.target.id == "prev") {
            if (this.state.current == "" || this.state.current == undefined || this.state.current == 1) {
            } else {
                if (this.state.current != 0) {
                    this.payload[changeKey(this.props.imageTrackingNumber)] = this.props.orderNumber
                    this.payload["pageNo"] = this.state.current - 1;
                    this.props.handleApiChange("getImageTrackingRequest", this.payload)
                }
            }
        } else if (e.target.id == "next") {
            if (this.state.current != this.state.maxPage) {
                this.payload[changeKey(this.props.imageTrackingNumber)] = this.props.orderNumber
                this.payload["pageNo"] = this.state.current + 1;
                this.props.handleApiChange("getImageTrackingRequest", this.payload)
            }
        }
        else if (e.target.id == "first") {
            if (this.state.current == 1 || this.state.current == "" || this.state.current == undefined) {

            } else {
                if (this.state.current >= 1) {
                    this.payload[changeKey(this.props.imageTrackingNumber)] = this.props.orderNumber
                    this.payload["pageNo"] = 1;
                    this.props.handleApiChange("getImageTrackingRequest", this.payload)
                }
            }
        }
        else if (e.target.id == "last") {
            if (this.state.maxPage == 1 || this.state.maxPage == "" || this.state.maxPage == undefined) {

            } else {
                if (this.state.current < this.state.maxPage) {
                    this.payload[changeKey(this.props.imageTrackingNumber)] = this.props.orderNumber
                    this.payload["pageNo"] = this.state.maxPage;
                    this.props.handleApiChange("getImageTrackingRequest", this.payload)
                }
            }
        }
    }
    filterPage = (dir, filter) => {
        if (dir == "prev" && this.state.currentFilterPage !== 1) {
            this.filterPayload["key"] = filter;
            this.filterPayload["pageNo"] -= 1;
            this.props.handleApiChange("getSearchFiltersRequest", this.filterPayload)
        }
        else if (dir == "next" && this.state.currentFilterPage !== this.state.maxFilterPage) {
            this.filterPayload["key"] = filter;
            this.filterPayload["pageNo"] += 1;
            this.props.handleApiChange("getSearchFiltersRequest", this.filterPayload)
        }
    }

    changeSearchType = (e) => {
        let filterSearchType = { ...this.state.filterSearchType }
        filterSearchType[e.target.id] = e.target.value
        this.setState({ filterSearchType })
    }

    getAnyPage = _ => {
        if (_.target.validity.valid) {
            this.setState({ jumpPage: _.target.value })
            if (_.key == "Enter" && _.target.value != this.state.current) {
                ``
                if (_.target.value != "") {
                    if (this.payload["filter"] && Object.keys(this.payload["filter"]).some(key => this.payload["filter"][key] != "")) {
                        this.paylaod["type"] = 2;
                    }
                    else {
                        this.payload["type"] = 1;
                        this.payload["filter"] = {};
                    }
                    this.payload[this.props.imageTrackingNumber] = this.props.orderNumber
                    this.payload["pageNo"] = _.target.value;
                    this.props.handleApiChange("getImageTrackingRequest", this.payload)
                }
            }
        }
    }
    handleFilterModal = (filter) => {
        let filterModal = { ...this.state.filterModal }
        this.filterPayload["key"] = filter;
        this.filterPayload["pageNo"] = 1;
        this.filterPayload["search"] = this.state.searchFilter[filter];
        this.filterPayload["searchBy"] = this.state.filterSearchType[filter];
        this.props.handleApiChange("getSearchFiltersRequest", this.filterPayload)
        Object.keys(filterModal).map(key => {
            key === filter ? filterModal[filter] = true : filterModal[key] = false;
        })
        this.setState({ filterModal })
    }
    closeFilterModal = () => {
        let filterModal = { ...this.state.filterModal }
        Object.keys(filterModal).map(key => {
            filterModal[key] = false
        })
        this.setState({ filterModal })
    }
    handleItemSelect = (filter, value) => {
        let selectedFilters = { ...this.state.selectedFilters }
        if (selectedFilters[filter].some(fi => fi === value)) {
            selectedFilters[filter] = selectedFilters[filter].filter(item => item !== value)
        } else {
            selectedFilters[filter].push(value);
        }
        this.setState({ selectedFilters: selectedFilters })
    }
    applyFilters = () => {
        let key = changeKey(this.props.imageTrackingNumber)
        this.payload["pageNo"] = 1;
        this.payload["type"] = 1;
        this.payload["filter"] = {}
        this.payload["getBy"] = key
        this.payload[key] = this.props.orderNumber
        this.closeFilterModal();
        if (this.state.selectedFilters && Object.keys(this.state.selectedFilters).some(key => this.state.selectedFilters[key] != "")) {
            this.state.selectedFilters && Object.keys(this.state.selectedFilters).map(key => {
                this.payload["filter"][key] = this.state.selectedFilters[key].join(',')
            })
            this.payload["type"] = 2;
        }
        this.props.handleApiChange("getImageTrackingRequest", this.payload)
    }
    handleImageSlider = (dir, item) => {
        let data = [...this.state.data];
        if (dir === 'next' && item['imgIndex'] < item['image_path'].split(',').length) {
            data.map(k => { k.item_code === item.item_code ? k['imgIndex'] += 1 : null })
        }
        if (dir === 'prev' && item['imgIndex'] > 1) {
            data.map(k => { k.item_code === item.item_code ? k['imgIndex'] -= 1 : null })
        }
        this.setState({ data })
    }
    previewImage = (index, item) => {
        let data = [...this.state.data];
        data.map(k => { k.item_code === item.item_code ? k['imgIndex'] = index : null })
        this.setState({ data })
    }
    clearAllFilters = () => {
        this.closeFilterModal();
        this.payload["pageNo"] = 1;
        this.payload["type"] = 1;
        this.payload[this.props.imageTrackingNumber] = this.props.orderNumber
        if (this.payload["filter"] && Object.keys(this.payload["filter"]).some(key => this.payload["filter"][key] != "")) {
            this.payload["filter"] = {}
            this.props.handleApiChange("getImageTrackingRequest", this.payload)
        }
        let selectedFilters = { ...this.state.selectedFilters }
        selectedFilters && Object.keys(selectedFilters).map(key => {
            selectedFilters[key] = []
        })
        let searchFilter = { ...this.state.searchFilter };
        searchFilter && Object.keys(searchFilter).map(key => searchFilter[key] = "")
        this.setState({ selectedFilters, searchFilter })
    }
    handleSearchFilter = (e) => {
        let searchFilter = { ...this.state.searchFilter }
        searchFilter[e.target.name] = e.target.value;
        this.setState({ searchFilter })
    }
    applyFilterSearch = (e) => {
        if (e.key === "Enter") {
            this.handleFilterModal(e.target.name)
        }
    }
    render() {
        return (
            <div className="modal">
                <div className="backdrop modal-backdrop-new" onClick={() => this.props.close()}></div>
                <div className="modal-content image-detail-tracking-modal">
                    <div className="idtm-head">
                        <div className='idtmh-left'>
                            <div className="idtm-col">
                                <p>Order Number</p>
                                <h3>{this.props.orderNumber}</h3>
                            </div>
                            <div className="idtm-col">
                                <p>Vendor Name</p>
                                <h3>{this.props.vendor}</h3>
                            </div>
                        </div>
                        <div className='idtmh-right'>
                            <button type="button" onClick={() => this.props.close()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 12.047 12.047">
                                    <path d="M7.127 6.023l4.691-4.691a.78.78 0 1 0-1.1-1.1L6.023 4.92 1.333.229a.78.78 0 1 0-1.1 1.1L4.92 6.023.229 10.714a.78.78 0 1 0 1.1 1.1l4.694-4.687 4.691 4.691a.78.78 0 1 0 1.1-1.1zm0 0" data-name="close (1)" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='idtm-body'>
                        <div className="subscription-tab procurement-setting-tab">
                            <ul className="subscription-tab-list" role="tablist">
                                {/* <li className="nav-item">
                                    <a className="nav-link st-btn p-l-0" href="#orderTracking" role="tab" data-toggle="tab">Order Tracking</a>
                                </li> */}
                                <li className="nav-item active">
                                    <a className="st-btn" href="#poDetails" role="tab" data-toggle="tab">PO Details</a>
                                </li>
                                <li className='pst-button'>
                                    <div className="pstb-inner">
                                        <button onClick={this.clearAllFilters} className="generic-default-btn" type="button">Clear All</button>
                                        <button onClick={this.applyFilters} type="button" className="generic-approve-btn">Apply Filters</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade" id="orderTracking" role="tabpanel"></div>
                            <div className="tab-pane fade in active" id="poDetails" role="tabpanel">
                                <div className='idtmb-order-tracking'>
                                    {/* <div className='idtmb-top-section'>
                                        <div className="idtmbts-left">
                                            <div className="idtmbts-search">
                                                <span className="idtmbtss-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 18.51 18.51">
                                                        <path fill="none" d="M0 0h18.51v18.51H0z"/>
                                                        <path fill='#71819b' d="M8.941 2A6.941 6.941 0 1 1 2 8.941 6.944 6.944 0 0 1 8.941 2zm0 12.34a5.4 5.4 0 1 0-5.4-5.4 5.4 5.4 0 0 0 5.4 5.4zm6.544.055l2.182 2.181-1.091 1.091-2.181-2.182 1.091-1.091z" transform="translate(-.458 -.458)"/>
                                                    </svg>
                                                </span>
                                                <input type="search" className="idtmbtss-input" value={this.state.search} onKeyDown={(e)=>this.handleSearch(e)} onChange={(e)=>this.handleSearch(e)} placeholder='Search Icode, design, size, color...' />
                                            </div>
                                        </div>
                                        <div className="idtmbts-right">
                                            <button type="button" className='idtmbts-add-filter' onClick={this.showFilters}>Add Filter</button>
                                            <button type="button" className="idtmbts-clear" onClick={this.showFilters}>Clear Filters</button>
                                        </div>
                                    </div> */}
                                    <div className='idtmb-mid-section'>
                                        {this.itemFilterMap.map(item =>
                                            <div className="sdfl-input-col">
                                                <div className="generic-smart-input">
                                                    {this.state.selectedFilters[item.key].length === 0 ? null : <span className="gsi-selected">{`${this.state.selectedFilters[item.key].length}`}</span>}
                                                    <input placeholder={item.value} autoComplete="off" name={item.key} onKeyPress={(e) => this.applyFilterSearch(e)} value={this.state.searchFilter[item.key]} onChange={(e) => this.handleSearchFilter(e)} />
                                                    {/* <span className="gsis-clear">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.119" height="13.119" viewBox="0 0 13.119 13.119">
                                                        <path fill="none" d="M0 0h13.119v13.119H0z"/>
                                                        <path fill="#627da8" d="M9.115 8.342l2.706-2.706.773.773-2.706 2.706 2.706 2.706-.773.773-2.706-2.706-2.706 2.706-.773-.773 2.706-2.706-2.706-2.706.773-.773z" transform="translate(-2.555 -2.555)"/>
                                                    </svg>
                                                </span> */}
                                                    <span className="gsi-search">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18.51 18.51">
                                                            <path fill="none" d="M0 0h18.51v18.51H0z" />
                                                            <path fill='#67768E' d="M8.941 2A6.941 6.941 0 1 1 2 8.941 6.944 6.944 0 0 1 8.941 2zm0 12.34a5.4 5.4 0 1 0-5.4-5.4 5.4 5.4 0 0 0 5.4 5.4zm6.544.055l2.182 2.181-1.091 1.091-2.181-2.182 1.091-1.091z" transform="translate(-.458 -.458)" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                {this.state.filterModal[item.key] &&
                                                    <React.Fragment>
                                                        <div className='backdrop-transparent' onClick={() => this.closeFilterModal()}></div>
                                                        <div className="dropdown-menu-city1 gen-width-auto zi999">

                                                            <ul className="dropdown-menu-city-item">
                                                                <div className="gwa-advance-filter">
                                                                    <div className="gwaaf-inner">
                                                                        {/* <button type="button" class="gwaaf-btn"><span class="">Search By: </span>{this.state.filterSearchType[item.key]}</button> */}
                                                                        {/* <span className='gwaaf-contain-text'>Select Type :</span> */}
                                                                        <select id={item.key} value={this.state.filterSearchType[item.key]} onChange={(e) => this.changeSearchType(e)}>
                                                                            <option value="" disabled>Select Type</option>
                                                                            <option value="CONTAINS">Contains</option>
                                                                            <option value="STARTS_WITH">Start With</option>
                                                                            <option value="EXACT_MATCH">Exactly Matches</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {this.state.selectedFilters[item.key].length === 0 ? null :
                                                                    <div className='dmci-checked'>
                                                                        {this.state.selectedFilters[item.key] && this.state.selectedFilters[item.key].map(filter => (
                                                                            <li>
                                                                                <label className="checkBoxLabel0">
                                                                                    <input name={filter} type="checkbox" checked={true} onChange={(e) => this.handleItemSelect(item.key, filter)} />
                                                                                    <span className="checkmark1"></span>
                                                                                    {filter}
                                                                                </label>
                                                                            </li>
                                                                        ))}
                                                                    </div>}
                                                                {!this.state.loading ?
                                                                    <React.Fragment>
                                                                        {this.state.filters[item.key] ? this.state.filters[item.key].map(filter => (
                                                                            this.state.selectedFilters[item.key] ?
                                                                                !this.state.selectedFilters[item.key].some(f => f === filter) &&
                                                                                <li>
                                                                                    <label className="checkBoxLabel0">
                                                                                        <input name={filter} type="checkbox" checked={false} onChange={(e) => this.handleItemSelect(item.key, filter)} />
                                                                                        <span className="checkmark1"></span>
                                                                                        {filter}
                                                                                    </label>
                                                                                </li>
                                                                                :
                                                                                <li>
                                                                                    <label className="checkBoxLabel0">
                                                                                        <input name={filter} type="checkbox" checked={this.state.selectedFilters[item.key] ? this.state.selectedFilters[item.key].some(fi => fi === filter) : false} onChange={(e) => this.handleItemSelect(item.key, filter)} />
                                                                                        <span className="checkmark1"></span>
                                                                                        {filter}
                                                                                    </label>
                                                                                </li>
                                                                        )) : <li>No Data Found</li>}
                                                                    </React.Fragment>
                                                                    : <li>Loading...</li>}
                                                            </ul>
                                                            <div className="gen-dropdown-pagination">
                                                                {this.state.loading ?
                                                                    <span className="pagination-loader"></span> :
                                                                    <React.Fragment>
                                                                        <div className="page-close">
                                                                            <button className='gsmihs-clear' type="button" onClick={this.closeFilterModal} >Close</button>
                                                                        </div>
                                                                        <div className="page-next-prew-btn">
                                                                            <button className="pnpb-prev" type="button" onClick={() => this.filterPage("prev", item.key)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                                                                    <path id="chevron_1_" fill="#21314b" d="M16.318 8.8a.752.752 0 0 1-1.063 0L8.269 1.815 1.283 8.8A.752.752 0 0 1 .22 7.738L7.738.22A.752.752 0 0 1 8.8.22l7.517 7.518a.752.752 0 0 1 .001 1.062z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)" />
                                                                                </svg>
                                                                            </button>
                                                                            <button className="pnpb-no" type="button" disabled>{this.state.currentFilterPage}/{this.state.maxFilterPage}</button>
                                                                            <button className="pnpb-next" type="button" onClick={() => this.filterPage("next", item.key)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                                                                    <path id="chevron_1_" fill="#21314b" d="M16.318.22a.752.752 0 0 0-1.063 0L8.269 7.206 1.283.22A.752.752 0 0 0 .22 1.283L7.738 8.8a.752.752 0 0 0 1.062 0l7.517-7.518A.752.752 0 0 0 16.318.22z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </React.Fragment>}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>}
                                            </div>)}
                                    </div>
                                    {this.state.data ?
                                        this.state.data.map(item => (
                                            <div className='idtmb-main-section'>
                                                <div className='idtmbms-items'>
                                                    <div className="idtmbmsi-left">
                                                        { item.image_path?.split(',')?.[item.imgIndex - 1] ? <img src={item.image_path ? item.image_path.split(',')[item.imgIndex - 1] : require('../../../assets/icons/NoImgFound.svg')} /> : <Icons.NoImgFound/>}
                                                        <button type='button' className='idtmbmsil-left' onClick={() => item.imgIndex == 1 ? null : this.handleImageSlider("prev", item)}>
                                                            <svg width="27" height="52" viewBox="0 0 27 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g filter="url(#filter0_b_1656_3884)">
                                                                    <path d="M0 0H23C25.2091 0 27 1.79086 27 4V48C27 50.2091 25.2091 52 23 52H0V0Z" fill="white" fill-opacity="0.3" />
                                                                </g>
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.7537 29.8612C16.0821 30.1899 16.0821 30.7224 15.7537 31.051C15.425 31.3795 14.8925 31.3795 14.5639 31.051L9.75654 26.2437C9.42808 25.9151 9.42808 25.3826 9.75654 25.0539L14.5639 20.2466C14.8925 19.9181 15.425 19.9181 15.7537 20.2466C16.0821 20.5752 16.0821 21.1077 15.7537 21.4363L11.5389 25.6511L15.7537 29.8612Z" fill="white" />
                                                                <defs>
                                                                    <filter id="filter0_b_1656_3884" x="-13" y="-13" width="53" height="78" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                        <feGaussianBlur in="BackgroundImage" stdDeviation="6.5" />
                                                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1656_3884" />
                                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1656_3884" result="shape" />
                                                                    </filter>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                        <button type='button' className='idtmbmsil-right' onClick={() => item.image_path.split(',')[item.imgIndex] && this.handleImageSlider("next", item)}>
                                                            <svg width="27" height="52" viewBox="0 0 27 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g filter="url(#filter0_b_1656_3880)">
                                                                    <path d="M27 0H4C1.79086 0 0 1.79086 0 4V48C0 50.2091 1.79086 52 4 52H27V0Z" fill="white" fill-opacity="0.3" />
                                                                </g>
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M11.2463 29.8612C10.9179 30.1899 10.9179 30.7224 11.2463 31.051C11.575 31.3795 12.1075 31.3795 12.4361 31.051L17.2435 26.2437C17.5719 25.9151 17.5719 25.3826 17.2435 25.0539L12.4361 20.2466C12.1075 19.9181 11.575 19.9181 11.2463 20.2466C10.9179 20.5752 10.9179 21.1077 11.2463 21.4363L15.4611 25.6511L11.2463 29.8612Z" fill="white" />
                                                                <defs>
                                                                    <filter id="filter0_b_1656_3880" x="-13" y="-13" width="53" height="78" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                        <feGaussianBlur in="BackgroundImage" stdDeviation="6.5" />
                                                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1656_3880" />
                                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1656_3880" result="shape" />
                                                                    </filter>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                        <div className='idtmbmsil-pagination'>
                                                            {[1, 2, 3, 4].map(i => (
                                                                item.image_path?.split(',')?.[i - 1] ?
                                                                    <button type='button' onClick={() => { item.image_path && item.image_path.split(',')[i - 1] && this.previewImage(i, item) }} className={item["imgIndex"] === i ? 'idtmbmsil-active' : ""}>
                                                                        <img src={item.image_path?.split(',')?.[i - 1] ? item.image_path.split(',')[i - 1] : require('../../../assets/icons/NoImgFound.svg')} />
                                                                    </button> :
                                                                    <Icons.NoImgFound />
                                                            ))}
                                                            {/* <button type='button' onClick={()=>{this.previewImage(1,item)}} className={item["imgIndex"]===1 ? 'idtmbmsil-active':""}><img src={item.image_path.split(',')[0]?require(item.image_path.split(',')[0]):require('')} /></button>
                                                            <button type='button' onClick={()=>{this.previewImage(2,item)}} className={item["imgIndex"]===2 ? 'idtmbmsil-active':""}><img src={require('../../../assets/product-image.png')} /></button>
                                                            <button type='button' onClick={()=>{this.previewImage(3,item)}} className={item["imgIndex"]===3 ? 'idtmbmsil-active':""}><img src={require('../../../assets/product-image.png')} /></button>
                                                            <button type='button' onClick={()=>{this.previewImage(4,item)}} className={item["imgIndex"]===4 ? 'idtmbmsil-active':""}><img src={require('../../../assets/product-image.png')} /></button> */}
                                                        </div>
                                                    </div>
                                                    <div className='idtmbmsi-right'>
                                                        <div className="idtmbmsir-row">
                                                            <p>Icode : {item.item_code}</p>
                                                            <h3>{item.item_name}</h3>
                                                        </div>
                                                        <div className="idtmbmsir-row">
                                                            <p>Description</p>
                                                            <h3>{item.description ? item.description : "NA"}</h3>
                                                        </div>
                                                        <div className="idtmbmsir-row">
                                                            <p>Design Number</p>
                                                            <h3>{item.design}</h3>
                                                        </div>
                                                        <div className="idtmbmsir-row">
                                                            <div className='idtmbsirr-col'>
                                                                <p>Size</p>
                                                                <h3>{item.size1}</h3>
                                                            </div>
                                                            <div className='idtmbsirr-col'>
                                                                <p>Color</p>
                                                                <span className='idtmbsirr-color'>{item.color}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className="no-data-found">
                                            <img src={require('../../../assets/icons/NoDataFound.svg')} />
                                            <h3>No Items Available</h3>
                                        </div>
                                    }
                                </div>
                                <>
                                    <div className="new-gen-pagination">
                                        <div className="ngp-left">
                                            <div className="table-page-no">
                                                <span>Page :</span><input type="number" className="paginationBorder" max={this.state.maxPage} min="1" onKeyPress={this.getAnyPage} onChange={this.getAnyPage} value={this.state.jumpPage} />
                                            </div>
                                        </div>
                                        <div className="ngp-right">
                                            <div className="nt-btn">
                                                <Pagination {...this.state} {...this.props} page={this.page}
                                                    prev={this.state.prev} current={this.state.current} maxPage={this.state.maxPage} next={this.state.next} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
