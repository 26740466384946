import React, { Suspense, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom';
import Sidebar from './home/Sidebar';
import SupplymintHeader from './home/SupplymintHeader';
import { stacks, stackNames } from '../constants/routingStack'
import ParentModal from '../genericComponents/ParentModal';
import Dashboard from './dashboard';
import Icons from '../assets/icons';
import CreateTicketModal from './helpSection/CreateTicketModal';
import '../styles/stylesheets/home/Dashboard.scss'
import html2canvas from 'html2canvas'
import UploadExcel from './uploadExcel/UploadExcel';
import { CreateFilterAndHeaderManagement } from '../authComponents/CreateFilterAndHeaderManagement';
import '../styles/stylesheets/helper.scss'
import FullPageLoader from '../loaders/FullPageLoader';
import AuditLogs from './AuditLogs';
import UserProfileHome from './home/profileSection/userProfile/UserProfileHome';
import ChangeSetting from './home/profileSection/changeSetting/ChangeSetting';
import { parseJwt } from '../helper/genericFunction';
import Annoucement from './helpSection/Annoucement';
import DefaultPopUp from './defaultPopUp/DefaultPopUp';
import SupportTickets from './superAdmin/SupportTickets';
const NoPageFound = React.lazy(() => import('./helperComponents/NoPageFound'))
const ExcelUploadLogs = React.lazy(() => import('./uploadExcel/ExcelUploadLogs'));


const AppStack = (props) => {
	const [selectedModal, setSelectedModal] = useState("");
	const [ssFile, setSsFile] = useState("");
	let modules = JSON.parse(sessionStorage.getItem("modules")) || [];
	// To provide the routing routes
	const renderStackRouting = (stackName, type) => {
		return stacks?.[stackName]?.[type]?.map((path) => (
			<Route exact path={path} component={stackNames?.[type]} />
		))
	}

	const renderRouting = () => {
		let allModulesNames = modules?.map(item => item?.code);
		return allModulesNames?.map(stackName => Object.keys(stacks?.[stackName] || {})?.length > 0 && (
			// If the stack is available for the given routes;
			Object.keys(stacks?.[stackName])?.map(key => (
				renderStackRouting(stackName, key)
			))
		))
	}
	useEffect(() => {
		let parseToken = parseJwt(sessionStorage.getItem('token'))
		if (sessionStorage.getItem("showEthanacityPopUp") && parseToken?.schemaEntID == "3152")
			console.log("Parse Token", parseToken);
		if (sessionStorage.getItem("showEthanacityPopUp") && parseToken?.schemaEntID == "3152" && parseToken?.uType?.toUpperCase() == "VENDOR")
			setSelectedModal("annoucement");
	}, [])

	const getParentChildProps = () => {
		switch (selectedModal) {
			case "createTicketModal": {
				return {
					closeModal: () => { setSelectedModal(false) },
					postitionProps: { top: "15%", left: "22%", margin: 0, position: "fixed", borderRadius: 4 },
					dimensions: { width: "56vw", height: "70vh" },
					animationType: "center",
					ssFile: ssFile
				}
			}
			case "annoucement": {
				return {
					closeModal: () => { setSelectedModal(false) },
					postitionProps: { top: "10%", left: "25%", margin: 0, borderRadius: 4 },
					dimensions: { width: "50vw", height: "80vh" },
					animationType: "center",
				}
			}
			default:
		}
	}

	const getChildComponent = () => {
		switch (selectedModal) {
			case "createTicketModal": return CreateTicketModal;
			case "annoucement": return Annoucement
			default:
		}
	}

	const captureSS = () => {
		// $("#create_new_ticket").css("visibility", "hidden");
		let app = document.getElementById("app");
		html2canvas(app).then(canvas => {
			canvas.toBlob(blob => {
				let time = (new Date()).getTime();
				let fileName = `SMSS_${time}.png`;
				let updatedSsFile = new File([blob], fileName, { type: "image/png" });
				setSsFile(updatedSsFile)
				// $("#create_new_ticket").css("visibility", "visible")
			});
		})
	}

	const handleChange = (type, data) => {
		switch (type) {
			case "createTicketModal": {
				captureSS();
				setSelectedModal(type);

			}
		}
	}
	return (
		<Suspense fallback={<FullPageLoader />}>

			{/* Component : To handle the default pop up modals when the user comes to the application */}
			<DefaultPopUp />

			{/* To Handle the Filter and Headers api response */}
			<CreateFilterAndHeaderManagement />


			{/* Render the Side Bar Component */}
			<Sidebar />

			{/* Render the Top Headers of Supplymint Portal */}
			<SupplymintHeader setCurrentDocumentState={props.setCurrentDocumentState} createNewTicket={() => handleChange("createTicketModal")} {...props} />

			<div className='table-component-div1'>
				{/* Set up the module routes */}
				<Switch>
					{renderRouting()}
					<Route exact path="/excel-upload" component={UploadExcel} />
					<Route exact path="/excel-upload-logs" component={ExcelUploadLogs} />
					<Route exact path="/excel-upload-proc" render={() => <UploadExcel isFromProc={true} />} />
					<Route exact path="/auditLogs" component={AuditLogs} />
					<Route path='/home' component={Dashboard} />
					<Route path="/profile" component={UserProfileHome} />
					<Route path="/changeSetting" component={ChangeSetting} />
					<Route exact path="/settings/manageSupportTicket" render={() => <SupportTickets type="header" />} />

					<Route path='*' component={NoPageFound} />
				</Switch>
			</div>

			<Switch>

			</Switch>


			{/* The Create New Ticket Modal function will be available for all the windows */}
			<div id="globalAddMoreBtn" className="add-more-btn-home">
				<div className='gct-icon'>
					<div className="gct-plus">
						<Icons.PlusIcon />
					</div>
					<div className='amb-drop-tckt' onClick={() => handleChange("createTicketModal")}>
						<div className="create-gen-ticket">
							<Icons.CreateNewTicketIcon />
							<p>Create Ticket</p>
						</div>
					</div>
				</div>
			</div>
			{selectedModal && <ParentModal
				getParentChildProps={getParentChildProps()}
				childComponent={getChildComponent()}
				closeModal={() => { setSelectedModal(false) }}
			/>}

		</Suspense>
	)
}

export default AppStack