import React from 'react';
import IconsPath from '../../../assets/icons/index'

export default class TableLoader extends React.PureComponent {
    render () {
        return (
            <div className='table-loader-bg'>
                <div className="table-loader-modal">
                    <div className="tlm-head"></div>
                    <div className="tlm-body">
                        <div className="tlmb-loader">
                            <span className={this.props.isError ? "tlmbsm-load-static" : "tlmbsm-load"}>
                                {this.props.isError ?
                                <div className="tlmbsml-inner" onClick={this.props.onRefresh}>
                                    <IconsPath.ReloadIcon  />
                                </div> :
                                <span className="tlmbsml-inner"></span>}
                            </span>
                            {this.props.isError ? <p>Failed to fetch data!</p> : <p>Fetching data...</p>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}