/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import IconsPath from "../../../assets/icons/index";
const { RangePicker } = DatePicker;

const totalItemsLimitList = {
    // "PO_TO_GRN_SUMMARY_HEADERS":200000,
    // "PO_TO_GRN_HEADERS":200000,
    "PO_TABLE_HEADER_MAIN": 1000,
}

export class DownloadReport extends Component {
    constructor(props){
        super(props);
        this.state={
            search: "",
            headersLevel: "main",
            headersType: this.props.mainHeadersType,
            isCustomHeadersAvailable: this.props.mainHeadersType === "Custom Headers",
            headersMap: this.props.mainHeadersData[this.props.mainHeadersType],
            selectedItems: this.props.mainHeadersData[this.props.mainHeadersType],
            exportFilters: this.props.mainHeadersData.exportFilters ? this.props.mainHeadersData.exportFilters.map(item => ({...item, input: {from: "", to: ""}, error: false})) : [],
            dateDropdown: "",
            checkBoxes : {
                enableExportFilters : false,
            },
            //Making a state to see if custom filters will be available or not in the given file.
            isCustomFiltersAvailable: totalItemsLimitList.hasOwnProperty(this.props.mainHeadersKey) ? this.props.dataLength >= totalItemsLimitList[this.props.mainHeadersKey] ? true : false : true
        };
    }

    componentDidUpdate(){
        if(this.props.generalStore.downloadReportData.isSuccess){
            this.props.close();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.closeOnEsc);
        if(this.props.detailHeadersData) {
            const isCustomHeadersAvailable = this.props.mainHeadersType === "Custom Headers" && this.props.detailHeadersType.every(item => item === "Custom Headers");
            this.setState({
                headersType: isCustomHeadersAvailable ? "Custom Headers" : "Default Headers",
                isCustomHeadersAvailable
            });
        }
        if(typeof this.props.payload.filter === "object" && Object.keys(this.props.payload.filter).length > 0) {
            const filter = this.props.payload.filter, exportFilters = this.state.exportFilters;
            exportFilters.forEach(item => {
                if(filter[item.key]) {
                    item.input = {...filter[item.key]};
                    switch(item.type) {
                        case "NUMBER": {
                            if(Number(filter[item.key].from) - Number(filter[item.key].to) > item.range) {
                                item.error = true;
                            }
                            break;
                        }
                        case "DATE": {
                            if(moment(filter[item.key].to).diff(moment(filter[item.key].from), "days") > item.range) {
                                item.error = true;
                            }
                            break;
                        }
                    }
                }
            });
            this.setState({
                exportFilters
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.closeOnEsc);
    }

    closeOnEsc = e => {
        if(e.key === "Escape") {
            this.props.close();
        }
    }

    handleInput = e => {
        const field = e.target.name, value = e.target.value;
        this.setState({
            [field]: value
        }, () => {
            if(field === "headersLevel" || field === "headersType") {
                this.setHeaders();
            }
        });
    }

    setHeaders = () => {
        let headersMap = {};
        headersMap = {...this.props.mainHeadersData[this.state.headersType]};
        if(this.state.headersLevel === "detail") {
            this.props.detailHeadersData.forEach(item => {
                headersMap = {
                    ...headersMap,
                    ...item[this.state.headersType]
                };
            });
        }
        this.setState({
            headersMap,
            selectedItems: headersMap
        });
    }

    selectItem = e => {
        const key = e.target.name;
        this.setState(prevState => {
            let selectedItems = {...prevState.selectedItems};
            if(selectedItems[key]) {
                delete selectedItems[key];
            }
            else {
                selectedItems[key] = prevState.headersMap[key];
            }
            return {
                selectedItems
            };
        });
    }

    handleSelectAll = () => {
        this.setState(prevState => {
            if(Object.keys(prevState.selectedItems).length === Object.keys(prevState.headersMap).length) {
                return {
                    selectedItems: {}
                };
            }
            return {
                selectedItems: {...prevState.headersMap}
            };
        });
    }

    handleFiltersInput = (e, item) => {
        console.log(5555555555, e, item)
        try {
            e.persist();
        }
        catch(err) {}
        
        this.setState(prevState => {
            const exportFilters = [...prevState.exportFilters],
            index = exportFilters.findIndex(innerItem => innerItem.key === item.key);
            switch(item.type) {
                case "NUMBER": {
                    exportFilters[index].input[e.target.dataset.type] = Number(e.target.value) || "";
                    if(exportFilters[index].input.to - exportFilters[index].input.from > exportFilters[index].range) {
                        exportFilters[index].error = true;
                    }
                    else {
                        exportFilters[index].error = false;
                    }
                    break;
                }
                case "DATE": {
                    if(e === null) {
                        exportFilters[index]["input"] = {
                            from: "",
                            to: ""
                        };
                    }
                    else if(["SM_DATE_TODAY_VALUE", "SM_DATE_YESTERDAY_VALUE", "SM_DATE_LAST7DAYS_VALUE", "SM_DATE_LASTWEEK_VALUE", "SM_DATE_THISMONTH_VALUE", "SM_DATE_LASTMONTH_VALUE"].includes(e)) {
                        exportFilters[index]["input"] = {
                            ...this.getDateFromTo(e),
                            key: e
                        };
                    }
                    else {
                        exportFilters[index]["input"] = {
                            from: moment(e[0].$d).format('YYYY-MM-DD'),
                            to: moment(e[1].$d).format('YYYY-MM-DD')
                        };
                    }
                    if(moment(exportFilters[index]["input"].to).diff(moment(exportFilters[index]["input"].from), "days") > exportFilters[index].range) {
                        exportFilters[index].error = true;
                    }
                    else {
                        exportFilters[index].error = false;
                    }
                    break;
                }
            }
            return {exportFilters};
        });
    }

    getDateFromTo = key => {
        switch(key) {
            case "SM_DATE_TODAY_VALUE": {
                return {
                    from: moment().format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_YESTERDAY_VALUE": {
                return {
                    from: moment().subtract(1, "day").format('YYYY-MM-DD'),
                    to: moment().subtract(1, "day").format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LAST7DAYS_VALUE": {
                return {
                    from: moment().subtract(7, "days").format('YYYY-MM-DD'),
                    to: moment().subtract(1, "day").format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LASTWEEK_VALUE": {
                return {
                    from: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_THISMONTH_VALUE": {
                return {
                    from: moment().startOf('month').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LASTMONTH_VALUE": {
                return {
                    from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                    to: moment().subtract(1,'month').endOf('month').format('YYYY-MM-DD'),
                };
            }
            default: {
                return {
                    from: "",
                    to: ""
                };
            }
        }
    }

    renderExtraFooter = item => () => {
        return(
            <span className="date-picker-extra-footer">
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof item.input === "object" && item.input.key === "SM_DATE_TODAY_VALUE"} onChange={e => this.handleFiltersInput("SM_DATE_TODAY_VALUE", item)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Today</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof item.input === "object" && item.input.key === "SM_DATE_YESTERDAY_VALUE"} onChange={e => this.handleFiltersInput("SM_DATE_YESTERDAY_VALUE", item)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Yesterday</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof item.input === "object" && item.input.key === "SM_DATE_LAST7DAYS_VALUE"} onChange={e => this.handleFiltersInput("SM_DATE_LAST7DAYS_VALUE", item)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last 7 Days</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof item.input === "object" && item.input.key === "SM_DATE_LASTWEEK_VALUE"} onChange={e => this.handleFiltersInput("SM_DATE_LASTWEEK_VALUE", item)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last Week</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof item.input === "object" && item.input.key === "SM_DATE_THISMONTH_VALUE"} onChange={e => this.handleFiltersInput("SM_DATE_THISMONTH_VALUE", item)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">This Month</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof item.input === "object" && item.input.key === "SM_DATE_LASTMONTH_VALUE"} onChange={e => this.handleFiltersInput("SM_DATE_LASTMONTH_VALUE", item)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last Month</span>
                </label>
            </span>
        );
    }

    isSubmitDisabled = () => {
        if(this.props.dataLength <= 100) {
            return this.state.isCustomFiltersAvailable ? Object.keys(this.state.selectedItems).length === 0 : Object.keys(this.state.selectedItems).length === 0;
        }
        else return this.state.isCustomFiltersAvailable ? Object.keys(this.state.selectedItems).length === 0 || this.state.exportFilters.some(item => !item.input.from || !item.input.to || item.error) : Object.keys(this.state.selectedItems).length === 0;
    }

    onSubmit = () => {
        console.log(777777777777, this.props.payload);
        let  payload = JSON.parse(JSON.stringify(this.props.payload));
        if(this.state.exportFilters.length) {
            payload.type == 1 ? payload.type = 2 : null;
            this.state.exportFilters.forEach(item => {
                    console.log(item)
                    if(this.state.checkBoxes.enableExportFilters && this.props.mainHeadersData.exportFilters && this.props.mainHeadersData.exportFilters.length > 0 && this.props.mainHeadersData.exportFilters.key == item.key){
                        switch(item.type){
                            case 'DATE' : {
                                if(item.input.from != '' && item.input.to != ''){
                                    payload.filter[item.key] = item.input;
                                }
                                break
                            }
                            default : payload.filter[item.key] = item.input;
                        }
                    }
                    else{
                        switch(item.type){
                            case 'DATE' : {
                                if(item.input.from != '' && item.input.to != ''){
                                    payload.filter[item.key] = item.input;
                                }
                                break
                            }
                            default : payload.filter[item.key] = item.input;
                        }
                    }
            });
        }
        if(this.props.detailLevelModule === "PO_TABLE_HEADER_DET" || this.props.detailLevelModule === "PI_TABLE_HEADER_DET") {
            this.props.submit({
                module: this.state.headersLevel === "main" ? this.props.headerLevelModule : this.props.detailLevelModule,
                isAllData: this.state.headersLevel === "detail",
                isOnlyCurrentPage: false,
                data: {
                    ...payload,
                    filter: payload.filter,
                    reportFilter:payload.reportFilter,
                    headers: this.state.selectedItems,
                    isMainHeader: this.state.headersLevel === "main" ? true : false
                }
            });
        }
        else {
            this.props.submit({
                module: this.state.headersLevel === "main" ? this.props.headerLevelModule : this.props.detailLevelModule,
                isAllData: this.state.headersLevel === "detail",
                isOnlyCurrentPage: false,
                data: {
                    ...payload,
                    filter: payload.filter,
                    reportFilter:payload.reportFilter,
                    headers: this.state.selectedItems
                }
            });
        }
    }
    handleChange= (e) =>{
        let checkBoxes = {...this.state.checkBoxes}
        checkBoxes[e.target.name] = !this.state.checkBoxes
        this.setState({
            checkBoxes
        })
    }

    closeReportModal = () => {
		document.getElementById("reportModal")?.classList?.remove("from-right-in")
		document.getElementById("reportModal")?.classList?.add("from-right-out")
		setTimeout(()=>{
			this.props.close();
		}, 250)
	}

    render() {
        console.log("Dwonload Report Name", this.props.downloadReportName);
        return (
            <div className="modal">
                <div className="backdrop modal-backdrop-new" onClick={this.closeReportModal}></div>
                <div id='reportModal' className="modal-content email-report-modal from-right-in">
                    <div className="erm-head">
                        <h3>Download Report</h3>
                        <span className="ermh-close" onClick={this.closeReportModal}>
                            <span className="ermh-esc">Esc</span>
                            <IconsPath.ClearSearch />
                        </span>
                    </div>
                    <div className="erm-search-fields">
                        <div className="gen-pi-formate">
                            <ul className="gpf-radio-list">
                                <li>
                                    <label className="gen-radio-btn">
                                        <input type="radio" name="headersLevel" value="main" checked={this.state.headersLevel === "main"} onClick={this.handleInput} />
                                        <span className="checkmark"></span>
                                        Header Level Data
                                    </label>
                                </li>
                                {this.props.detailHeadersData && <li>
                                    <label className="gen-radio-btn">
                                        <input type="radio" name="headersLevel" value="detail" checked={this.state.headersLevel === "detail"} onClick={this.handleInput} disabled={!this.props.detailHeadersData} />
                                        <span className="checkmark"></span>
                                        Detail Level Data
                                    </label>
                                </li>}
                            </ul>
                        </div>
                    </div>
                    <div className="erm-search-fields">
                        <select name="headersType" value={this.state.headersType} onChange={this.handleInput} disabled={!this.state.isCustomHeadersAvailable}>
                            <option value="" disabled>Select Option</option>
                            <option value="Default Headers">Default</option>
                            <option value="Custom Headers">Custom</option>
                        </select>
                        <div className="ermsf-search">
                            <button type="button">
                                <svg className='m-lft-5' width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.25 1.5A6.752 6.752 0 0 1 15 8.25 6.752 6.752 0 0 1 8.25 15 6.752 6.752 0 0 1 1.5 8.25 6.752 6.752 0 0 1 8.25 1.5zm0 12c2.9 0 5.25-2.35 5.25-5.25C13.5 5.349 11.15 3 8.25 3A5.248 5.248 0 0 0 3 8.25c0 2.9 2.349 5.25 5.25 5.25zm6.364.053 2.121 2.121-1.06 1.061-2.122-2.121 1.06-1.06z" fill="#21314b"></path>
                                </svg>
                                <input type="text" autoComplete="off" name="search" value={this.state.search} onChange={this.handleInput} placeholder="Search headers..." />
                            </button>
                        </div>
                    </div>
                    <div className="drm-body">
                        <div className="drm-checkbox drmb-for-potogrn">
                            <label className="checkBoxLabel0 drm-select-all">
                                <input type="checkBox" checked={Object.keys(this.state.selectedItems || {})?.length === Object.keys(this.state.headersMap || {})?.length} onChange={this.handleSelectAll} />
                                Select All
                                <span className="checkmark1"></span>
                            </label>
                            {Object.keys(this.state.headersMap).map(key =>
                                this.state.headersMap[key].toLowerCase().includes(this.state.search.toLowerCase()) ?
                                <label className="checkBoxLabel0" key={key}>
                                    <input type="checkBox" checked={this.state.selectedItems[key] !== undefined} onChange={this.selectItem} name={key} />
                                    {this.state.headersMap[key]}
                                    <span className="checkmark1"></span>
                                </label> :
                                null
                            )}
                        </div>
                        {this.state.exportFilters.length !== 0 &&
                        <div className="drm-filters">
                            <h3>Filters</h3>
                            {this.state.exportFilters.map(item =>
                                item.type === "NUMBER" ?
                                <div key={item.key} className="drmf-number">
                                    <label>{item.value}</label>
                                    <div>
                                        <input
                                            className={item.error ? "errorBorder" : ""}
                                            type="number"
                                            data-type="from"
                                            id={item.key + "From"}
                                            value={item.input.from}
                                            placeholder="From"
                                            onChange={(e) => this.handleFiltersInput(e, item)}
                                        />
                                        <input
                                            className={item.error ? "errorBorder" : ""}
                                            type="number"
                                            data-type="to"
                                            id={item.key + "To"}
                                            value={item.input.to}
                                            placeholder="To"
                                            onChange={(e) => this.handleFiltersInput(e, item)}
                                        />
                                    </div>
                                    {item.error && <span className='error'>Allowed range is {item.range}</span>}
                                </div> :

                                // item.type === "DATE"
                                <div key={item.key} className="drmf-date">
                                    <label>{item.value}</label>
                                    <RangePicker
                                        className={item.error ? "errorBorder" : ""}
                                        format="YYYY-MM-DD"
                                        id={item.key}
                                        value={[item.input.from ? dayjs(item.input.from) : null, item.input.to ? dayjs(item.input.to) : null]}
                                        onChange={(e) => this.handleFiltersInput(e, item)}
                                        renderExtraFooter={this.renderExtraFooter(item)}
                                        onOpenChange={open => open ? this.setState({dateDropdown: item.key}) : this.setState({dateDropdown: ""})}
                                        open={this.state.dateDropdown === item.key}
                                    />
                                    {item.error && <span className='error-msg'>Allowed range is {item.range} days</span>}
                                </div>
                            )}
                            <div className='other-applied-filter'>
                                <div className='applied-body'>
                                    <h3>Other Applied Filters</h3>
                                    <label className="checkBoxLabel0">
                                        <input type="checkbox"  name="enableExportFilters" onChange={this.handleChange}/>Download report with other applied filters<span className="checkmark1"></span>
                                    </label>
                                    <div className='oaf-status'>
                                      {/* <h3>PO Status</h3> */}
                                        <div className="show-applied-filter">
                                        {Object.keys(this.props.payload.filter).length != 0 && 
                                            <div className="show-applied-filter">
                                                
                                                {Object.keys(this.props.payload.filter).map(key => {
                                if(key === "item") {
                                    return Object.keys(this.props.payload.filter.item).map(key=> (
                                        <button type="button" className="saf-btn">{this.props.mainHeadersData && this.props.mainHeadersData.item && this.props.mainHeadersData.item[this.props.mainHeadersType && this.props.mainHeadersType.item && this.props.mainHeadersType.item][key]}
                                          
                                            <span className="generic-tooltip">
                                                {this.props.getItemFilterValue(key)}
                                            </span>
                                        </button>
                                    ))
                                }
                                return <button type="button" className="saf-btn">{this.props.mainHeadersData[this.props.mainHeadersType][key]}
                                    <span className="generic-tooltip">
                                        {this.props.getFilterValue(key)}
                                    </span>
                                </button>}
                            )}


                                                {/* {Object.keys(this.props.payload.filter).map(key => {
                                                    <button type="button" className="saf-btn">{this.props.mainHeadersData[this.props.mainHeadersType][key]}
                                                        <span className="generic-tooltip">
                                                            {this.props.getFilterValue(key)}
                                                        </span>
                                                    </button>
                                                })} */}
                                            </div>}
                                            {/* <button type='button' className='saf-btn'>Pending
                                                <span className="generic-tooltip">Pending</span>
                                            </button>
                                            <button type="button" className='saf-btn'>Approved
                                                <span className="generic-tooltip">Approved</span>
                                            </button> */}
                                        </div>
                                    </div>
                                    {/* <div className='oaf-status'>
                                      <h3>Vendor Name</h3>
                                        <div className="show-applied-filter">
                                            <button type='button' className='saf-btn'>Owais Enterprises India
                                                <span className="generic-tooltip">Owais Enterprises India</span>
                                            </button>
                                            <button type="button" className='saf-btn'>Salasar Garments
                                                <span className="generic-tooltip">Salasar Garments</span>
                                            </button>
                                        </div>
                                    </div>                                     */}
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="erm-footerv2">
                        {this.props.loading ?
                        <button type="button" className="generic-approve-btn btn-loader" disabled><div className="spinner-border"></div></button> :
                        !this.props.downloadReportName && this.isSubmitDisabled() ?
                        <button type='button' className='generic-approve-btn opacity04;' disabled>Download Report</button> :
                        <button type='button' className='generic-approve-btn' onClick={this.onSubmit}>Download Report</button>}
                    </div>
                </div>
            </div>
        );
    }
}

export default DownloadReport;