import React, { useEffect, useState } from 'react'
import GenericInput from '../../../genericComponents/input/GenericInputComponent';
import { openDB } from 'idb';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets/icons';
import "../../../styles/stylesheets/home/SalesDashboard.scss"
import { parseJwt } from '../../../helper/genericFunction';
import GenericTableData from './GenericTableData';
import useRenderClassComponent from '../../../hooks/useRenderClassComponent';
import { PRIMARY_FILTERS } from './constants';
import SalesOrderSummary from './SalesOrderSummary';
import SalesOrderDashboardCountCards from './SalesOrderDashboardCountCards';
import { Radio } from 'antd';
import GenericFormComponent from '../../../genericComponents/forms/GenericFormComponent';
import moment from 'moment';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';

const SalesOrderDashboard = () => {
    const dispatch = useDispatch()

    const salesManagementStore = useSelector((state) => state.salesManagementStore)
    const generalStore = useSelector((state) => state.generalStore)
    const [masterDbResponse, setMasterDbResponse] = useState({});
    const [changeHeaderType, setChangeHeaderType] = useState(false)
    const [itemsData, setItemsData] = useState({
        itemLevel: "desc",
        itemLimit: 5,
        itemType: "item",
    })

    const [tableHeadersMap, setTableHeadersMap] = useState({
        main: {
            name: "Main",
            key: "SALES_ORDER_ITEM_PERFORMANCE_ANALYSIS"
        },
    })

    // State: To store the filter data
    const [filterData, setFilterData] = useState({});

    useEffect(() => {
        updateMasterDbResponse();
    }, [])
    useEffect(() => {
        dispatchHandler(dispatch, 'getSOPerfAnalysisRequest', {
            itemLevel: itemsData?.itemLevel,
            itemLimit: itemsData?.itemLimit,
            itemType: itemsData?.itemType,
            filter: {}
        })
        setChangeHeaderType(false);
    }, [itemsData])

    useEffect(() => {
        let brandPayload = {
            type: 1,
            pageNo: 1,
            pageSize: 10,
            sortedBy: "",
            sortedIn: "",
            search: "",
            filter: {},
            itemLevel: "asc",
        };
        dispatchHandler(dispatch, 'getSalesDashboardBrandSummaryRequest', brandPayload)
    }, [salesManagementStore?.getSOStatusCountData?.isSuccess, salesManagementStore?.getSOStatusCountData?.data?.resource])

    // Function: To update the master db response
    const updateMasterDbResponse = async () => {
        const db = await openDB("Supplymint-ai-local-storage", 1);
        let updatedMasterDbResponse = {};
        ["DYNAMIC_ITEM_CONFIG", "DYNAMIC_SITE_CONFIG"]?.map(async key => {
            let response = await db.get("MASTER_DB_DROPDOWN", key) || [];
            updatedMasterDbResponse[key] = response;
        })
        setMasterDbResponse(updatedMasterDbResponse)
    }
    console.log("Master Db Response", masterDbResponse);

    // Function : To update the sales dashbaord performancen analysis settings
    const updatePerformanceAnalysisData = (key, data) => {
        let updatedItemsData = { ...itemsData };
        updatedItemsData[key] = data;
        console.log("Data  Debug", data)
        setItemsData(updatedItemsData);
        switch (key) {
            case "itemType": {
                setTableHeadersMap((prev) => ({ ...prev, main: { ...prev.main, key: data === "item" ? "SALES_ORDER_ITEM_PERFORMANCE_ANALYSIS"  : "SALES_ORDER_PERFORMANCE_ANALYSIS"} }));
                setChangeHeaderType(true)
            }
        }
    }

    console.log("Filter Data", filterData);

    // toggle items buttons
    const toggleItemsButtons = () => {
        return (
            <React.Fragment>
                <div className="sds-top">
                    <Radio.Group value={itemsData?.["itemLevel"]} onChange={() => updatePerformanceAnalysisData("itemLevel", itemsData?.["itemLevel"] == "asc" ? "desc" : "asc")}>
                        <Radio.Button value={"asc"}>Top</Radio.Button>
                        <Radio.Button value={"desc"}>Bottom</Radio.Button>
                    </Radio.Group>

                    <Radio.Group value={itemsData?.["itemType"]} onChange={() => updatePerformanceAnalysisData("itemType", itemsData?.["itemType"] == "item" ? "customer" : "item")}>
                        <Radio.Button value={"item"}>Items</Radio.Button>
                        <Radio.Button disabled={parseJwt(sessionStorage.getItem('token'))?.uType != "ENT"} value={"customer"}>Customers</Radio.Button>
                    </Radio.Group>
                    <GenericInput
                        key="itemLimit"
                        iconRight={<Icons.DownArrowNew />}
                        value={itemsData?.["itemLimit"] || 5}
                        onChange={(e) => updatePerformanceAnalysisData("itemLimit", e?.limit)}
                        type="dropdown"
                        inputProps={() => ({
                            headerKeys: { "limit": {} },
                            itemKeys: { "limit": ["limit"] },
                            getListData: () => ([
                                { limit: 5 }, { limit: 10 }, { limit: 20 }, { limit: 50 }, { limit: 100 },
                            ])
                        })}
                    />
                </div>
            </React.Fragment>
        )
    }

    // Function to provide the filter inputs props
    const inputPropsMachine = (filter) => {
        return {
            viewDataType: "table-view",
            modalMainClass: "use-as-generic-dropdown",
            search: {
                placeholder: `Search ${filter?.value}`,
                searchOptions: {
                    "CONTAINS": "Contains",
                    "STARTS_WITH": "Starts With",
                    "EXACT_MATCH": "Exactly Matched"
                },
                searchOptionLabel: "Search By",
            },
            headerKeys: {
                multiSelectCol: {},
                [filter?.key]: {
                    value: "Code",
                },
                [filter?.code]: {
                    value: "Name"
                }
            },
            itemKeys: {
                [filter?.key]: [filter?.key],
                [filter?.code]: [filter?.code],
            },
            multiselect: {
                selectId: `${filter?.key}`,
                selectedData: filterData?.[filter?.storeCode] || {}
            },
            api: {
                reduxState: "generalStore",
                apiName: "getSearchFilters",
                apiPayload: (params) => {
                    const otherData = getFiltersOtherData(filter, filterData); 
                    // console.log("others data", otherData);
                    return {
                        "entity": filter?.entity,
                        "key": filter.key,
                        "code": filter?.code,
                        "search": params?.currentSearch || "",
                        "searchBy": params.searchFilter || "STARTS_WITH",
                        "pageNo": params?.currentPage || 1,
                        "moduleName": "",
                        "other_data": otherData,
                        "columnName": {}
                    }
                },
                getMaxPage: (states, functions, response) => {
                    return Number(response?.resource?.maxPage || "");
                }
            },
            getListData: (data) => data?.resource?.searchResultNew || [],
        }
    }

    // Function to provide all the currently selected filters to the current filter.
    const getFiltersOtherData = (filter, filterData) => {
        let otherData = {};
        const DROPDOWN_TYPE = filter.entity === "item" || filter.entity === "product_catalogue_vw" || filter.entity === "logged_actions" || filter.entity === "item_master" || filter.entity === "generic_item_master_report" ? "DYNAMIC_ITEM_CONFIG" : filter.entity === "site" ? "DYNAMIC_SITE_CONFIG" : filter.entity === "asn_dashboard_details" ? "DYNAMIC_SITE_CONFIG" : filter.entity === "box_performance_details" ? "DYNAMIC_SITE_CONFIG" : null;
        if (DROPDOWN_TYPE) {
            let response = masterDbResponse?.[DROPDOWN_TYPE] || [];
            Object.keys(filterData || {})?.map(key => {
                if (key != filter?.storeCode) {
                    if (key != "orderDate" && response?.includes(key)) {
                        otherData[key] = Object.keys(filterData?.[key] || {})?.join(',')
                    }
                }
            })
        }
        return otherData;
    }

    // Function  to provide the filter inputs fields
    const provideFilterInputs = () => {
        return PRIMARY_FILTERS?.map(filter => ({
            key: filter.storeCode,
            type: filter.key === "orderDate" ? "range" : "dropdown",
            inputProps: () => inputPropsMachine(filter),
            iconRight: filter?.key === "orderDate" ? null : <Icons.GlobalSearchIcon />,
            placeholder: filter.key !== "orderDate" ? filter?.value : ""
        }))
    }

    // Function to render the primary filters data
    const renderPrimaryFilters = () => {
        return (
            <div className="sd-top">
                <GenericFormComponent
                    rowInput={{
                        rowType: "singleRow",
                        availableInputs: provideFilterInputs(),
                        inputState: { ...filterData },
                        inputStateChangeFunction: (data) => { setFilterData(data) },
                        rowRightExtras: <React.Fragment>
                            <div className='flex justify-end'>
                                <GenericCustomButton
                                    label="Clear All"
                                    type="default"
                                    onClick={() => setFilterData({})}
                                    disabled={() => salesManagementStore?.["getSOStatusCountData"]?.isLoading || Object.keys(filterData || {})?.length == 0}
                                />
                            </div>
                        </React.Fragment>
                    }}
                />
            </div>
        )
    }

    // Function = Function to get all the filters in the intended form to send inside the payload
    const getCurrentMainLevelFilters = () => {
        let filters = {};
        Object.keys(filterData || {})?.map(key => {
            if (filterData?.[key]) {
                if (key == "orderDate") {
                    filters["orderDate"] = {
                        "from": filterData?.["orderDate"]?.[0] ? moment(filterData?.["orderDate"]?.[0])?.format("YYYY-MM-DD") : "",
                        "to": filterData?.["orderDate"]?.[1] ? moment(filterData?.["orderDate"]?.[1])?.format("YYYY-MM-DD") : ""
                    }
                } else {
                    filters[key] = Object.keys(filterData?.[key])?.join(',');
                }
            }
        })
        console.log("Filters Applied", filters);
        return filters;
    }

    return (
        <div className="sales-dashboard">
            {renderPrimaryFilters()}
            {/* Render the Sales order count dashbaord */}
            <SalesOrderDashboardCountCards filterData={filterData} getCurrentMainLevelFilters={getCurrentMainLevelFilters} />

            {/* Performance Anaylisis */}
            <div className="sd-sec">
                <h3>Performance Analysis</h3>
                <React.Fragment>
                    {
                        useRenderClassComponent(GenericTableData, {
                            actionBarConfig: {
                                quickFilter: {
                                    disabled: generalStore?.getQuickFiltersData?.isLoading
                                },
                                filters: {
                                    disabled: generalStore?.getHeadersData?.isLoading,
                                },
                            },
                            tableConfig: {
                                toggleitem: {
                                    render: toggleItemsButtons,
                                },
                            },
                            tableHeadersPayload: tableHeadersMap,
                            changeHeaderType : changeHeaderType,
                            quickFiltersPayload: {
                                attributeType: "PERFORMANCE_ ANALYSISI_SOMAIN",
                                displayName: "PERFORMANCE_ ANALYSISI_SOMAIN",
                                filterName: ""
                            },
                            tableDataPayload: {
                                filter: {},
                                itemLevel: itemsData?.itemLevel,
                                itemLimit: itemsData?.itemLimit,
                                itemType: itemsData?.itemType
                            },
                            getTableDataRequest: 'getSOPerfAnalysisRequest',
                            getTableDataLoading: salesManagementStore?.getSOPerfAnalysisData.isLoading,
                            getTableDataSuccess: salesManagementStore?.getSOPerfAnalysisData?.isSuccess,
                            getTableDataError: salesManagementStore?.getSOPerfAnalysisData?.isError,

                            tableData: {
                                data: salesManagementStore?.getSOPerfAnalysisData?.data?.resource?.data || [],
                                totalItems: salesManagementStore?.getSOPerfAnalysisData?.data?.resultedDataCount || 0,
                                currentPage: salesManagementStore?.getSOPerfAnalysisData?.data?.currPage || 1,
                                maxPage: salesManagementStore?.getSOPerfAnalysisData?.data?.maxPage || 1
                            },

                            generalStore: generalStore,
                            refreshData: salesManagementStore?.getSOPerfAnalysisRequest?.isSuccess
                        },
                            ["generalStore", "salesManagementStore"],
                        )}
                </React.Fragment>
            </div>

            {/* order summary */}
            <SalesOrderSummary
                filterData={filterData}
                getCurrentMainLevelFilters={getCurrentMainLevelFilters}
                getFiltersOtherData={getFiltersOtherData}
            />
        </div>
    )
}

export default SalesOrderDashboard