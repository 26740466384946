/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react'
import moment from 'moment';
import '../../styles/stylesheets/home/Notification.scss'

const NotificationBox = (props) => {
    const { event_category, full_name, event_display_name, action_time, document_ref_no } = props.item
    const [copied, setCopied] = useState(false);

    const handleCopy = (textCopy) => {
        navigator.clipboard.writeText(textCopy);
        setCopied(true)
    }

    useEffect(()=>{
        if(copied)
            setTimeout (() => {
                setCopied(false);
            },3000)
    },[copied])

    const getIcon = (icon , name , time) => {
        // eslint-disable-next-line default-case
        switch (icon) {
            case "SUCCESS": return (
                <div className="status-icon si-success">
                    <span>{name[0]}</span>
                    <div className='si-sub-icon'>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.43652 6.64757C1.43652 6.1908 1.80681 5.82052 2.26358 5.82052C2.72034 5.82052 3.09063 6.1908 3.09063 6.64757V9.95578C3.09063 10.4125 2.72034 10.7828 2.26358 10.7828C1.80681 10.7828 1.43652 10.4125 1.43652 9.95578V6.64757Z" fill="#20BF6B"/>
                            <path d="M3.642 6.55567V9.54993C3.642 9.96762 3.87798 10.3495 4.25157 10.5362L4.27906 10.55C4.5853 10.7031 4.92299 10.7828 5.26537 10.7828H8.25165C8.77731 10.7828 9.22988 10.4118 9.33297 9.89636L9.99462 6.58815C10.1311 5.90578 9.60917 5.26915 8.91329 5.26915H6.95021V3.06367C6.95021 2.45465 6.45649 1.96094 5.84747 1.96094C5.54296 1.96094 5.2961 2.20779 5.2961 2.51231V2.87988C5.2961 3.35708 5.14133 3.82141 4.85501 4.20317L4.08309 5.23239C3.79677 5.61415 3.642 6.07847 3.642 6.55567Z" fill="#20BF6B"/>
                        </svg>
                    </div>
                </div>
            )
                break;
            case "FAILURE": return (
                <div className="status-icon si-failed">
                    <span>{name[0]}</span>
                    <div className='si-sub-icon'>
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.64545 5.48891C8.64545 5.88205 8.32675 6.20076 7.93361 6.20076C7.54047 6.20076 7.22176 5.88205 7.22176 5.48891V2.64153C7.22176 2.24839 7.54047 1.92969 7.93361 1.92969C8.32675 1.92969 8.64545 2.24839 8.64545 2.64153V5.48891Z" fill="#FD7272"/>
                            <path d="M6.7472 5.568V2.99084C6.7472 2.63134 6.54408 2.30269 6.22254 2.14192L6.19888 2.13009C5.9353 1.9983 5.64465 1.92969 5.34996 1.92969L2.77967 1.92969C2.32724 1.92969 1.9377 2.24903 1.84897 2.69267L1.2795 5.54005C1.16203 6.12736 1.61125 6.67532 2.21019 6.67532H3.89982V8.57357C3.89982 9.09776 4.32476 9.5227 4.84895 9.5227C5.11104 9.5227 5.32351 9.31023 5.32351 9.04813V8.73176C5.32351 8.32103 5.45673 7.92139 5.70316 7.59281L6.36755 6.70696C6.61398 6.37838 6.7472 5.97873 6.7472 5.568Z" fill="#FD7272"/>
                        </svg>
                    </div>
                </div>
            )
                break;
            case "WARN": return (
                <div className="status-icon si-plus">
                    <span>{name[0]}</span>
                    <div className='si-sub-icon'>
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.6585 1.71875V8.52403M9.06114 5.12139L2.25586 5.12139" stroke="#2969FF" stroke-width="1.00909" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            )
                break;
            case "INFO": return (
                <div className="status-icon si-plus">
                    <span>{name[0]}</span>
                    <div className='si-sub-icon'>
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.6585 1.71875V8.52403M9.06114 5.12139L2.25586 5.12139" stroke="#2969FF" stroke-width="1.00909" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            )
                break;
            case "REQC": return (
                <div className="status-icon si-reqc">
                    <span>{name[0]}</span>
                    <div className='si-sub-icon'>
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.86427 2.52179C4.04958 2.70709 4.04958 3.00753 3.86427 3.19283L2.77629 4.28081H5.42675C7.26116 4.28081 8.74825 5.7679 8.74825 7.60231V8.55131C8.74825 8.81337 8.53581 9.02581 8.27375 9.02581C8.01169 9.02581 7.79925 8.81337 7.79925 8.55131V7.60231C7.79925 6.29202 6.73704 5.22981 5.42675 5.22981H2.77629L3.86427 6.31779C4.04958 6.50309 4.04958 6.80353 3.86427 6.98883C3.67897 7.17414 3.37853 7.17414 3.19323 6.98883L1.29523 5.09083C1.10992 4.90553 1.10992 4.60509 1.29523 4.41979L3.19323 2.52179C3.37853 2.33649 3.67897 2.33649 3.86427 2.52179Z" fill="#F28C67"/>
                        </svg>
                    </div>
                </div>
            )
                break;
            case "COMMENT": return (
                <div className="status-icon si-cmnt">
                    <span>{name[0]}</span>
                    <div className='si-sub-icon'>
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.91398 6.66393H3.91882M5.84751 6.66393H5.85234M7.78104 6.66393H7.78587M10.1979 6.66393C10.1979 8.79965 8.25019 10.531 5.84751 10.531C5.10345 10.531 4.40303 10.365 3.79056 10.0723L1.49707 10.531L2.17138 8.73282C1.74435 8.13455 1.49707 7.42491 1.49707 6.66393C1.49707 4.52822 3.44483 2.79688 5.84751 2.79688C8.25019 2.79688 10.1979 4.52822 10.1979 6.66393Z" stroke="#50617D" stroke-width="0.845703" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            )
                break;
        }
    }

    return (
        <div className="notificatio-list-component">
            {/* bg-active */}
            {getIcon(event_category , full_name , action_time)}
            <div className="log-details">
                <label><b>{full_name}</b>{event_display_name}</label>
                <div className="ld-time">
                    <span className="ldt-date-time">{action_time && moment(action_time).format("MMM D, YYYY [at] h:mm A")}</span>
                    <span className="ldt-separator"></span>
                    <p className="ldt-poNo">{document_ref_no || "NA"}</p>
                    <div className="ldt-copy-icon" onClick={()=> handleCopy(document_ref_no)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.77978 14.2826H9.68369C10.8186 14.2826 11.7492 13.4311 11.8948 12.3353H12.28C13.5155 12.3353 14.5208 11.33 14.5208 10.0946V3.5415C14.5208 2.30609 13.5155 1.30078 12.2801 1.30078H6.37613C5.14072 1.30078 4.13542 2.30609 4.13542 3.5415V3.89714H3.77978C2.54437 3.89714 1.53906 4.90244 1.53906 6.13785V12.0418C1.53906 13.2772 2.54437 14.2826 3.77978 14.2826ZM5.43359 3.5415C5.43359 3.02177 5.85641 2.59896 6.37613 2.59896H12.28C12.7998 2.59896 13.2227 3.02177 13.2227 3.5415V10.0945C13.2227 10.6143 12.7998 11.0371 12.2801 11.0371H11.9245V6.13785C11.9245 4.90244 10.9192 3.89714 9.68376 3.89714H5.43359V3.5415ZM2.83724 6.13785C2.83724 5.61813 3.26006 5.19531 3.77978 5.19531H9.68369C10.2035 5.19531 10.6263 5.61813 10.6263 6.13785V12.0418C10.6263 12.5616 10.2035 12.9844 9.68376 12.9844H3.77978C3.26006 12.9844 2.83724 12.5616 2.83724 12.0418V6.13785Z" fill="#2969FF" />
                        </svg>
                        {copied && <span className="generic-tooltip">Copied</span>}
                    </div>
                </div>
            </div>
            {/* <span className="nndb-active-dot"></span> */}
        </div>
    )
}

export default NotificationBox