import React from 'react';
// import PoError from '../loaders/poError';
import GenericCustomButton from '../../GenericCustomButton';
import IconsPath from '../../../assets/icons/index'
export default class SaveFilterModal extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            filterName: "",
            filterType: "new",
            quickFilterItems: [],
            selectedFilter: "",
            alert: false,
            customHeadersType: this.props?.headersData?.item && this.props?.headersData?.item?.data && this.props?.headersData?.item["Custom Headers"] === undefined || this.props?.headersData?.item && this.props?.headersData?.item?.data && this.props?.headersData?.item["Custom Headers"]?.length === 0 ? "Default Headers" : "Custom Headers",
        }
    }

    getFilterValue = key => {
        switch(this.props.filterItems.columnType[key]) {
            case "NUMBER": 
            case "TIME": {
                return `From ${this.props.checkedFilters[key].from} to ${this.props.checkedFilters[key].to}`;
            }
            case "DATE": {
                if(!this.props.checkedFilters[key].key)
                    return `From ${this.props.checkedFilters[key].from} to ${this.props.checkedFilters[key].to}`;
                else if(this.props.checkedFilters[key].key === "SM_DATE_TODAY_VALUE")
                    return "Today";
                else if(this.props.checkedFilters[key].key === "SM_DATE_YESTERDAY_VALUE")
                    return "Yesterday";
                else if(this.props.checkedFilters[key].key === "SM_DATE_LAST7DAYS_VALUE")
                    return "Last 7 Days";
                else if(this.props.checkedFilters[key].key === "SM_DATE_LASTWEEK_VALUE")
                    return "Last Week";
                else if(this.props.checkedFilters[key].key === "SM_DATE_THISMONTH_VALUE")
                    return "This Month";
                else if(this.props.checkedFilters[key].key === "SM_DATE_LASTMONTH_VALUE")
                    return "Last Month";
                return "";
            }
            default: {
                if(this.props.checkedFilters[key] == "SM_TEXT_EMPTY_VALUE")
                    return "is Empty";
                else if(this.props.checkedFilters[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                    return "is Not Empty";
                else
                    return this.props.checkedFilters[key];
            }
        }
    }

    getItemFilterValue = key => {
        switch(this.props.headersData.item.data.columnType[key]) {
            case "NUMBER": 
            case "TIME": {
                return `From ${this.props.checkedFilters.item[key].from} to ${this.props.checkedFilters.item[key].to}`;
            }
            case "DATE": {
                if(!this.props.checkedFilters.item[key].key)
                    return `From ${this.props.checkedFilters.item[key].from} to ${this.props.checkedFilters.item[key].to}`;
                else if(this.props.checkedFilters.item[key].key === "SM_DATE_TODAY_VALUE")
                    return "Today";
                else if(this.props.checkedFilters.item[key].key === "SM_DATE_YESTERDAY_VALUE")
                    return "Yesterday";
                else if(this.props.checkedFilters.item[key].key === "SM_DATE_LAST7DAYS_VALUE")
                    return "Last 7 Days";
                else if(this.props.checkedFilters.item[key].key === "SM_DATE_LASTWEEK_VALUE")
                    return "Last Week";
                else if(this.props.checkedFilters.item[key].key === "SM_DATE_THISMONTH_VALUE")
                    return "This Month";
                else if(this.props.checkedFilters.item[key].key === "SM_DATE_LASTMONTH_VALUE")
                    return "Last Month";
                return "";
            }
            default: {
                if(this.props.checkedFilters.item[key] == "SM_TEXT_EMPTY_VALUE")
                    return "is Empty";
                else if(this.props.checkedFilters.item[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                    return "is Not Empty";
                else
                    return this.props.checkedFilters.item[key];
            }
        }
    }

    saveQuickFilter = () => {
        if(this.state.filterType === "new" && this.props.quickFilterItems.filter(item => item.filterName === this.state.filterName).length > 0) {
            this.setState({
                alert: true
            });
        }
        else {
            let filterName = this.state.filterType === "new" ? this.state.filterName : this.state.selectedFilter;
            this.props.saveQuickFilter(filterName, this.props.checkedFilters, false)();
        }
    }

    saveItemQuickFilter = () => {
        if(this.state.filterType === "new" && this.props.quickFilterItems.filter(item => item.filterName === this.state.filterName).length > 0) {
            this.setState({
                alert: true
            });
        }
        else {
            let filterName = this.state.filterType === "new" ? this.state.filterName : this.state.selectedFilter;
            this.props.saveQuickFilter(filterName, this.props.checkedFilters, false)();
        }
    }

    closeErrorModal = () => {
        this.setState({
            alert: false
        });
    }

    getExistingItemFilterValue = (key, filterValue) => {
        switch(this.props.headersData.item.data.columnType[key]) {
            case "NUMBER": 
            case "TIME": {
                return `From ${filterValue[key].from} to ${filterValue[key].to}`;
            }
            case "DATE": {
                if(!filterValue[key].key)
                    return `From ${filterValue[key].from} to ${filterValue[key].to}`;
                else if(filterValue[key].key === "SM_DATE_TODAY_VALUE")
                    return "Today";
                else if(filterValue[key].key === "SM_DATE_YESTERDAY_VALUE")
                    return "Yesterday";
                else if(filterValue[key].key === "SM_DATE_LAST7DAYS_VALUE")
                    return "Last 7 Days";
                else if(filterValue[key].key === "SM_DATE_LASTWEEK_VALUE")
                    return "Last Week";
                else if(filterValue[key].key === "SM_DATE_THISMONTH_VALUE")
                    return "This Month";
                else if(filterValue[key].key === "SM_DATE_LASTMONTH_VALUE")
                    return "Last Month";
                return "";
            }
            default: {
                if(filterValue[key] == "SM_TEXT_EMPTY_VALUE")
                    return "is Empty";
                else if(filterValue[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                    return "is Not Empty";
                else
                    return filterValue[key];
            }
        }
    }

    getExistingFilterValue = (key, filterValue) => {
        switch(this.props.filterItems.columnType[key]) {
            case "NUMBER": 
            case "TIME": {
                return `From ${filterValue[key].from} to ${filterValue[key].to}`;
            }
            case "DATE": {
                if(!filterValue[key].key)
                    return `From ${filterValue[key].from} to ${filterValue[key].to}`;
                else if(filterValue[key].key === "SM_DATE_TODAY_VALUE")
                    return "Today";
                else if(filterValue[key].key === "SM_DATE_YESTERDAY_VALUE")
                    return "Yesterday";
                else if(filterValue[key].key === "SM_DATE_LAST7DAYS_VALUE")
                    return "Last 7 Days";
                else if(filterValue[key].key === "SM_DATE_LASTWEEK_VALUE")
                    return "Last Week";
                else if(filterValue[key].key === "SM_DATE_THISMONTH_VALUE")
                    return "This Month";
                else if(filterValue[key].key === "SM_DATE_LASTMONTH_VALUE")
                    return "Last Month";
                return "";
            }
            default: {
                if(filterValue[key] == "SM_TEXT_EMPTY_VALUE")
                    return "is Empty";
                else if(filterValue[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                    return "is Not Empty";
                else
                    return filterValue[key];
            }
        }
    }
    
    render () {
        return (
            <div className="modal">
                <div className="backdrop modal-backdrop-new" onClick={this.props.closeSaveFilter}></div>
                <div className="modal-content save-filter-modal">
                    <div className="sfm-head">
                        <div className="sfmh-top">
                            <h3>Save Filter</h3>
                            <button type="button" onClick={this.props.closeSaveFilter}>
                                <IconsPath.ClearSearch />
                            </button>
                        </div>
                        <div className="sfmh-bottom">
                            <p>Create a new filter or replace an existing one</p>
                        </div>
                    </div>
                    <div className="sfm-body">
                        <div className="sfmb-filter-row">
                            <div className="sfmbfr-filter width_30">
                                <label className="gen-radio-btn">
                                    <input type="radio" name="imageUploadType" value="Normal" checked={this.state.filterType == "new"} autocomplete="off" onClick={() => this.setState({filterType: "new", selectedFilter: ""})}/>
                                    <span class="checkmark"></span>
                                    Save as new filter
                                </label>
                                {this.state.filterType === "new" ? (
                                     <input className="width_235 onFocus" type="text" placeholder="Enter filter name" value={this.state.filterName} onChange={(e) => this.setState({ filterName: e.target.value })} /> ) 
                                     : ( this.props.quickFilterItems !== undefined && this.props.quickFilterItems.length !== 0 && ( <select className="sfmbfr-filter width_235 onFocus" value={this.state.selectedFilter} onChange={(e) => this.setState({ selectedFilter: e.target.value })} > <option value="" > Select filter </option>
                                     {this.props.quickFilterItems.map((item, index) => (
                                         <option key={index} value={item.filterName}>
                                             {item.filterName}
                                         </option>
                                          ))}
                                     </select> ))}
                            </div>
                            <div className="sfmbfr-filter width_30">   
                                <label className="gen-radio-btn">
                                    <input type="radio" name="imageUploadType" value="Normal" checked={this.state.filterType == "existing"}  autocomplete="off" onClick={() => this.setState({filterType: "existing", filterName: ""})}/>
                                    <span class="checkmark"></span>
                                    Replace with existing filter
                                </label>
                                {/* {this.state.filterType === "new" ? 
                                <select className="sfmbfr-disabled" value="" disabled>
                                    <option value="" disabled>Select filter</option>
                                </select> :
                                <select className="sfmbfr-filter" value={this.state.selectedFilter} onChange={(e) => this.setState({selectedFilter: e.target.value})} >
                                    <option value="" disabled>Select filter</option>
                                    {this.props.quickFilterItems.map((item, index) => (
                                        <option value={item.filterName}>{item.filterName}</option>
                                    ))}
                                </select>} */}
                            </div>
                        </div>
                        <div className="sfmb-selected-filter">
                            <h3>Selected Filters</h3>
                            <div className="sfmbsf-box">
                                {Object.keys(this.props.checkedFilters).map((key) => {
                                    if(key === "item") {
                                        return Object.keys(this.props.checkedFilters.item).map(newKey=> (
                                            <div className="sfmbsfb-fil">
                                                <span className="sfmbsfb-name">{this.props.headersData.item.data[this.state.customHeadersType][newKey]}</span>
                                                <span className="sfmbsfb-num">{this.getItemFilterValue(newKey)}</span>
                                            </div>
                                        ))
                                    }
                                    return <div className="sfmbsfb-fil">
                                        <span className="sfmbsfb-name">{this.props.filterItems[this.props.headerType][key]}</span>
                                        <span className="sfmbsfb-num">{this.getFilterValue(key)}</span>
                                    </div>
                                })}
                            </div>
                        </div>
                        {this.state.filterType === "existing" && this.state.selectedFilter !== "" ?
                        <React.Fragment>
                            <div className="sfmb-existing-filter">
                                <h3>Existing Filters</h3>
                                <div className="sfmbsf-box">
                                    {this.props.quickFilterItems.map((item, index) =>
                                        item.filterName === this.state.selectedFilter ?
                                        Object.keys(item.filterValue).map((key) => {
                                            if(key === "item") {
                                                return Object.keys(item.filterValue.item).map(newKey=> (
                                                    <div className="sfmbsfb-fil">
                                                        <span className="sfmbsfb-name">{this.props.headersData.item.data[this.state.customHeadersType][newKey]}</span>
                                                        <span className="sfmbsfb-num">{this.getExistingItemFilterValue(newKey, item.filterValue.item)}</span>
                                                    </div>
                                                ))
                                            }
                                            return <div className="sfmbsfb-fil">
                                                <span className="sfmbsfb-name">{this.props.filterItems[this.props.headerType][key]}</span>
                                                <span className="sfmbsfb-num">{this.getExistingFilterValue(key, item.filterValue)}</span>
                                            </div>
                                        }) : null
                                    )}
                                </div>
                            </div>
                            <div className="sfmh-bottom m-top-15">
                                <img src={require('../../../assets/icons/SaveFilteWarningIcon.svg')} /><p className="warning"> On Saving, existing filter values will be replaced with selected filter values </p>
                            </div>
                        </React.Fragment> :
                        null}
                    </div>
                    <div className="sfm-footer">
                        <GenericCustomButton
                            className="generic-approve-btn"
                            disabled={(this.state.filterType === "new" && this.state.filterName === "") || (this.state.filterType !== "new" && this.state.selectedFilter === "")} 
                            onClick={this.props.isItemRequire ? this.saveItemQuickFilter : this.saveQuickFilter}
                            loading={this.props.isLoading}
                            label="Save Filter"
                        />
                    </div>
                </div>
                {/* {this.state.alert ? <PoError errorMassage={"A filter already exists with this name! Please enter a different name or select the existing filter to replace it."} closeErrorRequest={this.closeErrorModal} /> : null} */}
            </div>
        )
    }
}