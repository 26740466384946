import React, { useState } from 'react'
import Icons from '../../../../assets/icons';
import PurchaseDiscountAndTexPopover from './PurchaseDiscountAndTexPopover';

const TableColumnHOC = (props) => {

	let { tableConfig = {}, mainKey, item } = props;

	let { columnConfig = {} } = tableConfig;

	let { purcahseTermCharges = {} } = columnConfig;

	// Purchase term charges will look as 
	// purcahseTermCharges = {
	//   "orderNumber": {
	//     // Configuration for custom charges
	//   },
	//   "orderNumber" : () => {
	//     // For custom configuration
	//   }
	// }
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleMouseEnter = () => {
		setIsModalVisible(item?.id);
	};

	const handleMouseLeave = (e) => {
		if (e && !e?.currentTarget?.contains?.(e?.relatedTarget)) {
			setIsModalVisible(false);
		}
	};

	const renderPurchaseTermCharges = () => {
		if (mainKey in purcahseTermCharges) {
			// Render the purchase term popover here
			return typeof (purcahseTermCharges?.[mainKey]) == "function" ? purcahseTermCharges?.[mainKey]?.() : <div className='purchase-term-icon' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				<Icons.PurchaseTerm />
			</div>
		}
	}

	const renderExternalIcons = () => {

		return <React.Fragment>
			{(mainKey in purcahseTermCharges) && <>
				{renderPurchaseTermCharges()}
				{item?.id == isModalVisible && <PurchaseDiscountAndTexPopover handleMouseLeave={handleMouseLeave} handleMouseEnter={handleMouseEnter}
					termConfiguration={purcahseTermCharges?.[mainKey]}
					{...props}
				/>}
			</>}

		</React.Fragment>

	}

	return (
		<React.Fragment>
			{/* Render the column childern here */}
			{props?.children}

			{renderExternalIcons({})}
			{/* Render the external icons and buttons here */}
		</React.Fragment>
	)
}

export default TableColumnHOC