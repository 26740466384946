import { openDB } from "idb";
import { dispatchHandler } from "../../helper/apiHelperFunctions";
import { parseJwt, setCookie } from "../../helper/genericFunction";
import { isSetAutoLoginCookie } from "./helper/autoLoginFunctions";
import { getAllAnnouncements } from "../../initialPhaseOperations/getInitialPhaseFunctions";

// Function : To provide the updated modules for new changes SMP  
const getUpdatedModulesObject = (obj) => {
    let removeCodes = ["RDPPICIV2", "RDPPIVPRS","RDPPOCOV1","RDPPOCOV2","RDAREPSCHWH","RDAREPSCHIST", "RDPCONFMAIN"];
    let updateDisplayName = {
        "RDPPICORDER" : "Create Order",
        "RDDIPICIV2" : "Create Indent",
        "RDPPICIV3" : "Create Indent",
        "RDPPOCOV3" : "Create Order"
    }

    // console.log("ASDfdasf", obj, typeof(obj));

    obj = obj?.map(module => {
        // Remove these module or pages from the side menu
        if(removeCodes?.includes(module?.code)){
            return null;
        }
        // Changes the name of the page if required
        if(updateDisplayName?.[module?.code]){
            module.name = updateDisplayName[module?.code];
        }
        //  repeat the same process for the submodules also
        if(module?.subModules){
            module.subModules = getUpdatedModulesObject(module?.subModules)
        }
        return module;
    })?.filter(item => item ? true : false )

    return obj;

}

// Function -> Set the data inside the session storage
export const setSessionStorageItems = async (data) => {
    // Set all the modules 
    console.log("Data Token", data);
    if (sessionStorage?.getItem("loginTempInfo")) {
        sessionStorage?.removeItem("loginTempInfo");
    }
    sessionStorage.setItem("token", data?.token)
    sessionStorage.setItem('modules',  JSON.stringify(getUpdatedModulesObject(data?.modules?.["0"] || [])));
    sessionStorage.setItem("dashboardModules", JSON.stringify(data?.dashboardModules || []));
    sessionStorage.setItem("loginResponse", JSON.stringify(data || {}));
    
    // Update and set the token inside the cookie for auto login of user
    data?.token && isSetAutoLoginCookie() && setCookie({name : "jwt", value : data?.token, hours : 6})

    openDB('Supplymint-ai-local-storage', 1, {
        upgrade(db) {
            db.createObjectStore("MASTER_DB_FILTER");
            db.createObjectStore("MASTER_DB_COLORS");
            db.createObjectStore("MASTER_DB_HEADER");
            db.createObjectStore("MASTER_DB_DOWNLOAD_DOCUMENTS");
            db.createObjectStore("MASTER_DB_DROPDOWN");
            db.createObjectStore("AUTO_SAVE_DATA");
        }
    });
}

// Function -> Set the data inside the local storage.
export const setLocalStorageItems = async (data, dispatch) => {
    data?.token && localStorage.setItem('token', data?.token);
    let parseToken = parseJwt(data?.token);
    // Create User Session 
    dispatchHandler(dispatch, "userSessionCreateRequest", { autoSignIn: "NA", emailId: parseToken?.eml || "", keepMeSignIn: "false", mode: "WEB", token: data?.token, type: "LOGGEDIN", userName: parseToken?.prn || "" });
    // Call for the master Db Dropdown, Filter, Headers api.
    dispatchHandler(dispatch, "getMasterDbHeaderRequest", { "entity": "header" });
    dispatchHandler(dispatch, "getMasterDbFilterRequest", { "entity": "filter" });
    dispatchHandler(dispatch, "getMasterDbDropdownRequest", { "entity": "dropdown" });
    // Get the dropdown data also for the selected current organisation
    dispatchHandler(dispatch, "getMasterDbDocumentDropdownRequest", {});
    getAllAnnouncements({dispatch})
}
