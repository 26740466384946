import React from 'react';
import ReactDragListView from 'react-drag-listview';
import IconsPath from "../../../assets/icons/index";
import "../../../styles/stylesheets/home/SortingModal.scss"

export default class SortingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: {},
            sortedIn: this.props.sortedIn || "ASC",
        };
    }

    componentDidMount() {
        var items = [], checkedItems = {};
        if(this.props.checkedItems !== "") {
            items = this.props.checkedItems.split(",");
        }
        items.forEach(item => {
            checkedItems[item] = this.props.filterItems[item];
        });
        this.setState({
            checkedItems
        });
    }

    changeOrder = e => {
        this.setState({
            sortedIn: e.target.value
        });
    }

    checkItem = key => () => {
        this.setState(prevState => {
            let checkedItems = {...prevState.checkedItems};
            if(!checkedItems[key]) {
                checkedItems[key] = this.props.filterItems[key];
            }
            else {
                delete checkedItems[key];
            }
            return {
                checkedItems
            };
        });
    }

    closeSortingModal = () => {
        document.getElementById("sortingModal")?.classList?.remove("from-center-in")
        document.getElementById("sortingModal")?.classList?.add("from-center-out")
        setTimeout(()=>{
            this.props.close();
        }, 250)
    }
    
    render() {
        const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = Object.keys(that.state.checkedItems);
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                let dataObject = {};
                data.forEach((item) => {
                    dataObject[item] = that.state.checkedItems[item]
                });
                that.setState({ checkedItems: dataObject });
            },
            nodeSelector: 'li',
            handleSelector: 'a',
            lineClassName: 'drag-line'
        };

        return (
            <div className="modal">
                <div className="backdrop" onClick={this.closeSortingModal}></div>
                <div id='sortingModal' className="modal-content invetory-setting-modal advance-sorting-modal from-center-in">
                    <div className="asm-head">
                        <div className="asm-left">
                            <h3>Advance Sorting</h3>
                            <p>You can do column dependent sorting & arrange the column order</p>
                        </div>
                        <div className="asm-right">
                            <button type="button" onClick={this.closeSortingModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" id="close_6_" width="23.341" height="23.34" viewBox="0 0 23.341 23.34">
                                    <g id="Group_3015">
                                        <path fill="#21314b" id="Path_926" d="M11.671 23.351A11.67 11.67 0 0 1 3.414 3.424 11.676 11.676 0 1 1 11.67 23.35zM4.693 4.7a9.868 9.868 0 1 0 13.955 0 9.881 9.881 0 0 0-13.955 0z" class="cls-1" transform="translate(0 -.011)"></path>
                                    </g>
                                    <g id="Group_3016" transform="translate(7.625 7.51)">
                                        <path fill="#21314b" id="Path_927" d="M168.176 172.939a.9.9 0 0 1-.639-1.544l6.394-6.395a.9.9 0 0 1 1.279 1.279l-6.394 6.394a.9.9 0 0 1-.64.266z" class="cls-1" transform="translate(-167.27 -164.735)"></path>
                                        <path fill="#21314b" id="Path_928" d="M174.55 172.939a.9.9 0 0 1-.639-.265l-6.394-6.394A.9.9 0 0 1 168.8 165l6.394 6.394a.9.9 0 0 1-.639 1.544z" class="cls-1" transform="translate(-167.251 -164.735)"></path>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="asm-top">
                        <div className='w-fit relative'>
                            <label>Sorting Order</label>
                            <select onChange={this.changeOrder} value={this.state.sortedIn} >
                                <option value="ASC">Ascending</option>
                                <option value="DESC">Descending</option>
                            </select>
                            <IconsPath.DownArrowNew className='asmt-down-arrow' />
                        </div>
                    </div>
                    <div className="ism-body">
                        <div className="ismb-left">
                            <div className="ismbl-head">
                                <h3>Available Items</h3>
                                <p>You can select multiple headers from the below list.</p>
                            </div>
                            <ul className="chmbl-headers">
                                {Object.keys(this.props.filterItems).length === 0 || Object.keys(this.props.filterItems).length === Object.keys(this.state.checkedItems).length ?
                                <div className="chmbl-nodata">
                                    <div className='flex justify-center'><IconsPath.NoHeader /></div>
                                    <span>No Headers Available</span>
                                </div> :
                                Object.keys(this.props.filterItems).map(item => (
                                    (this.props.columnType && this.props.columnType[item] === "NOSORT") || this.state.checkedItems[item] !== undefined ?
                                    null :
                                    <li className="chmblh-items">
                                        <label className="checkBoxLabel0">
                                            <input type="checkBox" checked={this.state.checkedItems[item] !== undefined} onClick={this.checkItem(item)} />
                                            <span className="checkmark1"></span>
                                            {this.props.filterItems[item]}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="ismb-right">
                            <div className="ismbr-head">
                                <h3>Selected Items</h3>
                                <p>You can drag and drop the below column headers to arrange the order.</p>
                            </div>
                            {Object.keys(this.state.checkedItems).length === 0 ?
                            <div className="chmbl-nodata">
                                <div className='flex justify-center'><IconsPath.NoHeader /></div>
                                <span>No Headers Selected</span>
                            </div> :
                            <ReactDragListView {...dragProps}>
                                <ul>
                                    {Object.keys(this.state.checkedItems).map(item => (
                                        <li>
                                            <a><span className="chmblh-drag"></span></a>
                                            <span className="chmblhsl-text">{this.state.checkedItems[item]}</span>
                                            <span className="chmblhsl-close"><IconsPath.ClearSearch onClick={this.checkItem(item)} /></span>
                                        </li>
                                    ))}
                                </ul>
                            </ReactDragListView>}
                        </div>
                    </div>
                    <div className="asm-footer">
                        <button type="button" onClick={this.props.apply(Object.keys(this.state.checkedItems).join(","), this.state.sortedIn)}>Apply</button>
                    </div>
                </div>
            </div>
        )
    }
}