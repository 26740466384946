import React, { useEffect, useState } from 'react'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import Icons from '../../../assets/icons'
import GenericFormComponent from '../../../genericComponents/forms/GenericFormComponent'
import { useSelector } from 'react-redux'
import { openDB } from 'idb'

const PerformanceDashFilters = (props) => {
    const { availableDocument, PRIMARY_FILTERS, currentFilterKeys } = props

    const digivendStore = useSelector(state => state.digivendStore)
    const [errorData, setErrorData] = useState({})
    const [inputData, setInputData] = useState({})
    const [masterDbResponse, setMasterDbResponse] = useState({});

    useEffect(() => {
        updateMasterDbResponse()
    }, [])

    // Function: To update the master db response
    const updateMasterDbResponse = async () => {
        const db = await openDB("Supplymint-ai-local-storage", 1);
        let updatedMasterDbResponse = {};
        ["DYNAMIC_ITEM_CONFIG", "DYNAMIC_SITE_CONFIG"]?.map(async key => {
            let response = await db.get("MASTER_DB_DROPDOWN", key) || [];
            updatedMasterDbResponse[key] = response;
        })
        setMasterDbResponse(updatedMasterDbResponse)
    }

    const handleChange = (type, data) => {
        setInputData(prevInputData => ({
            ...prevInputData,
            [type]: data
        }));
    }

    // Function to provide all the currently selected filters to the current filter.
    const getFiltersOtherData = (filter, filterData = inputData) => {
        let otherData = {};
        const DROPDOWN_TYPE = filter.entity === "item" || filter.entity === "product_catalogue_vw" || filter.entity === "logged_actions" || filter.entity === "item_master" || filter.entity === "generic_item_master_report" ? "DYNAMIC_ITEM_CONFIG" : filter.entity === "site" ? "DYNAMIC_SITE_CONFIG" : filter.entity === "asn_dashboard_details" ? "DYNAMIC_SITE_CONFIG" : filter.entity === "box_performance_details" ? "DYNAMIC_SITE_CONFIG" : null;
        if (DROPDOWN_TYPE) {
            let response = masterDbResponse?.[DROPDOWN_TYPE] || [];
            Object.keys(filterData || {})?.map(key => {
                if (key != filter?.key) {
                    if (response?.includes(key)) {
                        otherData[key] = Object.keys(filterData?.[key] || {})
                    }
                }
            })
        }
        return otherData;
    }

    const provideInputs = () => {
        let inputFieldsData = []
        inputFieldsData.push(...PRIMARY_FILTERS.map((config) => {
            return {
                key: config?.key,
                iconRight: <Icons.DownArrowFilled />,
                viewDataType: "table-view",
                modalMainClass: "use-as-generic-dropdown",
                placeholder: `Select ${config?.value}`,
                type: 'dropdown',
                value: inputData?.[config?.key],
                onChange: (data) => handleChange(config?.key, data),
                inputProps: () => ({
                    viewDataType: 'table-view',
                    // label: { content: `Choose ${config?.value}` },
                    // search: {
                    //     placeholder: `Search ${config?.value}`,
                    //     searchOptions: {
                    //         "CONTAINS": "Contains",
                    //         "STARTS_WITH": "Starts With",
                    //         "EXACT_MATCH": "Exactly Matched"
                    //     },
                    //     searchOptionLabel: "Search By",
                    // },
                    api: {
                        reduxState: 'generalStore',
                        apiName: 'getSearchFilters',
                        apiPayload: (params) => {
                            return {
                                "entity": config?.entity,
                                "key": config.key,
                                "code": config?.code,
                                "search": params?.currentSearch || "",
                                "searchBy": params.searchFilter || "CONTAINS",
                                "pageNo": params?.currentPage || 1,
                                "moduleName": "",
                                "other_data": getFiltersOtherData(config),
                                "columnName": {}
                            }
                        },
                        getMaxPage: (states, functions, response) => Number(response?.resource?.maxPage || ""),
                    },
                    getListData: (response) => {
                        switch (config.key) {
                            default: return response?.resource?.searchResultNew?.map(data => ({ [config.key]: data[config.key], ...data }))
                        }
                    },
                    headerKeys: config.headerKeys ?? {
                        multiSelectCol: {},
                        [config.key]: { value: [config.value] },
                    },
                    itemKeys: config.itemKeys ?? {
                        [config.key]: [config.key],
                    },
                    multiselect: config.multiselect ?? {
                        selectId: config?.key,
                    },
                }),
            }
        }))
        return inputFieldsData
    }

    // function to get the applyied filters
    const applyFilters = (keys = currentFilterKeys || []) => {
        let filtersData = {};
        keys?.map(key => {
            if (
                Array?.isArray(inputData?.[key]) ? inputData?.[key]?.length > 0 : typeof (inputData?.[key]) == "object" ? Object.keys(inputData?.[key] || {})?.length > 0 : inputData?.[key]
            ) {
                switch (key) {
                    default: {
                        filtersData[key] = Object?.keys(inputData?.[key] || {});
                        break;
                    }
                }
            }
        })
        return filtersData;
    }

    const renderPrimaryFilters = () => {
        return (
            <GenericFormComponent
                rowInput={{
                    rowType: "singleRow",
                    availableInputs: provideInputs(),
                    inputState: { ...inputData },
                    inputStateChangeFunction: (data) => {
                        setInputData(data);
                    },
                    errorData: { ...errorData },
                    updateErrorData: (data) => {
                        setErrorData(data);
                    },
                    rowRightExtras: <React.Fragment>
                        <div className='flex justify-end'>

                            {/* button to apply filters */}
                            <GenericCustomButton
                                className='wid-104'
                                label="Apply Filter"
                                type="primary"
                                onClick={() => props.applyFilter(applyFilters())}
                                loading={digivendStore?.getARSDashboardDataData?.isLoading}
                            />

                            <GenericCustomButton
                                label="Clear All"
                                type="default"
                                onClick={() => {
                                    setInputData({});
                                    props.applyFilter()
                                }}
                                disabled={() => digivendStore?.getARSDashboardDataData?.isLoading ||
                                    Object.keys(inputData || {})?.length === 0}
                            />
                        </div>
                    </React.Fragment>,
                }}
                availableDocument={availableDocument || null}
            />
        )
    }

    return (
        <div className='primary-filters'>
            {renderPrimaryFilters()}
        </div>
    )
}

export default PerformanceDashFilters