import React from "react";
import { getTokenData } from "../../helper/apiHelperFunctions";
import '../../styles/stylesheets/home/LoadingPages.scss'
import IconsPath from '../../assets/icons/index'
import $ from 'jquery'
import GenericCustomButton from "../../genericComponents/GenericCustomButton";
import ParentModal from "../../genericComponents/ParentModal";
import UpgradePopUpRender from "./UpgradePopUpRender";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            hasError: false,
            reportingIssue: false,
            modalType: "",
            isOnline: false,
        };
    }

    componentDidMount() {
        $("div#globalHeader")?.addClass("d-none");
        $("div#globalSidebar")?.addClass("d-none");
        $("div#globalFooter")?.addClass("d-none");
        $("div#globalAddMoreBtn")?.addClass("d-none");
        window.addEventListener("online", this.handleOnline);
    }
    componentWillUnmount() {
        $("div#globalHeader")?.removeClass("d-none");
        $("div#globalSidebar")?.removeClass("d-none");
        $("div#globalFooter")?.removeClass("d-none");
        $("div#globalAddMoreBtn")?.removeClass("d-none");
        window.removeEventListener("online", this.handleOnline);
    }

    handleOnline = () => {
        this.setState({
            isOnline : navigator.onLine,
        })
    }

    static getDerivedStateFromError(error) {
        if (error?.message?.toLowerCase()?.includes("loading chunk") && error?.message?.toLowerCase()?.includes("failed") && this.state.isOnline) {
            return ({
                modalType: "loadingChunkError",
                hasError: true,
                error: error.message + "\n" + error.stack,
            })
        }
        return {
            error: error.message + "\n" + error.stack,
            hasError: true
        };
    }

    button = document.getElementById('globalAddMoreBtn');
    reportIssue = (e) => {
        e.preventDefault();
        // this.setState({
        //     reportingIssue: true
        // });
        this.button.addEventListener(onclick)
    }

    home = () => {
        if (getTokenData() == null) {
            window.location.href = "#/";
        }
        else {
            window.location.href = "#/home";
        }
        this.setState({
            error: {},
            hasError: false,
            reportingIssue: false,
            modalType: ""
        });
    }

    render() {
        if (this.state.hasError) {

            if (this.state.modalType == "loadingChunkError") {
                return <>
                    {this.props.children}

                    {/* Render the parent modal here to render the chunk load error */}
                    <ParentModal
                        getParentChildProps={{
                            // animationType: "center",
                            postitionProps: { top: "50%", left: "50%", borderRadius: 0 },
                            dimensions: { width: "40vw", height: "auto" },
                            zIndexClass: "with-auto-height",
                            isCloseBlocked: true
                        }}
                        childComponent={UpgradePopUpRender}
                    />
                </>
            }

            return (
                <div className="page-crashed">
                    <div className="pc-content">
                        <label>500 error</label>
                        <h1>Oops, Thats our bad</h1>
                        <p>We’re not exactly sure what happened, but our servers say something is wrong</p>
                        <div className="pcc-btns">
                            <GenericCustomButton
                                type="normal"
                                className="home-btn"
                                onClick={this.home}
                                label="Go Back"
                                leftIcon={<IconsPath.LeftArrow />}
                            />
                            {/* <GenericCustomButton
                                type="normal"
                                className="issue-btn"
                                onClick={this.reportIssue}
                                label="Report Issue"
                            /> */}
                        </div>
                        <div className="pcc-help">
                            <button onClick={() => { window.open("https://www.supplymint.com/contact") }} >
                                <div className="flex items-center">
                                    <label>Contact</label>
                                    <IconsPath.RightArrowBlue />
                                </div>
                                <p>Ask your Queries</p>
                            </button>
                            <button onClick={() => { window.open("https://www.supplymint.com/blogs/") }}>
                                <div className="flex items-center">
                                    <label>Our Blog</label>
                                    <IconsPath.RightArrowBlue />
                                </div>
                                <p>Read the latest post on our blog</p>
                            </button>
                            <button onClick={() => { window.open('https://helpsupplymint.myfreshworks.com/login') }}>
                                <div className="flex items-center">
                                    <label>Help & Support</label>
                                    <IconsPath.RightArrowBlue />
                                </div>
                                <p>Read Documentation & get Product functional Knowledge</p>
                            </button>
                        </div>
                    </div>
                    <div>
                        <svg width="418" height="364" viewBox="0 0 418 364" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="233.921" cy="196.363" rx="137.274" ry="139.413" fill="#E4E7EC" />
                            <g filter="url(#filter0_d_23375_18239)">
                                <g filter="url(#filter1_d_23375_18239)">
                                    <path d="M115.033 89.024L232.636 130.506V270.346L115.033 217.318V89.024Z" fill="url(#paint0_linear_23375_18239)" />
                                </g>
                                <g filter="url(#filter2_d_23375_18239)">
                                    <path d="M351.094 72.3456L232.636 130.506V270.346L351.094 216.462V72.3456Z" fill="url(#paint1_linear_23375_18239)" />
                                </g>
                                <path d="M115.033 89.024L232.636 37.7065L351.094 72.3457L232.636 130.506L115.033 89.024Z" fill="#F3F4F8" />
                                <path d="M193.72 54.5921L305.763 94.5832L270.269 112.117L162.93 68.2767L193.72 54.5921Z" fill="#666AF6" />
                                <path d="M305.764 94.5832V139.058L270.269 156.384V112.117L305.764 94.5832Z" fill="#666AF6" />
                            </g>
                            <circle cx="401.132" cy="156.164" r="16.2505" fill="#666AF6" />
                            <circle cx="8.12527" cy="229.292" r="8.12527" fill="#666AF6" />
                            <circle cx="135.564" cy="336.203" r="27.797" fill="#F3F4F8" />
                            <circle cx="46.6142" cy="53.957" r="10.6911" fill="#F3F4F8" />
                            <defs>
                                <filter id="filter0_d_23375_18239" x="94.5061" y="37.7065" width="277.115" height="273.693" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="20.527" />
                                    <feGaussianBlur stdDeviation="10.2635" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.776471 0 0 0 0 0.8 0 0 0 0 0.831373 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_23375_18239" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_23375_18239" result="shape" />
                                </filter>
                                <filter id="filter1_d_23375_18239" x="94.5061" y="80.471" width="158.657" height="222.376" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="11.9741" />
                                    <feGaussianBlur stdDeviation="10.2635" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.776471 0 0 0 0 0.8 0 0 0 0 0.831373 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_23375_18239" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_23375_18239" result="shape" />
                                </filter>
                                <filter id="filter2_d_23375_18239" x="212.109" y="63.7927" width="159.512" height="239.054" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="11.9741" />
                                    <feGaussianBlur stdDeviation="10.2635" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.776471 0 0 0 0 0.8 0 0 0 0 0.831373 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_23375_18239" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_23375_18239" result="shape" />
                                </filter>
                                <linearGradient id="paint0_linear_23375_18239" x1="229.642" y1="131.361" x2="115.461" y2="216.035" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#F2F3F7" />
                                    <stop offset="1" stop-color="#F3F4F8" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_23375_18239" x1="232.636" y1="130.933" x2="351.522" y2="207.482" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E4E7EC" />
                                    <stop offset="1" stop-color="#F2F3F7" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}