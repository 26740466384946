import { CONFIG } from '../../config/index';
import { AUTH_CONFIG } from '../../authConfig/index'
export const authStore = [
    {
        title: "getCurrrentUserAuthDetailsData",
        actionPrefix: 'getCurrrentUserAuthDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${AUTH_CONFIG.BASE_URL}/get/details`,
            extraHeader: {
                "Page-Link": undefined,
            }
        }
    },
    {
        title: "getCurrrentUserAuthDetailsCopyData",
        actionPrefix: 'getCurrrentUserAuthDetailsCopy',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${AUTH_CONFIG.BASE_URL}/get/details`,
            extraHeader: {
                "Page-Link": undefined,
            }
        }
    },
    {
        title: "loginData",
        actionPrefix: 'login',
        message: 'User Logged In Successfully.',
        errorMessage: "Failed To Log In With Provided Credentials!",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}${AUTH_CONFIG.LOGIN}`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined,
                "Origin": undefined,
            }
        }
    },
    {
        title: "getOtpData",
        actionPrefix: 'getOtp',
        message: 'Otp Sent Successfully on Provided Credentials.',
        errorMessage: "Failed To Send OTP on Provided Credentials.",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}/verify/account`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined
            }
        }
    },
    {
        title: "verifyOtpData",
        actionPrefix: 'verifyOtp',
        message: 'Provided OTP Verified Successfully.',
        errorMessage: "Failed to Verify the Provided OTP",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}/verify/account/otp`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined
            }
        }
    },
    {
        title: "resetPasswordData",
        actionPrefix: 'resetPassword',
        message: 'Your Password Has Been Successfully Changed.',
        errorMessage: "Failed To Change Your Password!",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}/reset/password`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined
            }
        }
    },
    {
        title: "changePasswordData",
        actionPrefix: 'changePassword',
        message: 'Your Password Has Been Successfully Changed.',
        errorMessage: "Failed To Change Your Password!",
        requestData: {
            token : false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}${AUTH_CONFIG.CHANGE_PASSWORD}`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined
            }
        }
    },
    {
        title: "refreshTokenData",
        actionPrefix: 'refreshToken',
        message: 'Logged in Again Into Supplymint.',
        errorMessage: "Failed to Relogin Into Supplymint",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}${AUTH_CONFIG.LOGIN}`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined
            }
        }
    },
    {
        title: "getUserProfileData",
        actionPrefix: 'getUserProfile',
        message: '',
        errorMessage: "Cannot Find Profile Data for Current User!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/get/profile`,
        }
    },
    {
        title: "getSupportUserData",
        actionPrefix: 'getSupportUser',
        message: '',
        errorMessage: "Cannot Find Support User for Current User!",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}/has/support/user`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined
            }
        }
    },
    {
        title: "editUserData",
        actionPrefix: 'editUser',
        message: 'User Information has been Updated Successfully.',
        errorMessage: "Cannot Edit User Information at this Moment!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/edit/profile`,
        }
    },
    {
        title: "getProfileMappingData",
        actionPrefix: 'getProfileMapping',
        message: '',
        errorMessage: "Unable to Get the Data Mapping For Current User!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/data/mapping`,
        }
    },
    {
        title: "updateUserInformationData",
        actionPrefix: "updateUserInformation",
        message: 'OTP Sent Successfully on Provided Email.',
        errorMessage: "Unable to Verify Your Provided Credentials!",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}/verify/account/update`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined
            }
        }
    },
    {
        title: "getNotificationActiveData",
        actionPrefix: "getNotificationActive",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/get/status`,
        }
    },
    {
        title: "updateNotificationActiveData",
        actionPrefix: "updateNotificationActive",
        message: "Notification Settings Changed Successfully.",
        errorMessage: "Failed To Updated the Notification Changes!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/update/status`,
        }
    },
    {
        title: "getNotificationModuleData",
        actionPrefix: "getNotificationModule",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/get/all/module`,
        }
    },
    {
        title: "getNotificationSubModuleData",
        actionPrefix: "getNotificationSubModule",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/get/all/subModule?moduleName=${payload.key}`,
        }
    },
    {
        title: "getNotificationConfigModuleData",
        actionPrefix: "getNotificationConfigModule",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/get/configuration/property?subModuleName=${payload.key}`,
        }
    },
    {
        title: "getNotificationEmailData",
        actionPrefix: "getNotificationEmail",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/get/configuration?moduleName=${payload.moduleName}&subModuleName=${payload.subModuleName}&configurationProperty=${payload.configName}`,
        }
    },
    {
        title: "updateNotificationEmailData",
        actionPrefix: "updateNotificationEmail",
        message: "Email Notification Created Successfully for Selected Module.",
        errorMessage: "Failed To Create Email Notification for Selected Module!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/update`,
        }
    },
    {
        title: "switchEntData",
        actionPrefix: "switchEnt",
        message: 'Switched to Selected Oraganization.',
        errorMessage: "Failed to Switch to Selcted Oraganization",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}/switch/ent/info`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined
            }
        }
    },
    {
        title: "updateOrgIdData",
        actionPrefix: "updateOrgId",
        message: 'Default Oraganization Set Successfully.',
        errorMessage: "Failed to Set the Default Oraganization!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/user/update/default/org/${payload.entId}/${payload.orgId}`,
        },
        responseData: {
            successDataHandler: (response, payload) => {
                let resp = JSON.parse(JSON.stringify(response));
                if (resp?.data?.data) {
                    resp.data.data["orgId"] = payload?.["orgId"];
                    resp.data.data["entId"] = payload?.["entId"];
                }
                // console.log("Resource Data1", resp, payload);
                return resp?.data?.data;
            },
        }
    },
    // vendoronboarding apis
    {
        title: "getMenuDetailsData",
        actionPrefix: "getMenuDetails",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/user/start/vendor/onboarding?type=${payload?.type}`,
        }
    },
    {
        title: "getMenuBarDetailsData",
        actionPrefix: "getMenuBarDetails",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/user/start/vendor/onboarding?type=${payload?.type}`,
        }
    },
    {
        title: "getVendorOnboardingPercentageData",
        actionPrefix: "getVendorOnboardingPercentage",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/user/onboarding/progress/percentage`,
        }
    },
    {
        title: "saveVendorOnBoardingDetailsData",
        actionPrefix: "saveVendorOnBoardingDetails",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/user/save/onboarding/progress`,
        }
    },
    {
        title: "getFactoryDetailsData",
        actionPrefix: "getFactoryDetails",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/user/start/vendor/onboarding?type=${payload?.type}`,
        }
    },
    {
        title: "createNewUserData",
        actionPrefix: "createNewUser",
        message: "User setup completed successfully!",
        errorMessage: "Failed to complete the user setup!",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}/account/setup`,
            extraHeader: {
                "access-control-request-headers": "content-type",
                "access-control-request-method": "POST",
                "Page-Link": undefined,
                "Origin": undefined,
            }
        }
    },
    {
        title: "mergeVendorsAccountData",
        actionPrefix: "mergeVendorsAccount",
        message: "Vendor accounts merged successfully!",
        errorMessage: "Failed to merge the vendors account!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/call/functions`,
        }
    },
    {
        title: "getAllMergeVendorListData",
        actionPrefix: "getAllMergeVendorList",
        errorMessage: "Failed to fetch the vendors list!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/managevendor/get/other/pending/signup`,
        }
    },
    {
        title: "getMergeAccountListData",
        actionPrefix: "getMergeAccountList",
        errorMessage: "Failed to fetch the list!",
        requestData: {
            token: false,
            buildUrl: (payload) => `${CONFIG.BASE_URL}/get/details`,
        }
    },
    {
        title: "newVendoronboardingRegisterData",
        actionPrefix: "newVendoronboardingRegister",
        errorMessage: "Failed to register!",
        requestData: {
            token: false,
            buildUrl: (payload) => `${CONFIG.BASE_URL}/open/key/register/vendor/onboarding`,
        }
    },
]