import React from 'react'
import {Switch} from 'antd'

const GenericToggleComponent = (props) => {
    const {checked = false, loading, checkedComponent, uncheckedComponent, className, disabled, onChange} = props;
    return (
        <div className='ant-toggle'>
            <Switch 
                className= {className}
                loading = {loading} 
                disabled = {disabled}
                checked={checked} 
                onChange = {val => onChange(val) }
                checkedChildren={checkedComponent}
                unCheckedChildren={uncheckedComponent}
            />
        </div>
    )
}

export default GenericToggleComponent