import dayjs from "dayjs";
import { validateNumber, validateEmail, validateMobileNumber } from "./apiHelperFunctions";
export function validatePincode(val) {
    return val !== "" && val.match(/^[0-9]{6}$/) ? true : false;
    // return val !== "" && val.match(/^[1-9][0-9]{5}$/) ? true :false;
}

export function changeKey(key) {
    if (key.includes("order")) return "orderNumber"
    else if (key.includes("shipment")) return "shipmentAdviceCode"
    else if (key.includes("logistic")) return "logisticNumber"
    else if (key.includes("set")) return "setBarCode"
    else if (key.includes("item")) return "itemCode"
    return key;
}

// Function : To check for the mandatory condition for multiple inputs 
export const finalSubmitMandatoryValidationsCheck = (inputFields, state, labelKey, parameters = {}) => {

    let { decimalPoint } = parameters;
    let updatedErrorData = {}
    inputFields?.forEach((item, inputIndex) => {

        updatedErrorData[item?.key] = [];
        // Mandatory condition check
        if ((item?.mandatory ? (typeof (item?.mandatory) == "function" ? item?.mandatory() : item?.mandatory) : false) && !state?.[item?.key]) {
            let avoidMandatoryCheck = item?.avoidMandatoryCheck ? typeof (item?.avoidMandatoryCheck) == "function" ? item?.avoidMandatoryCheck() : item?.avoidMandatoryCheck : false;
            if (!avoidMandatoryCheck) {
                updatedErrorData?.[item?.key].push(`${item?.[labelKey] || "This"} is a mandatory field, please provide a valid value inside input.`)
            }
        }
        else if (item?.mandatory && item?.type === "upload" && state?.[item?.key] && state?.[item?.key]?.length == 0) {
            updatedErrorData?.[item?.key].push(`${item?.[labelKey] || "This"} is a mandatory field, please provide a valid value inside input.`)
        }
        else if (item?.type === "number" && state?.[item?.key]) {
            let errorMessages = validateNumber(state?.[item?.key] || "", "number", "number")
            errorMessages?.length > 0 && updatedErrorData?.[item?.key].push(...errorMessages);
        } else if (item?.type === "decimal" && state?.[item?.key]) {
            let errorMessages = validateNumber(state?.[item?.key] || "", "decimal", "decimal", decimalPoint)
            errorMessages?.length > 0 && updatedErrorData?.[item?.key].push(...errorMessages);
        } else if (item?.type === "email" && state?.[item?.key]) {
            let isEmailValid = validateEmail(state?.[item?.key])
            !isEmailValid && updatedErrorData?.[item?.key].push(`Please provide a valid email inside ${item?.[labelKey]} input field.`);
        } else if (item?.type === "mobileNo" && state?.[item?.key]) {
            let isMobileNumberValid = validateMobileNumber(state?.[item?.key])
            !isMobileNumberValid && updatedErrorData?.[item?.key].push(`Please provide a valid mobile number inside ${item?.[labelKey]} input field.`);
        }

        if (item?.validationFunction) {
            let errorMessages = item?.validationFunction(state?.[item?.key]);
            errorMessages?.length > 0 && updatedErrorData?.[item?.key].push(...errorMessages);
        }

        if (updatedErrorData?.[item?.key]?.length === 0) {
            delete updatedErrorData?.[item?.key];
        }
    })
    return updatedErrorData
}

// Function -> To replace the image if image not gets loaded
export const handleImageError = (e, imagePath) => {
    const img = document.createElement("img");
    img.src = imagePath;
    img.alt = "Image Not available";
    img.className = "pltmt-img";
    e.target.replaceWith(img);
}


export function onDragEnd(fromIndex, toIndex, config) {
    let { selectedData = {} } = config;
    const data = Object.keys(selectedData || {});
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    let dataObject = {};
    data.forEach((item) => {
        dataObject[item] = selectedData[item]
    });
    return dataObject;
}


// Function : To update the cache and clear all the data inside from the cache
export function emptyCache() {
    if ('caches' in window) {
        let flag = false;
        caches.keys().then((names) => {
            console.log("cache keys", names);
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
                flag = true;
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        flag && window.location.reload(true);
    }
}

export function getCurrentDateFormat(dateString) {

    if (dateString) {
        const formats = [
            "DD MMMM YYYY",
            "DD MMM YYYY",
            "YYYY-MM-DD",
            "YYYY/MM/DD HH:mm",
            'YYYY-MM-DD',
            "YYYY/MM/DD HH:mm",
            "DD MMMM YYYY HH:mm",
            "DD MMM YYYY HH:mm",
            "DD-MM-YYYY HH:mm",
            "DD MMM YYYY 'AT' HH:mm",
            'YYYY-MM-DDTHH:mm:ssZ',
            "E MMM dd HH:mm",
            'MM/DD/YYYY',
            'DD-MM-YYYY',
            'YYYY/MM/DD'
            // Add other formats as needed
        ];

        let matchedFormat = null;

        for (const format of formats) {
            const parsedDate = dayjs(dateString, format, true); // strict parsing
            if (parsedDate.isValid()) {
                matchedFormat = format;
                break;
            }
        }
        return matchedFormat;
    }

    return null

}

// Function : To get the type based on the url 
export const getFileTypeFromUrl = (url) => {
    let fileUrl = url?.split('?')?.[0];
    let lastIndexOfDot = fileUrl.lastIndexOf('.');
    let ext = fileUrl?.slice(lastIndexOfDot+1, fileUrl.length);
    return ext;
}

// Function : To get the name of the file type based on the extension of file
export const getFileNameFromExt = (ext) => {
    let fileName = '';
    switch(ext){
        case 'jpg':
        case 'jpeg':
        case 'png' :
            fileName = 'image';
            break;
        case 'mp4':
        case '.mov':
        case '.wmv':
        case 'avi' :
            fileName = 'video';
            break;
        case 'pdf':
            fileName = 'pdf';
            break;
        default:{
            fileName = 'file';
            break;
        }
    }
    return fileName;
}

// Function : To convert the file disposition type to inline
export const convertNormalFileUrlIntoDispositionUrl = (url) => {
        
}