import React, { useEffect, useState } from 'react'
import ProfileMappingSkeletonLoader from '../../../skeletons/ProfileMappingSkeletonLoader';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { parseJwt } from '../../../../helper/genericFunction';
import Icons from '../../../../assets/icons';

const DataMappingView = () => {
    const dispatch = useDispatch()
    const authStore = useSelector(state => state.authStore);

    const [mappingData, setMappingData] = useState({});

    useEffect(() => {
        if (authStore?.["getProfileMappingData"]?.data?.resource) {
            setMappingData(authStore?.["getProfileMappingData"]?.data?.resource || {})
        } else if (parseJwt(sessionStorage.getItem('token')).uType === "ENT") {
            let payload = {
                key: null
            }
            dispatchHandler(dispatch, "getProfileMappingRequest", payload);
        }
    }, [])

    useEffect(() => {
        if (authStore?.["getProfileMappingData"]?.isSuccess) {
            setMappingData(authStore?.["getProfileMappingData"]?.data?.resource || {})
        }
    }, [authStore?.["getProfileMappingData"]?.isSuccess])

    const checkDefault = () => {

    }

    return (
        authStore?.["getProfileMappingData"]?.isLoading ? <ProfileMappingSkeletonLoader /> : 
        <div className="upr-data-mapping">
            <div className="pdmt-head">
                <h3>Data Mapping</h3>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.PackageProducts/>
                        <h3>Item Data</h3>
                        <span className="pdmtit-count">{mappingData?.ITEM_MAPPING != undefined ? mappingData?.ITEM_MAPPING?.length : ""}</span>
                    </div>
                    <span className="pdmtit-text">Below are the items mapped with this account</span>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.ITEM_MAPPING != undefined && mappingData?.ITEM_MAPPING?.length != 0 ? mappingData?.ITEM_MAPPING.map((data1, key1) => (
                                <tr>
                                    <td className="pdmti-depart">{data1.column_key && data1.column_key}</td>
                                    <td className="pdmti-flex-td">
                                        {data1?.search_value && JSON.parse(data1?.search_value).map((data2, key2) => (
                                            <input type="text" autoComplete="off" value={data2} />
                                        ))}
                                    </td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.SiteMappingData/>
                        <h3>Site Data</h3>
                        <span className="pdmtit-count">{mappingData?.SITE_MAPPING != undefined ? mappingData?.SITE_MAPPING?.length : ""}</span>
                    </div>
                    <span className="pdmtit-text">Below are the Sites mapped with this account</span>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.SITE_MAPPING != undefined && mappingData?.SITE_MAPPING?.length != 0 ? mappingData?.SITE_MAPPING?.map((data1, key1) => (
                                <tr>
                                    <td className="pdmti-depart">{data1?.column_key && data1?.column_key}</td>
                                    <td className="pdmti-flex-td">
                                        {data1?.search_value && JSON.parse(data1.search_value).map((data2, key2) => (
                                            <input type="text" autoComplete="off" value={data2} className={checkDefault(data1, key2) ? "default-value" : ""} />
                                        ))}
                                    </td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.VendorMappingData/>
                        <h3>Vendor Data</h3>
                        <span className="pdmtit-count">{mappingData?.VENDOR_MAPPING != undefined ? mappingData?.VENDOR_MAPPING?.length : ""}</span>
                    </div>
                    <span className="pdmtit-text">Below are the vendors data mapped with this account</span>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.VENDOR_MAPPING != undefined && mappingData?.VENDOR_MAPPING?.length != 0 ? mappingData?.VENDOR_MAPPING?.map((data1, key1) => (
                                <tr>
                                    <td className="pdmti-depart">{data1.column_key && data1.column_key}</td>
                                    <td className="pdmti-flex-td">
                                        {data1?.search_value && JSON.parse(data1.search_value).map((data2, key2) => (
                                            <input type="text" autoComplete="off" value={data2} />
                                        ))}
                                    </td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.ManagementData/>
                        <h3>Manager</h3>
                        <span className="pdmtit-count">{mappingData?.MANAGER_MAPPING != undefined ? mappingData?.MANAGER_MAPPING?.length : ""}</span>
                    </div>
                    <span className="pdmtit-text">Below are the Managers mapped with this account</span>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.MANAGER_MAPPING != undefined && mappingData?.MANAGER_MAPPING?.length != 0 ? mappingData?.MANAGER_MAPPING?.map((data1, key1) => (
                                <tr>
                                    <td className="pdmti-depart">{data1.column_key && data1.column_key}</td>
                                    <td className="pdmti-flex-td">
                                        {data1.search_value && JSON.parse(data1.search_value).map((data2, key2) => (
                                            <input type="text" autoComplete="off" value={data2} />
                                        ))}
                                    </td>

                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DataMappingView