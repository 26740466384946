import React from 'react'
import IconsPath from '../assets/icons/index'

const NoDataFound = (props) => {
  return (
    <div className='no-data-found-screen'>
      <div className='ndfs-inner'>
        <IconsPath.NoDataFound />
        <h3>{props?.primaryText || "No Data Found!"}</h3>
        {props.secondryText && <p>{props.secondryText}</p>}
      </div>
    </div>
  )
}

export default NoDataFound