/* eslint-disable default-case */
import React from 'react'

const GenericTabsComponent = (props) => {
    const { current, handleTabChange, tabsData, disabledAction, type = 1, className, disabledTab } = props

    const renderTabs = () => {
        return <ul className={`generic-tabs ${className}`}>
            {Object.keys(tabsData || {})?.map(key => {
                return (
                    <button className={`gt-list ${current == key ? "activeTab" : ""}`} disabled={disabledTab?.[key] || false} onClick={() => {key!=current && handleTabChange(key)}} key = {key} >
                        {tabsData?.[key]}
                    </button>
                )
            })}
        </ul>
    }

    const renderTabsData = () => {
        switch(type){
            case 1 : {
                return tabsData && Object.keys(tabsData || {})?.length > 0 && renderTabs()
            }
        }
    }

    return (
        <React.Fragment>
            {renderTabsData()}
        </React.Fragment>
    )
}

export default GenericTabsComponent