/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React from 'react';
import Attach from '../../assets/icons/attach.svg';
import DownloadPdf from '../../assets/icons/downloadpdf.svg';
import SmallClose from '../../assets/icons/smallClose.svg';
import moment from 'moment';
import axios from 'axios';
import { CONFIG } from '../../config/index'
// import ToastLoader from '../loaders/toastLoader';
import { decodeToken, parseJwt } from "../../helper/genericFunction"
// import FilterLoader from '../loaders/filterLoader';
import EmailTranscript from './emailGenericComponents/EmailTranscript';
import GenericConfirmationModal from '../GenericConfiramationModal'
import Tagify from '@yaireo/tagify'
import IconsPath from '../../assets/icons/index'
export default class CommentBoxModal extends React.Component {
    constructor(props) {
        console.log("Comment Box Modal mounted");
        super(props)
        this.state = {
            closeChatBox: "show",
            comment: "",
            current: 0,
            toastLoader: false,
            toastMsg: "",
            loader: false,
            sendComment: [],
            allComment: [],
            id: this.props.data.orderCode,
            isAttachment: false,
            attachments: [],
            allEmails: [],
            handleEmailDrop: false,
            searchComment: "",
            type: 1,
            emailAttach: [],
            cursor: 0,
            file: "",
            filteredEmails: [],
            selectedTags: [],
            message: '',
            errorToast: false,
            successToast: false,
            transcriptModal: false,
            confirmModal: false,
            toEmails: "",
            ccEmails: "",
            headerMsg: "",
            prefix: undefined,
            disableSendTranscriptBtn: false,
            remark: "",
            successMessage: ""
        }
        this.myRef = React.createRef();
        this.subRef = React.createRef();
        this.successToastTimeout = null;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.getEmails()
        let payload = {
            // shipmentId: this.props.data.shipmentId == undefined ? this.props.data.id : this.props.data.shipmentId,
            // module: this.props.module,
            // no: this.state.current + 1,
            // orderNumber: this.props.data.orderNumber,
            // // orderCode: this.props.data.orderCode,
            // orderId: this.props.orderId,
            // documentNumber: this.props.documentNumber,
            // subModule: "COMMENTS"

            module: this.props.module,
            pageNo: this.state.current + 1,
            subModule: "COMMENTS",
            isAllAttachment: "",
            type: 1,
            search: "",
            commentId: this.props.data.commentId,
            commentCode: this.props.data.commentCode,
            boxNumber: this.props.data.boxNumber || "",
        }
        this.props.handleApiChange("getAllCommentRequest", payload)
        // this.props.getAllCommentRequest(payload)

        // -------------------------------------------------------------
        let commentPayload = {
            module: this.props.module,
            status: "",
            orderNumber: this.props.data.orderNumber,
            orderId: this.props.data.orderId,
            status: "SEEN",
            shipmentId: this.props.data.shipmentId == undefined ? this.props.data.id : this.props.data.shipmentId,
            commentId: this.props.data.commentId,

        }
        this.props.handleApiChange("commentNotificationRequest", commentPayload)

        this.props.onRef(this);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        clearInterval(this.newMessage)
        this.props.onRef(undefined);
        clearInterval(this.intervalId);
        document.removeEventListener('keyup', this.enterTextFunction);
    }
    handleClickOutside = (event) => {
        if (this.myRef.current && !this.myRef.current.contains(event.target)) {
            this.props.openChatBox('close')
        }
        if (event != undefined && event.target != null && event.target.className.baseVal == undefined && event.target.className.includes("backdrop")) {
            this.props.openChatBox('close')
        }
        if (event != undefined && event.target != null && event.target.className.baseVal == undefined && event.target.className.includes("save")) {
            this.setState({ confirmModal: false })
        }
    }
    convertToMsg = () => {
        var tag = document.getElementsByTagName('tags')[0]?.querySelector('span')
        var div = document.getElementsByTagName('tags')[0]?.querySelector('span')?.querySelectorAll('div')
        var allTags = tag?.querySelectorAll('tag')
        for (let i = 0; i < allTags.length; i++) {
            allTags[i].outerHTML = '@' + allTags[i].getAttribute('value') + ' '
        }
        for (let j = 0; j < div.length; j++) {
            if (div[j].innerHTML.innerText == undefined) {
                div[j].outerHTML = div[j].innerHTML
            }
        }
        return tag.innerHTML?.replace(/\&nbsp;/g, '')?.replace(/\<br>/g, '')
    }
    sendMessage = () => {
        let userWatcher = "", emailWatcher = ""
        this.state.selectedTags.map((_) => { userWatcher = [...userWatcher, _.useName], emailWatcher = [...emailWatcher, _.title] })
        let commentData = []
        let allComment = [...this.state.allComment]
        if(document.getElementsByTagName('tags')[0])
            document.getElementsByTagName('tags')[0].querySelector('span').innerHTML = ''
        allComment.map((item) => {
            if (item.commentVersion == "NEW") {
                let data = {
                    commentedBy: item.commentedBy,
                    comments: item.comments,
                    commentDate: item.commentDate,
                    commentType: item.commentType,
                    folderDirectory: item.folderDirectory,
                    folderName: item.folderName,
                    filePath: item.filePath,
                    fileName: item.fileName,
                    fileURL: item.fileURL,
                    urlExpirationTime: item.urlExpirationTime,
                    isUploaded: item.isUploaded,
                    uploadedOn: item.uploadedOn,
                    subModule: "COMMENTS"
                }
                commentData.push(data)
            }
        })
        let moduleName = this.props.module;
        let payload = {
            shipmentId: this.props.data.shipmentId == undefined ? (this.props.data.saId == undefined ? 0 : this.props.data.saId) : this.props.data.shipmentId,
            module: moduleName,
            isQCDone: "",
            QCStatus: "",
            isInvoiceUploaded: "",
            orderNumber: this.props.data.orderNumber || "",
            orderId: this.props.data.orderId || "",
            documentNumber: this.props.documentNumber || "",
            reason: "",
            lgtNumber: this.props.lgtNumber,
            shipmentAdviceCode: this.props.shipmentAdviceCode || "",
            userWatcher: userWatcher.length > 0 && userWatcher.join(','),
            emailWatcher: emailWatcher.length > 0 && emailWatcher.join(','),
            [moduleName]: {
                QC: [],
                COMMENTS: [this.state.sendComment],
                INVOICE: []
            },
            gateEntryNo: this.props.data.gateEntryNo,
            grcNumber: this.props.data.grcNumber,
            commentId: this.props.data.commentId,
            commentCode: this.props.data.commentCode,
            vendorCode: this.props.data.vendorCode,
            boxNumber: this.props.data.boxNumber || "",

        }
        this.setState({ sendComment: [], emailAttach: [] })
        this.props.handleApiChange("qcAddCommentRequest", payload);
    }
    addComment = (data) => {
        var parsedMsg = this.convertToMsg()
        if (data != "" && (parsedMsg != "" && this.state.file == "")) {
            let date = new Date()
            let allComment = this.state.allComment
            let payload = {
                subModule: this.props.subModule,
                commentedBy: parseJwt(sessionStorage.getItem('token')).prn,
                comments: parsedMsg,
                commentDate: moment(date).format("YYYY-MM-DDTHH:mm:ss") + "+05:30",
                commentType: "TEXT",
                folderDirectory: "",
                folderName: "",
                filePath: "",
                fileName: "",
                fileURL: "",
                urlExpirationTime: "",
                isUploaded: "FALSE",
                uploadedOn: "",
                // commentVersion: "NEW"
            }
            this.setState({
                sendComment: payload
            })
            allComment = [...allComment, payload]
            // this.props.setAddComment(allComment)
            this.setState(
                {
                    allComment: allComment,
                    callAddComments: true,
                    comment: ""
                }, () => {
                    this.sendMessage()
                    document.getElementById('scrollBot').scrollTop = document.getElementById('scrollBot').scrollHeight
                })
        }
        if (this.state.file != "") {
            this.handleFile("", "", "send", parsedMsg)
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.generalStore.getAllCommentData.isSuccess) {
            if (nextProps.generalStore.getAllCommentData?.data?.resource != null) {
                let commentData = []
                let allComment = []
                commentData = this.state.allComment.length == 0 ? nextProps.generalStore.getAllCommentData?.data.resource.COMMENTS : [...allComment.reverse(), ...nextProps.generalStore.getAllCommentData?.data?.resource?.COMMENTS]
                allComment = [...commentData.reverse()]
                // this.props.setAddComment(allComment)
                var attach = this.state.isAttachment ? nextProps.generalStore.getAllCommentData?.data?.resource?.COMMENTS?.reverse() : []
                this.setState({
                    allComment,
                    attachments: attach
                }, () => {
                    document.getElementById('scrollBot').scrollTop = document.getElementById('scrollBot').scrollHeight
                })
            }
            this.setState({ loader: false })
        }
        if (nextProps.generalStore.getUserEmailsData.isSuccess) {
            var formatEmails = nextProps.generalStore.getUserEmailsData?.data?.resource !== null ? nextProps.generalStore.getUserEmailsData?.data?.resource?.map((_) => { return { id: _.id, value: _.userName.concat('(' + _.email + ')'), title: _.email, useName: _.userName } }) : []
            this.setState({
                allEmails: formatEmails,
                filteredEmails: formatEmails
            }, () => {
                this.tagifyFun()
            })
            // this.props.getUserEmailsClear()
        }
        if (nextProps.generalStore.getUserEmailsData.isError) {
            this.tagifyFun()
            // this.props.getUserEmailsClear()
        }

        // email transcript  
        if (nextProps.generalStore.emailTranscriptData.isSuccess) {
            this.setState({
                loader: false,
                successToast: true,
                transcriptModal: false,
                disableSendTranscriptBtn: false,
                toEmails: "",
                ccEmails: "",
                successMessage: (nextProps.generalStore.emailTranscriptData?.data && nextProps.generalStore.emailTranscriptData?.data) ? nextProps.generalStore.emailTranscriptData?.data?.message : ""
            })
            this.successToastTimeout = setTimeout(() => { this.setState({ successToast: false }); }, 3000);
            // this.props.emailTranscriptClear()
        } else if (nextProps.generalStore.emailTranscriptData?.isError) {
            this.setState({
                errorToast: true,
                errorMessage: nextProps.generalStore.emailTranscriptData?.message?.error == undefined ? undefined : nextProps.generalStore.emailTranscriptData?.message?.error.errorMessage,
                errorCode: nextProps.generalStore.emailTranscriptData?.message?.error == undefined ? undefined : nextProps.generalStore.emailTranscriptData?.message?.error.errorCode,
                code: nextProps.generalStore.emailTranscriptData?.message?.status,
                alert: true,
                loader: false,
                disableSendTranscriptBtn: false
            })
            // this.props.emailTranscriptClear()
        }
        if (nextProps.generalStore.qcAddCommentData.isSuccess) {
            this.setState({ loader: false })
            let payload = {
                module: this.props.module,
                pageNo: this.state.current + 1,
                subModule: "COMMENTS",
                isAllAttachment: "",
                type: 1,
                search: "",
                commentId: this.props.data.commentId,
                commentCode: this.props.data.commentCode,
                boxNumber: this.props.data.boxNumber || "",
            }
            this.props.handleApiChange("getAllCommentRequest", payload);
            // this.props.qcAddCommentClear();
        }
        else if (nextProps.generalStore.qcAddCommentData?.isError) {
            this.setState({ loader: false })
            // this.props.qcAddCommentClear();
        }

        if (nextProps.generalStore.getAllCommentData.isLoading || nextProps.generalStore.emailTranscriptData.isLoading || nextProps.generalStore.qcAddCommentData.isLoading) {
            this.setState({ loader: true })
        }

        if (nextProps.generalStore.emailTranscriptData.isLoading) {
            this.setState({ disableSendTranscriptBtn: true });
        }
    }

    componentWillUnmount() {
        this.state.successToastTimeout && clearTimeout(this.successToastTimeout);
    }

    handleFile = (e, subModule, type, parsedMsg) => {
        document.getElementById('fileName').innerHTML = type == "add" ? e.target.files[0].name : ""
        if (type == "add") {
            this.setState({ file: e.target.files })
        } else if (type == "delete") {
            this.setState({ file: "" })
            document.getElementById('commentUpload').value = ""
        } else if (type == "send") {
            this.onFileUpload(this.state.file, subModule, parsedMsg)
            document.getElementById('commentUpload').value = ""
        }
    }

    onFileUpload(e, subModule, parsedMsg) {
        let files = ""
        files = Object.values(this.state.file)
        let date = new Date()
        let id = "commentUpload";
        var allPdf = files.some((data) => (data.name.split('.').pop().toLowerCase() != "pdf" && data.name.split('.').pop().toLowerCase() != "png" && data.name.split('.').pop().toLowerCase() != "jpg" && data.name.split('.').pop().toLowerCase() != "svg" && data.name.split('.').pop().toLowerCase() != "bmp" && data.name.split('.').pop().toLowerCase() != "jpeg"))
        var fileSize = files.some((data) => (data.size > 5000000))
        if (!allPdf && id == "commentUpload") {
            if (!fileSize) {
                let uploadNode = {
                    orderId: this.props.data.orderId,
                    documentNumber: this.props.documentNumber || "",
                    shipmentId: this.props.data.shipmentId == undefined ? this.props.data.id : this.props.data.shipmentId,
                    orderNumber: this.props.data.orderNumber || "",
                    module: this.props.module,
                    subModule: "COMMENTS",
                    comments: parsedMsg,
                    commentId: this.props.data.commentId,
                    commentCode: this.props.data.commentCode,
                    vendorCode: this.props.data.vendorCode,
                }
                const fd = new FormData();
                this.setState({
                    loader: true
                })
                let headers = {
                    'X-Auth-Token': sessionStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
                for (var i = 0; i < files.length; i++) {
                    fd.append("files", files[i]);
                }
                fd.append("uploadNode", JSON.stringify(uploadNode))

                axios.post(`${CONFIG.BASE_URL}/vendorportal/comqc/upload`, fd, { headers: headers })
                    .then(res => {
                        this.setState({
                            loader: false
                        })
                        let result = res.data.data.resource
                        document.getElementsByTagName('tags')[0].querySelector('span').innerHTML = ""
                        if (id == "fileUpload") {
                            let invoice = this.state.invoice
                            for (let i = 0; i < result.length; i++) {
                                let payload = {
                                    commentedBy: parseJwt(sessionStorage.getItem('token')).prn,
                                    comments: parsedMsg,
                                    commentDate: "",
                                    commentType: "URL",
                                    folderDirectory: result[i].folderDirectory,
                                    folderName: result[i].folderName,
                                    filePath: result[i].filePath,
                                    fileName: result[i].fileName,
                                    fileURL: result[i].fileURL,
                                    urlExpirationTime: result[i].urlExpirationTime,
                                    invoiceVersion: "NEW",
                                    uploadedOn: moment(date).format("YYYY-MM-DDTHH:mm:ss") + "+05:30",
                                    submodule: subModule

                                }
                                invoice.push(payload)
                            }
                            this.setState({
                                invoice,
                                loader: false,
                                fileUploadedNew: true
                            }, () =>
                                document.getElementById('scrollBot').scrollTop = document.getElementById('scrollBot').scrollHeight
                            )
                        } else {
                            let allComment = this.state.allComment
                            for (let i = 0; i < result.length; i++) {
                                let payload = {
                                    commentedBy: parseJwt(sessionStorage.getItem('token')).prn,
                                    comments: parsedMsg,
                                    commentDate: "",
                                    commentType: "URL",
                                    folderDirectory: result[i].folderDirectory,
                                    folderName: result[i].folderName,
                                    filePath: result[i].filePath,
                                    fileName: result[i].fileName,
                                    fileURL: result[i].fileURL,
                                    urlExpirationTime: result[i].urlExpirationTime,
                                    isUploaded: "TRUE",
                                    uploadedBy: parseJwt(sessionStorage.getItem('token')).prn,
                                    commentVersion: "NEW",
                                    uploadedOn: moment(date).format("YYYY-MM-DDTHH:mm:ss") + "+05:30",
                                    submodule: subModule
                                }
                                allComment.push(payload)
                            }
                            this.setState({
                                loader: false,
                                allComment,
                                callAddComments: true,
                                file: ""
                            },
                                () => {
                                    document.getElementById('scrollBot').scrollTop = document.getElementById('scrollBot').scrollHeight
                                })
                        }
                    }).catch((error) => {
                        this.setState({
                            loader: false
                        })
                    })
            } else {
                this.setState({
                    toastMsg: "File Size Not More then 5mb ",
                    toastLoader: true
                })
                setTimeout(() => {
                    this.setState({
                        toastLoader: false
                    })
                }, 1500);
            }

        } else {
            this.setState({
                toastMsg: "Please upload PDF and Image file type only ",
                toastLoader: true
            })
            setTimeout(() => {
                this.setState({
                    toastLoader: false
                })
            }, 1500);
        }
    }
    handleSearch = (e) => {
        e.stopPropagation()
        let userName = e.target.dataset.username
        if (e.target.id == "email") {
            document.getElementById('searchComment').focus()
            this.setState((prevState) => ({ handleEmailDrop: false, searchComment: prevState.searchComment.concat(userName?.replace(/\((.+?)\)/g, "")) }))
        } else {
            this.setState({ searchComment: e.target.value })
            if (e.which == 50) {
                this.setState({ handleEmailDrop: true })
                document.getElementById('drop-position').style.top = '28%'
            } else if (e.which == 27) {
                this.setState({ handleEmailDrop: false })
            }
            else if (e.which == 13) {
                let payload = {
                    // type: 3,
                    // search: this.state.searchComment,
                    // shipmentId: this.props.data.shipmentId == undefined ? this.props.data.id : this.props.data.shipmentId,
                    // module: this.props.module,
                    // no: 1,
                    // orderCode: this.props.data.orderCode,
                    // orderNumber: this.props.data.orderNumber,
                    // subModule: "COMMENTS",
                    // isAllAttachment: "FALSE",
                    // refresh: 'load',
                    // orderId: this.props.orderId,
                    // documentNumber: this.props.documentNumber,

                    module: this.props.module,
                    pageNo: 1,
                    subModule: "COMMENTS",
                    isAllAttachment: "FALSE",
                    type: 3,
                    search: this.state.searchComment,
                    commentId: this.props.data.commentId,
                    commentCode: this.props.data.commentCode,
                    boxNumber: this.props.data.boxNumber || "",

                }
                this.setState({ type: 3 })
                this.props.handleApiChange("getAllCommentRequest", payload);
            }
        }
    }
    searchClear = () => {
        if (this.state.type == 3) {
            let payload = {
                // type: 1,
                // search: "",
                // shipmentId: this.props.data.shipmentId == undefined ? this.props.data.id : this.props.data.shipmentId,
                // module: this.props.module,
                // no: 1,
                // orderCode: this.props.data.orderCode,
                // orderNumber: this.props.data.orderNumber,
                // subModule: "COMMENTS",
                // isAllAttachment: "FALSE",
                // refresh: 'load',
                // orderId: this.props.orderId,
                // documentNumber: this.props.documentNumber,

                module: this.props.module,
                pageNo: 1,
                subModule: "COMMENTS",
                isAllAttachment: "FALSE",
                type: 1,
                search: "",
                commentId: this.props.data.commentId,
                commentCode: this.props.data.commentCode,
                boxNumber: this.props.data.boxNumber || "",
            }
            this.setState({ searchComment: "" })
            this.props.handleApiChange("getAllCommentRequest", payload);
        }
        this.setState({ searchComment: "" })
    }
    getEmails = () => {
        const tokenDetails = decodeToken()
        const uType = parseJwt(sessionStorage.getItem('token')).uType
        var payload = {
            retailerEId: tokenDetails.schemaEntID,
            vendorEId: uType == "VENDOR" ? tokenDetails.eid : "",
            retailerOrgId: tokenDetails.schemaOrgID,
            vendorOrgId: uType == "VENDOR" ? tokenDetails.coreOrgID : "",
            dataRequired: "BOTH",
            userCallFrom: uType == "VENDOR" ? "VENDOR" : "RETAILER"
        }
        this.props.handleApiChange("getUserEmailsRequest", payload);
        // this.props.getUserEmailsRequest(payload)
    }
    // viewAllAttachment = () => {
    //     if (!this.state.isAttachment) {
    //         let payload = {
    //             shipmentId: this.props.data.shipmentId == undefined ? this.props.data.id : this.props.data.shipmentId,
    //             module: this.props.module,
    //             pageNo: 1,
    //             orderCode: this.props.data.orderCode,
    //             orderNumber: this.props.data.orderNumber,
    //             subModule: "COMMENTS",
    //             isAllAttachment: "TRUE",
    //             refresh: 'load',
    //             orderId: this.props.orderId,
    //             documentNumber: this.props.documentNumber,

    //         }
    //         this.props.getAllCommentRequest(payload)
    //     } else {
    //         let payload = {
    //             shipmentId: this.props.data.shipmentId == undefined ? this.props.data.id : this.props.data.shipmentId,
    //             module: this.props.module,
    //             no: this.state.current + 1,
    //             orderNumber: this.props.data.orderNumber,
    //             orderCode: this.props.data.orderCode,
    //             subModule: "COMMENTS",
    //             refresh: 'load',
    //             orderId: this.props.orderId,
    //             documentNumber: this.props.documentNumber,

    //         }
    //         this.props.getAllCommentRequest(payload)
    //     }
    //     this.setState({ isAttachment: !this.state.isAttachment })
    // }
    deleteUploads(data) {
        // let barcode = this.state.barcode.filter((check) => check.fileURL != data.fileURL)
        let files = []
        let a = {
            fileName: data.fileName,
            isActive: "FALSE"
        }
        files.push(a)
        let payload = {
            shipmentId: this.props.data.shipmentId == undefined ? 0 : this.props.data.shipmentId,
            module: this.props.module,
            subModule: "COMMENTS",
            files: files,
            commentId: this.props.data.commentId
        }
        this.setState({
            selectedFiles: files,
            // barcode
        })
        this.props.handleApiChange("deleteUploadsCommentsRequest", payload);
        // this.props.deleteUploadsRequest(payload)
    }
    tagifyFun = () => {
        const t = this
        var allEmails = this.state.allEmails
        var input = document.querySelector('[name=mix]'),
            tagify = new Tagify(input, {
                mode: 'mix',
                //pattern: /@|#/,
                pattern: /@/,
                transformTag: transformaTag,
                dropdown: {
                    enabled: 0,
                    position: "text",
                    classname: "customSuggestionsList",
                    searchKeys: ["value", "title"],
                    highlightFirst: true,  // automatically highlights first sugegstion item in the dropdown
                },
                whitelist: allEmails.map(function (item) { return typeof item == 'string' ? { value: item } : item }),
                callbacks: {
                    add: console.log,  // callback when adding a tag
                    remove: console.log   // callback when removing a tag
                }
            })
        function transformaTag(tagData) {
            tagData.value = tagData.value?.replace(/\((.+?)\)/g, "")
        }
        tagify.on('input', function (e) {
            var prefix = e.detail.prefix;
            if (prefix) {
                if (prefix == '@')
                    tagify.settings.whitelist = allEmails;

                if (e.detail.value.length > 1) {
                    tagify.dropdown.show.call(tagify, e.detail.value);
                }
            }
            t.setState({ message: tagify.DOM.input.innerText, })
        })
        tagify.on('add', function (e) {
            t.setState({ selectedTags: tagify.value, prefix: '@' })
        })
        document.addEventListener('keyup', this.enterTextFunction);
    }

    enterTextFunction = (e) => {
        if (e.key === 'Enter' && !e.altKey && !e.ctrlKey && !e.shiftKey && this.state.prefix == undefined) {
            this.addComment();
        }
        if (e.key === 'Enter' && (e.altKey || e.ctrlKey || e.shiftKey) && this.state.prefix == undefined) {
            document.getElementsByClassName('tagify__input')[0].innerText + "\n";
        }
        else {
            this.setState({ prefix: undefined })
        }
    }

    closeConfirmModal = (e) => {
        this.setState({
            confirmModal: !this.state.confirmModal,
        })
    }

    submitTranscript = (data) => {
        // var toTags = [...document.getElementById("toEmail").querySelectorAll('tag')]
        // var ccTags = [...document.getElementById("ccEmail").querySelectorAll('tag')]
        // console.log({toTags, ccTags});
        // this.state.toEmails = toTags.map((_) => _.getAttribute('value'))
        // this.state.ccEmails = ccTags.map((_) => _.getAttribute('value'))
        // var subject = this.state.subject
        // if (this.state.toEmails.length == 0) {
        //     // To cannot be empty
        //     this.setState({
        //         toastMsg: "Please enter a valid email address.",
        //         toastLoader: true

        //     })
        //     setTimeout(() => {
        //         this.setState({
        //             toastLoader: false
        //         })
        //     }, 1500);

        // }

        console.log(Object.keys(data?.["basicDetails"]?.["email"] || {})?.length > 0 , data?.["otherDetails"]?.subject, "wefaser")
        if (Object.keys(data?.["basicDetails"]?.["email"] || {})?.length > 0 && (!data?.["otherDetails"]?.subject)) {
            this.setState({
                headerMsg: "Are you sure want to send this message without subject ?",
                confirmModal: true,
                subject: data,
            })

        }

        if (Object.keys(data?.["basicDetails"]?.["email"] || {})?.length > 0 && (data?.["otherDetails"]?.subject)) {
            this.resetColumn(data);
        }

    }
    resetColumn = (data = this.state.subject) => {
        // console.log({ccEmails: this.state.ccEmails, toEmails: this.state.toEmails});
        let toEmails = Object.keys(data?.["basicDetails"]?.["email"] || {})?.join(',') || "";
        let ccEmails = Object.keys(data?.["otherDetails"]?.["email"] || {})?.join(',') || "";
        let payload = {
            "shipmentId": this.props.data.shipmentId == undefined ? this.props.data.id : this.props.data.shipmentId,
            "orderId": this.props.data.orderId,
            "module": this.props.module,
            "subModule": "COMMENTS",
            "shipmentAdviceCode": this.props.shipmentAdviceCode || "",
            "documentNumber": this.props.documentNumber,
            "logisticNumber": this.props.lgtNumber || "",
            "to": toEmails,
            "cc": ccEmails,
            "subject": data?.["otherDetails"]?.["subject"] || "",
            "remarks": data?.["otherDetails"]?.["remark"],
            commentId: this.props.data.commentId
        }
        this.props.handleApiChange("emailTranscriptRequest", payload);
        // this.props.emailTranscriptRequest(payload)
        // api call
    }

    handleDesc = (event, name) => {
        this.setState({
            [name]: event.target.value
        })
    }
    handleEmailTranscriptModal = () => {
        this.setState({
            transcriptModal: !this.state.transcriptModal
        })
    }
    highlighTag = (comment) => {
        if (comment.match(`@\\w+`) === null) return comment.trim();
        else {
            var finalCommentStr = "";
            //if (comment.match(`@\\w+`) !== null) {
            var commentArray = comment.split(" ");
            commentArray.forEach(function (word) {
                if (word.match(`@\\w+`)) {
                    word.split("@").forEach(function (char, index) {
                        if (index == 0)
                            finalCommentStr += char;
                        else
                            finalCommentStr += char?.replace(char, `<span class="mention-user"> @${char} </span> `);
                    })

                } else
                    finalCommentStr += word + " ";
            });
            // var replaceStr = comment.match(`@\\w+`)[0]
            // replaceStr = replaceStr?.replace('@', '')
            // comment = comment?.replace('@' + replaceStr, `<span class="mention-user"> @${replaceStr} </span> `)
            // if (comment.match(`@\\w+`) !== null) return this.highlighTag(comment)
            // else {
            //     return comment
            // }
            return finalCommentStr.trim();
            //}
        }
    }

    setEmails = (key, emails) => {
        this.setState({
            [key]: emails,
        })
    }

    render() {
        console.log(8889, this.state.confirmModal)
        const { searchComment, loader, file } = this.state
        return (
            // <div className={this.state.closeChatBox == "hide" ? "table-chat-box" : this.state.closeChatBox == "show" ? "table-chat-box visible-chatbox" : this.state.closeChatBox == "open" ? "table-chat-box visible-chatbox minimize-chatbox" : null}>
            <>
                <div className="backdrop" onClick={(event, data) => this.props.openChatBox(event, data)}></div>
                {/* <div ref={this.myRef}> */}
                <div className="table-chat-box-inner" >
                    <div className="tcb-head" id="openMinimize" onClick={(event, data) => this.props.openChatBox(event, data)}>
                        <div className="tcb-head-top">
                            <h3>Comments</h3>
                            {/* <span><img id="close" className="displayPointer" onClick={(event, data) => this.props.openChatBox(event, data)} id="minimize" src={Minimize}></img></span> */}
                            <span className="tcbh-close">
                                <span className="tcbh-esc">Esc</span>
                                <IconsPath.ClearSearch
                                    className='cursor-pointer'
                                    onClick={(event, data) => this.props.openChatBox(event, data)}
                                    data-id="closeChat"
                                    id={this.props.data.orderNumber == undefined ? this.props.openChats[0].id : this.props.data.orderNumber}
                                />

                            </span>
                        </div>
                        <div className="tcb-head-bottom">
                            <div className="tcb-name">
                                {this.props.module == "SHIPMENT" && <table>
                                    <tr><td><p>PO NUMBER</p></td><td className="p-lft-10"><p>ASN NO.</p></td></tr>
                                    <tr>
                                        <td id="openMinimize"><span>{this.props.data.orderNumber}</span></td>
                                        <td id="openMinimize" className="p-lft-10"><span>{this.props.data.adviceNo}</span></td>
                                    </tr>
                                </table>}
                                {this.props.module == "GOODS_IN_TRANSIT" && <table>
                                    <tr><td><p>LOGISTICS NO</p></td></tr>
                                    <tr><td id="openMinimize"><span>{this.props.data.lgtNo}</span></td></tr>
                                </table>}
                                {this.props.module == "GATE_ENTRY" && <table>
                                    <tr><td><p>GATE ENTRY NO.</p></td><td className="p-lft-10"><p>ASN NO.</p></td></tr>
                                    <tr><td id="openMinimize"><span>{this.props.data.gateEntryNo}</span></td>
                                        <td id="openMinimize" className="p-lft-10"><span>{this.props.data.adviceNo}</span></td>
                                    </tr>
                                </table>}
                                {this.props.module == "GRC_STATUS" && <table>
                                    <tr><td><p>GRC NO.</p></td><td className="p-lft-10"><p>ASN NO.</p></td></tr>
                                    <tr><td id="openMinimize"><span>{this.props.data.grcNumber}</span></td>
                                        <td id="openMinimize" className="p-lft-10"><span>{this.props.data.adviceNo}</span></td>
                                    </tr>
                                </table>}
                                {this.props.module == "ORDER" && <table>
                                    <tr><td><p>PO NUMBER</p></td></tr>
                                    <tr><td id="openMinimize"><span>{this.props.data.orderNumber}</span></td></tr>
                                </table>}

                                {/* <p>{this.props.module == "SHIPMENT" ? "ASN NO." : this.props.module == "LOGISTICS" ? "LOGISTICS NO" : "PO NUMBER"}</p>
                                <a id="openMinimize">{this.props.module == "SHIPMENT" ? this.props.data.adviceNo : this.props.module == "LOGISTICS" ? this.props.data.lgtNo : this.props.data.orderNumber}</a> */}
                                <div className="tcb-hb-search-box">
                                    <input className='onFocus' type="text" autoautoComplete="off" id="searchComment" placeholder="Search comments" onKeyDownCapture={(e) => this.handleSearch(e)} onChange={(e) => this.handleSearch(e)} value={searchComment} />
                                    {searchComment != "" ?
                                        <button onClick={this.searchClear}><IconsPath.ClearSearch /></button> :
                                        <span><IconsPath.SearchIcon /></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tcb-body" >
                        {/* <div className="tch-msg-attachment">
                            <label className="loadingCenter">Loading...</label>
                            {this.state.loader && <label className="loadingCenter">Loading...</label>}
                            <span><img src={Attachments} className="displayPointer" onClick={this.viewAllAttachment}></img></span>
                            <span><img src={refreshIcon} className="displayPointer" onClick={this.refreshChat} /></span>
                        </div> */}
                        {!this.state.isAttachment ? <div className="tcb-messages w-100" id="scrollBot">
                            <div className="msg-sender">
                                <span>
                                    {/* <a className="ms-short-name">GS</a> */}
                                </span>
                                <span></span>
                            </div>
                            <div className="msg-reciver">
                                {this.state.allComment.length != 0 ? this.state.allComment.map((data, key) => (
                                    <div key={key} className={parseJwt(sessionStorage.getItem('token')).prn == data.commentedBy || parseJwt(sessionStorage.getItem('token')).prn == data.uploadedBy ? "messageSender " : "messageReceiver "}>
                                        <div className="each-comment m-top-15">
                                            <div className="msgBg">
                                                {/* <img className="msg-user-icon" src={userIcon} /> */}
                                                <span className="msg-user-icon-text">{data.commentedBy.charAt(0)}</span>
                                                {data.commentType == "TEXT" ?
                                                    <label>
                                                        <pre dangerouslySetInnerHTML={{
                                                            __html: this.highlighTag(data.comments)
                                                        }} />
                                                    </label>
                                                    // <label>{/*<span className="mention-user"></span>*/} {this.highlighTag(data.comments), console.log("asdads", this.highlighTag(data.comments))}</label>
                                                    : <div>
                                                        {data.comments != "" && <label><pre dangerouslySetInnerHTML={{ __html: this.highlighTag(data.comments) }} /></label>}
                                                        <div className="tcb-file">
                                                            <span className="msg-name-pdf">
                                                                {/* <img src={require('../../assets/pdf-file.svg')} /> */}
                                                                {/* <img src={require('../../assets/doc-file.svg')} /> */}
                                                            </span>
                                                            <a href={data.fileURL} className="fileNameQc" target="_blank" >{data.fileName}<img href={data.fileURL} className="msg-pdfdownload-link" src={require('../../assets/icons/msg-download.svg')} /></a>{parseJwt(sessionStorage.getItem('token')).prn == data.uploadedBy && <span className="msg-clear-msg" onClick={(e) => this.deleteUploads(data)}><img src={require('../../assets/icons/clearSearch.svg')} /></span>}</div>
                                                    </div>}
                                            </div>
                                            <div className="msg-user-detail">
                                                <span className="msg-user-name">{parseJwt(sessionStorage.getItem('token')).prn == data.commentedBy || parseJwt(sessionStorage.getItem('token')).prn == data.uploadedBy ? "You" : parseJwt(sessionStorage.getItem('token')).uType == "ENT" ? "Vendor" : "Retailer"}</span>
                                                {data.commentType == "TEXT" ? <span className="dtof-msg">{data.commentDate}</span> : <span className="dtof-msg">{data.uploadedOn}</span>}
                                            </div>
                                        </div>

                                    </div>
                                )) : null}
                            </div>
                        </div>
                            : <div className="tcb-messages" id="scrollBot"> {this.state.attachments.map((data) => (<div className="messageReceiver"><div className="each-comment m-top-15"><div className="msgBg" >
                                <span className="msg-user-icon-text"></span>
                                <div className="tcb-file">
                                    {/* <span className="msg-name-pdf">PDF</span> */}
                                    <a href={data.fileURL} className="fileNameQc" target="_blank" >{data.fileName} <img href={data.fileURL} className="msg-pdfdownload-link" src={DownloadPdf} /></a>
                                    <span className="displayPointer" onClick={(e) => this.deleteUploads(data)}>&#10005;</span></div>
                            </div>
                            </div>
                            </div>))}</div>}
                    </div>
                    <div className="tcb-footer">
                        <div className="tcb-footer-inner">
                            <div className="tcb-footer-inner-item">
                                <div className="tcb-footer-write-comment-field">
                                    <div id="all-email-container">
                                        {/* {emailAttach.length != 0 && emailAttach.map((_, k) => (
                                            <span className={_.userName} key={k}>{_.userName}</span>
                                        ))} */}
                                    </div>
                                    {/* <input type="text" name="message" autoautoComplete="off" value={comment} id="message" onKeyDownCapture={(e) => this.handleMsg(e)} onChange={(e) => this.handleMsg(e)} placeholder="Write your comment here…" /> */}
                                    <textarea name='mix' placeholder="Write a message"> </textarea>

                                    {/* <span className="tcb-attach-img"><input type="file" /><img src={Attach}></img></span> */}
                                    <div className="tcb-footer-attach-file">
                                        <span className="tcbfaf-jumpnewline">
                                            <span className="tcbfafj-uper">Jump to new line</span>
                                            <span className="tcbfafj-lower">Shift + Enter</span>
                                        </span>
                                        <span className="tcbfaf-jumpnewline tcbfaf-border">
                                            <span className="tcbfafj-uper">Send Comment</span>
                                            <span className="tcbfafj-lower">Enter</span>
                                        </span>
                                        <div className="tcb-footer-attach-file-name">
                                            {file != "" && <span className="tcb-fafn-icon" onClick={(e) => this.handleFile("", "", "delete")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.826" height="15.391" viewBox="0 0 12.826 15.391">
                                                    <path fill="#a4b9dd" id="prefix__iconmonstr-trash-can-2" d="M6.489 12.185a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm2.565 0a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm2.565 0a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm3.207-10.9v1.28H2V1.283h3.663c.577 0 1.046-.7 1.046-1.283h3.409c0 .578.468 1.283 1.046 1.283zM12.9 3.848v10.261H3.924V3.848H2.641v11.543h11.544V3.848z" transform="translate(-2)" />
                                                </svg>
                                            </span>}
                                            <p id="fileName"></p>
                                            {/* <span className="tcb-footer-attach-file-count">+2</span> */}
                                        </div>

                                        {/* <div className="tcb-footer-attach-file-mini-modal">
                                            <li>
                                                <span className="tcb-fafn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.826" height="15.391" viewBox="0 0 12.826 15.391">
                                                        <path fill="#a4b9dd" id="prefix__iconmonstr-trash-can-2" d="M6.489 12.185a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm2.565 0a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm2.565 0a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm3.207-10.9v1.28H2V1.283h3.663c.577 0 1.046-.7 1.046-1.283h3.409c0 .578.468 1.283 1.046 1.283zM12.9 3.848v10.261H3.924V3.848H2.641v11.543h11.544V3.848z" transform="translate(-2)" />
                                                    </svg>
                                                </span>
                                            <p>Documents.pdf</p>
                                            </li>
                                            <li>
                                            <span className="tcb-fafn-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.826" height="15.391" viewBox="0 0 12.826 15.391">
                                                    <path fill="#a4b9dd" id="prefix__iconmonstr-trash-can-2" d="M6.489 12.185a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm2.565 0a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm2.565 0a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm3.207-10.9v1.28H2V1.283h3.663c.577 0 1.046-.7 1.046-1.283h3.409c0 .578.468 1.283 1.046 1.283zM12.9 3.848v10.261H3.924V3.848H2.641v11.543h11.544V3.848z" transform="translate(-2)" />
                                                </svg>
                                            </span>
                                            <p>Report.pdf</p>
                                            </li>
                                            <li>
                                                <span className="tcb-fafn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.826" height="15.391" viewBox="0 0 12.826 15.391">
                                                        <path fill="#a4b9dd" id="prefix__iconmonstr-trash-can-2" d="M6.489 12.185a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm2.565 0a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm2.565 0a.641.641 0 0 1-1.283 0V5.772a.641.641 0 0 1 1.283 0zm3.207-10.9v1.28H2V1.283h3.663c.577 0 1.046-.7 1.046-1.283h3.409c0 .578.468 1.283 1.046 1.283zM12.9 3.848v10.261H3.924V3.848H2.641v11.543h11.544V3.848z" transform="translate(-2)" />
                                                    </svg>
                                                </span>
                                                <p>Invoice_vmart.pdf</p>
                                            </li>
                                        </div> */}

                                        <div className="writeComment">
                                            <label className="custom-file-Icon">
                                                <input type="file" id="commentUpload" onChange={(e) => this.handleFile(e, "COMMENTS", "add")} />
                                                <img src={Attach} />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="tcb-footer-addcomment">
                                    {/* <span className="tcb-send-img"><img src={Send} onClick={() => this.addComment(this.state.comment)}></img></span> */}
                                    <button type="button" className="tcb-footer-add-comment" onClick={() => this.addComment()}>Add Comment
                                        <IconsPath.AddComentEnterIcon />
                                    </button>
                                    {/* <button type="button" className="tcb-footer-add-comment" onClick={() => this.addComment(this.state.comment)}>Add Comment</button> */}

                                    <button type="button" className="tcb-footer-email-tcranscript" onClick={() => this.handleEmailTranscriptModal()}>
                                        <IconsPath.EmailTranscriptIcon />
                                        Email Transcript
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mention-person-in-comment" id="drop-position">
                        {/* {this.state.handleEmailDrop && <EmailsDrop  {...this.state} handleKeyDown={this.handleKeyDown} allEmails={this.state.allEmails} handleMsg={this.handleSearch} />} */}
                        {this.state.handleEmailDrop && <div className="mention-person-in-comment-inner">
                            <div className="mpic-list-container">
                                <ul className="mpic-list">
                                    {this.state.filteredEmails.length != 0 && this.state.filteredEmails.map((_, k) => (
                                        // <span className="mpic-short-name">GS</span>
                                        <li key={k} className={this.state.cursor === k ? 'active' : null} onClick={(_) => this.handleSearch(_)} tabIndex="1" data-eachemail={_.type} id="email" data-username={_.value} ><span className="mpic-full-name">{_.value}</span></li>
                                    ))}
                                </ul>
                                <div className="mpic-help-tools">
                                    <span className="mpic-ht-enter">
                                        <span className="mpic-ht-enter-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.654" height="8.232" viewBox="0 0 16.654 8.232">
                                                <path d="M2.7 4.9l2.01 2.01a.771.771 0 0 1-1.076 1.1l-3.4-3.369a.764.764 0 0 1 0-1.1L3.546.229a.781.781 0 1 1 1.1 1.1l-2.01 2.01h12.49V.795a.764.764 0 1 1 1.529 0v3.34a.764.764 0 0 1-.764.764z" data-name="Path 490" />
                                            </svg>
                                        </span>
                                        to Enter</span>
                                    <span className="mpic-ht-esc"><span> esc</span> to dismiss</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                {this.state.transcriptModal && <EmailTranscript {...this.props} {...this.state} submitTranscript={this.submitTranscript} ref={this.subRef} handleDesc={this.handleDesc} handleEmailTranscriptModal={this.handleEmailTranscriptModal} setEmails={this.setEmails} />}
                {/* </div> */}
                {this.state.errorToast && <div className="email-transcript-alert-msg">
                    <div className="etam-inner">
                        <h3>Failed to Send</h3>
                        <span className="etam-status-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" id="prefix__cancel" width="18" height="18" viewBox="0 0 22 22">
                                <g id="prefix__Group_26" data-name="Group 26">
                                    <path fill="#eb0008" id="prefix__Path_11" d="M11 22a11 11 0 1 1 11-11 11.012 11.012 0 0 1-11 11zm0-20.167A9.167 9.167 0 1 0 20.167 11 9.177 9.177 0 0 0 11 1.833zm3.667 13.75a.914.914 0 0 1-.648-.269L6.685 7.981a.917.917 0 0 1 1.3-1.3l7.333 7.333a.917.917 0 0 1-.648 1.565zm0 0a.914.914 0 0 1-.648-.269L6.685 7.981a.917.917 0 0 1 1.3-1.3l7.333 7.333a.917.917 0 0 1-.648 1.565zm-7.333 0a.917.917 0 0 1-.648-1.565l7.333-7.333a.917.917 0 0 1 1.3 1.3l-7.338 7.33a.914.914 0 0 1-.648.269z" data-name="Path 11" />
                                </g>
                            </svg>
                        </span>
                        <p>{this.state.successMessage}</p>
                        {/* <p>Please try again.</p> */}
                        <sapn className="etam-close" onClick={() => this.setState({ errorToast: false })}>
                            <img src={SmallClose} />
                        </sapn>
                    </div>
                </div>}
                {this.state.successToast && <div className="email-transcript-alert-msg">
                    <div className="etam-inner">
                        <h3>Transcript Sent</h3>
                        <span className="etam-status-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                                <path fill="#3ee585" id="prefix__success" d="M10.5 0A10.5 10.5 0 1 0 21 10.5 10.5 10.5 0 0 0 10.5 0zM9.406 14.453l-3.937-3.779L6.69 9.418l2.693 2.57 5.342-5.441 1.243 1.233z" />
                            </svg>
                        </span>
                        <p>Please check your mailbox or spam.</p>
                        <sapn className="etam-close" onClick={() => this.setState({ successToast: false })}>
                            <img src={SmallClose} />
                        </sapn>
                    </div>
                </div>}
                {/* {loader && <FilterLoader />}
                {this.state.toastLoader ? <ToastLoader toastMsg={this.state.toastMsg} /> : null} */}
                {this.state.confirmModal ? <GenericConfirmationModal
                    labelComponent={this.state.headerMsg}
                    buttons={[
                        {
                            label: "Sure, Yes",
                            className: "generic-approve-btn",
                            onClick: () => {
                                this.resetColumn()
                            },
                        },
                        {
                            label: "No, wait",
                            className: "no-wait-btn",
                            onClick: (e) => { this.closeConfirmModal(e) }
                        }
                    ]}
                /> : null}
            </>
        )
    }
}