import React, { useEffect, useState } from 'react'
import { handleChange, parseJwt } from '../../../../helper/genericFunction';
import GenericCustomButton from '../../../../genericComponents/GenericCustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import UserProfileSkeletonLoader from '../../../skeletons/UserProfileSkeletonLoader';
import GenericInput from '../../../../genericComponents/input/GenericInputComponent';
import Icons from '../../../../assets/icons';
import ParentModal from '../../../../genericComponents/ParentModal'
import EditEmail from './EditEmail';

// Note  ->  Doc

// Edit Email Component is getting used to edit the Mobile number and the email account 
// based on the type == "mobile" or type == "email".

// ------------------------- //

const ProfileDetails = (props) => {
    const [editMode, setEditMode] = useState(false);
    const tokenData = parseJwt(sessionStorage?.getItem("token")) || {};
    const authStore = useSelector(state => state.authStore);
    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState({});

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [selectedModal, setSelectedModal] = useState(false);

    useEffect(() => {
        if (authStore?.["getUserProfileData"]?.["data"]?.resource) {
            setProfileData(authStore?.["getUserProfileData"]?.["data"]?.resource || {})
        } else {
            dispatchHandler(dispatch, "getUserProfileRequest")
        }
    }, [])

    useEffect(() => {
        setFirstName(profileData?.["firstName"]);
        setLastName(profileData?.["lastName"]);
    }, [profileData])

    useEffect(() => {
        if (authStore?.["getUserProfileData"]?.isSuccess) {
            setProfileData(authStore?.["getUserProfileData"]?.data?.resource || {});
        }
    }, [authStore?.["getUserProfileData"]?.isSuccess])

    useEffect(() => {
        if (authStore?.["editUserData"]?.isSuccess || authStore?.["verifyOtpData"]?.isSuccess) {
            dispatchHandler(dispatch, "getUserProfileRequest")
            setEditMode(false)
            setSelectedModal(false);
        }
    }, [authStore?.["editUserData"]?.isSuccess, authStore?.["verifyOtpData"]?.isSuccess])

    // Reset Data After Success of the Verify otp api
    useEffect(() => {
        if (authStore?.["verifyOtpData"]?.isSuccess) {
            dispatchHandler(dispatch, "getUserProfileRequest")
            setEditMode(false)
            setSelectedModal(false);
        }
    }, [authStore?.["verifyOtpData"]?.isSuccess])

    const handleChange = (type, data) => {
        switch (type) {
            case "editProfile": {
                dispatchHandler(dispatch, "editUserRequest", {
                    firstName: firstName,
                    lastName: lastName,
                })
                break;
            }
            case "editemail": {
                setSelectedModal("editEmail");
                break;
            }
            case "editphone": {
                setSelectedModal("editMobileNumber");
                break;
            }
        }
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => { setSelectedModal("") },
            animationType: "center",
            postitionProps: { top: "25%", left: "25%", position: "absolute", margin: 0, borderRadius: 4 },
            dimensions: { width: "50vw", height: "50vh" },
            animationType: "center",
            popUpOpenedFromProfile: true
        }

        switch (selectedModal) {
            case "editEmail": {
                return {
                    ...payload,
                    type: "email",
                }
            }
            case "editMobileNumber": {
                return {
                    ...payload,
                    type: "mobile"
                }
            }
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "editEmail":
            case "editMobileNumber": {
                return EditEmail;
            }
            // case "editMobileNumber" :{
            //     return EditMobileNumber;
            // }
        }
    }


    return (
        authStore?.["getUserProfileData"]?.isLoading ? <UserProfileSkeletonLoader /> : <>
            {/* Render the profile main details */}
            <div className="upr-info">
                <div className="upri-name">
                    <span>{tokenData?.firstName == null ? "" : tokenData?.firstName?.charAt(0)}</span>
                </div>
                <div className="upri-det">
                    <h3>{profileData?.firstName} {profileData.lastName}</h3>
                    <p>{profileData.email}</p>
                    {!editMode && <GenericCustomButton
                        type="primary"
                        label="Edit Profile"
                        onClick={() => setEditMode(true)}
                    />}
                </div>

            </div>

            <div className='upr-det'>
                {/* First Name Input Field */}
                <div className='uprd'>
                    <label>First Name</label>
                    {editMode ? <GenericInput
                        key="firstName"
                        onChange={(e) => setFirstName(e?.target?.value || "")}
                        value={firstName}
                        dependentFieldsObject={["firstName"]}
                    /> : <input type='text' autoComplete="off" value={profileData?.["firstName"]} disabled={true} />}
                </div>

                {/* Last Name Input Field */}
                <div className='uprd'>
                    <label>Last Name</label>
                    {editMode ? <GenericInput
                        key="lastName"
                        onChange={(e) => setLastName(e?.target?.value || "")}
                        value={lastName}
                        dependentFieldsObject={["lastName"]}
                    /> : <input type='text' autoComplete="off" value={profileData?.["lastName"]} disabled={true} />}
                </div>
                {
                    [
                        { "key": "email", label: "Email", },
                        { "key": "phone", label: "Mobile No" },
                        { "key": "enterpriseId", label: "Enterprise Id" },
                        { "key": "organisationId", label: "Organisation Id" },
                        { "key": "username", label: "Username" },
                        { "key": "uType", label: "User Type" },
                        { "key": "uCode", label: "User Code" },
                        { "key": "language", label: "Language", value: "English" },
                    ]?.map(item => (
                        <div className='uprd'>
                            <label className='flex items-center justify-between'>{item?.label}
                                <span onClick={() => handleChange(`edit${item?.key}`)}>{["email", "phone"]?.includes(item?.key) && <Icons.EditIcon />}</span>
                            </label>
                            <input disabled={true} value={item?.value || profileData?.[item?.key]} type='text' />
                        </div>
                    ))
                }

                {/* Final Submit Buttons */}
                {editMode &&
                    <div className='uprd-bot'>
                        <GenericCustomButton
                            label={"Cancel"}
                            onClick={() => setEditMode(false)}
                            type='default'
                        />
                        <GenericCustomButton
                            label="Save"
                            type="primary"
                            disabled={!firstName || !lastName || ((profileData?.["firstName"] == firstName) ? (profileData?.["lastName"] == lastName) : false)}
                            onClick={() => handleChange("editProfile")}
                        />
                    </div>
                }
                {selectedModal && <ParentModal
                    getParentChildProps={getParentChildProps()}
                    childComponent={getChildComponent()}
                    closeModal={() => { setSelectedModal(false) }}
                />}

            </div>
        </>
    )
}

export default ProfileDetails