import React, { useEffect, useState } from 'react'
import GenericTableComponent from '../../genericComponents/tableComponent/GenericTableComponent'
import GenericCustomButton from '../../genericComponents/GenericCustomButton'
import Icons from '../../assets/icons'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import ListComponent from '../../genericComponents/ListComponent'
import GenericSummarySkeleton from '../skeletons/GenericSummarySkeleton'
import { CONFIG } from '../../config';

const SupportTickets = (props) => {
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search),
        params = Object.fromEntries(urlSearchParams.entries());
    const [currentTab, setCurrentTab] = useState(params.view ? JSON.parse(decodeURIComponent(params.view)) : "SELF");
    const generalStore = useSelector(state => state.generalStore);
    const [summaryData, setSummaryData] = useState({});
    const [summaryLoading, setSummaryLoading] = useState(true);

    const tableHeadersMap = {
        main: {
            name: "Main",
            key: "SUPPORT_TICKET_DATA"
        },
    }

    useEffect(() => {
        if (generalStore?.["getTableComponentListData"]?.isSuccess) {
            setSummaryLoading(false);
            let data = generalStore?.["getTableComponentListData"]?.data?.resource;
            let obj = {
                "summaryData": data?.["summaryData"] || {},
                "priorityWiseData": data?.["priorityWiseData"] || {},
                "statusWiseData": data?.["statusWiseData"] || {},
                "dateWiseData": data?.["dateWiseData"] || {},
            }
            setSummaryData(obj || {});
        }
    }, [generalStore?.["getTableComponentListData"]?.isSuccess])

    // const renderRHSExtras = selectedItems => {
    //     return (

    //     )
    // }

    const isSmall = (str) => {
        return !(str && str.toString().length <= 45);
    }

    const changeTab = (tab) => () => {
        setCurrentTab(tab);
    }

    const renderTableSummary = () => {
        return summaryLoading ? <GenericSummarySkeleton /> :
            <div className="nph-history-search">
                <div className="nph-pi-po-quantity">
                    <ul className="nppq-inner">
                        <li className="nppq-history">
                            <label>Total</label>
                            <span>{summaryData?.["summaryData"]?.total_count || 0}
                                <span className="nppqh-info">
                                    <Icons.SummaryInfoIcon />
                                    <div className="nppqhi-information">
                                        <ListComponent
                                            headerKeys={{
                                                "status": { value: "Status" },
                                                "count": { value: "Count" }
                                            }}
                                            itemKeys={{
                                                "status": [(states, functions, item) => <label
                                                    className={
                                                        item.status === "Open" ? "nppqhii-status nppqhiis-can" :
                                                            item.status === "Pending" ? "nppqhii-status nppqhiis-pen" :
                                                                item.status === "Resolved" ? "nppqhii-status nppqhiis-apv" :
                                                                    item.status === "Closed" ? "nppqhii-status" :
                                                                        "nppqhii-status nppqhiis-pen"
                                                    }
                                                >
                                                    {item.status}
                                                </label>],
                                                "count": ["count"]
                                            }}
                                            getListData={() => summaryData?.statusWiseData || []}
                                        />
                                    </div>
                                </span>
                            </span>
                        </li>
                        <li className="nppq-history nppqh-borderlft">
                            <label>Open</label>
                            <span>{summaryData?.["summaryData"]?.open_count || 0}
                                <span className="nppqh-info">
                                    <Icons.SummaryInfoIcon />
                                    <div className="nppqhi-information">
                                        <ListComponent
                                            viewDataType="table-view"
                                            headerKeys={{
                                                "created_at": { value: "Date" },
                                                "total_count": { value: "Total" },
                                                "open_count": { value: "Open" },
                                                "closed_count": { value: "Closed" }
                                            }}
                                            itemKeys={{
                                                "created_at": ["created_at"],
                                                "total_count": ["total_count"],
                                                "open_count": ["open_count"],
                                                "closed_count": ["closed_count"]
                                            }}
                                            getListData={() => summaryData?.dateWiseData || []}
                                        />
                                    </div>
                                </span>
                            </span>
                        </li>
                        <li className="nppq-history nppqh-borderlft">
                            <label>Closed</label>
                            <span>{summaryData?.["summaryData"]?.closed_count || 0}</span>
                        </li>
                        <li className="nppq-history nppqh-borderlft">
                            <label>Open (7 days avg)</label>
                            <span>{summaryData?.["summaryData"]?.open_count_avg_per_day || 0}</span>
                        </li>
                        <li className="nppq-history nppqh-borderlft">
                            <label>Closed (7 days avg)</label>
                            <span>{summaryData?.["summaryData"]?.closed_count_avg_per_day || 0}
                                <span className="nppqh-info">
                                    <Icons.SummaryInfoIcon />
                                    <div className="nppqhi-information">
                                        <ListComponent
                                            headerKeys={{
                                                "priority": { value: "Priority" },
                                                "total_count": { value: "Total" },
                                                "open_count": { value: "Open" },
                                                "closed_count": { value: "Closed" }
                                            }}
                                            itemKeys={{
                                                "priority": [(states, functions, item) => <label
                                                    className={
                                                        item.priority === "Low" ? "nppqhii-status nppqhiis-drft" :
                                                            item.priority === "Medium" ? "nppqhii-status nppqhiis-apv" :
                                                                item.priority === "High" ? "nppqhii-status nppqhiis-pen" :
                                                                    item.priority === "Urgent" ? "nppqhii-status nppqhiis-can" : ""
                                                    }
                                                >
                                                    {item.priority}
                                                </label>],
                                                "total_count": ["total_count"],
                                                "open_count": ["open_count"],
                                                "closed_count": ["closed_count"]
                                            }}
                                            getListData={() => summaryData?.["priorityWiseData"] || []}
                                        />
                                    </div>
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
    }

    return (
        <GenericTableComponent
            actionBarConfig={{
                emailReport: false,
                downloadReport: {
                    headerLevelModule: tableHeadersMap?.main?.key,
                    tableHeadersMap: tableHeadersMap
                },
                tabs: {
                    key: "view",
                    data: props.type == "header" ? {
                        SELF: "Self",
                        ORG_INTERNAL: "Org Internal",
                        ORG_EXTERNAL: "Org External"
                    } : {
                        SELF: "Open",
                        ORG_INTERNAL: "Close",
                        ORG_EXTERNAL: "Portal Access"
                    },
                    onChange: changeTab
                },
                rhsExtras: (selectedItems) => <React.Fragment>
                    <button className="generic-approve-btn">
                        <a  style={{display:"flex", flexDirection:"row"}} href="https://helpsupplymint.myfreshworks.com/login/auth/1629049404188?client_id=451980303908285122&amp;redirect_uri=https%3A%2F%2Fsupplymint.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dhelp.supplymint.com" target="_blank">
                            Access Portal
                            <Icons.VendorRedirectIconWhite />
                        </a>
                    </button>
                </React.Fragment>
            }}
            tableConfig={{
                customCols: {
                    ticketId: item => {
                        return <label>
                            <a data-interactive="true" style={{ display: "flex", flexDirection: "row" }} href={item.ticketUrl} target="_blank">
                                {item["ticketId"]} <Icons.VendorRedirectIcon />
                                {isSmall(item["ticketId"]) && <div className="table-tooltip"><label>{item["ticketId"]}</label></div>}
                            </a>
                        </label>
                    },
                },
                summary: {
                    render: renderTableSummary
                }
            }}
            tableDataPayload={{
                reportFilter: {},
                pageNo: 1,
                pageSize: 10,
                sortedBy: "",
                sortedIn: "",
                search: "",
                filter: {},
                type: 1,
                view: currentTab,
                apiUrl: "/sm/admin/support/ticket/get/all",
                baseUrl: CONFIG?.["BASE_MICRO_SERVICE_URL"]
            }}
            tableHeadersPayload={tableHeadersMap}
            tableData={
                {
                    data: generalStore?.getTableComponentListData?.data?.resource?.["data"] || [],
                    totalItems: generalStore?.getTableComponentListData?.data?.resource?.totalRecord || 0,
                    currentPage: generalStore?.getTableComponentListData?.data?.resource?.currPage || 1,
                    maxPage: generalStore?.getTableComponentListData?.data?.resource?.maxPage || 1,
                    getTableApiData: generalStore?.getTableComponentListData?.data?.resource || []
                }
            }
            availableStores={["generalStore"]}
            {...props}
        />
    )
}

export default SupportTickets