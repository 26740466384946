import React, { useEffect, useState, useRef } from 'react';
import { deleteCookie, parseJwt } from '../../../helper/genericFunction';
import ProfilePlaceholder from '../../../assets/icons/ProfilePlaceholder.svg';
import Icons from '../../../assets/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteDB } from 'idb';
import SwitchOrg from './SwitchOrg';
import FullPageLoader from '../../../loaders/FullPageLoader';
import '../../../styles/stylesheets/home/Header.scss';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useDispatch } from 'react-redux';

const ProfileSection = (props) => {
    const dispatch = useDispatch();
    const { loading, setLoading } = props;

    const history = useHistory();
    // Token data
    let tokenData = parseJwt(sessionStorage.getItem('token'));
    // The login response of the api
    let loginResponse = JSON.parse(sessionStorage.getItem("loginResponse")) || {};

    const [enterprises, setEnterprises] = useState([]);
    const [openProfileDrop, setOpenProfileDrop] = useState(false);
    const [switchOrgDrop, setSwitchOrgDrop] = useState(false);

    const profileRef = useRef(null);

    useEffect(() => {
        setEnterprises(loginResponse?.["user"]?.enterprises?.filter(_ => _.active == 1) || []);

        // Add event listener when component mounts
        document.addEventListener("click", handleClickOutside);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const openProfileDropdown = () => {
        setOpenProfileDrop(prevState => !prevState);
    }

    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event?.target)) {
            setOpenProfileDrop(false);
            setSwitchOrgDrop(false);
        }
    };

    const onLogout = async () => {
        setLoading(true);
        dispatchHandler(dispatch, "userSessionCreateRequest", { autoSignIn: "NA", emailId: tokenData?.eml || "", keepMeSignIn: "false", mode: "WEB", token: sessionStorage.getItem('token') || "", type: "LOGGEDOUT", userName: tokenData?.prn || "", id: sessionStorage.getItem("loginId") || "" });
        deleteCookie('jwt')
        localStorage.clear();
        sessionStorage.clear();
        window?.location?.reload();
        await deleteDB("Supplymint-ai-local-storage");
        setLoading(false);
    };

    const handleRedirect = (path) => {
        history.push(path);
        setOpenProfileDrop(false);
    }


    const data = [
        {
            "eid": 1,
            "oid": 585,
            "ecode": "tcloud",
            "ename": "TCLOUD Retailer-Tcloud",
            "ucode": "ENT",
            "default": 0,
            "subUserType": null,
            "isHold": 0,
            "holdLabel": null,
            "holdMessage": null,
            "active": 1
        },
        {
            "eid": 1,
            "oid": 2,
            "ecode": "tcloud",
            "ename": "TCLOUD-Tcloud",
            "ucode": "ENT",
            "default": 0,
            "subUserType": null,
            "isHold": 1,
            "holdLabel": 'Onhold',
            "holdMessage": 'This is hold message',
            "active": 0
        }
    ]

    return (
        <React.Fragment>
            {/* // Render the dropdown section for profile */}
            <div className='hohrm-user-info' ref={profileRef}>

                {/* The profile dropdown handler  */}
                <div className='flex' onClick={openProfileDropdown}>
                    <div className='hu-logo'>
                        <img src={tokenData?.org_logo || ProfilePlaceholder} loading="lazy" />
                    </div>
                    <div className='hu-det'>
                        <div className='flex items-center'>
                            <h4>{enterprises?.[0]?.["ename"]}</h4>
                            <span className={`hud-icon ${openProfileDrop && "rotate-180"}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.293" height="6.146" viewBox="0 0 14.293 8.146">
                                    <path fill="none" stroke="#21314b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" id="prefix__Path_377" d="M6 14.732L11.732 9l5.732 5.732" data-name="Path 377" transform="translate(-4.586 -8)"></path>
                                </svg>
                            </span>
                        </div>
                        <p>{tokenData?.firstName == null ? "" : tokenData?.firstName} {tokenData?.lastName == null ? "" : tokenData?.lastName}</p>
                    </div>
                </div>

                {/* The profile dropdowns */}
                {openProfileDrop && <div className='profile-dropdown'>
                    <ul>
                        {/* vendoronboarding form redirect */}
                        {tokenData?.uType == 'VENDOR' && <div className='p-10'>
                            <div className='onboarding-alert'>
                                <div> <Icons.OnboardingWarningIcon /> </div>
                                <div className='oa-det'>
                                    <h2>Complete your Onboarding</h2>
                                    <p>Complete your onboarding process to avail full benefits of supplymint features</p>
                                    <GenericCustomButton
                                        rightIcon={<Icons.RightArrowThin />}
                                        label='Complete Now'
                                        onClick={() => {
                                            props.setAppStackDocState('otherStack')
                                            history.push('/vendoronboarding/summary')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>}

                        {/* The profile url handler */}
                        <li onClick={() => handleRedirect('/profile')}>
                            <span className="hund-icon"><Icons.ProfileFaceIcon /></span>
                            <label>Profile</label>
                        </li>

                        {/* The settings url for supplymint */}
                        <li onClick={() => handleRedirect('/changeSetting')}>
                            <span className="hund-icon"><Icons.ProfileSettingIcon /></span>
                            <label>Settings</label>
                        </li>

                        {/* Accounts change section */}
                        <li className="pd-label">
                            <span>Your Accounts</span>
                            <span>{loginResponse?.["user"]?.["enterprises"]?.length}</span>
                        </li>

                        {/* Currently set up account */}
                        <li id="cascadeEnterprises" className={`switch-retailer ${switchOrgDrop && " sr-active"}`} onClick={() => setSwitchOrgDrop(!switchOrgDrop)}>
                            <div className="sr-org-inner">
                                <div className="sr-left">
                                    <span className="hund-icon">
                                        <Icons.ProfileOrgIcon />
                                    </span>
                                    <label>{enterprises?.[0]?.ename}</label>
                                </div>
                                <span className={switchOrgDrop ? "" : "sricon-rotete180"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.293" height="6.146" viewBox="0 0 14.293 8.146">
                                        <path fill="none" stroke="#21314b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" id="prefix__Path_377" d="M6 14.732L11.732 9l5.732 5.732" data-name="Path 377" transform="translate(-4.586 -8)" />
                                    </svg>
                                </span>
                            </div>
                            <span className="sroi-name">{enterprises?.[0]?.ename}</span>

                            {/* Render the swith organisation dropdown */}
                            <div id="orgDropdownMain">
                                {switchOrgDrop && <SwitchOrg
                                    enterprises={loginResponse?.["user"]?.["enterprises"]}
                                // enterprises={data}
                                />}
                            </div>
                        </li>

                        <li className="pd-logout" onClick={(e) => onLogout(e)}>
                            <span className="hund-icon">
                                <Icons.LogoutIcon />
                            </span>
                            <label>Logout</label>
                        </li>
                    </ul>
                </div>}
            </div>
        </React.Fragment>
    )
}

export default ProfileSection;
