import React, { Suspense } from 'react'
import FullPageLoader from '../loaders/FullPageLoader'
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import NoPageFound from '../components/helperComponents/NoPageFound'
import VendorOnboarding from '../authComponents/vendorOnboarding/VendorOnboarding'
import PercentageSummary from '../authComponents/vendorOnboarding/components/PercentageSummary'

const OtherStack = (props) => {
    return (
        <Suspense fallback={<FullPageLoader />}>

            <Switch>
                <Route exact path="/vendoronboarding" component={VendorOnboarding} />
                <Route exact path="/vendoronboarding/summary" render={(routeProps) => <PercentageSummary {...props} {...routeProps} />} />

                <Route path='*' component={NoPageFound} />
            </Switch>
        </Suspense>
    )
}

export default OtherStack