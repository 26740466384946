import React, { useEffect, useState, useRef } from 'react'
import GenericCustomButton from './GenericCustomButton'
import Icons from '../assets/icons';

function AppliedFiltersPopUp(props) {

    const { isAppliedFilterModalOpen } = props;

    const modalRef = useRef(null);

    const [appliedFilters, setAppliedFilters] = useState(props?.appliedFilters || {})
    const [headersData, setHeadersData] = useState(props?.headersData || {})
    const [headersType, setHeadersType] = useState(props?.headersType || {})
    const [modalFromTop, setModalFromTop] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            document.addEventListener('click', hideModal);
        }, 0)
        return () => {
            document.removeEventListener('click', hideModal);
        };
    }, [])

    useEffect(() => {
        //   console.log("val: ", modalRef?.current)
        const modalFromTop = modalRef?.current?.getBoundingClientRect()?.top;
        setModalFromTop(modalFromTop);
    }, [modalRef])

    const hideModal = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            props?.handleTrueFalse("showAppliedFilters", false);
        }
    }

    const getFilterValue = (key) => {
        if (headersData?.main?.columnType[key]) {
            switch (headersData?.main?.columnType[key]) {
                case "NUMBER":
                case "TIME": {
                    return <span className='afpbr-value'>From {appliedFilters[key].from} to {appliedFilters[key].to}</span>;
                }
                case "LOV": case "SEARCH": {
                    if (typeof appliedFilters[key] == "string") {
                        return checkOthersValue(appliedFilters[key])
                    }
                    else if (Array.isArray(appliedFilters[key])) {
                        return appliedFilters[key]?.map((itemVal) => {
                            return <span className='afpbr-value'>{itemVal}</span>
                        })
                    }
                }
                case "DATE": {
                    if (!appliedFilters[key].key)
                        return <span className='afpbr-value'>From {appliedFilters[key].from} to {appliedFilters[key].to}</span>;
                    else if (appliedFilters[key].key === "SM_DATE_TODAY_VALUE")
                        return <span className='afpbr-value'>Today</span>;
                    else if (appliedFilters[key].key === "SM_DATE_YESTERDAY_VALUE")
                        return <span className='afpbr-value'>Yesterday</span>;
                    else if (appliedFilters[key].key === "SM_DATE_LAST7DAYS_VALUE")
                        return <span className='afpbr-value'>Last 7 Days</span>;
                    else if (appliedFilters[key].key === "SM_DATE_LASTWEEK_VALUE")
                        return <span className='afpbr-value'>Last Week</span>;
                    else if (appliedFilters[key].key === "SM_DATE_THISMONTH_VALUE")
                        return <span className='afpbr-value'>This Month</span>;
                    else if (appliedFilters[key].key === "SM_DATE_LASTMONTH_VALUE")
                        return <span className='afpbr-value'>Last Month</span>;
                    return <span className='afpbr-value'></span>;
                }
                default: {
                    if (appliedFilters[key] == "SM_TEXT_EMPTY_VALUE")
                        return <span className='afpbr-value'>is Empty</span>;
                    else if (appliedFilters[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                        return <span className='afpbr-value'>is Not Empty</span>;
                    else
                        return <span className='afpbr-value'>{appliedFilters[key]}</span>;
                }
            }

        }
        else if(appliedFilters?.[key]) {
            if (appliedFilters[key] == "SM_TEXT_EMPTY_VALUE")
                return <span className='afpbr-value'>is Empty</span>;
            else if (appliedFilters[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                return <span className='afpbr-value'>is Not Empty</span>;
            else
                return <span className='afpbr-value'>{appliedFilters[key]}</span>;
        }
    }

    const checkOthersValue = (value) => {
        if (value == "SM_TEXT_EMPTY_VALUE")
            return <span className='afpbr-value'>is Empty</span>;
        else if (value == "SM_TEXT_NOT_EMPTY_VALUE")
            return <span className='afpbr-value'>is Not Empty</span>;
        else return value?.split(",")?.map((itemVal) => {
            return <span className='afpbr-value'>{itemVal}</span>
        })
    }

    const getExistingItemFilterValue = (key, filterValue) => {
        if(headersData?.item?.columnType?.[key]) {
            switch (headersData?.item?.columnType?.[key]) {
                case "NUMBER":
                case "TIME": {
                    return <span className='afpbr-value'>From {filterValue[key].from} to {filterValue[key].to}</span>;
                }
                case "LOV": case "SEARCH": {
                    if (typeof filterValue[key] == "string") {
                        return checkOthersValue(filterValue[key])
                    }
                    else if (Array.isArray(filterValue[key])) {
                        return filterValue[key]?.map((itemVal) => {
                            return <span className='afpbr-value'>{itemVal}</span>
                        })
                    }
                }
                case "DATE": {
                    if (!filterValue[key].key)
                        return <span className='afpbr-value'>From {filterValue[key].from} to {filterValue[key].to}</span>;
                    else if (filterValue[key].key === "SM_DATE_TODAY_VALUE")
                        return <span className='afpbr-value'>Today</span>;
                    else if (filterValue[key].key === "SM_DATE_YESTERDAY_VALUE")
                        return <span className='afpbr-value'>Yesterday</span>;
                    else if (filterValue[key].key === "SM_DATE_LAST7DAYS_VALUE")
                        return <span className='afpbr-value'>Last 7 Days</span>;
                    else if (filterValue[key].key === "SM_DATE_LASTWEEK_VALUE")
                        return <span className='afpbr-value'>Last Week</span>;
                    else if (filterValue[key].key === "SM_DATE_THISMONTH_VALUE")
                        return <span className='afpbr-value'>This Month</span>;
                    else if (filterValue[key].key === "SM_DATE_LASTMONTH_VALUE")
                        return <span className='afpbr-value'>Last Month</span>;
                    return "";
                }
                default: {
                    if (filterValue[key] == "SM_TEXT_EMPTY_VALUE")
                        return <span className='afpbr-value'>is Empty</span>;
                    else if (filterValue[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                        return <span className='afpbr-value'>is Not Empty</span>;
                    else
                        return <span className='afpbr-value'>{filterValue[key]}</span>;
                }
            }
        }
        else if(filterValue?.[key]) {
            if (filterValue[key] == "SM_TEXT_EMPTY_VALUE")
                return <span className='afpbr-value'>is Empty</span>;
            else if (filterValue[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                return <span className='afpbr-value'>is Not Empty</span>;
            else
                return <span className='afpbr-value'>{filterValue[key]}</span>;
        }
    }

    const clearFilter = () => {
        props?.clearFilter();
        props?.handleTrueFalse("showAppliedFilters", false);
    }

    const clearSingleFilter = (key) => {
        let filters = { ...appliedFilters };
        if(filters?.[key] || key in filters) {
            delete filters[key]
        }
        else if(filters?.item?.[key]) {
            delete filters.item[key]
        }
        setAppliedFilters(filters);
        if(Object.keys(filters || {})?.length == 0 || (Object.keys(filters || {})?.length == 1 && 'item' in filters && Object.keys(filters.item || {})?.length == 0)) {
            props?.handleTrueFalse("showAppliedFilters", false);
            props?.applyFilter({})();
        }
        else {
            props?.applyFilter(filters)();
        }
    }

    return (
        <div className={`applied-filters-popup left-0 ${isAppliedFilterModalOpen ? 'appliedFilterModalOpen' : ''}`} ref={modalRef}>
            <div className='afp-head'>
                <h3>Applied Filters</h3>
                <p>Below are the filters applied to your search terms. You can save these as a quick filter for future use.</p>
            </div>
            <div className="afp-body" style={{
                maxHeight: `calc(100vh - ${modalFromTop}px - 150px)`
            }}>
                {Object.keys(appliedFilters || {})?.map((key) => {
                    if (key === "item") {
                        return Object.keys(appliedFilters?.item || {})?.map(newKey => (
                            <div className="filterDet">
                                <label className='filterLabel'>{headersData?.item?.[headersType?.item][newKey]}</label>
                                <div className='filterData'>
                                    {getExistingItemFilterValue(newKey, appliedFilters?.item)}
                                    <span className='clear-filter' onClick={()=> clearSingleFilter(newKey)}><Icons.ModalCloseIcon /></span>
                                </div>
                            </div>
                        ))
                    }
                    return <div className="filterDet">
                        <label className='filterLabel'>{headersData?.main?.[headersType?.main][key]}</label>
                        <div className='filterData'>
                            {getFilterValue(key)}
                            <span className='clear-filter' onClick={()=> clearSingleFilter(key)}><Icons.ModalCloseIcon /></span>
                        </div>
                    </div>
                })}
            </div>
            <div className="afp-footer">
                <GenericCustomButton label="Clear Filter" type='default' onClick={() => clearFilter()} />
                <GenericCustomButton label={`Save as “Quick Filter”`} type="primary" onClick={() => props?.saveFilters()} />
            </div>
        </div>
    )
}

export default AppliedFiltersPopUp