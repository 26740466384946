import { AUTH_CONFIG } from '../../authConfig';
import { CONFIG } from '../../config/index';
export const generalStore = [
    {
        title: "getMasterDbHeaderData",
        actionPrefix: 'getMasterDbHeader',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/core/org/get/masterDbData`,
        },
    },
    {
        title: "getMasterDbFilterData",
        actionPrefix: 'getMasterDbFilter',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/core/org/get/masterDbData`,
        },
    },
    {
        title: "getMasterDbDropdownData",
        actionPrefix: 'getMasterDbDropdown',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/core/org/get/masterDbData`,
        },
    },
    {
        title: "getDataExportModalData",
        actionPrefix: 'getDataExportModal',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/core/org/data/export/getAll`,
        },
    },
    {
        title: "cancelDataExportModalData",
        actionPrefix: 'cancelDataExportModal',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/download/cancel/export`,
        },
    },
    {
        title: "getNotificationModalData",
        actionPrefix: 'getNotificationModal',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/get/all/notifications`,
        },
    },
    {
        title: "getHeadersData",
        actionPrefix: 'getHeaders',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/headerconfig/get/header?enterpriseName=${payload.enterpriseName}&attributeType=${payload.attributeType}&displayName=${payload.displayName}&orgId=`,
        },
        responseData: {
            successDataHandler: (response, payload) => {
                console.log("Resource Data1", response, payload);
                if (response?.data?.data) {
                    response.data.data["basedOn"] = payload?.["basedOn"];
                }
                return response?.data?.data;
            },
        }
    },
    {
        title: "getQuickFiltersData",
        actionPrefix: 'getQuickFilters',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/headerconfig/get/filter`
        },
    },
    {
        title: "getDropdownData",
        actionPrefix: 'getDropdown',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/core/dropdown/get?type=${payload?.type}`
        },
    },
    {
        title: "downloadReportData",
        actionPrefix: 'downloadReport',
        message: 'Request Completed Successfully',
        requestData: {
            isPayloadExistParams: true,
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/download/module/data?fileType=XLS&module=${payload.module}&isAllData=${payload.isAllData}&isOnlyCurrentPage=${payload.isOnlyCurrentPage}`
        },
    },
    {
        title: "downloadDocumentsData",
        actionPrefix: 'downloadDocuments',
        message: 'Document Downloaded & Saved Successfully!',
        errorMessage: "Failed To Download the Required Document!",
        requestData: {
            // updatePayload: ([...payload]) => {
            //     if (payload[0].key === "POPDFWITHIMG") {
            //         payload?.forEach(item => delete item.key)
            //     }
            //     if(payload?.[0]?.key == "BOXBARCODEV2"){
            //         payload?.forEach(item => delete item.key)
            //         return payload[0];
            //     }
            //     if(payload?.[0]?.key == "QRCODE"){
            //         payload?.forEach(item => delete item?.["QRCODE"]);
            //         return payload;
            //     }
            //     return payload;
            // },
            // buildUrl: (payload) => {
            //     switch (payload[0].key) {
            //         case "BARCODE": {
            //             return `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorlogi/find/barcodepdf`
            //         }
            //         case "LRPDF": {
            //             return `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/entlogi/get/lrpdf`
            //         }
            //         case "BOX": {
            //             return `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorlogi/find/barcodepdf`
            //         }
            //         case "POPDFWITHIMG": {
            //             return `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/new/download/document`
            //         }
            //         case "BOXBARCODEV2": {
            //             return `${CONFIG.BASE_MICRO_SERVICE_URL}/utility/document/download`
            //         }
            //         case "QRCODE" : {
            //             return `${CONFIG.BASE_URL}/admin/catalogue/qrcode/download`
            //         }
            //         default: {
            //             return `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/download/document`
            //         }
            //     }
            // }
            buildUrl: (payload) => `${CONFIG.BASE_URL}/utility/document/download`
        },
    },
    {
        title: "createEmailSchedulesData",
        actionPrefix: 'createEmailSchedules',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.SYSTEM_CONFIG}/create/email/scheduler`
        },
    },
    {
        title: "deleteEmailSchedulesData",
        actionPrefix: 'deleteEmailSchedules',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.SYSTEM_CONFIG}/delete/emailScheduler`
        },
    },
    {
        title: "emailReportData",
        actionPrefix: 'emailReport',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                delete payload.summaryApiUrl;
                delete payload.key;
                return payload;
            },
            isPayloadExistParams: true,
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/download/send/email/module/data?fileType=XLS&module=${payload.module}&isAllData=${payload.isAllData}&isOnlyCurrentPage=${payload.isOnlyCurrentPage}`
        },
    },
    {
        title: "getImageTrackingData",
        actionPrefix: 'getImageTracking',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                payload.type == 2 && Object.keys(payload.filter).map(key => {
                    if (payload.filter[key] === "") {
                        delete payload.filter[key];
                    }
                })
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.PO}/get/po/detail`,
        },
    },
    {
        title: "getRefreshReportData",
        actionPrefix: 'getRefreshReport',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/refresh/mview`
        },
    },
    {
        title: "createHeadersData",
        actionPrefix: 'createHeaders',
        message: 'Headers Configuration Updated Successfully.',
        errorMessage: "Failed To Set Up Headers Configuration!",
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/headerconfig/create`
        },
        responseData: {
            successDataHandler: (response, payload) => {
                let resp = JSON.parse(JSON.stringify(response));
                if (resp?.data?.data) {
                    resp.data.data["basedOn"] = payload?.["basedOn"];
                    resp.data.data["displayName"] = payload?.["displayName"];
                    resp.data.data["Custom Headers"] = payload?.["customHeaders"];
                    resp.data.data["Default Headers"] = payload?.["defaultHeaders"];
                    resp.data.data["Fixed Headers"] = payload?.["fixedHeaders"];
                    resp.data.data["exportFilters"] = payload?.["exportFilter"]
                }
                console.log("Resource Data1", resp, payload);
                return resp?.data?.data;
            },
        }
    },
    {
        title: "createQuickFiltersData",
        actionPrefix: 'createQuickFilters',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/headerconfig/create/filter`
        },
        responseData: {
            successDataHandler: (response, payload) => {
                let resp = JSON.parse(JSON.stringify(response));
                if (resp?.data?.data) {
                    resp.data.data["basedOn"] = payload?.["basedOn"];
                    resp.data.data["displayName"] = payload?.["displayName"];
                    resp.data.data["isDeleteFilter"] = payload?.["isDeleteFilter"];
                    resp.data.data["is_default"] = payload?.["is_default"];
                    resp.data.data["filterName"] = payload?.["filterName"]
                    resp.data.data["filterValue"] = payload?.["filterValue"]
                }
                // console.log("Resource Data1", resp, payload);
                return resp?.data?.data;
            },
        }
    },
    {
        title: "getBarcodeReportData",
        actionPrefix: 'getBarcodeReport',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/barcode/get/data`
        },
    },
    {
        title: "getSearchFiltersData",
        actionPrefix: 'getSearchFilters',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/search/arsGenericFilters`
        },
    },
    {
        title: "getEmailSchedulesData",
        actionPrefix: 'getEmailSchedules',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.SYSTEM_CONFIG}/get/all/emailScheduler`
        },
    },
    {
        title: "getAllAuditData",
        actionPrefix: 'getAllAudit',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/get/all/default/logs`
        },
    },
    {
        title: "getAuditData",
        actionPrefix: 'getAudit',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/get/all/default/logs`
        },
    },
    {
        title: "getAuditDetailData",
        actionPrefix: 'getAuditDetail',
        errorMessage: 'Failed to get the audit log details!',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/get/all/detailed/logs`
        },
    },
    {
        title: "getTableComponentSummaryData",
        actionPrefix: 'getTableComponentSummary',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl
                delete payload.summaryApiUrl;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload.summaryApiUrl}`
        },
    },
    {
        title: "getHeaderConfigExcelData",
        actionPrefix: 'getHeaderConfigExcel',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.summaryApiUrl;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload.summaryApiUrl}`
        },
    },
    {
        title: "getTableComponentListData",
        actionPrefix: 'getTableComponentList',
        message: 'Table Data Recieved Successfully.',
        errorMessage: "Unable To Get the Table Data!",
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.baseUrl;
                delete payload.apiUrl;
                delete payload.summaryApiUrl;
                if("deleteKeysPayload" in payload) {
                    if(Object.keys(payload.deleteKeysPayload || {})?.length !== 0) {
                        Object.keys(payload.deleteKeysPayload || {})?.map((key) => {
                            if(!payload.deleteKeysPayload?.[key]?.includes(payload?.[key])) {
                                delete payload[key];
                            }
                        });
                        delete payload.deleteKeysPayload
                    }
                    else {
                        delete payload.deleteKeysPayload
                    }
                }
                return payload;
            },
            buildUrl: (payload) => `${payload.baseUrl ? payload?.baseUrl : CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getGeneralListData",
        actionPrefix: 'getGeneralList',
        message: 'Data Fetched Successfully.',
        errorMessage: "Unable To fetch the data!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${payload.baseUrl ? payload?.baseUrl : CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getTableComponentSecondListData",
        actionPrefix: 'getTableComponentSecondList',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                console.log(678678, payload)
                delete payload.apiUrl;
                delete payload.baseUrl;
                return payload;
            },
            buildUrl: (payload) => `${payload.baseUrl ? payload?.baseUrl : CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getTableComponentThirdListData",
        actionPrefix: 'getTableComponentThirdList',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                delete payload.baseUrl;
                return payload;
            },
            buildUrl: (payload) => `${payload.baseUrl ? payload?.baseUrl : CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getTableComponentFourthListData",
        actionPrefix: 'getTableComponentFourthList',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                delete payload.baseUrl;
                return payload;
            },
            buildUrl: (payload) => `${payload.baseUrl ? payload?.baseUrl : CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getTableComponentFifthListData",
        actionPrefix: 'getTableComponentFifthList',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                delete payload.baseUrl;
                return payload;
            },
            buildUrl: (payload) => `${payload.baseUrl ? payload?.baseUrl : CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getGenericExcelTemplateData",
        actionPrefix: 'getGenericExcelTemplate',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/upload/get/all/templates`
        }
    },
    {
        title: "validateGenericExcelSheetData",
        actionPrefix: 'validateGenericExcelSheet',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/submit/excel/data`
        }
    },
    {
        title: "getSupplymintTraningGuidesData",
        actionPrefix: 'getSupplymintTraningGuides',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/get/all/training/guide`
        }
    },
    {
        title: "commentNotificationData",
        actionPrefix: 'commentNotification',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/comments/notification`
        }
    },
    {
        title: "qcAddCommentData",
        actionPrefix: 'qcAddComment',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/add/comqcinvoice`
        }
    },
    {
        title: "getAllCommentData",
        actionPrefix: 'getAllComment',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/find/all/comqcinvoice`
        }
    },
    {
        title: "getUserEmailsData",
        actionPrefix: 'getUserEmails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.CORE_USER}/find/info`
        }
    },
    {
        title: "deleteUploadsCommentsData",
        actionPrefix: 'deleteUploadsComments',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/delete/uploads`
        }
    },
    {
        title: "emailTranscriptData",
        actionPrefix: 'emailTranscript',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/send/transcript`
        }
    },
    {
        title: "removeMultipleFileUploadData",
        actionPrefix: 'removeMultipleFileUpload',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/custom/remove/multiple/file/upload`
        }
    },
    {
        title: "getProcExcelTemplateData",
        actionPrefix: "getProcExcelTemplate",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/excel/upload/drop/down/data`
        }
    },
    {
        title: "getProcExcelTemplateData",
        actionPrefix: "getProcExcelTemplate",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/excel/upload/drop/down/data`
        }
    },
    {
        title: "validateProcExcelData",
        actionPrefix: "validateProcExcel",
        message: "Excel Data Validated Successfully",
        errorMessage: "Failed To Validate Excel Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/excel/data/validation`
        }
    },
    {
        title: "submitProcExcelData",
        actionPrefix: "submitProcExcel",
        message: "Digiproc Excel Data Submitted Successfully",
        errorMessage: "Failed To Submit Excel Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/excel/submit/data`
        }
    },
    {
        title: "getUploadedUtilityDocumentData",
        actionPrefix: "getUploadedUtilityDocument",
        errorMessage: "Failed To fetch the uploaded files!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/utility/document/download/filepath`
        }
    },
    {
        title: "getAnnouncementData",
        actionPrefix: "getAnnouncement",
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}/get/details`,
            extraHeader: {
                "access-control-request-headers" : "content-type",
                "access-control-request-method" : "POST",
                "Page-Link":undefined
            }
        }
    },
    {
        title: "getDocumentDownloadFilepathData",
        actionPrefix: "getDocumentDownloadFilepath",
        errorMessage: "Failed To Load Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/utility/document/download/filepath`
        }
    },
    {
        title: "utilityDocumentDownloadData",
        actionPrefix: "utilityDocumentDownload",
        message: "Document Download Successfully",
        errorMessage: "Failed To Load Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/utility/document/download`
        }
    },
    // Api : To get the generic dropdown data for all modules.
    {
        title: "getMasterDbDocumentDropdownData",
        actionPrefix: "getMasterDbDocumentDropdown",
        errorMessage: "Failed To Get the List of Documents Dropdown!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/list/download/document?displayName=`
        }
    },
    // Api : To get the data of the specific document dropdown according to a display name.
    {
        title: "getDocumentDropdownData",
        actionPrefix: "getDocumentDropdown",
        errorMessage: "Failed To Get the List of Documents Dropdown!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/list/download/document?displayName=${payload.displayName}`
        }
    },
    // Api:  to get the taxn and purchase term dicount  information throughout the supplymint
    {
        title: "getPurchaseTermOrderInfoData",
        actionPrefix: "getPurchaseTermOrderInfo",
        errorMessage: "Failed To Get the purchase information for document!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/term/info`
        }
    },
    {
        title: "userSessionCreateData",
        actionPrefix: "userSessionCreate",
        errorMessage: "Failed To Create Session!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/user/session/create`
        }
    },
]