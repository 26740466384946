import React from 'react'
import GenericCustomButton from '../../genericComponents/GenericCustomButton';
import Lottie from 'lottie-react';
import JobSuccessTickMarkGreen from '../../animationsJSON.js/WhiteSuccessLotti.json'
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: JobSuccessTickMarkGreen,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    },
    style: {
        width: 150,
        height: 150
    }
};

const MergeSuccessModal = (props) => {

    const { submitModal,buttonLabel = "Complete Process" } = props;

    return (
        <div className='acc-setup-cmpltd'>
            <div className="setup-cmpltd">
                <div className='sc-top'>
                    <Lottie
                        {...defaultOptions}
                    />
                    <div className="sc-content">
                        <label>Account Merge Successful</label>
                        <p>You can now login with your primary account to access all your merged accounts.</p>
                    </div>
                </div>
                <div className="sc-bot">
                    <GenericCustomButton
                        type="normal"
                        label={buttonLabel}
                        onClick={() => {
                            submitModal();
                        }}
                    />
                </div>
            </div>
            <div className="switch-org">
                <p>See below how you can switch between your organizations !</p>
                <div className='success-img'>
                    <img src={require('../../assets/images/organization-switch.gif')} />
                </div>
            </div>
        </div>
    )
}

export default MergeSuccessModal