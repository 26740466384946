import React, { useEffect, useState } from 'react'
import LoginPasswordInput from '../LoginPasswordInput'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import Icons from '../../../assets/icons';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useSelector } from 'react-redux'

const ResetPassword = (props) => {
	const authStore = useSelector(state => state.authStore);

	const [currentPass, setCurrentPass] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPass, setConfirmPass] = useState("");

	// Handle the response of the change password api
    useEffect(() => {
        if (props?.showCurrentPass && authStore?.["changePasswordData"]?.isSuccess) {
            setPassword("");
			setCurrentPass("");
			setConfirmPass("");
        }
    }, [authStore?.["changePasswordData"]?.isSuccess])

	const handleChange = (type, data) => {
		switch (type) {
			case "submitData": {
				if (!isSubmitButtonDisabled()) {
					if(props?.showCurrentPass) {
						let passObj = {
							currentPass: currentPass,
							newPass: password
						}
						props.changePassword(passObj);
					}
					else {
						props.changePassword(password);
					}
				}
				break;
			}
		}
	}

	const isSubmitButtonDisabled = () => {
		if (!password || !confirmPass) {
			return true;
		}
		if (password?.length < 8 || !(password.match(/(?=.*[a-z])/)) || !(password.match(/(?=.*[A-Z])/)) || !password.match(/(?=.*[!,%,&,@,#,$,^,*,?,_,~,<,>,])/) || !password.match(/(?=.*[0-9])/)) {
			return true;
		}
		if (password != confirmPass) {
			return true;
		}

		if (props?.showCurrentPass && (currentPass?.length < 8 || !(currentPass.match(/(?=.*[a-z])/)) || !(currentPass.match(/(?=.*[A-Z])/)) || !currentPass.match(/(?=.*[!,%,&,@,#,$,^,*,?,_,~,<,>,])/) || !currentPass.match(/(?=.*[0-9])/))) {
			return true;
		}

		return false;
	}

	const getStrength = () => {
		let s = 0;
		if (password?.length >= 8) s = s + 1;
		if (password?.match(/(?=.*[0-9])/)) s = s + 1;
		if (password?.match(/(?=.*[!,%,&,@,#,$,^,*,?,_,~,<,>,])/)) s = s + 1;
		if (password?.match(/(?=.*[a-z])/)) s = s + 1;
		return s;
	}

	const strength = getStrength();
	return (
		<div className='check-password'>
			<h2>Enter New Password</h2>
			<p>Double-type your new password according to the specified criteria.</p>
			<div className='cp-body'>
				{props?.showExpiredPasswordStrip && <div className='cpb-password-expired'>
					<Icons.WarningYellowIcon />
					<div className='cpbpe-right'>
						<h3>Password Expired</h3>
						<p>Your password has been expired. please create new password to continue</p>
					</div>
				</div>}
				{props?.showCurrentPass && <LoginPasswordInput
					label="Current Password"
					password={currentPass}
					setPassword={setCurrentPass}
					iconLeft={<Icons.PasswordLockIcon />}
					submit={() => handleChange("submitData")}
					placeholder="Password must be 8 characters."
				/>}
				<LoginPasswordInput
					password={password}
					label={props?.showCurrentPass ? "New Password" : null}
					setPassword={setPassword}
					iconLeft={<Icons.PasswordLockIcon />}
					submit={() => handleChange("submitData")}
					placeholder="Password must be 8 characters."
				/>
				<div className='cpb-check'>
					<div className='flex justify-between'>
						<div className={`cpbc ${password?.length >= 8 && "cpbc-checked"}`}>
							<Icons.PasswordCheckCircle />
							<span>Use at least 8 characters</span>
						</div>
						<div className={`cpbc ${(password.match(/(?=.*[a-z])/) && password.match(/(?=.*[A-Z])/)) && "cpbc-checked"}`}>
							<Icons.PasswordCheckCircle />
							<span>Use upper & lower case letters</span>
						</div>
					</div>
					<div className='flex justify-between'>
						<div className={`cpbc ${password.match(/(?=.*[!,%,&,@,#,$,^,*,?,_,~,<,>,])/) && "cpbc-checked"}`}>
							<Icons.PasswordCheckCircle />
							<span>Use one special character</span>
						</div>
						<div className={`cpbc ${password.match(/(?=.*[0-9])/) && "cpbc-checked"}`}>
							<Icons.PasswordCheckCircle />
							<span>Use one number</span>
						</div>
					</div>
				</div>

				{password && <div className="prog-strong-text">
					<div className={strength == 4 ? "prog-strong" : strength >= 2 ? "prog-medium" : "prog-weak"}>
						<progress id="file" value="100" max="100"></progress>
						<progress id="file" value="100" max="100"></progress>
						<progress id="file" value="100" max="100"></progress>
						<progress id="file" value="100" max="100"></progress>
					</div>
					<h3 className={`lineStrength-${strength}`}>{strength == 4 ? "Strong" : strength >= 2 ? "Moderate" : "Weak"}</h3>
				</div>}

				<LoginPasswordInput
					password={confirmPass}
					label="Confirm Password"
					setPassword={setConfirmPass}
					submit={() => handleChange("submitData")}
					placeholder="Password must be same as new password."
				/>

				<div className='flex justify-end p-top-20'>
					<GenericCustomButton
						type='primary'
						disabled={() => isSubmitButtonDisabled()}
						label="Change My Password"
						onClick={() => handleChange("submitData")}
					/>
				</div>
			</div>
		</div>

	)
}

export default ResetPassword