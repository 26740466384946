import { CONFIG } from '../../config/index';
let headers = {
    'X-Auth-Token': sessionStorage.getItem('token'),
    'Content-Type': 'multipart/form-data'
}
export const digivendStore = [
    {
        title: "getRetailerShipmentRetryData",
        actionPrefix: 'getRetailerShipmentRetry',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorship/document/edit/restrict`
        }
    },
    {
        title: "getSystemConfigKeysData",
        actionPrefix: 'getSystemConfigKeys',
        message: 'Config Keys Stored Successfully!',
        errorMessage: "Unable To Get the Digivend Config Keys!",
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.SYSTEM_CONFIG}/get/active/key`
        }
    },
    {
        title: "EnterpriseGetPoDetailsremQtyData",
        actionPrefix: 'EnterpriseGetPoDetailsremQty',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/entpo/get/po/rem/qty`
        }
    },
    {
        title: "EnterpriseGetPoDetailsremQtyUpdateData",
        actionPrefix: 'EnterpriseGetPoDetailsremQtyUpdate',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/entpo/update`
        }
    },
    {
        title: "EnterpriseCancelPoData",
        actionPrefix: 'EnterpriseCancelPo',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.userType;
                return payload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/${payload.userType ? payload?.userType : "entpo"}/update`
        }
    },
    {
        title: "EnterpriseHoldAsnData",
        actionPrefix: 'EnterpriseHoldAsn',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/${payload?.userType}/update/asn/hold/status`
        }
    },
    {
        title: "extendPOValidityData",
        actionPrefix: 'extendPOValidity',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.PO}/extend/validity`
        }
    },
    {
        title: "bulkOperationData",
        actionPrefix: 'bulkOperation',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.PO}/bulk/operation`
        }
    },
    {
        title: "approvePOConfirmData",
        actionPrefix: 'approvePOConfirm',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorpo/confirm`
        }
    },
    {
        title: "enterpriseApprovedPoData",
        actionPrefix: 'enterpriseApprovedPo',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                return payload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload?.apiUrl}`
        }
    },
    {
        title: "shipmentConfirmCancelData",
        actionPrefix: 'shipmentConfirmCancel',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/entship/update/sr`
        }
    },
    {
        title: "getComqcInvoiceData",
        actionPrefix: 'getComqcInvoice',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.uniqueKey;
                return payload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/find/all/comqcinvoice`,
        }
    },
    {
        title: "uploadUtilityDocumentData",
        actionPrefix: 'uploadUtilityDocument',
        message: 'Image/File uploaded successfully!',
        errorMessage : `Failed to upload Image/File!`,
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/utility/document/upload`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        }
    },
    {
        title: "getCompleteDetailShipmentData",
        actionPrefix: 'getCompleteDetailShipment',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/entship/find/srDetail`
        }
    },
    {
        title: "addMultipleCommentQcData",
        actionPrefix: 'addMultipleCommentQc',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/add/multiple/comqcinvoice`
        }
    },
    {
        title: "updateExtensionDateData",
        actionPrefix: 'updateExtensionDate',
        message: 'Request Completed Successfully',
        requestData: {
            // updatePayload: ({ ...payload }) => {
            //     return { shipmentUpdate: payload }
            // },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorship/update/date`
        }
    },
    {
        title: "updateVendorCancelLrData",
        actionPrefix: 'updateVendorCancelLr',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorlogi/lr/cancel`
        }
    },
    {
        title: "getAllVendorEntryDetailsData",
        actionPrefix: 'getAllVendorEntryDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/entlogi/get/gate/entry/details`
        }
    },
    {
        title: "indentBasePoV2Data",
        actionPrefix: 'indentBasePoV2',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl
                return payload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/${decodeURIComponent(payload?.apiUrl)}`
        }
    },
    {
        title: "approveApptData",
        actionPrefix: 'approveAppt',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/entship/apt/approval`
        }
    },
    {
        title: "updateGateEntryCancelData",
        actionPrefix: 'updateGateEntryCancel',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorlogi/gateentry/cancel`
        }
    },
    {
        title: "getArsCancelData",
        actionPrefix: 'getArsCancel',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorlogi/partial/asn/cancel`
        }
    },
    {
        title: "getSystemConfigDataData",
        actionPrefix: 'getSystemConfigData',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.SYSTEM_CONFIG}/get/data`
        }
    },
    {
        title: "addWHApptSiteData",
        actionPrefix: 'addWHApptSite',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.SYSTEM_CONFIG}/add/appointment/wh/sites`
        }
    },
    {
        title: "addWHApptThresholdData",
        actionPrefix: 'addWHApptThreshold',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/appot/det/wh/create/threshold/config`
        }
    },
    {
        title: "getWHApptSiteDetailsData",
        actionPrefix: 'getWHApptSiteDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/appot/det/wh/get/site/details`
        }
    },
    {
        title: "addWHApptSiteDetailsData",
        actionPrefix: 'addWHApptSiteDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/appot/det/wh/create/appointment/site/details`
        }
    },

    {
        title: "getWHApptThresholdData",
        actionPrefix: 'getWHApptThreshold',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/appot/det/wh/get/threshold/config`
        }
    },
    {
        title: "deleteWHApptThresholdData",
        actionPrefix: 'deleteWHApptThreshold',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/appot/det/wh/delete/threshold/${payload}`
        }
    },
    {
        title: "getPoMrpRangeData",
        actionPrefix: 'getPoMrpRange',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/po/get/mrp/range`
        }
    },
    {
        title: "getVendorInvoiceData",
        actionPrefix: 'getVendorInvoice',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorship/get/invoice/details`
        }
    },
    {
        title: "updateInvoiceData",
        actionPrefix: 'updateInvoice',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorship/update/invoice`
        }
    },
    {
        title: "findSiteCapacityData",
        actionPrefix: 'findSiteCapacity',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.uniqueKey;
                return payload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorlogi/find/capacity`
        }
    },
    {
        title: "updateVendorData",
        actionPrefix: 'updateVendor',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorship/update/status`
        }
    },
    {
        title: "updateTrackingStatusData",
        actionPrefix: 'updateTrackingStatus',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorlogi/update/tracking/status`
        }
    },
    {
        title: "retryLRData",
        actionPrefix: 'retryLR',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorlogi/retry/create/lr`
        }
    },
    {
        title: "appotDetWhAsnDetData",
        actionPrefix: 'appotDetWhAsnDet',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/appot/det/wh/asn/detail`
        }
    },
    {
        title: "getAllOrderStatusData",
        actionPrefix: 'getAllOrderStatus',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/entpo/get/all/order/status`
        }
    },
    {
        title: "uploadVendorFilesImagesData",
        actionPrefix: 'uploadVendorFilesImages',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/custom/generic/file/upload`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        }
    },
    {
        title: "updateTnaStatusData",
        actionPrefix: 'updateTnaStatus',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/entpo/create/tna/status`
        }
    },
    {
        title: "updateBillDiscountingData",
        actionPrefix: 'updateBillDiscounting',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ent/finance/update/status/bill/discount`
        }
    },
    {
        title: "calculateBillDiscountingData",
        actionPrefix: 'calculateBillDiscounting',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorfinance/calculate/bill/discount`
        }
    },
    {
        title: "submitBillDiscountingData",
        actionPrefix: 'submitBillDiscounting',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorfinance/submit/finance/data`
        }
    },
    {
        title: "vendFindAllAddressData",
        actionPrefix: 'vendFindAllAddress',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorship/find/all/address`
        }
    },
    {
        title: "vendFindAllLocationData",
        actionPrefix: 'vendFindAllLocation',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorship/find/all/location`
        }
    },
    {
        title: "getAllTransportersData",
        actionPrefix: 'getAllTransporters',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorlogi/get/all/transporters?type=${payload?.type}&pageNo=${payload?.pageNo}&search=${payload?.search}&slCode=${payload?.slCode}`
        }
    },
    {
        title: "vendorpoGetPodetailData",
        actionPrefix: 'vendorpoGetPodetail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorpo/get/poDetail`
        }
    },
    {
        title: "vendorshipFindSrdetailData",
        actionPrefix: 'vendorshipFindSrdetail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorship/find/srDetail`
        }
    },
    {
        title: "vendorshipCreateSrNewData",
        actionPrefix: 'vendorshipCreateSrNew',
        message: 'Request Completed Successfully',
        requestData: {
            getExtraHeaderWithPayload: (payload) => {
                return { "X-REQUEST-ID": payload?.uuid }
            },
            updatePayload: ({ ...payload }) => {
                delete payload.uuid;
                return payload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorship/create/sr/new`,
        }
    },

    {
        title: "updateAutoASNLevelsData",
        actionPrefix: 'updateAutoASNLevels',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/auto/approve/asn/update/asn`
        }
    },
    {
        title: "createAutoASNLevelsData",
        actionPrefix: 'createAutoASNLevels',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/auto/approve/asn/create/asn/config`
        }
    },
    {
        title: "deleteAutoASNLevelsData",
        actionPrefix: 'deleteAutoASNLevels',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/auto/approve/asn/delete/asn`
        }
    },
    {
        title: "getAutoRefreshData",
        actionPrefix: 'getAutoRefresh',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/auto/approve/asn/retrigger`
        }
    },
    {
        title: "createAutoASNLevelsExcelData",
        actionPrefix: 'createAutoASNLevelsExcel',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/auto/approve/asn/create/asn`
        }
    },
    {
        title: "addAutoASNConfigData",
        actionPrefix: 'addAutoASNConfig',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.SYSTEM_CONFIG}/add/auto/asn/config`
        }
    },
    {
        title: "poGlobalUploadExcelData",
        actionPrefix: 'poGlobalUploadExcel',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/po/global/config/upload/excel`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        }
    },
    {
        title: "poGlobalConfigDeleteData",
        actionPrefix: 'poGlobalConfigDelete',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/po/global/config/delete`,
        }
    },
    {
        title: "getAllCommentsData",
        actionPrefix: 'getAllComments',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/comqc/find/comments`
        }
    },

    {
        title: "formSettingGetLrData",
        actionPrefix: 'formSettingGetLr',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/form/setting/get/lr`
        }
    },
    {
        title: "vendorlogiLrCreateData",
        actionPrefix: 'vendorlogiLrCreate',
        message: 'Request Completed Successfully',
        requestData: {
            getExtraHeaderWithPayload: (payload) => {
                return { "X-REQUEST-ID": payload?.uuid }
            },
            updatePayload: ({ ...payload }) => {
                delete payload.uuid;
                return payload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorlogi/lr/create`,
        }
    },
    {
        title: "vendorlogiFindLrAutoData",
        actionPrefix: 'vendorlogiFindLrAuto',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorlogi/find/lr/auto`
        }
    },
    {
        title: "getPoStockReportData",
        actionPrefix: 'getPoStockReport',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/vstockreport`
        }
    },
    {
        title: "getCreateOrderHistoryData",
        actionPrefix: 'getCreateOrderHistory',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/vendorder/request/history`
        }
    },
    {
        title: "createPoArticleData",
        actionPrefix: 'createPoArticle',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/multiple/article/create`
        }
    },
    {
        title: "utilityDocumentDeleteData",
        actionPrefix: 'utilityDocumentDelete',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/utility/document/delete`,
        }
    },
    {
        title: "vendorlogiLrGetRateData",
        actionPrefix: 'vendorlogiLrGetRate',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorlogi/lr/get/rate`,
        }
    },
    {
        title: "updateLogisticsData",
        actionPrefix: 'updateLogistics',
        message: 'Request Completed Successfully',
        requestData: {
            getExtraHeaderWithPayload: (payload) => {
                return { "X-REQUEST-ID": payload?.uuid }
            },
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                delete payload.uuid;
                return payload
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload?.apiUrl}`
        }
    },
    {
        title: "getShipmentAdviceAllSsData",
        actionPrefix: 'getShipmentAdviceAllSs',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorlogi/find/all/ss`,
        }
    },
    {
        title: "vendorlogiRecommendationData",
        actionPrefix: 'vendorlogiRecommendation',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorlogi/recommendation`,
        }
    },
    {
        title: "poGlobalConfigCreateData",
        actionPrefix: 'poGlobalConfigCreate',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiAction;
                return payload
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/po/global/config/${payload.apiAction}`,
        }
    },
    {
        title: "getDashboardSiteFilterData",
        actionPrefix: 'getDashboardSiteFilter',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/dashboard/filter`,
        }
    },
    {
        title: "getWhDashboardData",
        actionPrefix: 'getWhDashboard',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/appot/det/wh/dashboard`,
        }
    },
    {
        title: "whDashboardDetailData",
        actionPrefix: 'whDashboardDetail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/appot/det/wh/dashboard/detail`,
        }
    },
    {
        title: "getBoxAnalysisDashboardData",
        actionPrefix: 'getBoxAnalysisDashboard',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorship/pie/chart/graph`,
        }
    },
    {
        title: "getASNDashboardDetailsData",
        actionPrefix: 'getASNDashboardDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/vendorship/get/asn/dashboard/details`,
        }
    },
    {
        title: "getOrderTrackingDashboardUpperDetailsData",
        actionPrefix: 'getOrderTrackingDashboardUpperDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/dashboard/upr/wdw`,
        }
    },
    {
        title: "getOrderTrackingDashboardGrcDetailsData",
        actionPrefix: 'getOrderTrackingDashboardGrcDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/dashboard/upr/wdw`,
        }
    },
    {
        title: "getOrderTrackingDashboardGraphDetailsData",
        actionPrefix: 'getOrderTrackingDashboardGraphDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/dashboard/upr/wdw`,
        }
    },
    {
        title: "getOrderTrackingDashboardBottomDetailsData",
        actionPrefix: 'getOrderTrackingDashboardBottomDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/dashboard/lwr/wdw`,
        }
    },
    {
        title: "saveSiteAndItemFilterData",
        actionPrefix: 'saveSiteAndItemFilter',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/save/arsSiteAndArsItemFilter?filterType=${payload?.filterType}`,
        }
    },
    {
        title: "getARSDashboardDataData",
        actionPrefix: 'getARSDashboardData',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.ARS}/get/all/arsDashboard/data`,
        }
    },
    {
        title: "deleteWHApptSiteDetailsData",
        actionPrefix: 'deleteWHApptSiteDetails',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/appot/det/wh/delete/level/${payload}`
        }
    },
    {
        title: "entlogiUpdateShipmentData",
        actionPrefix: 'entlogiUpdateShipment',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/entlogi/update/shipment`,
        }
    },
    {
        title: "documentGetAllFileData",
        actionPrefix: 'documentGetAllFile',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/utility/document/get/all/file`,
        }
    },
    {
        title: "getOrderRequestColorData",
        actionPrefix: 'getOrderRequestColor',
        errorMessage: 'Failed to get the colors list.',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/icodecolmap`,
        }
    },
    {
        title: "createOrderRequestIndentData",
        actionPrefix: 'createOrderRequestIndent',
        message : "Order created successfully!",
        errorMessage: 'Failed to create order with this order request details!',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/multiple/article/create`,
        }
    },
    {
        title: "deleteAddressLineItemData",
        actionPrefix: 'deleteAddressLineItem',
        message : "Address deleted successfully!",
        errorMessage: 'Failed to delete the selected address!',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/address/master/delete?id=${payload.id}`,
        }
    },
    {
        title: "updateAddressData",
        actionPrefix: 'updateAddress',
        message : "Address update successfully!",
        errorMessage: 'Failed to update the address!',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl
                return payload
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "getAllTNAOrderHistoryData",
        actionPrefix: 'getAllTNAOrderHistory',
        message : "Order created successfully!",
        errorMessage: 'Failed to create order with this order request details!',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/entpo/get/all/tna/order/history`,
        }
    },
    {
        title: "checkAsnRestrictionData",
        actionPrefix: 'checkAsnRestriction',
        errorMessage: 'Cannot Create ASN',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorship/check/asn/restriction`,
        }
    }
]