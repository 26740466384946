import { CONFIG } from '../../config/index';
export const digicatStore = [
    {
        title : "updateDigicatStatusData",
        actionPrefix: 'updateDigicatStatus',
        message: 'Product Status Updated Successfully!',
        errorMessage : "Failed to Update Status of the Selected Products!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/update/product/status`,
        }
    },
    {
        title : "getSamplingCostingFormData",
        actionPrefix: 'getSamplingCostingForm',
        errorMessage : "Failed to Get the Sampling & Costing Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/managevendor/find/all/vendor`,
        }
    },
    {
        title : "updateSamplingCostingFormData",
        actionPrefix: 'updateSamplingCostingForm',
        messgae : "Sampling & Costing Form Submitted Successfully!",
        errorMessage : "Failed to Update the Sampling & Costing Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/insert/sample/cost`,
        }
    },
    {
        title : "getDigicatMasterData",
        actionPrefix: 'getDigicatMaster',
        errorMessage : "Failed to Get the Category Data for Products!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/master/data`,
        }
    },
    {
        title : "getDigicatMasterDetailsData",
        actionPrefix: 'getDigicatMasterDetails',
        errorMessage : "Failed to Get the Attribute Details for Products!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/get/master/data`,
        }
    },
    {
        title : "saveDigicatMasterDetailsData",
        actionPrefix: 'saveDigicatMasterDetails',
        errorMessage : "Failed to save the details!",
        message : "Entries saved successfully in master data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/insert/master/data`,
        }
    },
    {
        title : "createProductData",
        actionPrefix: 'createProduct',
        message : "Product Updated Successfully!",
        errorMessage : "Failed to Update Product With Provided Details!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload?.productId ? "/admin/catalogue/product/library/update" : "/admin/catalogue/product/library/create"}`,
        }
    },
    {
        title : "getProductDetailData",
        actionPrefix: 'getProductDetail',
        errorMessage : "Failed to Get Product With Provided Details!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/get/product/detail`,
        }
    },
    {
        title : "createFabricData",
        actionPrefix: 'createFabric',
        message : "Fabric or Cost sheet created successfully!",
        errorMessage : "Failed to create fabric or Cost sheet with provided details!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/create/fabric`,
        }
    },
    {
        title : "updateFabricData",
        actionPrefix: 'updateFabric',
        message : "Fabric updated successfully!",
        errorMessage : "Failed to update fabric with provided details!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/update/fabric`,
        }
    },
    {
        title : "loadFabricData",
        actionPrefix: 'loadFabric',
        errorMessage : "Failed to load fabic with provided number!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/get/by/fabric`,
        }
    },
    {
        title : "createCostsheetData",
        actionPrefix: 'createCostsheet',
        message : "Cost sheet created successfully!",
        errorMessage : "Failed to create Cost sheet with provided details!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/create/cost/sheet`,
        }
    },
    {
        title : "updateCostsheetData",
        actionPrefix: 'updateCostsheet',
        message : "Cost sheet updated successfully!",
        errorMessage : "Failed to update cost sheet with provided details!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/update/cost/sheet`,
        }
    },
    {
        title : "loadCostsheetData",
        actionPrefix: 'loadCostsheet',
        errorMessage : "Failed to load cost sheet with provided number!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/get/by/cost/sheet`,
        }
    },
    {
        title : "getDigicatalogueFiltersData",
        actionPrefix: 'getDigicatalogueFilters',
        errorMessage : "Failed to get the digicatalogue filters!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/get/view/filters`,
        }
    },
    {
        title : "deleteProductsData",
        actionPrefix: 'deleteProducts',
        errorMessage : "Failed to delete the product!",
        message : "Product deleted successfully!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/product/delete`,
        }
    },
    {
        title : "dispatchSampleCostsheetData",
        actionPrefix: 'dispatchSampleCostsheet',
        errorMessage : "Failed to Dispath Request!",
        message : "Request Dispatched successfully!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/dispatch/sample`,
        }
    },
    {
        title : "catUpdateStatusSampleCostingData",
        actionPrefix: 'catUpdateStatusSampleCosting',
        errorMessage : "Failed to Dispath Request!",
        message : "Request Dispatched successfully!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/catalogue/update/status/sample/costing`,
        }
    }
]