import React from 'react';
import GenericSpinnerLoader from '../loaders/GenericSpinnerLoader';
import { Tooltip } from 'antd';

const GenericCustomButton = ({
  label,
  loading,
  disabled,
  onClick,
  className,
  leftIcon,
  rightIcon,
  tooltip = false,
  type = "normal",
  style = {},
}) => {

  let isButtonDisabled = typeof (disabled) == "function" ? disabled() : disabled

  const handleClick = (e) => {
    if (!(isButtonDisabled) && !(typeof (loading) == "function" ? loading() : loading)) {
      // Execute the onClick function only if the button is not disabled or in a loading state
      onClick && onClick(e);
    }
  };
  const buttonType = {
    "primary": "generic-approve-btn",
    "success": "generic-approve-btn",
    "normal": "",
    "cancel": "generic-reject-btn",
    "default": "generic-default-btn",
    "active": "btn-active",
    "error": "generic-reject-btn",
    "failed": "generic-reject-btn",
    "reject": "generic-reject-btn",
  }

  let button = <button
    className={`${buttonType?.[type]} ${className}`}
    onClick={handleClick}
    disabled={isButtonDisabled}
    type='button'
    data-interactive="true"
    style={{position: "relative", ...style}}
  >
    {loading ? (
      <GenericSpinnerLoader />
    ) : (
      <>
        {typeof (leftIcon) == "function" ? leftIcon() : leftIcon}
        {typeof (label) == "function" ? label() : <span>{label}</span>}
        {typeof (rightIcon) == "function" ? rightIcon() : rightIcon}
        {/* {tooltip && <span onClick={(e) => e.preventDefault()} className="generic-tooltip">{tooltip}</span>} */}
      </>
    )}
  </button>

  return (
    <>

      {tooltip ? <Tooltip overlayClassName="button-tooltip"
        title={<p>{tooltip}</p>}
        color='black'
      >
        {button}
      </Tooltip> : button}
    </>
  );
};

export default GenericCustomButton;
