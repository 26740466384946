import React from 'react';
// import ColumnSettingModal from "./columnSettingModal";
import ReactDragListView from 'react-drag-listview';
import ExpandedRow from '../../../genericComponents/oldGenericComponents/tableHelperComponents/ExpandedRow';
import NewTableLoader from '../../../genericComponents/oldGenericComponents/tableHelperComponents/TableSkeletonLoader';
import { changeKey } from '../../../helper/helperTableFunctions';
import TableMainLevelFilters from '../../../genericComponents/oldGenericComponents/TableMainLevelFilters';
import Icons from '../../../assets/icons';

export default class GenericTableData extends React.Component {
    constructor(props) {
        super(props);
        this.jumpInputRef = React.createRef();
        this.state = {
            getTableDataSuccess: false,
            getTableDataError: false,
            getTableHeadersSuccess: false,
            getTableHeadersError: false,

            tableDataPayload: this.props.tableDataPayload,
            tableData: this.props.tableData.data,
            currentPage: this.props.tableData.currentPage,
            maxPage: this.props.tableData.maxPage,
            pageNumber: this.props.tableData.currentPage,
            totalItems: this.props.tableData.totalItems,
            selectedItems: {},

            headersType: {
                main: "Default Headers"
            },
            headersData: {
                main: {}
            },
            customSearchApplied: false,
            customSearchBy: this.props.actionBarConfig.search && this.props.actionBarConfig.search.customSearch ? Object.keys(this.props.actionBarConfig.search.customSearch)[0] : "",
            customSearchData: [],
            quickFilterData: [],
            applyDefaultFilter: true,
            openChats: [],

            customSearchModal: false,
            quickFilterDropdown: false,
            quickFilterSettings: false,
            documentsDropdown: false,
            emailModal: false,
            downloadReportModal: false,
            sortingModal: false,
            filterModal: false,
            moreMenuDropdown: false,
            columnSettingModal: false,
            commentModal: "close",

            confirmationType: "",
            headMsg: "",
            paraMsg: "",
            rightClickMenu: false,
            selectedColumn: "",
            downloadReport: false,
            customHeaders: false,
            completeHeadersData: {},

            reportFilter: {},
            imageTrackingModal: false,
            imageTrackingData: null,
            loading: false,
            imageVendor: "",
            imageOrderNumber: "",
            imageTrackingNumberType: this.props?.imageOrderNumber,
            selectedRowId: "",
            leftPosition: 0,
            topPosition: 0,
            bottomPosition: 0,
            rightPosition: 0,
            successMessage: false,
            selectPage: false,
            tdFixActionClass: this.props?.tableConfig?.tdFixActionClass || '',
            userminWidth: this.props?.tableConfig?.userminWidth || '',
            eyeClass: this.props?.tableConfig?.eyeClass || "",
            saveFilterData: {},
            saveFilterModal: false,
            enableJumpPage: false,
            braedCrumps: this.props?.braedCrumps !== undefined ? this.props?.braedCrumps : true,

        };

        this.commentBoxRef = null;
    }

    //-------------------- REACT LIFECYCLE METHODS --------------------//

    componentDidMount() {
        try {
            const urlSearchParams = new URLSearchParams(this.props.location.search),
                params = Object.fromEntries(urlSearchParams.entries());

            Object.keys(params).forEach(key => {
                params[key] = JSON.parse(decodeURIComponent(params[key]));
            });

            let customSearchApplied = false,
                customSearchBy = this.props.actionBarConfig.search && this.props.actionBarConfig.search.customSearch ? Object.keys(this.props.actionBarConfig.search.customSearch)[0] : "",
                customSearchData = [];
            if (params.customSearch) {
                customSearchApplied = true;
                customSearchBy = Object.keys(params.filter)[0];
                customSearchData = params.filter[customSearchBy].split(",");
            }

            let applyDefaultFilter = true;
            if (params.search || params.filter) {
                applyDefaultFilter = false;
            }
            this.setState(prevState => ({
                tableDataPayload: {
                    reportFilter: params.filter ? params.filter : {},
                    displayName: this.props.tableHeadersPayload.main.key,
                    ...prevState.tableDataPayload,
                    ...params
                },

                customSearchApplied,
                customSearchBy,
                customSearchData,
                applyDefaultFilter
            }), () => {
                this.props.handleApiChange("getQuickFiltersRequest", this.props.quickFiltersPayload);
                this.getTableHeaders("db");
                if (!applyDefaultFilter) this.getTableData();
            });
        }
        catch (err) {
            this.props.handleApiChange("getQuickFiltersRequest", this.props.quickFiltersPayload);
            this.getTableHeaders("db");
            this.getTableData();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.generalStore.getHeadersData.isSuccess) {

            return {
                getTableHeadersSuccess: prevState.getTableHeadersSuccess || nextProps.generalStore.getHeadersData.data.basedOn == "main",

                headersType: {
                    ...prevState.headersType,
                    [nextProps.generalStore.getHeadersData.data.basedOn]: nextProps.generalStore.getHeadersData.data.resource["Custom Headers"] === undefined || Object.keys(nextProps.generalStore.getHeadersData.data.resource["Custom Headers"]).length === 0 ? "Default Headers" : "Custom Headers",
                },
                headersData: {
                    ...prevState.headersData,
                    [nextProps.generalStore.getHeadersData.data.basedOn]: nextProps.generalStore.getHeadersData.data.resource
                },
                completeHeadersData: nextProps.generalStore.getHeadersData.data,

            };
        }
        else if (nextProps.generalStore.getHeadersData.isError) {
            return {
                getTableHeadersError: true
            };
        }
        if (nextProps.generalStore.getQuickFiltersData.isSuccess && nextProps.generalStore.getQuickFiltersData.data.resource !== null) {
            // nextProps.getQuickFiltersClear();
            return {
                quickFilterData: nextProps.generalStore?.getQuickFilters?.data?.resource
            };
        }
        if (nextProps.generalStore?.downloadReportData?.isSuccess && nextProps.generalStore.downloadReportData?.data?.resource !== null) {
            // nextProps.downloadDataClear();
            window.open(nextProps.generalStore.downloadReportData.data.resource);
        }
        // if (nextProps.generalStore.downloadDocumentsData?.isSuccess && nextProps.generalStore?.downloadDocumentsData?.data?.resource !== null) {
        //     window.open(nextProps.generalStore.downloadDocumentsData?.data?.resource);
        // }

        // The Email success function handled here to just show the success message after the closure of modal!!
        if (nextProps.generalStore.createEmailSchedulesData.isSuccess) {
            return {
                successMessage: nextProps.generalStore?.createEmailSchedulesData?.data?.message
            };
        }
        if (nextProps.generalStore.emailReportData.isSuccess) {
            return {
                successMessage: nextProps.generalStore?.emailReportData?.data?.message
            };
        }
        // ======================================================================================================//

        if (nextProps.generalStore.getImageTrackingData.isSuccess) {
            // nextProps.getImageTrackingClear();
            return {
                loading: false,
                imageTrackingData: nextProps.generalStore.getImageTrackingData.data.resource || null,
                imageVendor: nextProps.generalStore.getImageTrackingData.data.resource ? nextProps.generalStore.getImageTrackingData.data.resource.data ? nextProps.generalStore.getImageTracking.data.resource.data[0].vendor_name : "" : ""
            }
        }
        if (nextProps.generalStore.getImageTrackingData?.isLoading) {
            return {
                loading: true
            }
        }

        if (nextProps.getTableDataSuccess) {
            return {
                getTableDataSuccess: true,

                tableData: nextProps.tableData?.data,
                currentPage: nextProps.tableData?.currentPage,
                maxPage: nextProps.tableData?.maxPage,
                pageNumber: nextProps.tableData?.currentPage,
                totalItems: nextProps.tableData?.totalItems
            };
        }
        else if (nextProps.getTableDataError) {
            return {
                getTableDataError: true
            };
        }
        else if (nextProps.getTableDataLoading) {
            return {
                getTableDataSuccess: false,
                getTableDataError: false,
                selectedItems: {}
            };
        }
        if (nextProps.refreshData) {
            return {
                confirmationType: "",
                headMsg: "",
                paraMsg: ""
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("Generic Table Data", this.props.updatetablePayload);

        if (this.props.changeHeaderType) {
            this.getTableHeaders('db');
        }
        if (this.props.updatetablePayload) {
            this.setState({
                tableDataPayload: this.props?.tableDataPayload,
            }, () => this.props.resetstate())
        }
        if (this.props.customAction) {
            if (this.props.actionBarConfig?.downloadReport?.tableHeadersMap?.main?.key == "SOMAIN") {
                this.props.handleApiChange("downloadDataRequest", {
                    module: "EXCEL_EXPORT_SALES_ORDER",
                    isAllData: false,
                    isOnlyCurrentPage: false,
                    data: this.state.tableDataPayload
                })
                this.props.resetstate()
            }
        }

        // Update this.state.tableDataPayload and request data & headers after the tab is changed in parent component
        if (prevProps.actionBarConfig?.tabs && prevProps.tableDataPayload[prevProps.actionBarConfig?.tabs?.key] != this.props.tableDataPayload[this.props.actionBarConfig?.tabs?.key]) {
            this.setState({
                tableDataPayload: this.props.tableDataPayload,
                getTableHeadersSuccess: false,
                selectedItems: {},
                customSearchApplied: false,
                customSearchData: []
            }, () => {
                this.getTableData();
                this.getTableHeaders("db");
            });
        }

        if (this.props.actionBarConfig?.quickFilter && this.state.applyDefaultFilter) {
            if (this.props.generalStore?.getQuickFiltersData?.isSuccess) {
                const defaultFilter = this.state?.quickFilterData?.filter(item => item?.is_default == 1);
                if (defaultFilter?.length) {
                    this.applyFilter(defaultFilter[0]?.filterValue)();
                }
                else {
                    this.getTableData();
                }
                this.setState({
                    applyDefaultFilter: false
                });
            }
            else if (this.props.generalStore?.getQuickFiltersData?.isError) {
                this.getTableData();
                this.setState({
                    applyDefaultFilter: false
                });
            }
        }

        if (this.props.getTableDataSuccess) {
            document.querySelectorAll("tr .sticky-col").forEach(elem => {
                let temp = elem, left = 0;
                while (temp.previousElementSibling) {
                    left += temp.previousElementSibling.clientWidth;
                    temp = temp.previousElementSibling;
                }
                elem.style.left = `${left}px`;
            });
            document.querySelectorAll("td label").forEach(elem => {
                if (elem.scrollWidth > 200) {
                    let div = document.createElement("div"), label = document.createElement("label");
                    div.className = "table-tooltip";
                    label.innerText = elem.innerText;
                    div.appendChild(label);
                    elem.parentElement.appendChild(div);
                }
            })
            document.querySelectorAll("div .sticky-col").forEach(elem => {
                let temp = elem, left = 0;
                while (temp.previousElementSibling) {
                    left += temp.previousElementSibling.clientWidth;
                    temp = temp.previousElementSibling;
                }
                elem.style.left = `${left}px`;
            });
        }

        if (this.props.generalStore?.createHeadersData?.isSuccess) {
            // this.props.createHeadersClear();
            setTimeout(() => {
                this.getTableHeaders("db");
                this.closeColumnSettingModal();
                this.getTableData();
            }, 10)
        }

        if (this.props.generalStore?.createQuickFiltersData?.isSuccess) {
            // this.props.createQuickFiltersClear();
            setTimeout(() => {
                this.props.handleApiChange("getQuickFiltersRequest", this.props?.quickFiltersPayload);
            }, 10)
        }

        if (this.props.refreshData) {
            this.setState({
                tableDataPayload: this.props.tableDataPayload
            }, this.getTableData);
        }
    }

    //-------------------- GET TABLE HEADERS METHODS --------------------//

    getTableHeaders = from => {
        switch (from) {
            case "db": {
                Object.keys(this.props.tableHeadersPayload).forEach(key => {
                    this.props.handleApiChange("getHeadersRequest", {
                        enterpriseName: "TURNINGCLOUD",
                        displayName: this.props?.tableHeadersPayload[key]?.key,
                        basedOn: key
                    })
                });
                break;
            }
            case "state": {
                let temp = {};
                Object.keys(this.props.tableHeadersPayload).forEach(key => {
                    temp[key] = {
                        ...this.props.tableHeadersPayload[key],
                        data: this.state?.headersData[key]
                    };
                });
                return temp;
            }
            default: { }
        }

    }

    //-------------------- GET TABLE DATA METHODS --------------------//

    getTableData = () => {
        let payload = { ...this.state.tableDataPayload };
        this.props.handleApiChange(this.props.getTableDataRequest, payload)
        if (this.props.actionBarConfig?.isSummaryApiRequire) {
            this.props.summaryRequest(this.state.tableDataPayload)
        }
    }

    //-------------------- REFRESH METHODS --------------------//

    onRefresh = () => {
        this.props.handleApiChange("getQuickFiltersRequest", this.props?.quickFiltersPayload);
        this.getTableHeaders("db");
        this.setState({
            tableDataPayload: this.props.tableDataPayload
        }, this.getTableData);
    }

    //-------------------- QUICK FILTER METHODS --------------------//

    openQuickFilter = () => {
        this.setState({
            quickFilterDropdown: true
        }, () => document.addEventListener("click", this.closeQuickFilterOnClick));
    }

    closeQuickFilter = () => {
        this.setState({
            quickFilterDropdown: false,
            quickFilterSettings: false
        }, () => document.removeEventListener("click", this.closeQuickFilterOnClick));
    }

    closeQuickFilterOnClick = e => {
        let path = e.path || (e.composedPath && e.composedPath());
        if (!path.some(elem => elem.id == "quickFilterDropdown")) {
            this.closeQuickFilter();
        }
    }

    toggleQuickFilterSettings = () => {
        this.setState(prevState => ({
            quickFilterSettings: !prevState.quickFilterSettings
        }));
    }

    saveQuickFilter = (filterName, checkedFilters, isDelete = false, is_default) => e => {
        if (!this.props.generalStore?.createQuickFiltersData?.isLoading) {
            let payload = {
                displayName: this.props?.quickFiltersPayload?.displayName,
                filterName: [filterName],
                filterValue: checkedFilters
            };
            if (isDelete) {
                payload.isDeleteFilter = true;
            }
            else if (is_default !== undefined) {
                payload.is_default = is_default;
            }
            this.props.handleApiChange("createQuickFiltersRequest", payload);
            this.closeSaveFilter();
        }
        e && e.stopPropagation();
    }


    //-------------------- FILTER METHODS --------------------//

    openFilterModal = () => {
        this.setState(prevState => ({
            filterModal: !prevState.filterModal
        }), () => document.addEventListener('click', this.closeFilterOnClick));
    }

    closeFilterModal = () => {
        this.setState({
            filterModal: false
        }, () => document.removeEventListener('click', this.closeFilterOnClick));
    }

    closeFilterOnClick = e => {
        if (e.target && document.getElementById("vendorFilterBackdrop").contains(e.target)) {
            this.closeFilterModal();
        }
    }

    applyFilter = checkedFilters => () => {
        let type;
        if (Object.keys(checkedFilters).length == 0) {
            type = this.state.tableDataPayload.type == 2 ? 1 : this.state.tableDataPayload?.type == 4 ? 3 : this.state.tableDataPayload.type;
        }
        else {
            type = this.state.tableDataPayload.type == 3 || this.state.tableDataPayload.type == 4 ? 4 : 2;
        }
        this.setState(prevState => ({
            tableDataPayload: {
                ...prevState.tableDataPayload,
                filter: checkedFilters
            },
            customSearchApplied: false,
            customSearchData: []
        }), this.getTableData);
        this.closeFilterModal();
        this.closeQuickFilter();

    }

    removeFilter = key => () => {
        let filters = { ...this.state.tableDataPayload.filter };
        delete filters[key];
        filters["item"] && Object.keys(filters).length === 1 && Object.keys(filters["item"]).length === 0 && delete filters["item"]
        this.applyFilter(filters)();
    }

    removeItemFilter = key => () => {
        let itemFilter = { ...this.state.tableDataPayload.filter };
        delete itemFilter["item"][key];
        itemFilter["item"] && Object.keys(itemFilter).length === 1 && Object.keys(itemFilter["item"]).length === 0 && delete itemFilter["item"]
        this.applyFilter(itemFilter)();
    }

    getFilterValue = key => {
        switch (this.state.headersData.main.columnType[key]) {
            case "NUMBER":
            case "TIME": {
                return `From ${this.state.tableDataPayload.filter[key].from} to ${this.state.tableDataPayload.filter[key].to}`;
            }
            case "DATE": {
                if (!this.state.tableDataPayload.filter[key].key)
                    return `From ${this.state.tableDataPayload.filter[key].from} to ${this.state.tableDataPayload.filter[key].to}`;
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_TODAY_VALUE")
                    return "Today";
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_YESTERDAY_VALUE")
                    return "Yesterday";
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_LAST7DAYS_VALUE")
                    return "Last 7 Days";
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_LASTWEEK_VALUE")
                    return "Last Week";
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_THISMONTH_VALUE")
                    return "This Month";
                else if (this.state.tableDataPayload.filter[key].key === "SM_DATE_LASTMONTH_VALUE")
                    return "Last Month";
                return "";
            }
            default: {
                if (this.state.tableDataPayload.filter[key] == "SM_TEXT_EMPTY_VALUE")
                    return "is Empty";
                else if (this.state.tableDataPayload.filter[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                    return "is Not Empty";
                else
                    return this.state.tableDataPayload.filter[key];
            }
        }
    }

    getItemFilterValue = key => {
        if (this.state.headersData && !this.state.headersData.item || !this.state.headersData.item.columnType) {
            return ""
        }
        switch (this.state.headersData.item.columnType[key]) {
            case "NUMBER":
            case "TIME": {
                return `From ${this.state.tableDataPayload.filter.item[key].from} to ${this.state.tableDataPayload.filter.item[key].to}`;
            }
            case "DATE": {
                if (!this.state.tableDataPayload.filter.item[key].key)
                    return `From ${this.state.tableDataPayload.filter.item[key].from} to ${this.state.tableDataPayload.filter.item[key].to}`;
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_TODAY_VALUE")
                    return "Today";
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_YESTERDAY_VALUE")
                    return "Yesterday";
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_LAST7DAYS_VALUE")
                    return "Last 7 Days";
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_LASTWEEK_VALUE")
                    return "Last Week";
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_THISMONTH_VALUE")
                    return "This Month";
                else if (this.state.tableDataPayload.filter.item[key].key === "SM_DATE_LASTMONTH_VALUE")
                    return "Last Month";
                return "";
            }
            default: {
                if (this.state.tableDataPayload.filter.item[key] == "SM_TEXT_EMPTY_VALUE")
                    return "is Empty";
                else if (this.state.tableDataPayload.filter.item[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                    return "is Not Empty";
                else
                    return this.state.tableDataPayload.filter.item[key];
            }
        }
    }


    //-------------------- CONFIRMATION METHODS --------------------//

    confirmAction = type => e => {
        switch (type) {
            case "delete": {
                this.setState({
                    confirmationType: type,
                    headMsg: "Delete selected item(s)?",
                    paraMsg: "Are you sure you want to perform this action? It cannot be undone."
                });
                break;
            }
            default: { }
        }
    }

    onConfirmation = () => {
        switch (this.state.confirmationType) {
            case "delete": {
                this.props.actionBarConfig?.moreMenu?.options(this.state.selectedItems).delete.onClick(this.state.selectedItems);
                break;
            }
            default: { }
        }
    }

    onCancellation = () => {
        this.setState({
            confirmationType: "",
            headMsg: "",
            paraMsg: ""
        });
    }
    closeImageTrackingModal = () => { this.setState({ imageTrackingModal: false, viewImageModal: false }) }
    handleImageTrackingModal = (item, vendor, key) => {
        key = changeKey(key)
        let payload = {
            "orderNumber": key == "setBarCode" || key == "itemCode" ? this.props.tableConfig && this.props.tableConfig.expand && this.props.tableConfig.expand.expandedRow.orderNumber : "",
            "shipmentAdviceCode": "",
            "setBarCode": "",
            "itemCode": "",
            "logisticNumber": "",
            "getBy": key,
            "search": "",
            "pageSize": 10,
            "pageNo": 1,
            "type": 1,
            "filter": {},
        }
        payload[key] = item;
        this.props.getImageTrackingRequest(payload);
        this.setState({
            imageVendor: vendor,
            imageOrderNumber: item,
            imageTrackingNumberType: key,
            imageTrackingModal: true
        })
    }

    handleViewImageModal = (item, key) => {
        key = changeKey(key)
        let payload = {
            "orderNumber": "",
            "shipmentAdviceCode": "",
            "setBarCode": "",
            "itemCode": "",
            "logisticNumber": "",
            "getBy": key,
            "search": "",
            "pageSize": 10,
            "pageNo": 1,
            "type": 1,
            "filter": {},
        }
        payload[key] = item;
        // this.props.getImageTrackingRequest(payload);
        this.setState({
            imageOrderNumber: item,
            imageTrackingNumberType: key,
            viewImageModal: true,
        })
    }

    // Right Click Functions
    onRightClick = (e, key, item, id) => {
        e.preventDefault();
        this.setState(prevState => {
            let selectedItems = { ...prevState.selectedItems };
            if (selectedItems[item.id]) {
                delete selectedItems[item.id];
            }
            else {
                selectedItems[item.id] = item;
            }
            return {
                selectedItems,
                rightClickMenu: true,
                selectedColumn: key,
                selectedRowId: id,
            };
        });
        // this.setState({
        //     rightClickMenu: true,
        //     selectedColumn: key,
        //     selectedRowId: id,
        // });
        if (window.innerWidth <= e.pageX + 200 && window.innerHeight <= e.pageY + 250) {
            this.setState({
                leftPosition: e.pageX - 200,
                topPosition: e.pageY - 250,
            });
        }
        else if (window.innerWidth <= e.pageX + 200) {
            this.setState({
                leftPosition: e.pageX - 200,
                topPosition: e.pageY,
            });
        }
        else if (window.innerHeight <= e.pageY + 250) {
            this.setState({
                leftPosition: e.pageX,
                topPosition: e.pageY - 250,
            })
        }
        else {
            this.setState({
                leftPosition: e.pageX,
                topPosition: e.pageY,
            })
        }
    }

    rightClickAction = (type) => {
        switch (type) {
            case 'search': {
                this.setState(prevState => ({
                    tableDataPayload: {
                        ...prevState.tableDataPayload,
                        search: this.state.selectedItems[0][this.state.selectedColumn]
                    }
                }), () => this.onSearch())
                break;
            }
            case 'hideColumn': {
                this.createCustomHeaders()
                break;
            }
        }
        // this.setState({
        //     rightClickMenu: false,
        //     selectedColumn: '',
        //     selectedItems: '',
        //     selectedRowId: '',
        // })
        this.closeRightClickMenu();
    }

    closeRightClickMenu = () => {
        this.setState({
            rightClickMenu: false,
            selectedColumn: '',
            selectedItems: '',
            selectedRowId: '',
        })
    }

    createCustomHeaders = () => {
        let customHeadersObj = this.state.headersData.main[this.state.headersType.main];
        let ans = this.state.headersData.main["Default Headers"];
        const deletedCloumn = this.state.selectedColumn;
        delete ans[deletedCloumn];
        delete customHeadersObj[deletedCloumn];
        let payload = {
            displayName: this.props.tableHeadersPayload.main.key,
            fixedHeaders: this.state.headersData.main["Fixed Headers"],
            defaultHeaders: ans,
            customHeaders: customHeadersObj
        }
        this.props.handleApiChange("createHeadersRequest", payload);
    }

    closeSaveFilter = () => {
        this.setState({
            saveFilterModal: false
        });
    }


    render() {
        const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                let data = Object.keys(that.state.headersData.main[that.state.headersType.main]);
                let stickyColsLength = that.props.tableConfig.stickyCols ? that.props.tableConfig.stickyCols.length : 0
                data = that.props.tableConfig.stickyCols ? data.filter(key => !that.props.tableConfig.stickyCols.includes(key)) : data
                const item = data.splice(fromIndex - 1 - stickyColsLength, 1)[0];
                data.splice(toIndex - 1 - stickyColsLength, 0, item);
                if (fromIndex - 1 - stickyColsLength >= 0 && toIndex - 1 - stickyColsLength >= 0) {
                    that.setState((prevState) => {
                        let headersData = { ...prevState.headersData }
                        let x = headersData.main[prevState.headersType.main]
                        headersData.main[prevState.headersType.main] = {};
                        data.map(key => headersData.main[prevState.headersType.main][key] = x[key])
                        that.props.tableConfig.stickyCols && that.props.tableConfig.stickyCols.map(key => { headersData.main[prevState.headersType.main][key] = x[key] })

                        return {
                            headersData: headersData,
                        }
                    }, () => that.createCustomHeaders());
                }

            },
            nodeSelector: "th",

            lineClassName: "chmb-drag-line"
        };
        const { actionBarConfig, tableConfig, itemLevel, itemType } = this.props;
        // const {itemLevel,itemType}=this.state
        return (
            <React.Fragment>
                {tableConfig.toggleitem &&
                    (tableConfig.toggleitem.render ? tableConfig.toggleitem.render() :
                        null
                    )}
                <div className="table-component-new-style">
                    <div className="tableBody-border">
                        {this.state.getTableDataSuccess && this.state.getTableHeadersSuccess ?
                            <div className="invoice-manage-table">
                                <div className="imt-manage-table">

                                    <table className="table imt-main-table">
                                        <thead>
                                            {this.state.headersData.main[this.state.headersType.main] &&
                                                <ReactDragListView.DragColumn {...dragProps}>
                                                    <div className={`fix-action-btn default-th ${this.state.userminWidth}`}>
                                                        <ul className="rab-refresh">
                                                            {tableConfig.select &&
                                                                <li className="rab-rinner">
                                                                    <label className="checkBoxLabel0">
                                                                        <input type="checkBox" autoComplete="off" checked={this.state.tableData.length != 0 && Object.keys(this.state.selectedItems).length == this.state.tableData.length} onChange={this.selectAll} />
                                                                        <span className="checkmark1"></span>
                                                                    </label>
                                                                    {this.state.tableData.length != 0 && Object.keys(this.state.selectedItems).length == this.state.tableData.length &&
                                                                        <span className="select-all-text">
                                                                            {this.state.tableData.length}
                                                                        </span>}
                                                                </li>}
                                                            <li className="rab-rinner" onClick={this.onRefresh}>
                                                                <svg width="16" height="16" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7.1216 3.23566V3.68806C7.1216 4.0048 7.30237 4.2761 7.57399 4.45687C7.70965 4.54741 7.89073 4.59253 8.02639 4.59253C8.16204 4.59253 8.2977 4.54741 8.43336 4.50198L10.7398 3.32617C11.0111 3.19052 11.1922 2.91921 11.2373 2.55736C11.2825 2.19551 11.1017 1.92421 10.8304 1.74344L8.5239 0.160354C8.25259 -0.0204152 7.89074 -0.065844 7.57398 0.115241C7.25724 0.250896 7.07647 0.567636 7.07647 0.929158V1.42667C3.27752 1.87906 0.29248 5.13541 0.29248 9.07004C0.29248 10.7433 0.835104 12.3264 1.8301 13.6832C2.01087 13.9094 2.2825 14.0451 2.5538 14.0451C2.73457 14.0451 2.91566 14 3.09642 13.8643C3.50339 13.5476 3.59393 13.0049 3.27719 12.598C2.50838 11.5578 2.10138 10.3366 2.10138 9.0703C2.14649 6.13008 4.31732 3.68769 7.12153 3.23558L7.1216 3.23566Z" fill="#67768E" />
                                                                    <path d="M15.7149 9.02496C15.7149 7.35165 15.1723 5.76864 14.1773 4.41176C13.8606 4.0048 13.3179 3.91426 12.911 4.23099C12.504 4.54773 12.4134 5.09038 12.7302 5.49734C13.499 6.53747 13.906 7.75867 13.906 9.02501C13.906 11.9646 11.7352 14.4071 8.93091 14.814V14.4522C8.93091 14.1354 8.75014 13.8641 8.52394 13.6833C8.25263 13.5026 7.9359 13.5026 7.66456 13.6382L5.31269 14.814C5.04138 14.9497 4.81519 15.221 4.81519 15.5829C4.81519 15.9447 4.95084 16.216 5.22215 16.3968L7.52863 17.9799C7.66428 18.0704 7.84537 18.1155 8.02614 18.1155C8.16179 18.1155 8.29745 18.0704 8.4331 18.025C8.74984 17.8893 8.93061 17.5726 8.93061 17.2111V16.7136C12.7299 16.216 15.7149 12.9598 15.7149 9.02512V9.02496Z" fill="#67768E" />
                                                                </svg>
                                                                <span className="generic-tooltip">Refresh</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    {tableConfig.stickyCols &&
                                                        tableConfig.stickyCols.map(key =>
                                                            <div key={key} className={this.state.tableDataPayload.sortedBy == key && this.state.tableDataPayload.sortedIn == "ASC" ? "rotate180 sticky-col default-th" : "sticky-col default-th"} onClick={this.state.headersData.main.columnType[key] == "NOSORT" ? null : this.sortData(key, this.state.tableDataPayload.sortedBy == key && this.state.tableDataPayload.sortedIn == "ASC" ? "DESC" : "ASC")}>
                                                                <div className="td-header">
                                                                    <label>{this.state.headersData.main[this.state.headersType.main][key]} <Icons.ArrowUpIcon /></label>
                                                                    {this.state.headersData.main.columnType[key] != "NOSORT" && <Icons.DragDropIcon className="imgHead" />}
                                                                </div>
                                                            </div>)}

                                                    {Object.keys(this.state.headersData.main[this.state.headersType.main]).map(key =>
                                                        tableConfig.stickyCols && tableConfig.stickyCols.includes(key) ? null :
                                                            <th key={key}>
                                                                <div className="td-header">
                                                                    <label>{this.state.headersData.main[this.state.headersType.main][key]} </label>
                                                                </div>
                                                            </th>
                                                    )}
                                                </ReactDragListView.DragColumn>
                                            }

                                            {actionBarConfig?.filters && <TableMainLevelFilters
                                                appliedFilters={this.state.tableDataPayload?.filter}
                                                applyFilter={this.applyFilter}
                                                closeFilterModal={this.closeFilterModal}
                                                filterItems={this.state.headersData.main}
                                                isLoading={this.props.generalStore?.createQuickFiltersData?.isLoading}
                                                quickFilterItems={this.state.quickFilterData}
                                                saveQuickFilter={this.saveQuickFilter}
                                                saveSuccess={this.props.generalStore?.createQuickFiltersData?.isSuccess}
                                                headersData={this.getTableHeaders("state")}
                                                tableConfig={tableConfig}
                                                getTableDataSuccess={this.props.getTableDataSuccess}
                                                clearMainFilter={this.applyFilter({})}
                                                hideSaveButton={true}
                                                {...this.props}
                                            />}
                                        </thead>
                                        <tbody>{
                                            Object.keys(this.state.headersData.main).length == 0 || this.state.tableData.length == 0 ?
                                                <tr><td colSpan={100} align="left"><label>No data found!</label></td></tr> :
                                                this.state.tableData.map((item, outerKey) =>
                                                    <React.Fragment>
                                                        <tr key={item.id} className={this.state.selectedItems[item.id] ? "vgt-tr-bg" : ""}>
                                                            <td className={`fix-action-btn maxWidthNone ${this.state.tdFixActionClass}`}>
                                                                <ul className="table-item-list">


                                                                    {tableConfig.extras && tableConfig.extras(item)}

                                                                </ul>
                                                            </td>

                                                            {tableConfig.stickyCols &&
                                                                tableConfig.stickyCols.map((key, index) =>
                                                                    <td className={key === this.props.imageOrderNumber || key === this.props.showImageOn ? "sticky-col po-num-col" : "sticky-col"} align={this.state.headersData.main.columnType[key] === "NUMBER" ? "right" : "left"}>
                                                                        {tableConfig.customCols && tableConfig.customCols[key] ? tableConfig.customCols[key](item) : <label>{item[key]}</label>}
                                                                        {key === this.props.imageOrderNumber || key === this.props.showImageOn ?
                                                                            <svg className='ponc-icon' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => key === this.props.imageOrderNumber ? this.handleImageTrackingModal(item[key], item["vendorName"], key) : key === this.props.showImageOn ? this.handleViewImageModal(item[key], key) : null}>
                                                                                <path d="M9.5 8.709V6.334L12.667 9.5 9.5 12.667v-2.375H6.333V8.709H9.5zm0-7.125A7.92 7.92 0 0 1 17.417 9.5 7.92 7.92 0 0 1 9.5 17.417 7.92 7.92 0 0 1 1.583 9.5 7.92 7.92 0 0 1 9.5 1.583zm0 14.25c3.5 0 6.334-2.835 6.334-6.334 0-3.499-2.835-6.333-6.334-6.333A6.332 6.332 0 0 0 3.167 9.5C3.167 13 6 15.834 9.5 15.834z" fill="#334A81" />
                                                                            </svg> : null}
                                                                    </td>
                                                                )}

                                                            {Object.keys(this.state.headersData.main[this.state.headersType.main]).map((key, index) =>
                                                                tableConfig.stickyCols && tableConfig.stickyCols.includes(key) ? null :
                                                                    <td className={`${key === this.props.imageOrderNumber || key === this.props.showImageOn ? "po-num-col" : ''} ${this.state.selectedColumn === key && this.state.selectedRowId === outerKey && "selected-col"}`} align={this.state.headersData.main.columnType[key] === "NUMBER" ? "right" : "left"} id={"selectedCol" + outerKey} onContextMenu={(e) => this.onRightClick(e, key, item, outerKey)}>
                                                                        {tableConfig.customCols && tableConfig.customCols[key] ? tableConfig.customCols[key](item) :
                                                                            <label>{item[key]}</label>}
                                                                        {key === this.props.imageOrderNumber || key === this.props.showImageOn ?
                                                                            <svg className='ponc-icon' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => key === this.props.imageOrderNumber ? this.handleImageTrackingModal(item[key], item["vendorName"], key) : key === this.props.showImageOn ? this.handleViewImageModal(item[key], key) : null}>
                                                                                <path d="M9.5 8.709V6.334L12.667 9.5 9.5 12.667v-2.375H6.333V8.709H9.5zm0-7.125A7.92 7.92 0 0 1 17.417 9.5 7.92 7.92 0 0 1 9.5 17.417 7.92 7.92 0 0 1 1.583 9.5 7.92 7.92 0 0 1 9.5 1.583zm0 14.25c3.5 0 6.334-2.835 6.334-6.334 0-3.499-2.835-6.333-6.334-6.333A6.332 6.332 0 0 0 3.167 9.5C3.167 13 6 15.834 9.5 15.834z" fill="#334A81" />
                                                                            </svg> : null}
                                                                    </td>
                                                            )}
                                                        </tr>
                                                        {tableConfig.expand && tableConfig.expand.expandedRow && tableConfig.expand.expandedRow.id == item.id &&
                                                            <tr>
                                                                <td colSpan="100%" className="pad-0">
                                                                    {tableConfig.expand.render ? tableConfig.expand.render() :
                                                                        <ExpandedRow
                                                                            handleImageTrackingModal={this.handleImageTrackingModal}
                                                                            applyFilter={this.applyFilter}
                                                                            closeFilterModal={this.closeFilterModal}
                                                                            saveQuickFilter={this.saveQuickFilter}
                                                                            {...tableConfig.expand}
                                                                        // {...this.state}
                                                                        // {...this.props}
                                                                        />}
                                                                </td>
                                                            </tr>}
                                                    </React.Fragment>)
                                        }</tbody>
                                    </table>
                                </div>
                            </div> :
                            this.state.getTableDataError || this.state.getTableHeadersError ?
                                <NewTableLoader isError={true} onRefresh={this.onRefresh} /> :
                                <React.Fragment>
                                    <NewTableLoader />
                                </React.Fragment>}
                    </div>
                </div>
            </React.Fragment>

        )
    }
}