import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import '../../styles/stylesheets/SideBar.css'
import { parseJwt } from '../../helper/genericFunction'
import IconsPath from '../../assets/icons/index'
import $, { timers } from 'jquery'
import {getNumberOfStrips} from '../../initialPhaseOperations/InitialAnnouncementModal'
import '../../styles/stylesheets/Breadcrumb/Sidebar.scss'
import {useSelector} from 'react-redux'

const Sidebar = () => {
    const history = useHistory();
    const tokenData = parseJwt(sessionStorage?.getItem("token")) || {}
    let activeHome = history.location.pathname.includes("home");
    let modules = JSON.parse(sessionStorage?.getItem("modules"));
    const [topValue, setTopValue] = useState(0);
    const [sideBackdrop, setSideBackdrop] = useState(false)
    const availableModules = ["RDAMAIN", "RADMMAIN", "MDMMAIN", "SALMNGMAIN","RSUPERADM", "VDREPOMAIN","RDREPOMAIN","RDVMAIN", "VDVMAIN", "RDPMAIN", "CSALMNGMAIN", "PDRMAIN", "PCVMAIN"] // Available modules in newer portal
    // Logo Name
    let LOGO = {
        "VENDOR": IconsPath.VendorSupplymintLogo,
        "CUSTOMER": IconsPath.CustomerSupplymintLogo,
        "ENT": IconsPath.NewSupplymintLogo
    }

    const getUserAnnoucementDetails = useSelector((state) => state.generalStore?.getAnnouncementData);

    const [stripHeight, setStripHeight] = useState(getNumberOfStrips() || 0);

    useEffect(() => {
        setInterval(() => {
            setStripHeight(getNumberOfStrips())
        }, 400)
    }, [])

    const moveToHome = () => {
        if(activeHome) return;
        // Function to move the route to home
        let dashboardModules = JSON.parse(sessionStorage?.getItem("dashboardModules"));
        if (dashboardModules?.length !== 0) {
            let pinnedModules = localStorage.getItem(parseJwt(sessionStorage.getItem('token')).jti), pageUrl = "";
            if (pinnedModules) {
                let id = Number(pinnedModules.split(",")[0]);
                let page_Url = dashboardModules.find(item => item.id === id).pageUrl;
                pageUrl = page_Url?.replace(/^#/, '');
            }
            else {
                let page_Url = dashboardModules[0].pageUrl;
                pageUrl = page_Url?.replace(/^#/, '');
            }
            history.push(pageUrl);
            return;
        }
        history.push("/");
    }
    // Function to shift when number of icons becomes more than size of screen
    // const shiftIcons = (e) => {
    //     changeIcon();
    //     if (e.clientX <= 65) {
    //         let iconsCount = modules?.length + 1;
    //         setTopValue((((iconsCount * 70) - document.getElementById("mainUl2")?.clientHeight) * (e.clientY - 75)) / (document.getElementById("sidebarDiv")?.clientHeight - 75))
    //     }
    // }
    const changeIcon = () => {
        if (document.getElementsByClassName('sub-menu').length > 0) {
            var h1 = document.querySelectorAll('.sub-menu');
            for (var i = 0; i < h1.length; i++) {
                var bounding = h1[i].getBoundingClientRect()
                if (bounding.top >= 0 && bounding.left >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)) {
                }
                else {
                    if(h1[i].className.includes("last-sub-menu-bottom-up")) {

                    }
                    else {
                        h1[i].className += " last-sub-menu-bottom-up";
                    }
                    var bounding2 = h1[i]?.getBoundingClientRect();
                    if (bounding2?.top < 45) {
                        // MAKE YOUR CHANGES HERE
                        h1[i].className += " top-new-pos";
                    }
                }
            }
        } else {
            setTimeout(changeIcon, 5000);
        }
    }
    const activeMenu = (e) => {
        $("li.h2-main-item.active")?.removeClass("active");
    }
    // Function -> To get the current icon
    const getIcon = (url) => {
        switch(url){
            case "digi-ars-icon.svg" : return < IconsPath.DigiArsMenuIcon />
            case "digi-vendor-icon.svg" : return < IconsPath.DigiVendorMenuIcon/>
            case "ent-catalogue-icon.svg" : return < IconsPath.EntCatalogueMenuIcon/>
            case "sales-management.svg" : return < IconsPath.SalesManagementMenuIcon/>
            case "master-data-icon.svg" : return < IconsPath.MasterDataMenuIcon/>
            case "admin-icon-sidebar.svg" : return < IconsPath.AdminIconMenuIcon/>
            case "vendor-catalogue-icon.svg" : return < IconsPath.VendorCatalogueMenuIcon/>
            case "invoice-management.svg" : return < IconsPath.InvoiceManagementMenuIcon/>
            case "digi-proc-icon.svg" : return < IconsPath.DigiProcMenuIcon/>
            case "reports-analytics.svg" : return < IconsPath.ReportsAnalyticsMenuIcon/>
            case "superadmin-icon-sidebar.svg" : return < IconsPath.SuperAdminMenuIcon/>
            default: return <IconsPath.DigiArsMenuIcon/>
        }
    }
    // Function to render the menu list
    const renderMenu = (module) => {
        return <React.Fragment>
            <ul className="sub-menu" id="subMenu">
                {!module.parentCode && <li className="sm-head">
                    <span data-key="order_number" className="sm-head-menu">{module.name}</span>
                </li>}
                {module?.subModules?.[0]?.category ?
                    renderMenuByCategory(module?.subModules) :
                    module?.subModules?.map((subModule, index) => {
                        if (subModule?.pageUrl === null && subModule?.subModules === null) return null
                        else {
                            return (
                                <li className="sm-inner-item" key={index} onClick={subModule?.pageUrl && openPage(subModule)}>
                                    <div className="mdd-content">
                                        <p>{subModule?.name || ""}</p>
                                    </div>
                                    {subModule?.isPage === 0 &&
                                        <svg className="drop-menu-right-arrow" xmlns="http://www.w3.org/2000/svg" width="6.485" height="10.97" viewBox="0 0 6.485 10.97">
                                            <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" id="prefix__Path_206" d="M6 9l4.071 4.071L14.142 9" data-name="Path 206" transform="rotate(-90 3.985 11.571)" />
                                        </svg>}
                                    {subModule?.isPage === 0 && renderMenu(subModule)}
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </React.Fragment>
    }
    // Function to render the submenu
    const renderMenuByCategory = subModules => {
        const CATEGORY_MAP = {};
        subModules?.forEach(item => {
            if (!CATEGORY_MAP[item.category]) {
                CATEGORY_MAP[item.category] = [];
            }
            CATEGORY_MAP[item.category].push(item);
        });
        return (
            <div className="smv2">
                {Object.keys(CATEGORY_MAP || {})?.map(key =>
                    <ul className="sm-cat-vice" key={key}>
                        <li className="sm-inner-heading">{key}</li>
                        {CATEGORY_MAP?.[key]?.map((item, index) =>
                            <li key={index} className="sm-inner-item" onClick={item?.pageUrl ? openPage(item) : null}>
                                {item?.name}
                            </li>
                        )}
                    </ul>
                )}
            </div>
        );
    }
    // Function to move to the another url

    const openPage = module => e => {
        const cleanPageUrl = module?.pageUrl?.replace(/^#/, ''); // Remove '#' from the beginning

        history.push(cleanPageUrl);
    };

    const shiftIcons = (e) => {
        changeIcon();
        if (e.clientX <= 65) {
            let iconsCount = modules.length + 1;
            setTopValue((((iconsCount * 70) - document.getElementById("mainUl2").clientHeight) * (e.clientY - 75)) / (document.getElementById("sidebarDiv").clientHeight - 75))
        }
    }

    return (
        <React.Fragment>
            {sideBackdrop && <div className="backdrop zi99"></div>}
            <div id="sidebarDiv" className={`sidebar-new top-${stripHeight}`}>
                <div className="sn-logo">
                    {/* The Home Supplymint Logo according to user. */}
                    <img className={parseJwt(sessionStorage.getItem('token'))?.uType == "ENT" ?.toUpperCase() ? "" : "snl-img"} onClick={moveToHome}
                        src={LOGO?.[parseJwt(sessionStorage.getItem('token'))?.uType?.toUpperCase()] || IconsPath.NewSupplymintLogo} alt='Supplymint Auth Logo Image.'
                    />
                </div>
                <ul id="mainUl2" onMouseMove={(e)=> shiftIcons(e)} onMouseLeave={() => { setTopValue(0) }} style={{ top: `-${topValue}px` }}>
                    {/* Render the Home Icon */}
                    <li className={`h2-main-item ${activeHome && 'active'}`} id="home-page" >
                        <a className="h2mi-home" id="homeUrl" onClick={activeMenu}>
                            <span className="h2mi-icon-area" onClick={moveToHome}>
                                <svg width="22" height="22" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.65839 6.64433C5.65839 6.13631 5.24663 5.72476 4.73882 5.72476C4.23081 5.72476 3.81925 6.13633 3.81925 6.64433V17.6311C3.81925 19.3526 5.21464 20.7482 6.93608 20.7482H17.9234C19.6448 20.7482 21.0402 19.3526 21.0402 17.6311V6.64433C21.0402 6.13631 20.6284 5.72476 20.1206 5.72476C19.6128 5.72476 19.2011 6.13631 19.2011 6.64433V17.6311C19.2011 18.3369 18.6291 18.909 17.9234 18.909H6.93608C6.23053 18.909 5.65839 18.3369 5.65839 17.6311V6.64433Z" fill="#707494" stroke="#707494" stroke-width="0.191097"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4604 11.633C10.4604 10.5456 11.342 9.66406 12.4294 9.66406C13.5169 9.66406 14.3984 10.5456 14.3984 11.633C14.3984 12.7205 13.5169 13.602 12.4294 13.602C11.342 13.602 10.4604 12.7205 10.4604 11.633Z" fill="#707494"></path><path d="M13.1626 2.78308L13.1627 2.78309L22.8896 9.59186C23.3057 9.88313 23.879 9.78193 24.1702 9.36587C24.4615 8.9498 24.3603 8.3765 23.9443 8.08524L14.2175 1.27647L14.2175 1.27646C13.1441 0.525242 11.7157 0.525236 10.6424 1.27647L10.6968 1.35419L10.6424 1.27647L0.915657 8.08524L0.97045 8.16352L0.915654 8.08524C0.499597 8.3765 0.398393 8.9498 0.689664 9.36587C0.980925 9.78193 1.55422 9.88313 1.97029 9.59186L11.697 2.78309L11.6422 2.70481L11.697 2.78308C12.137 2.47504 12.7227 2.47504 13.1626 2.78308Z" fill="#707494" stroke="#707494" stroke-width="0.191097"></path>
                                </svg>
                            </span>
                        </a>
                    </li>
                    {/* Render the complete menu list */}
                    {modules?.filter(item => availableModules?.includes(item?.code))?.map((_, key) => {
                        return (
                            <li onClick={activeMenu} onMouseEnter={() => setSideBackdrop(true)} onMouseLeave={() => setSideBackdrop(false)} className="h2-main-item" id={`main${key}`} key={key}>
                                <span className="hmi-img">
                                    {getIcon(_.iconUrl)}
                                    {renderMenu(_)}
                                </span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </React.Fragment>
    )
}
export default Sidebar