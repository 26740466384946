import React from 'react'
import Icons from '../../../../assets/icons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ArsDashboardCard1 = ({ status, pendingQty, totalQty, newEngineData, jobName }) => {
    const history = useHistory()

    const iconsMapping = {
        PENDING: <Icons.ArsPendindIcon />,
        APPROVED: <Icons.ArsApprovedIcon />,
        REJECTED: <Icons.ArsRejectedIcon />,
        CANCELLED: <Icons.ArsRejectedIcon />,
    }

    const redirect = () => {
        history.push({
            pathname: '/inventoryPlanning/transferOrder',
            search: `?status=%2522${status}%2522`,
            state: { id: "ALL", name: jobName, ruleId: newEngineData.id, "jobDisplayName" : "ALL" }
        })
    }

    return (
        <div className='tcdt-card' onClick={redirect}>
            <div className='flex justify-center'>
                <span className={`${status == "PENDING" && "span-pending"} ${status == "APPROVED" && "span-approved"}`}>
                    {iconsMapping[status]}
                </span>
            </div>
            <h1>{totalQty}</h1>
            <p>Transfer Order {status}</p>
            <div className='tcdtc-status'>
                <p>Quantity</p>
                <h4>{pendingQty || "NA"}</h4>
            </div>
        </div>
    )
}

export default ArsDashboardCard1