
import React from 'react';
import IconsPath from '../assets/icons'
import { Tooltip } from 'antd';

const CustomPopover = ({ infoMessage = [], hideErrorIcon = false }) => {
    return (
        <>
            {infoMessage?.[0] && <Tooltip title={
                infoMessage?.map(tip => (
                    <p>{tip}</p>
                ))
            } color='red'>
                {!hideErrorIcon && <div className='flex items-center h-100 p-4 p-rgt-0 cursor-pointer'>
                    <IconsPath.ErrorIcon />
                </div>}
            </Tooltip>}
        </>
    );
};

export default CustomPopover;

