import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ParentModal from '../genericComponents/ParentModal'
import Lottie from 'lottie-react'
import JobSuccessTickMark from '../animationsJSON.js/JobSuccessTickMark.json'
import JobFailedMark from '../animationsJSON.js/JobFailedMark.json'
import GenericConfirmationModal from '../genericComponents/GenericConfiramationModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const getAnimation = (type) => ({
    loop: true,
    autoplay: true,
    animationData: type === 'success' ? JobSuccessTickMark : JobFailedMark,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    },
    style: {
        width: 110,
        height: 80
    }
})

const WarehouseJobStatusComponent = () => {
    const history = useHistory()
    const seasonPlanningStore = useSelector(state => state.seasonPlanningStore)

    const [selectedModal, setSelectedModal] = useState('')
    const [jobStatus, setJobStatus] = useState('')
    const [jobData, setJobData] = useState({})
    const [jobAllData, setJobAllData] = useState({})

    useEffect(() => {
        return () => {
            clearAllIntervals();
        };
    }, []);

    useEffect(() => {
        if (jobStatus?.toLowerCase() == 'succeeded' || jobStatus?.toLowerCase() == 'failed') {
            clearAllIntervals();
            setSelectedModal(jobStatus?.toLowerCase() == 'succeeded' ? 'jobSuccessModal' : 'jobFailedModal');
        }
    }, [jobStatus]);

    // Function to clear all intervals applied to the window object
    const clearAllIntervals = () => {
        let jobRunInterval = localStorage.getItem("jobRunInterval");
        window.clearInterval(jobRunInterval);
        localStorage.removeItem("jobRunInterval")
    };

    // handle the suceesss for job run
    useEffect(() => {
        if (seasonPlanningStore?.jobRunDetailNewData?.isSuccess) {
            let data = seasonPlanningStore?.["jobRunDetailNewData"]?.data;
            if (data?.resource) {
                setJobData(data?.resource)
                if (data?.resource?.runningjob?.length !== 0) {
                    setJobStatus(data?.resource?.["runningjob"]?.[0]?.status || '');
                    return;
                }
                else if (data?.resource?.runningjob?.length === 0) {
                    setJobStatus(data?.resource?.lastEngineRun || '');
                    return
                }
            }
        }
    }, [seasonPlanningStore?.["jobRunDetailNewData"]?.isSuccess])

    useEffect(() => {
        if (seasonPlanningStore?.createRunOnDemandJobData?.isSuccess) {
            let data = seasonPlanningStore?.["createRunOnDemandJobData"]?.data;
            if (data?.resource) {
                setJobAllData(data?.resource)
            }
        }
    }, [seasonPlanningStore?.["createRunOnDemandJobData"]?.isSuccess])

    // logic to handle the error of the job run api
    useEffect(() => {
        if (seasonPlanningStore?.jobRunDetailNewData?.isError) {
            setJobStatus('')
            setSelectedModal('jobFailedModal')
        }
    }, [seasonPlanningStore?.["jobRunDetailNewData"]?.isError])

    // logic to open the success modal if job run succeeds
    useEffect(() => {
        if (jobStatus?.toLowerCase() === 'succeeded') {
            clearAllIntervals();
            setSelectedModal('jobSuccessModal')
        }
        if (jobStatus?.toLowerCase() === 'failed') {
            clearAllIntervals();
            setSelectedModal('jobFailedModal')
        }
    }, [jobStatus])

    console.log('jgdhstrysvcxfd', 'modal --', selectedModal, 'jobstatus--', jobStatus, 'jobdata--', jobData, jobAllData);

    // handle change function
    const handleChange = (type, data) => {
        switch (type) {
            case "viewSummary": {
                setSelectedModal('')
                history.push({
                    pathname: '/inventoryPlanning/summary',
                    states: {
                        id: jobData?.jobId || "",
                        manageid: jobAllData?.manageRuleId || "",
                        name: jobAllData?.jobName || "",
                        jobData: {
                            manageRuleId: jobAllData?.manageRuleId || "",
                            activeJobName: jobAllData?.jobName || "",
                            jobId: jobData?.jobId || "",
                            setActiveJobDetails: false
                        }
                    }
                });
                break;
            }
            case 'createNewJob':
                setSelectedModal('')
                history.push({
                    pathname: '/inventoryPlanning/whNew'
                });
                break;
            default:
                break;
        }
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => { setSelectedModal(false); setJobStatus('') },
            animationType: "center"
        }
        switch (selectedModal) {
            case 'jobFailedModal':
            case "jobSuccessModal": {
                return {
                    ...payload,
                    postitionProps: { top: "20%", left: "27%", borderRadius: 0 },
                    dimensions: { width: "46vw", height: "60vh" },
                    labelComponent: () => {
                        return (
                            <div className='gcmb-custom-msg'>
                                <h4>{jobStatus?.toLowerCase() === 'succeeded' ? 'Success' : 'Failed'}</h4>
                                <p>{jobStatus?.toLowerCase() === 'succeeded' ? 'Last engine run successfully' : 'Last engine run failed'}</p>
                                <label>Click on the summary to view report</label>
                            </div>
                        )
                    },
                    headerComponent: () => (
                        <div className='flex justify-center w-100'>
                            {
                                <Lottie
                                    {...getAnimation(jobStatus?.toLowerCase() === 'succeeded' ? 'success' : 'failed')}
                                />
                            }
                        </div>
                    ),
                    buttons: [
                        { type: "normal", label: "View Summary", onClick: () => handleChange("viewSummary"), className: "generic-approve-btn", },
                        { type: "default", label: "Create New Job", onClick: () => handleChange("createNewJob"), className: "no-wait-btn", },
                    ]
                }
            }
            default: {
                break;
            }
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "jobSuccessModal": return GenericConfirmationModal
            case "jobFailedModal": return GenericConfirmationModal;
            default: break
        }
    }

    return (
        <React.Fragment>
            {selectedModal ? <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false) }}
            /> : null}
        </React.Fragment>)
}

export default WarehouseJobStatusComponent