import React, { useEffect, useState } from 'react'
import ParentModal from '../genericComponents/ParentModal';
import AnnoucementPreview from '../components/administration/globalConfiguration/configComponent/annoucements/AnnoucementPreview';
import { useSelector } from 'react-redux';
import '../styles/stylesheets/Administration/GlobalConfiguration.scss'
import Icons from '../assets/icons';
import GenericCustomButton from '../genericComponents/GenericCustomButton';
import AnnoucementStrip from '../components/administration/globalConfiguration/configComponent/annoucements/AnnouncementStrip';
import dayjs from 'dayjs';

let getNumberOfStrips = () => 0

const InitialAnnouncementModal = (props) => {
    const { tokenData = {} } = props;

    const getUserAnnoucementDetails = useSelector((state) => state.generalStore?.getAnnouncementData);

    const [stripAnnoucements, setStripAnnoucements] = useState([]);
    const [popupAnnoucements, setPopAnnoucements] = useState([]);
    const [selectedModal, setSelectedModal] = useState('');
    const [selectedData, setSelectedData] = useState(null);

    useEffect(() => {
        if (getUserAnnoucementDetails?.data?.resource) {
            let updatedAnnoceuments = [];
            const subscriptionDetails = getUserAnnoucementDetails?.data?.resource?.subscriptionDetails || {};
            const announcements = getUserAnnoucementDetails?.data?.resource?.announcement || []

            let updatedStripAnnoucenments = [];

            // move the subscription expire popup
            if (subscriptionDetails?.level == 'L2' || subscriptionDetails?.level == 'L3') {
                updatedAnnoceuments?.push({
                    type: `subscriptionLevel${subscriptionDetails?.level}`,
                    message: `Your grace period is ending in ${(subscriptionDetails?.subscription_remaining_days || 0) + 15} days. To keep using our services without interruption please renew your subscription.`,
                    ...subscriptionDetails,
                    bannerImage: () => getBannerImage(subscriptionDetails),
                    renderCustomData: renderCustomData,
                    renderCustomButtons: renderCustomButtons
                });

                subscriptionDetails?.level == 'L2' && updatedStripAnnoucenments?.push({
                    ...subscriptionDetails,
                    type: `subscriptionLevel${subscriptionDetails?.level}`,
                    level: "L2",
                    message: `Your grace period is ending in ${(subscriptionDetails?.subscription_remaining_days || 0) + 15} days. To keep using our services without interruption please renew your subscription.`,
                    external_link: '',
                    bannerImage: () => getBannerImage({ ...subscriptionDetails, level: "L2" }),
                    renderCustomData: renderCustomData,
                    renderCustomButtons: renderCustomButtons,
                    // stripButtonLabel: "Know More",
                })
            }

            // Update the subscription strip data here
            if (subscriptionDetails?.level == "L1") {
                updatedStripAnnoucenments?.push({
                    ...subscriptionDetails,
                    type: `subscriptionLevel${subscriptionDetails?.level}`,
                    message: `${subscriptionDetails?.subscription_remaining_days || 0} Days left!  Your subscription is ending soon. To keep using our services without interruption please renew your subscription.`,
                    external_link: '',
                    bannerImage: () => getBannerImage(subscriptionDetails),
                    renderCustomData: renderCustomData,
                    renderCustomButtons: renderCustomButtons,
                    // stripButtonLabel: "Know More",
                })
            }

            // update the annoceument data inside strips and the popups here.
            announcements?.map(item => {
                let obj = {
                    type: "popupAnnoucements",
                    ...item,
                    bannerImage: item?.uploaded_doc_path,
                    title: item?.display_name,
                    annoucementView: item?.announcement_view
                }
                if (item?.announcement_view == "popup") {
                    updatedAnnoceuments?.push(obj);
                } else {
                    updatedStripAnnoucenments?.push(obj);
                }
            });

            setPopAnnoucements(updatedAnnoceuments);
            if (updatedAnnoceuments?.length > 0) {
                sessionStorage.setItem("popupAnnoucements", JSON.stringify(updatedAnnoceuments));
                setSelectedModal("announcement");
            }
            setStripAnnoucements(updatedStripAnnoucenments);
            if (updatedStripAnnoucenments?.length > 0) {
                sessionStorage.setItem("stripAnnoceuments", JSON.stringify(updatedStripAnnoucenments))
            }
        } else {
            if (sessionStorage?.getItem("stripAnnoceuments")) {
                let data = JSON.parse(sessionStorage.getItem("stripAnnoceuments"));
                if (data?.[0]?.type?.includes("subscriptionLevel")) {
                    data[0] = {
                        ...data?.[0],
                        bannerImage: () => getBannerImage(data?.[0]),
                        renderCustomData: renderCustomData,
                        renderCustomButtons: renderCustomButtons
                    }
                }
                setStripAnnoucements(data || [])
            }
            if (sessionStorage.getItem("popupAnnoucements")) {
                let data = JSON.parse(sessionStorage.getItem("popupAnnoucements"));
                if (data?.[0]?.type?.includes("subscriptionLevel")) {
                    data[0] = {
                        ...data?.[0],
                        bannerImage: () => getBannerImage(data?.[0]),
                        renderCustomData: renderCustomData,
                        renderCustomButtons: renderCustomButtons
                    }
                }
                setPopAnnoucements(data || []);
                setSelectedModal("announcement");
            }
        }
    }, [getUserAnnoucementDetails?.isSuccess])

    // console.log("ASDfasdfsadf", popupAnnoucements);


    getNumberOfStrips = () => {
        let num = 0;

        if (tokenData?.["loginStripMessage"]) num += 35;
        if (tokenData?.uType === "VENDOR" && +tokenData?.isMergeEligible === 1) num += 35;
        if (stripAnnoucements?.length > 0 && (selectedModal ? !sessionStorage.getItem("popupAnnoucements")  : true)) num += 35;

        return num;
    }


    const renderCustomData = ({ item }) => {
        // console.log("Grace period", item?.level);
        return (
            <div className='apb-subscription-det'>
                <h2>Subscription Details</h2>
                {item?.["message"] && <div className='apb-para'>
                    <label>Message</label>
                    <p>{item?.["message"] || "-"}</p>
                </div>}
                <div className='apbs-current'>
                    <div>
                        <Icons.Calender />
                    </div>
                    <div className='p-lft-15'>
                        <h1>Current Subscription Period</h1>
                        <p>
                            {`${item?.subs_activation_date ? dayjs(item?.subs_activation_date)?.format("DD-MMM-YYYY") : ""} - ${item?.subs_expiry_date ? dayjs(item?.subs_expiry_date)?.format("DD-MMM-YYYY") : ""}`}
                        </p>
                        <span>{`Subscription ${item?.level == "L1" ? "will expire" : "ended"} on ${item?.subs_expiry_date ? dayjs(item?.subs_expiry_date)?.format("DD-MMM-YYYY") : "-"} 12:00 Midnight `}</span>
                    </div>
                </div>

                {/* Render Grace Period if level is L2 */}
                {(item?.level == 'L2') && (
                    <div className='apbs-grace'>
                        <div>
                            <Icons.Calender />
                        </div>
                        <div className='p-lft-15'>
                            <h1>Grace Period</h1>
                            <p>
                                {`${item?.subs_expiry_date ? dayjs(item?.subs_expiry_date)?.format("DD-MMM-YYYY") : ""} - ${item?.grace_expiry_date ? dayjs(item?.grace_expiry_date)?.format("DD-MMM-YYYY") : ""}`}
                            </p>
                            <span>{`Grace Period will end on ${item?.grace_expiry_date ? dayjs(item?.grace_expiry_date)?.format("DD-MMM-YYYY") : "-"} 12:00 Midnight `}</span>
                        </div>
                    </div>
                )}

                {/* Render Modules if level is L3 */}
                {item?.level == 'L3' && (
                    <div className='apbs-current'>
                        <div className='flex'>
                            <Icons.ModuleIcon />
                        </div>
                        <div className='p-lft-15'>
                            <h1>Modules</h1>
                            <p>
                                {JSON.parse(item?.module_details?.value || "")?.map(item => item?.module_name)?.join(', ')}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        );
    };


    const renderCustomButtons = ({ item, setCurrentActivePost, posts }) => {
        return (
            <>
                <div className='ap-sub-footer'>
                    {(item?.level == 'L2') && <GenericCustomButton
                        label={'Continue With 15 Days Grace'}
                        type='default'
                        onClick={() => {
                            // console.log("sadfsdfsad", popupAnnoucements);
                            if(posts?.length == 1){
                                setSelectedModal("") ;
                                sessionStorage.removeItem("popupAnnoucements")
                            }else{
                                setCurrentActivePost(1);
                            }
                        }}
                    />}
                    {/* {item?.level == 'L3' && <GenericCustomButton
                    className={item?.level == 'L3' ? "w-100" : ""}
                    label={'Contact Now'}
                    type='primary'
                    onClick={() => }
                />} */}
                </div>
                <p className='ap-p'>If you have any questions or need assistance, feel free to contact our support team at <b>support@supplymint.com</b>.</p>
            </>
        );
    };
    const getBannerImage = (item) => {
        return <div className='p-5'>
            <div className='api-expired'>
                <div>
                    <Icons.SubscriptionExpired />
                </div>
                <div className='p-lft-15'>
                    {item?.level == "L1" ? <h1>Subscription will expire soon!</h1> : <h1>Subscription Expired.</h1>}
                    {item?.level == "L3" && <p>To continue with our services, please renew your subscription now. Click the button below to renew your subscription and regain access to our portal.</p>}
                    {item?.level == "L2" && <p>However,<b> we're offering a grace period of 15 days</b> to renew your subscription without interruption to our services.</p>}
                    {item?.level == "L1" && <p>Your subscription is ending soon.  To keep using our services without interruption please renew your subscription.</p>}
                </div>
            </div>
        </div>
    };

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => setSelectedModal(''),
            // animationType: "center",
        }
        switch (selectedModal) {
            case "stripAnnoucenmentPreview":
            case "announcement": {
                return {
                    ...payload,
                    postitionProps: { top: "50%", left: "50%", borderRadius: 0 },
                    dimensions: { width: "40vw", height: "auto" },
                    isCloseBlocked: selectedModal == "stripAnnoucenmentPreview" ? false : true,
                    closeModalOnFinalPost: () => {
                        setSelectedModal("");
                        setSelectedData(null);
                        sessionStorage.removeItem("popupAnnoucements")
                    },
                    parentModalMainClass: "announcement-modal-main",
                    posts: selectedModal == "stripAnnoucenmentPreview" ? stripAnnoucements || [] : popupAnnoucements || [],
                    currentActiveStrip: selectedData || 0
                }
            }
            default: break
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "stripAnnoucenmentPreview":
            case "announcement": return AnnoucementPreview;
            default:
        }
    }
    return (
        <>
            <div className='initial-announcement'>
                {stripAnnoucements?.length > 0 && (selectedModal ? !sessionStorage.getItem("popupAnnoucements")  : true) && <AnnoucementStrip posts={stripAnnoucements} setStripAnnoucements={setStripAnnoucements} setSelectedModal={setSelectedModal} setSelectedData={setSelectedData} />}

                {/* <div className='initial-announcement-popup h-100'>
                </div> */}
            </div>
            {selectedModal && <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
            />}
        </>
    )
}

export { InitialAnnouncementModal, getNumberOfStrips }