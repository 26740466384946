import React, { useEffect, useState } from 'react'
import { GenericFormSpreadTypeComponent } from '../../genericComponents/forms/GenericFormSpreadTypeComponent'
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../helper/apiHelperFunctions';
import GenericCustomButton from '../../genericComponents/GenericCustomButton';
import Icons from '../../assets/icons';
import { decodeToken } from '../../helper/genericFunction';
import { generalStore } from '../../redux/reduxState/generalStore';
import { handleApiChange } from '../../helper/apiHelperFunctions';
import IconsPath from '../../assets/icons'
const CreateTicketModal = (props) => {
    let decodedToken = decodeToken();
    let initialState = {
        "basicDetails": {
            "requesterEmail" : decodedToken?.eml || "",
            "userName" : decodedToken?.aud || "",
            "oraganisationName": decodedToken?.orgName || "NA",
        }
    }
    const [inputState, updateInputState] = useState(initialState);
    const [issuesType, setIssuesType] = useState({});
    const [isCcAvailable, setIsCcAvailabele] = useState(false);
    const [moduleList, setModuleList] = useState([]);
    const [issuesList, setIssuesList] = useState([]);
    const [ssFile, setSsFile] = useState("");
    const dispatch = useDispatch();
    const masterDbStore = useSelector(state => state.masterDbStore);
    const generalStore = useSelector(state => state?.generalStore)
    console.log("SS File", masterDbStore?.["createNewTicketData"]);

    useEffect(()=>{
        if(masterDbStore?.["createNewTicketData"]?.isSuccess || masterDbStore?.["createNewTicketWithAttachmentsData"]?.isSuccess){
            props.closeModal();
        }
    },[masterDbStore?.["createNewTicketData"]?.isSuccess, masterDbStore?.["createNewTicketWithAttachmentsData"]?.isSuccess])

    useEffect(() => {
        dispatchHandler(dispatch, "getCreateTicketModuleListRequest", {
            id: "82000804735",
        })
        dispatchHandler(dispatch, "getCreateTicketIssuesListRequest", {
            id: "82000804740",
        })
        handleApiChange(dispatch, "getDropdownRequest", {type: "CREATE_TICKET_ISSUE_TYPES"})
    }, [])

    useEffect(()=>{
        if(generalStore?.["getDropdownData"]?.isSuccess){
            let data = generalStore?.["getDropdownData"]?.data;
            setIssuesType(data?.resource || {});
        }
    },[generalStore?.["getDropdownData"]?.isSuccess])

    useEffect(() => {
        if (masterDbStore?.["getCreateTicketModuleListData"]?.isSuccess) {
            let data = masterDbStore?.["getCreateTicketModuleListData"]?.data;
            console.log("Head data", data);
            setModuleList(data || {});
        }
    }, [masterDbStore?.["getCreateTicketModuleListData"]?.isSuccess])

    useEffect(() => {
        if (masterDbStore?.["getCreateTicketIssuesListData"]?.isSuccess) {
            let data = masterDbStore?.["getCreateTicketIssuesListData"]?.data;
            setIssuesList(data || {});
        }
    }, [masterDbStore?.["getCreateTicketIssuesListData"]?.isSuccess]);


    const getPriority = (val) => {
        try {
            if(typeof issuesType[val?.["issueRelatedTo"]?.["issueRelatedTo"]] === "number")
                return issuesType[val?.["issueRelatedTo"]?.["issueRelatedTo"]];
            else
                return issuesType[val?.["issueRelatedTo"]?.["issueRelatedTo"]][val["issueSubCategory"]["issueSubCategory"]];
        }
        catch(e) {
            return 1;
        }
    }

    const handleChange = (type, data) => {
        switch (type) {
            case "applyScreenshot": {
                setSsFile(props?.ssFile || "");
                break;
            }
            case "finalSubmit":{
                let values = data?.["basicDetails"];
                console.log("values", values);
                if(ssFile || values?.["attachements"]){
                    let formData = new FormData();
                    formData.append("email", values?.["requesterEmail"] || "");
                    formData.append("subject", values?.["subject"] || "");
                    formData.append("description",  values?.["description"] || "");
                    formData.append("priority", [1,2,3,4].includes(getPriority()) ? getPriority() : 1);
                    formData.append("status", 2);
                    formData.append("custom_fields[cf_enterprise_id]", decodedToken?.eid || "");
                    formData.append("custom_fields[cf_issue_related_to]", values?.["issueRelatedTo"]?.["issueRelatedTo"] || "");
                    formData.append("custom_fields[cf_issue_sub_category]",  values?.["issueSubCategory"]?.["issueSubCategory"] || "");
                    formData.append("custom_fields[cf_module]", values?.["module"]?.["module"] || "");
                    formData.append("custom_fields[cf_organisation_id]", decodedToken?.oid || "");
                    formData.append("custom_fields[cf_organisation_name]", decodedToken?.orgName || "");
                    formData.append("custom_fields[cf_organisation_search_key]", (decodedToken?.["X-TENANT-ID"] + "_" + decodedToken?.["oid"]) || "");
                    formData.append("custom_fields[cf_user_id]",  decodedToken?.jti || "");
                    formData.append("custom_fields[cf_user_type]",decodedToken?.uType || "")
                    formData.append("custom_fields[cf_username]", decodedToken?.prn || "");
                    if(ssFile){
                        formData.append("attachments[]", ssFile);
                    }
                    if(values?.["attachements"]){
                        // formData.append("attachments[]",values?.["attachements"]?.[0])
                    }
                    dispatchHandler(dispatch,"createNewTicketWithAttachmentsRequest", formData);
                }else{
                    let payload = {
                        email: values?.["requesterEmail"] || "",
                        subject: values?.["subject"] || "",
                        description: values?.["description"] || "",
                        priority: [1,2,3,4].includes(getPriority()) ? getPriority() : 1,
                        status: 2,
                        cc_emails : isCcAvailable ? Object.keys(values?.["ccEmail"] || {}) || [] : undefined,
                        custom_fields: {
                            cf_enterprise_id: decodedToken?.eid || "",
                            cf_issue_related_to: values?.["issueRelatedTo"]?.["issueRelatedTo"] || "",
                            cf_issue_sub_category: values?.["issueSubCategory"]?.["issueSubCategory"] || "",
                            cf_module: values?.["module"]?.["module"] || "",
                            cf_organisation_id: decodedToken?.oid || "",
                            cf_organisation_name: decodedToken?.orgName || "",
                            cf_organisation_search_key: (decodedToken?.["X-TENANT-ID"] + "_" + decodedToken?.["oid"]) || "",
                            cf_user_id: decodedToken?.jti || "",
                            cf_user_type: decodedToken?.uType || "",
                            cf_username: decodedToken?.prn || ""
                        }
                    }
                    dispatchHandler(dispatch, "createNewTicketRequest", payload)
                }
                break;
            }
            default:
        }
    }

    console.log("States Ticket", {
        "module List": moduleList,
        "issue List": issuesList
    }, masterDbStore?.["getCreateTicketModuleListData"]?.isSuccess);

    return (
        <div id="create_new_ticket">
            <GenericFormSpreadTypeComponent
                headerComponent={{
                    label: {
                        contentPrimary: "Create New Ticket",
                        contentSecondry: "You can manage & check ticket status in " + "Manage Support Tickets" + " module after successful submission."
                    },
                    closeModal: props.closeModal
                }}
                inputSections={{
                    "basicDetails": {
                        inputFields: () => {
                            let inputFields = [
                                { key: "requesterEmail", label: () => (
                                <div className='flex items-center justify-between'>
                                    <label className='gib-label m-0 p-rgt-10'>Requester Email</label>
                                    <GenericCustomButton
                                        label={<h3 className='underline m-0'>Add CC</h3>}
                                        type='normal'
                                        onClick={() => {setIsCcAvailabele(!isCcAvailable)}}
                                    />
                                </div>), isDisabled: true, type: "email" },
                                { key: "userName", label: "Username", isDisabled: true, type: "text" },
                                { key: "oraganisationName", label: "Organisation Name", isDisabled: true, type: "text" },
                                { key: "subject", genInputExtraClass : "w-100", label: "Subject", type: "text", mandatory: true },
                                {
                                    key: "module", label: "Module", type: "dropdown", iconRight: <Icons.DownArrowNew />, mandatory: true, inputProps: () => ({
                                        headerKeys: {
                                            "module": {
                                                value: "module",
                                            }
                                        },
                                        itemKeys: {
                                            "module": ["module"],
                                        },
                                        getListData: () => {
                                            return moduleList?.choices?.map(item => ({ ...item, "module": item?.label })) || [];
                                        },
                                    })
                                },
                                {
                                    key: "issueRelatedTo", label: "Issue Related To", type: "dropdown", iconRight: <Icons.DownArrowNew />, mandatory: true, inputProps: () => ({
                                        headerKeys: {
                                            "issueRelatedTo": {
                                                value: "issueRelatedTo",
                                            }
                                        },
                                        itemKeys: {
                                            "issueRelatedTo": ["issueRelatedTo"],
                                        },
                                        getListData: () => {
                                            return issuesList?.choices?.map(item => ({ ...item, "issueRelatedTo": item?.label })) || [];
                                        },
                                    })
                                },
                                {
                                    key: "issueSubCategory", label: "Issue Sub-Category", type: "dropdown", iconRight: <Icons.DownArrowNew />, mandatory: true, isDisabled: () => !inputState?.["basicDetails"]?.["issueRelatedTo"], inputProps: () => ({
                                        headerKeys: {
                                            "issueSubCategory": {
                                                value: "issueSubCategory",
                                            }
                                        },
                                        itemKeys: {
                                            "issueSubCategory": ["issueSubCategory"],
                                        },
                                        getListData: () => {
                                            return inputState?.["basicDetails"]?.["issueRelatedTo"]?.choices?.map(item => ({ ...item, "issueSubCategory": item?.label })) || [];
                                        },
                                    })
                                },
                                { key: "description", genInputExtraClass : "generic-textarea", label: "Description", type: "textarea", mandatory: true, },
                                {
                                    key: "attachements", genInputExtraClass : "generic-upload", label: "Attachements", type: "upload", inputProps: () => ({
                                        acceptFiles: "image/*,.doc,.docx,.xls,.xlsx,.pdf",
                                        renderCustomPlaceholder : () => {
                                            return (
                                                <div className='gibu-inner'>
                                                    <h4>Drag & drop to attach, or <b>Browse file</b></h4>
                                                </div>
                                            )
                                        }
                                    }),
                                    inputComponents : {
                                        "bottom" : () => 
                                        <div className='gu-capture'>
                                            
                                            {!ssFile ? <div className='guc-div' onClick={() => { handleChange("applyScreenshot") }}>
                                                <IconsPath.CaptureIcon />
                                                <p>Attach Current Window</p>
                                            </div> : 
                                            <div className='guc-text'>
                                                <h3>{ssFile?.name || ""}</h3>
                                                <GenericCustomButton
                                                    type='normal'
                                                    leftIcon={<Icons.ModalCloseIcon />}
                                                    onClick={() => setSsFile(null)}
                                                />
                                            </div>}
                                        </div>
                                    }
                                }
                            ]
                            let ccInputField = {
                                key: "ccEmail", genInputExtraClass : "w-100", label: "Add CC", type: "dropdown", iconRight: <Icons.DownArrowNew />, inputProps: () => ({
                                    headerKeys: {
                                        multiSelectCol: {},
                                        email: {
                                            value: "Email",
                                        },
                                        username: {
                                            value: "Username"
                                        },
                                    },
                                    search: {
                                        placeholder: "Search Email...",
                                        isSearchIconRequire: true,
                                    },
                                    itemKeys: {
                                        "email": ["email"],
                                        "username": ["username"]
                                    },
                                    getListData: (response) => {
                                        return response?.resource?.searchResultNew || [];
                                    },
                                    multiselect: {
                                        selectId: "email"
                                    },
                                    viewDataType : "table-view",
                                    api: {
                                        reduxState: "generalStore",
                                        apiName: "getSearchFilters",
                                        apiPayload: (params) => {
                                            return {
                                                "entity": "supplymint.users",
                                                "key": "email",
                                                "code": "username",
                                                "search": params?.currentSearch || "",
                                                "searchBy": params?.searchFilter || "CONTAINS",
                                                "pageNo": params?.currentPage || 1,
                                                "moduleName": "",
                                                "other_data": {}
                                            }
                                        },
                                        getMaxPage: (states, fucntions, response) => {
                                            return response?.resource?.maxPage || 1;
                                        },
                                    }
                                })
                            }
                            if (isCcAvailable) {
                                inputFields = [...inputFields?.slice(0, 3), ccInputField, ...inputFields?.slice(3)]
                            }
                            return inputFields;
                        },
                    },
                }}
                finalSubmit={(data) => handleChange("finalSubmit", data)}
                inputState={inputState}
                updateInputState={updateInputState}
                finalSubmitButton={"Create Ticket"}
            />
        </div>
    )
}

export default CreateTicketModal