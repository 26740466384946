import React, { useEffect, useState } from 'react'
import ListComponent from '../../genericComponents/ListComponent'
import IconsPath from '../../assets/icons'
import GenericTabsComponent from '../../genericComponents/GenericTabsComponent'
import NotificationBox from './NotificationBox'
import { useSelector } from 'react-redux'
import moment from 'moment'
import GenericInput from '../../genericComponents/input/GenericInputComponent'
import '../../styles/stylesheets/home/Notification.scss'
import dayjs from 'dayjs'

const NotificationModal = (props) => {

    const [currentTab, setCurrentTab] = useState("ALL_ACTIVITIES");
    const [showCustomAction, setShowCustomAction] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [selectedRange, setSelectedRange] = useState(null);


    const generalStore = useSelector(state => state.generalStore);
    const dateFormat = 'YYYY/MM/DD';

    useEffect(() => {
        if (generalStore?.["getNotificationModalData"]?.isSuccess) {
            setRefreshData(false);
        }
    }, [generalStore?.["getNotificationModalData"]?.isSuccess]);

    useEffect(() => {
        if (selectedOption === "custom") {
            refreshListData();
        }
    }, [selectedRange])

    const refreshListData = () => {
        setRefreshData(true);
    }

    const handleChange = (type, data) => {
        // eslint-disable-next-line default-case
        switch (type) {
            case "searchOptionChange": {
                setSelectedOption(data.target.value);
                data.target.value != "custom" && refreshListData();
                break;
            }
            case 'customDateFilter': {
                // console.log('handle change for range clicked',);
                setSelectedRange(data);
                break;
            }
        }
    };


    console.log('selected range is ====', selectedRange);
    const searchOptions = () => {
        return <div className='gnm-filter'>
            <label>{"Sort By : "}</label>
            <select value={selectedOption} name="cars" id="cars" onChange={(e) => { handleChange("searchOptionChange", e) }}>
                <option value="all_activities">All Activities</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="custom">Custom</option>
            </select>
        </div>

    }

    const getActionTimeFilter = (searchFilter) => {
        // console.log("Search Filter", selectedRange[0]?.format("YYYY-MM-DD"));
        let filter = {};
        if (searchFilter === "today") {
            let getCurrentDate = moment(new Date()).format("YYYY-MM-DD");
            filter = {
                actionTime: {
                    from: getCurrentDate,
                    to: getCurrentDate,
                },
            };
        } else if (searchFilter === "yesterday") {
            let yesterday = new Date() - 1 * 24 * 60 * 60 * 1000;
            filter = {
                actionTime: {
                    from: moment(yesterday).format("YYYY-MM-DD"),
                    to: moment(yesterday).format("YYYY-MM-DD"),
                },
            };
        } else if (searchFilter === "custom" && selectedRange ) {
            filter = {
                actionTime:{
                    from: selectedRange?.[0]?.format("YYYY-MM-DD"),
                    to: selectedRange?.[1]?.format("YYYY-MM-DD"),
                } 
            };
        }
        return filter;
    };
    console.log(454545, props)

    return (
        <>
            <ListComponent
                modalMainClass = {`generic-notification-modal ${showCustomAction ? "notification-expand" : ''}`}
                label={{
                    content: "Notification Center",
                    rightComponent: () => {
                        return <button className='gen-action-btn storokeHoverWhite' onClick={() => { setShowCustomAction(!showCustomAction) }}>
                            <IconsPath.NotificationMenuIcon />
                        </button>
                    },
                }}

                closeModal = {props.closeModal}

                itemKeys={{
                    "notificationBox": [
                        (state, functions, data) => {
                            return <NotificationBox item={data} />
                        },
                    ]
                }}

                renderCustomItems={{
                    "0": () => {
                        return (
                            <GenericTabsComponent
                                tabsData={{
                                    "ALL_ACTIVITIES": "All Activities",
                                    "MY_ACTIVITIES": "My Activities"
                                }}
                                tabsKey={"currentTab"}
                                handleTabChange={(val) => {
                                    setCurrentTab(val);
                                    refreshListData();
                                }}
                                current={currentTab}
                                type={1}
                            />
                        )
                    },
                    "1": () => (
                        selectedOption === 'custom' &&
                        <GenericInput 
                            type='range' 
                            inputKey={"actionTime"}
                            value = {selectedRange || null}
                            dependentFieldsObject = {["actionTime"]}
                            onChange={(e) => handleChange("customDateFilter", e)} 
                            inputProps={() => ({
                            format: dateFormat
                        })} />
                    )
                }}

                headerKeys={{
                    "notificationBox": {
                        value: "notificationBox", // This can be a function also which can show the dynamic data
                        width: "100%" // This defines the width of the column
                    }
                }}

                search={showCustomAction ? {
                    placeholder: "Search",
                    searchOptions: searchOptions,

                    // searchOptionKey: "basedOn",
                    searchOptionLabel: "Sort By : ",
                } : false}

                api={{
                    reduxState: "generalStore",
                    apiName: "getNotificationModal",
                    apiPayload: (params) => {
                        return {
                            "notification": currentTab,
                            "search": showCustomAction ? params?.currentSearch || "" : "",
                            "pageSize": 30,
                            "pageNo": params?.currentPage || 1,
                            "filter": showCustomAction ? getActionTimeFilter(selectedOption) : {}
                        }
                    },
                    getMaxPage: (states, functions, response) => {
                        let currPage = states?.currentPage;
                        if (response?.resource?.length >= 30) {
                            return Number(currPage || "") + 1;
                        }

                        return Number(currPage || "");
                    }
                }}
                getListData={(response) => {
                    console.log("Resp", response);
                    return response?.resource || [];
                }}

                refreshData={refreshData}

            />
        </>
    )
}

export default NotificationModal