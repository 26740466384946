import React, { Suspense, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Icons from '../../assets/icons'
import GenericCustomButton from '../../genericComponents/GenericCustomButton'
import ListComponent from '../../genericComponents/ListComponent'
import '../../styles/stylesheets/SignIn/SignIn.scss'
import { getCookie } from '../../helper/genericFunction'
import {isGetAutoLoginCookie} from '../../authComponents/login/helper/autoLoginFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import FullPageLoader from '../../loaders/FullPageLoader'
import LoginSupplymintAnimation from '../LoginSupplymintAnimation'
import { loginRequest } from './helper/loginHelperFunctions'

const Login = React.lazy(() => import('./Login'))

const LoginStack = (props) => {


    const [signUpLoading, setSignUpLoading] = useState(false);
    const [backgroundUrl, setBackgroundUrl] = useState("");

    const [openModal, setOpenModal] = useState(false);
    const jwtToken = getCookie('jwt')
    const dispatch = useDispatch();
    const location = useLocation();

    const authStore = useSelector(state => state?.authStore)

    const [mode, setMode] = useState("normal");

    console.log("Check condition 1", jwtToken);

    // Get cookie here for auto login 
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const freshdeskParams = Object.fromEntries(urlSearchParams.entries());
        console.warn("Fresh desk params", freshdeskParams)
    
        if (freshdeskParams.client_id && freshdeskParams.nonce && freshdeskParams.redirect_uri && freshdeskParams.state) {
            // Auto login for the fresh desk params
            if(jwtToken){
                loginRequest({
                    token : jwtToken,
                    location: location,
                    dispatch: dispatch,
                    setLoading : setSignUpLoading,
                })
            }
        }
        else if (jwtToken !== null && !sessionStorage.getItem("token") && isGetAutoLoginCookie({location})) {
            dispatchHandler(dispatch, "getCurrrentUserAuthDetailsCopyRequest", {
                token: jwtToken,
                flowType: "RELOGIN"
            })
            setMode("autoLogin");
        }
    }, [jwtToken])

    useEffect(() => {
        if (authStore?.getCurrrentUserAuthDetailsCopyData?.isError) {
            setMode("normal");
        }
    }, [authStore?.getCurrrentUserAuthDetailsCopyData])

    useEffect(() => {
        let url = `https://s3.ap-south-1.amazonaws.com/assets-supplymint-com/org_assets/${window.location.hostname}/bannerImage.png`;
        const checkImageUrl = async () => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve(true);
                img.onerror = () => resolve(false);
                img.src = url;
            });
        };

        const setBackgroundImage = async () => {
            const isPreviewAvailable = await checkImageUrl();
            if (isPreviewAvailable) {
                setBackgroundUrl(url);
            } else {
                setBackgroundUrl(false);
            }
        };
        setBackgroundImage();
    }, []);

    const handleChange = (type) => {
        switch (type) {
            case "supportDropdown": {
                setTimeout(() => document?.addEventListener("click", handleClickOutside), 0)
                setOpenModal("supportDropdown");
                break;
            }
        }
    }

    // Function to handle the outside click
    const handleClickOutside = () => {
        setTimeout(() => document.removeEventListener("click", handleClickOutside), 0);
        setOpenModal(false);
    }

    const renderSupportDropdown = () => {
        return (
            <div className='sft-btn'>
                <GenericCustomButton
                    label="Support"
                    rightIcon={<Icons.SupportIcon />}
                    onClick={() => handleChange("supportDropdown")}
                />
                {/* Render the support dropdown */}
                {openModal == "supportDropdown" && <ListComponent
                    label={{
                        content: "Help & Support",
                    }}
                    // closeModal = {() => handleClickOutside()}
                    headerKeys={{
                        "help": {}
                    }}
                    itemKeys={{
                        "help": ["label", "support"],
                    }}
                    getListData={() => ([
                        { label: "New Ticket", support: "Create New Support Ticket", onClick: () => { window.open("https://help.supplymint.com/support/tickets/new") } },
                        { label: "Support Documentation", support: "Supplymint Help Portal", onClick: () => { window.open("https://support.supplymint.com/") } },
                        { label: "Blogs", support: "Get Latest News & Updates", onClick: () => { window.open("https://www.supplymint.com/blogs/") } },
                        { label: "Contact Us", support: "We'll Respond As Quick As Possible", onClick: () => { window.open("https://www.supplymint.com/contact") } },
                    ])}
                    submitHandler={(data) => data?.onClick?.()}
                />}
            </div>
        )
    }
    if(mode == "autoLogin"){
        return <LoginSupplymintAnimation msg = {"Logging Back To Supplymint..."}/>
    }

    return (
        <Suspense fallback={<FullPageLoader />}>
            <div className='signin-flow'>
                <div className='sf-top' style={backgroundUrl ? { backgroundImage: `url(${backgroundUrl})` } : {}}>
                    {/* the Top div for the support dropdown */}
                    {renderSupportDropdown()}
                </div>
                {/* the backgorund div for the login screen */}
                <div className='py-[2.187rem] h-full w-full max-h-screen overflow-y-auto z-50'>
                    <div className='sf-modal'>
                        <Switch>
                            <Route path='*' render={() => <Login {...props} setSignUpLoading={setSignUpLoading} signUpLoading={signUpLoading} />} />
                        </Switch>
                    </div>
                </div>
            </div>
            {signUpLoading && <FullPageLoader />}
        </Suspense>
    )
}

export default LoginStack