import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useRenderClassComponent from '../../hooks/useRenderClassComponent';
import TableComponent from '../oldGenericComponents/TableComponent';
import { useParams } from 'react-router-dom';
import { dispatchHandler } from '../../helper/apiHelperFunctions';
import { v4 as uuidv4 } from 'uuid';

export default function GenericTableComponent(props) {
    // console.log("Generic table component props", props);

    const { apiName = "getTableComponentList", storeName = "generalStore", expandFirstProps = {}, summaryApiName = "getTableComponentSummary" } = props;


    const params = useParams()
    const [currentTab, setCurrentTab] = useState(params?.[props?.actionBarConfig?.tabsKey || "status"] ? JSON.parse(decodeURIComponent(params.status)) : "Pending")
    const dispatch = useDispatch();
    const generalStore = useSelector(state => state.generalStore);
    const states = {
        administrationStore: useSelector(state => state.administrationStore),
        masterDbStore: useSelector(state => state.masterDbStore),
        generalStore: useSelector(state => state.generalStore),
    }

    const [headersData, setHeadersData] = useState({});
    const [headersType, setHeadersType] = useState({});

    const [itemExpandedRow, setItemExpandedRow] = useState({})
    const [mainExpandedRow, setMainExpandedRow] = useState({})
    const [itemExpandedData, setItemExpandedData] = useState([])
    const [mainExpandedData, setMainExpandedData] = useState([])

    const [bit, setBit] = useState(false);

    useEffect(() => {
        if (generalStore?.getHeadersData?.isSuccess && generalStore?.getHeadersData?.data?.basedOn !== "main") {
            let data = generalStore?.getHeadersData?.data?.resource || {};
            setHeadersData(updatedHeadersData => {
                updatedHeadersData[`${generalStore?.getHeadersData?.data?.basedOn}`] = data;
                return updatedHeadersData
            })

            setHeadersType(updatedHeadersType => {
                updatedHeadersType[`${generalStore?.getHeadersData?.data?.basedOn}`] = Object.keys(data?.["Custom Headers"] || {})?.length > 0 ? "Custom Headers" : "Default Headers"
                return updatedHeadersType;
            })
            setBit(!bit);
        }
    }, [generalStore?.getHeadersData?.isSuccess]);

    useEffect(() => {
        // See if the current tab is changed or not!
        // console.log("Current tab changed", props.currentTab)
        resetExpandedData();
        setHeadersData({});
        setHeadersType({});
    }, [props.currentTab])

    const resetExpandedData = () => {
        setMainExpandedRow(false);
        setMainExpandedData([])
        setItemExpandedData([])
        setItemExpandedRow(false)
    }

    // Close the expanded row when the usern wants;
    useEffect(() => {
        if (expandFirstProps?.closeFirstExpandedRow) {
            resetExpandedData();
        }
    }, [expandFirstProps?.closeFirstExpandedRow])

    useEffect(() => {
        if (generalStore?.["getTableComponentSecondListData"]?.isSuccess) {
            let data = generalStore?.["getTableComponentSecondListData"]?.data;
            if (mainExpandedRow){
                let listData = props?.getExpandFirstListData ? props.getExpandFirstListData(data, {
                    mainExpandedRow : mainExpandedRow,
                }) : data?.resource || [];
                // console.log("List dAta", listData);
                setMainExpandedData(listData);
            }
        }
    }, [generalStore?.["getTableComponentSecondListData"]?.isSuccess])

    // console.log("Main ExpNDED", mainExpandedData);

    useEffect(() => {
        if (generalStore?.["getTableComponentThirdListData"]?.isSuccess) {
            let data = generalStore?.["getTableComponentThirdListData"]?.data;
            if (itemExpandedRow)
                setItemExpandedData(props?.getExpandSecondListData ? props.getExpandSecondListData(data) : data?.resource || [])
        }
    }, [generalStore?.["getTableComponentThirdListData"]?.isSuccess])

    const changeTab = (tab) => () => {
        setCurrentTab(tab);
    }

    const resetExpandedState = () => {
        setMainExpandedRow();
        setMainExpandedData([])
        setItemExpandedData([])
        setItemExpandedRow(false)
    }

    const expandRow = (item) => e => {
        if (props.getExpandFirstPayload) {
            if (!mainExpandedRow || mainExpandedRow.id != item.id) {
                let payload = props?.getExpandFirstPayload(item);
                let expandFirstRowItem = props.getExpandFirstRowItem ? props.getExpandFirstRowItem ({item}) : item
                dispatchHandler(dispatch, "getTableComponentSecondListRequest", payload);
                setMainExpandedRow(expandFirstRowItem);
                setMainExpandedData([])
                setItemExpandedData([])
                setItemExpandedRow(false)
            }
            else {
                setMainExpandedRow(false);
                setMainExpandedData([])
                setItemExpandedData([])
                setItemExpandedRow(false)
            }
        }
    }

    const expandSetRow = (item) => () => {
        if (props.getExpandSecondPayload) {
            if (!itemExpandedRow || itemExpandedRow.id != item.id) {
                let payload = props?.getExpandSecondPayload(item, mainExpandedRow);
                dispatchHandler(dispatch, "getTableComponentThirdListRequest", payload);
                setItemExpandedData([])
                setItemExpandedRow(item)
            }
            else {
                setItemExpandedData([])
                setItemExpandedRow(false)
            }
        }
    }

    const getExpandedData = (type) => {
        switch(type){
            case "main" : return mainExpandedData;
        }
    }

    // Required Props = :
    // downloadReport -> If available then this is always required component
    // console.log("table heder", props.tableHeadersPayload);
    // console.log("Summary Request", (props.tableDataPayload?.summaryApiUrl || props.summaryApiUrl) ? `${summaryApiName}Request` : false);
    return (
        <React.Fragment>
            {
                useRenderClassComponent(TableComponent, {
                    braedCrumps: props.braedCrumps,
                    downloadReportName : props.page == "POGRNREPORT" ? true : false,
                    actionBarConfig: {
                        search: {
                            disabled: false
                        },
                        quickFilter: {
                            disabled: generalStore?.getQuickFiltersData?.isLoading
                        },
                        tabs: props?.actionBarConfig?.tabsKey ? {
                            key: props?.actionBarConfig?.tabsKey || "status",
                            data: props?.actionBarConfig?.tabsData || {},
                            onChange: changeTab
                        } : false,
                        emailReport: {
                            disabled: false
                        },
                        downloadReport: props?.actionBarConfig?.isItemAvailable ? {
                            headerLevelModule: props?.tableHeadersPayload?.main?.key,
                            detailLevelModule: Object.values(props?.tableHeadersPayload || {})?.map(item => item?.key)?.join(),
                            detailHeadersData: Object.values(headersData || {}),
                            detailHeadersType: Object.values(headersType),
                            tableHeadersMap: props?.tableHeadersPayload
                        } : false,
                        sorting: {
                            disabled: generalStore?.getHeadersData?.isLoading
                        },
                        filters: {
                            disabled: generalStore?.getHeadersData?.isLoading,
                        },
                        ...props.actionBarConfig
                    },
                    tableConfig: {
                        columnSetting: {
                            disabled: generalStore?.getHeadersData?.isLoading || generalStore?.createHeadersData?.isLoading
                        },
                        expand: props.expandFirstKey ? {
                            expandedData: getExpandedData("main") || [],
                            disabled: false,
                            expandedRow: mainExpandedRow,
                            headersData: (props?.expandSecondKey ? props.isMultipleLevelAvailable?.(mainExpandedRow) : true) ? headersData?.[typeof (props?.expandFirstKey) == "function" ? props?.expandFirstKey?.(mainExpandedRow) : props.expandFirstKey] : headersData?.[typeof (props?.expandSecondKey) == "function" ? props.expandSecondKey(mainExpandedRow, mainExpandedRow) : props.expandSecondKey],
                            headersType: (props?.expandSecondKey ? props.isMultipleLevelAvailable?.(mainExpandedRow) : true) ? headersType?.[typeof (props?.expandFirstKey) == "function" ? props?.expandFirstKey?.(mainExpandedRow) : props.expandFirstKey] : headersType?.[typeof (props?.expandSecondKey) == "function" ? props.expandSecondKey(mainExpandedRow, mainExpandedRow) : props.expandSecondKey],
                            isLoading: generalStore?.["getTableComponentSecondListData"]?.isLoading,
                            onClick: expandRow,

                            expand: mainExpandedRow && (props.isMultipleLevelAvailable?.(mainExpandedRow) || false) ? {
                                expandedData: itemExpandedData,
                                expandedRow: itemExpandedRow,
                                headersData: headersData?.[typeof (props?.expandSecondKey) == "function" ? props.expandSecondKey(itemExpandedRow, mainExpandedRow) : props.expandSecondKey],
                                headersType: headersType?.[typeof (props?.expandSecondKey) == "function" ? props.expandSecondKey(itemExpandedRow, mainExpandedRow) : props.expandSecondKey],
                                isLoading: generalStore?.["getTableComponentThirdListData"]?.isLoading,
                                onClick: expandSetRow
                            } : false,

                            ...(typeof (expandFirstProps) == "function" ? expandFirstProps({ expandedRow: mainExpandedRow }) : expandFirstProps || {})
                        } : false,
                        ...props.tableConfig,
                    },
                    tableHeadersPayload: props.tableHeadersPayload,
                    quickFiltersPayload: props.quickFiltersPayload || {
                        attributeType: props.tableHeadersPayload?.main?.key,
                        displayName: props.tableHeadersPayload?.main?.key,
                        filterName: ""
                    },
                    tableDataPayload: props.tableDataPayload || {
                        type: 1,
                        pageNo: 1,
                        sortedBy: "",
                        sortedIn: "",
                        search: "",
                        filter: {},
                        pageSize: 10,
                        apiUrl: props.apiUrl || undefined,
                        baseUrl: props.baseUrl || undefined,
                        summaryApiUrl: props.summaryApiUrl || undefined,
                    },
                    summaryRequest: (props.tableDataPayload?.summaryApiUrl || props.summaryApiUrl) ? `${summaryApiName}Request` : false,
                    getTableDataRequest: props.getTableDataRequest ? props.getTableDataRequest : `${apiName}Request` || "",
                    getTableDataLoading: props.getTableDataLoading ? props.getTableDataLoading : states?.[storeName]?.[`${apiName}Data`]?.isLoading,
                    getTableDataSuccess: props.getTableDataSuccess ? props.getTableDataSuccess : states?.[storeName]?.[`${apiName}Data`]?.isSuccess,
                    getTableDataError: props.getTableDataError ? props.getTableDataError : states?.[storeName]?.[`${apiName}Data`]?.isError,
                    tableData: props?.tableData ? props.tableData : states?.[storeName]?.[`${apiName}Data`]?.data?.resource ?
                        {
                            data: states?.[storeName]?.[`${apiName}Data`]?.data.resource || [],
                            totalItems: states?.[storeName]?.[`${apiName}Data`]?.data?.[props.totalRecordKey || "totalCount"] || 0,
                            currentPage: states?.[storeName]?.[`${apiName}Data`]?.data?.currPage || 1,
                            maxPage: states?.[storeName]?.[`${apiName}Data`]?.data?.maxPage || 1,
                            getTableApiData: states?.[storeName]?.[`${apiName}Data`]?.data || []
                        } :
                        {
                            data: [],
                            totalItems: 0,
                            currentPage: 1,
                            maxPage: 1
                        },
                    refreshData: props.refreshData || false,
                    confirmationLoading: props.confirmationLoading || false,
                    customAction: props.customAction || false,
                    resetstate: props.resetstate,
                    tableDataPayloadUpdated: props.tableDataPayloadUpdated,
                    ref: props.tableComponentRef || null,
                    imageOrderNumber: props.imageOrderNumber,
                    breadCrumbsProps : props.breadCrumbsProps
                },
                    props.availableStores)
            }
        </React.Fragment>
    )

}