import React, { useState } from 'react'
import PerformanceDashFilters from './PerformanceDashFilters'
import { useSelector } from 'react-redux'
import { Radio } from 'antd'
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import ASMSkeleton from './skeletons/ASMSkeleton'

const PIE_CHART_COLORS = [
    "#5D5FEF", "#7667b4", "#695398", "#a063b0", "#c352b0", "#cd358b", "#f73f86", "#e15e67",
    "#ff0000", "#ff4800", "#ff9300", "#ffb11f", "#f6f600", "#d6e414", "#7acf47", "#00bc50",
    "#00984c", "#007543", "#00c8be", "#49c5ed", "#399dda"
];
const PRIMARY_FILTERS = [
    {
        entity: "site",
        key: "group1",
        value: "Client Type"
    },
    {
        entity: "pan_asm_dashboard",
        key: "region",
        value: "Region"
    },
    {
        entity: "site",
        key: "udf4",
        value: "Sales ASM"
    },
    {
        entity: "generic_item_master_report",
        key: "hl1_name",
        value: "Product Category"
    }
]
const DATA_TYPE_MAP = {
    salesQty: "Sales Quantity",
    salesValue: "Sales Value"
}
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
const currentFilterKeys = ['group1', 'region', 'udf4', 'hl1_name']

const AsmYtd = (props) => {
    const { dashboardData } = props
    const digivendStore = useSelector(state => state.digivendStore)
    const [dataType, setDataType] = useState('salesQty')

    // function to get the dashboard data
    const getDashboardData = (filtersData = {}) => {
        props.getDashboardData(filtersData)

    }
    return (
        <div className='asm-ytd'>
            <PerformanceDashFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                applyFilter={getDashboardData}
                currentFilterKeys={currentFilterKeys}
            />
            <div className="psp-radio ">
                <h1>Data Type</h1>
                <Radio.Group value={dataType} onChange={(e) => setDataType(e?.target?.value || "")}>
                    <Radio.Button value={"salesQty"}>Sales Quantity</Radio.Button>
                    <Radio.Button value={"salesValue"}>Sales Vaue</Radio.Button>
                </Radio.Group>
            </div>

            {/* graph data */}
            {digivendStore?.getARSDashboardDataData?.isLoading ? (<ASMSkeleton />) :
                (<div role="tabpanel" className="tab-pane fade" id="asm">
                    <div className="flex p-top-20 p-lr-12">
                        <div className='grid w-50 p-rgt-12'>
                            <div className="store-analytics-table-head">
                                <div className="sath-left">
                                    <h3>Month-wise {DATA_TYPE_MAP?.[dataType]}</h3>
                                </div>
                            </div>
                            <div className="store-analytics-table-body ars-graph-box">
                                {dashboardData?.monthWiseSales &&
                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart data={dashboardData?.monthWiseSales}>
                                            <XAxis dataKey="month" padding={{ left: 25, right: 25 }} />
                                            <YAxis type="number" />
                                            <CartesianGrid strokeDasharray="2" />
                                            <Legend align="right" verticalAlign="top" />
                                            <Tooltip labelStyle={{ color: "#94a5c6", fontWeight: 500 }} contentStyle={{ color: "#12203c", fontWeight: 600 }} />
                                            <Bar name={DATA_TYPE_MAP?.[dataType]} dataKey={dataType} fill={PIE_CHART_COLORS[0]}>
                                                <LabelList dataKey={dataType} position="top" />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>}
                            </div>
                        </div>
                        <div className="grid w-50 p-rgt-12">
                            <div className="store-analytics-table-head">
                                <div className="sath-left">
                                    <h3>Region-wise Store Grade Contribution %</h3>
                                </div>
                            </div>
                            <div className="store-analytics-table-body ars-graph-box">
                                {dashboardData?.storeGradeContriPer &&
                                    <ResponsiveContainer width="100%" height={400}>
                                        <PieChart>
                                            <Pie data={dashboardData?.storeGradeContriPer} cx="50%" cy="50%" dataKey="total" nameKey="grade" name="Total Contribution" innerRadius={80} labelLine={false} label={renderCustomizedLabel}>
                                                {dashboardData?.storeGradeContriPer?.map((item, index) => (
                                                    <Cell name={item?.["grade"] || ""} key={item?.grade} fill={PIE_CHART_COLORS?.[index]} />
                                                ))}
                                            </Pie>
                                            <Legend align="right" verticalAlign="middle" layout="vertical" />
                                            <Tooltip />
                                        </PieChart>
                                    </ResponsiveContainer>}
                            </div>
                        </div>
                    </div>
                    <div className="flex p-top-20 p-lr-12">
                        <div className="grid w-50 p-rgt-12">
                            <div className="store-analytics-table-head">
                                <div className="sath-left">
                                    <h3>Region-wise Section Contribution %</h3>
                                </div>
                            </div>
                            <div className="store-analytics-table-body ars-graph-box">
                                {dashboardData?.regionWiseSectionContriPer &&
                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart layout="vertical" data={dashboardData?.regionWiseSectionContriPer} stackOffset="expand">
                                            <XAxis type="number" hide={true} />
                                            <YAxis type="category" dataKey="region" />
                                            <CartesianGrid strokeDasharray="2" />
                                            <Legend align="right" verticalAlign="top" />
                                            <Tooltip />
                                            {Object.keys(dashboardData?.regionWiseSectionContriPer && dashboardData?.regionWiseSectionContriPer?.length > 0 && dashboardData?.regionWiseSectionContriPer?.[0])?.map((key, index) => key !== "region" &&
                                                <Bar dataKey={key} fill={PIE_CHART_COLORS[index]} stroke="#FFFFFF" stackId="section" />
                                            )}
                                        </BarChart>
                                    </ResponsiveContainer>}
                            </div>
                        </div>
                        <div className="grid w-50 p-rgt-12">
                            <div className="store-analytics-table-head">
                                <div className="sath-left">
                                    <h3>Month-wise Full Price & Discounted Sales Breakup</h3>
                                </div>
                            </div>
                            <div className="store-analytics-table-body ars-graph-box">
                                {dashboardData?.monthWisePriceTypeSales &&
                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart data={dashboardData?.monthWisePriceTypeSales} stackOffset="expand">
                                            <XAxis dataKey="month" padding={{ left: 25, right: 25 }} />
                                            <YAxis type="number" hide={true} />
                                            <CartesianGrid strokeDasharray="2" />
                                            <Legend align="right" verticalAlign="top" />
                                            <Tooltip />
                                            <Bar name="Full Price" dataKey="full" fill={PIE_CHART_COLORS[0]} stroke="#FFFFFF" stackId="priceType">
                                                <LabelList dataKey="full" position="center" />
                                            </Bar>
                                            <Bar name="Discounted Price" dataKey="discounted" fill={PIE_CHART_COLORS[7]} stroke="#FFFFFF" stackId="priceType">
                                                <LabelList dataKey="discounted" position="center" />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>}
                            </div>
                        </div>
                    </div>
                    <div className="p-lr-12 m-top-30">
                        <div className="store-analytics-table-head">
                            <div className="sath-left">
                                <h3>Department-wise ASM Contribution %</h3>
                            </div>
                        </div>
                        <div className="store-analytics-table-body ars-graph-box">
                            {console.log("PORT1", dashboardData?.depWiseASMContriPer)}
                            {dashboardData.depWiseASMContriPer &&
                                <ResponsiveContainer width="100%" height={500}>
                                    <PieChart>
                                        <Pie data={dashboardData?.depWiseASMContriPer} cx="50%" cy="50%" dataKey="contri" nameKey="dep" name="Total Contribution" innerRadius={80} label>
                                            {dashboardData?.depWiseASMContriPer?.map((item, index) => (
                                                <Cell name={item?.dep || ""} key={item?.grade} fill={PIE_CHART_COLORS?.[index]} />
                                            ))}
                                        </Pie>
                                        <Legend align="right" verticalAlign="middle" layout="vertical" />
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>}
                        </div>
                    </div>
                </div>
                )}
        </div>

    )
}

export default AsmYtd