/* eslint-disable no-undef */
import React from 'react'
import { GenericFormSpreadTypeComponent } from '../../../genericComponents/forms/GenericFormSpreadTypeComponent'
import '../../../styles/stylesheets/DigiVend/Comments.scss'
import Icons from '../../../assets/icons'

let SITE_FILTER = {
    key: "email",
    entity: "supplymint.users",
    code: "username",
    value: ""
}

function EmailTranscript(props) {
    // let props_ = JSON.parse(JSON.stringify(props));
    // delete props_.selectedItems;
    const inputProps = (key) => {
        switch (key) {
            case "toEmail":
            case "ccEmail":
                return {
                    viewDataType: "table-view",
                    headerKeys: {
                        multiSelectCol : {},
                        email: {
                            value: "Email",
                        },
                        username: {
                            value: "Username"
                        },
                    },
                    itemKeys: {
                        email: ["email"],
                        username: ["username"]
                    },
                    getListData: (response) => {
                        return response?.resource?.searchResultNew || [];
                    },
                    api: {
                        reduxState: "generalStore",
                        apiName: "getSearchFilters",
                        apiPayload: (params) => {
                            return {
                                "entity": SITE_FILTER.entity,
                                "key": SITE_FILTER.key,
                                "code": SITE_FILTER.code,
                                "search": params?.currentSearch || "",
                                "searchBy": params?.searchFilter || "",
                                "pageNo": params?.currentPage || 1,
                                "moduleName": "",
                                "other_data": {}
                            }
                        },
                    },
                    multiselect: {
                        selectId: "email",
                    }
                }
        }
    }

    const getInputFields = (type) => {
        switch (type) {
            case "basicDetails": return [
                { key: "email", mandatory: true, type: "dropdown", iconRight: <Icons.DownArrowNew />, label: "To Email", inputProps: () => inputProps("toEmail") }
            ]
            case "otherDetails": return [
                { key: "email", mandatory: false, type: "dropdown", iconRight: <Icons.DownArrowNew />, label: "CC Email", inputProps: () => inputProps("ccEmail") },
                { key: "subject", type: "text", label: "Subject", },
                { key: "remark", type: "textarea", label: "Remark", placeholder: "Write your remark here..." }
            ]
        }
    }

    return (
        <div className='email-transcript'>
            <GenericFormSpreadTypeComponent
                headerComponent={{
                    label: {
                        contentPrimary: "Email Transcript",
                    },
                    closeModal: props.handleEmailTranscriptModal
                }}
                finalSubmitButton={"Send Transcript"}

                inputSections={{
                    "basicDetails": {
                        inputFields: () => getInputFields("basicDetails"),
                    },
                    "otherDetails": {
                        inputFields: () => getInputFields("otherDetails"),
                    }
                }}
                closeModal={props.handleEmailTranscriptModal}

                finalSubmit={(data) => props.submitTranscript(data)}
            />
        </div>
    )
}

export default EmailTranscript