import { CONFIG } from '../../config/index';
import { AUTH_CONFIG } from '../../authConfig/index'
export const salesManagementStore = [
    {
        title: "getSoColorsData",
        actionPrefix: 'getSoColors',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.SO}/color/data`,
        }
    },
    {
        title: "getSalesCustomersDetailData",
        actionPrefix: 'getSalesCustomersDetail',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/customer/details?customerId=${payload.customerId}`,
        }
    },
    {
        title: "getSalesCustomersPricelistData",
        actionPrefix: 'getSalesCustomersPricelist',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/pricelist/value`,
        }
    },
    {
        title: "updateSalesCustomersPricelistData",
        actionPrefix: 'updateSalesCustomersPricelist',
        message: 'Request Completed Successfully',
        requestData: {
            method: "PUT",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/update/customer/pricelist`,
        }
    },
    {
        title: "updateSalesCustomersCreditLineData",
        actionPrefix: 'updateSalesCustomersCreditLine',
        message: 'Request Completed Successfully',
        requestData: {
            method: "PUT",
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/update/customer/creditline`,
        }
    },
    {
        title: "updatedBuyingPlanStatusData",
        actionPrefix: 'updatedBuyingPlanStatus',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/buying/plan/update/status`,
        }
    },
    {
        title: "getSalesCustomersData",
        actionPrefix: 'getSalesCustomers',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.type;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/get/customers?type=${payload.type}`,
        }
    },
    {
        title: "getSalesDispatchLocationData",
        actionPrefix: 'getSalesDispatchLocation',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/site/details`,
        }
    },
    {
        title: "getSalesDeliveryLocationData",
        actionPrefix: 'getSalesDeliveryLocation',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.type;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/get/delivery/dispatch/location?type=${payload.type}`,
        }
    },
    {
        title: "getSalesTransporterData",
        actionPrefix: 'getSalesTransporter',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/get/transporters`,
        }
    },
    {
        title: "getSalesAgentsData",
        actionPrefix: 'getSalesAgents',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/get/agents`,
        }
    },
    {
        title: "getViewFiltersData",
        actionPrefix: 'getViewFilters',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/get/view/filters`,
        }
    },
    {
        title: "soV2ViewProductData",
        actionPrefix: 'soV2ViewProduct',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/view/product`,
        }
    },
    {
        title : "updateDeliveryChallanStatusData",
        actionPrefix: 'updateDeliveryChallanStatus',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => {
                let url = "";
                switch(payload?.[0]?.status) { 
                    case "APPROVED": {
                        url = "/admin/so/v2/approve/delivery/challan";
                        break;
                    }
                    case "REJECTED":{
                        url = "/admin/so/v2/reject/delivery/challan"
                    }
                }
                return `${CONFIG.BASE_URL}${url}`;
            }
        }
    },
    {
        title: "retryDeliveryChallanStatusData",
        actionPrefix: 'retryDeliveryChallanStatus',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/create/checker/dc`,
        }
    },
    {
        title: "getSOStatusCountData",
        actionPrefix: 'getSOStatusCount',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/dashboard/status/count`,
        }
    },
    {
        title: "getSOPerfAnalysisData",
        actionPrefix: 'getSOPerfAnalysis',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/get/performanceAnalysis?itemLevel=${payload?.itemLevel}&itemLimit=${payload?.itemLimit}&itemType=${payload?.itemType}`,
        }
    },
    {
        title: "getSalesDashboardSummaryData",
        actionPrefix: 'getSalesDashboardSummary',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload:({...payload}) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getSalesDashboardOrderSummaryDetailData",
        actionPrefix: 'getSalesDashboardOrderSummaryDetail',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload:({...payload}) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getStatusOutstandingAmountData",
        actionPrefix: 'getStatusOutstandingAmount',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/get/outstanding/amount`,
        }
    },
    {
        title: "updateSoOrderData",
        actionPrefix: 'updateSoOrder',
        message: 'Request Completed Successfully',
        requestData: {
            isPayloadExistParams: true,
            buildUrl: (payload) => {
                let apiUrl = "";
                switch(payload?.status){
                    case "APPROVED" : {
                        apiUrl = "/admin/so/v2/approve/order";
                        break;
                    }
                    case "CANCELLED":{
                        apiUrl = "/admin/so/v2/cancel/order";
                        break;
                    }
                    case "REJECTED" : {
                        apiUrl = "/admin/so/v2/reject/order";
                        break;
                    }
                    case "DELIVERED":{
                        apiUrl = "/admin/so/v2/reject/order";
                        break;
                    }
                    case "UNAPPROVED" :{
                        apiUrl = "/admin/so/v2/unapprove/order";
                        break;
                    }
                }
                return `${CONFIG.BASE_URL}${apiUrl}`;
            }
        }
    },
    {
        title: "retrySoData",
        actionPrefix: 'retrySo',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/v2/sync/orderErp`,
        }
    },
    {
        title: "downloadSoPdfData",
        actionPrefix: 'downloadSoPdf',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}${CONFIG.SO}/download/pdf`,
        }
    },
    {
        title: "deleteSoItemsData",
        actionPrefix: 'deleteSoItems',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/admin/so/delete/order`,
        }
    },
    {
        title: "uploadSalesEditExcelData",
        actionPrefix: 'uploadSalesEditExcel',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/upload/excel`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        },
    },
    {
        title : "getSalesBagDetailsData",
        actionPrefix: 'getSalesBagDetails',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/get/bag/product`,
        }
    },
    {
        title: "soGetSummaryData",
        actionPrefix: 'soGetSummary',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.uniqueKey;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/get/summary`,
        }
    },
    {
        title: "soV2AddBagData",
        actionPrefix: 'soV2AddBag',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/add/bag`,
        }
    },
    {
        title: "soV2UpdateBagProductData",
        actionPrefix: 'soV2UpdateBagProduct',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/update/bag/product`,
        }
    },
    {
        title: "getSizeColorDataScanData",
        actionPrefix: 'getSizeColorDataScan',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/get/size/color/data/scan`,
        }
    },
    {
        title: "getSalesDcDetailData",
        actionPrefix: 'getSalesDcDetail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/get/order/details`,
        }
    },
    {
        title: "soV2SaveOrderData",
        actionPrefix: 'soV2SaveOrder',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/save/order`,
        }
    },
    {
        title: "soV2CopyOrderData",
        actionPrefix: 'soV2CopyOrder',
        message: 'Indent Copied Successfully!',
        errorMessage : "Failed to copy the sales indent!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/copy/order`,
        }
    },
    {
        title: "scanDcItemData",
        actionPrefix: 'scanDcItem',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/scan/item`,
        }
    },
    {
        title: "chooseDcItemData",
        actionPrefix: 'chooseDcItem',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/choose/item`,
        }
    },
    {
        title: "soV2RemoveProductData",
        actionPrefix: 'soV2RemoveProduct',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/remove/product`,
        }
    },
    {
        title: "convertBuyingPlanToIndentData",
        actionPrefix: 'convertBuyingPlanToIndent',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/buying/plan/convert/to/indent`,
        }
    },
    {
        title: "copyBuyingPlanData",
        actionPrefix: 'copyBuyingPlan',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/buying/plan/copy/buyingPlan`,
        }
    },
    {
        title: "getAllPriceListData",
        actionPrefix: 'getAllPriceList',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/getAll/item/pricelist`,
        }
    },
    {
        title: "soItemSetData",
        actionPrefix: 'soItemSet',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/item/set`,
        }
    },
    {
        title: "soCreateItemSetData",
        actionPrefix: 'soCreateItemSet',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/create/item/set`,
        }
    },
    {
        title: "getErpDcCodeData",
        actionPrefix: 'getErpDcCode',
        message: 'Request Completed Successfully',
        requestData: {
            method:"GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/data/config?type=ERP_DC_DOC_CODE_CONFIG`,
        }
    },
    {
        title: "createDcData",
        actionPrefix: 'createDc',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/v2/create/delivery/challan`
        }
    },
    {
        title: "updateDeliveryDateData",
        actionPrefix: 'updateDeliveryDate',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/extend/delivery/validity`
        }
    },
]
