import React, { useState } from 'react'
import PerformanceDashFilters from './PerformanceDashFilters'
import { useSelector } from 'react-redux'
import { Radio, Tooltip as AntdTooltip } from 'antd'
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, XAxis, YAxis, } from 'recharts'
import { Tooltip } from 'recharts'
import { ComposableMap, Geographies, Geography, Marker, Annotation } from 'react-simple-maps'
import MapTooltip from './MapTooltip'
import PANIndiaSkeleton from './skeletons/PANIndiaSkeleton';

const INDIA_TOPO_JSON = require('../../../animationsJSON.js/India.topo.json');
const PRIMARY_FILTERS = [
    {
        entity: "site",
        key: "group1",
        value: "Client Type"
    },
    {
        entity: "pan_asm_dashboard",
        key: "region",
        value: "Region"
    },
    {
        entity: "site",
        key: "udf4",
        value: "Sales ASM"
    },
    {
        entity: "site",
        key: "udf2",
        value: "Store Grade"
    },
    {
        entity: "generic_item_master_report",
        key: "hl1_name",
        value: "Product Category"
    }
]
const currentFilterKeys = ['group1', 'region', 'udf4', 'udf2', 'hl1_name']

const PANIndia = (props) => {
    const { dashboardData, colorScale } = props
    const digivendStore = useSelector(state => state.digivendStore)

    const [dataType, setDataType] = useState('salesQty')

    console.log('dashboarddataof graph', dashboardData);

    props.getDataType(dataType)

    // function to get the dashboard data
    const getDashboardData = (filtersData = {}) => {
        props.getDashboardData(filtersData)
    }

    const DATA_TYPE_MAP = {
        salesQty: "Sales Quantity",
        salesValue: "Sales Value"
    }
    const PIE_CHART_COLORS = [
        "#5D5FEF", "#7667b4", "#695398", "#a063b0", "#c352b0", "#cd358b", "#f73f86", "#e15e67",
        "#ff0000", "#ff4800", "#ff9300", "#ffb11f", "#f6f600", "#d6e414", "#7acf47", "#00bc50",
        "#00984c", "#007543", "#00c8be", "#49c5ed", "#399dda"
    ];
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div className='ps-pan-india'>
            <PerformanceDashFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                applyFilter={getDashboardData}
                currentFilterKeys={currentFilterKeys}
            />

            <div className="psp-radio">
                <h1>Data Type</h1>
                <Radio.Group value={dataType} onChange={(e) => setDataType(e?.target?.value || "")}>
                    <Radio.Button value={"salesQty"}>Sales Quantity</Radio.Button>
                    <Radio.Button value={"salesValue"}>Sales Vaue</Radio.Button>
                </Radio.Group>
            </div>

            {/* graphs */}
            {digivendStore?.getARSDashboardDataData?.isLoading ? (<PANIndiaSkeleton />)
                : (
                    <div role="tabpanel" className="tab-pane fade" id="panIndia">
                        <div className="flex p-top-20 p-lr-12">
                            <div className='grid w-50 p-rgt-12'>
                                <div className="store-analytics-table-head">
                                    <div className="sath-left">
                                        <h3>Month-wise {DATA_TYPE_MAP?.[dataType]}</h3>
                                    </div>
                                </div>
                                {console.log("PORT2", dashboardData?.monthWiseSales)}
                                <div className="store-analytics-table-body ars-graph-box">
                                    {dashboardData?.monthWiseSales &&
                                        <ResponsiveContainer width="100%" height={400}>
                                            <AreaChart data={dashboardData?.monthWiseSales}>
                                                <XAxis dataKey="month" padding={{ left: 25, right: 25 }} />
                                                <YAxis type="number" />
                                                <CartesianGrid strokeDasharray="2" />
                                                <Area dataKey={dataType} name={DATA_TYPE_MAP?.[dataType]} stroke={PIE_CHART_COLORS[0]} fill={PIE_CHART_COLORS[0]} label />
                                                <Legend align="right" verticalAlign="top" />
                                                <Tooltip labelStyle={{ color: "#94a5c6", fontWeight: 500 }} contentStyle={{ color: "#12203c", fontWeight: 600 }} />
                                            </AreaChart>
                                        </ResponsiveContainer>}
                                </div>
                            </div>
                            <div className="grid w-50">
                                <div className="store-analytics-table-head">
                                    <div className="sath-left">
                                        <h3>Region-wise {DATA_TYPE_MAP?.[dataType]}</h3>
                                    </div>
                                </div>
                                {console.log("PORT1", dashboardData?.regionWiseSales)}
                                <div className="store-analytics-table-body ars-graph-box">
                                    {dashboardData?.regionWiseSales &&
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie data={dashboardData?.regionWiseSales} cx="50%" cy="50%" dataKey={dataType} nameKey="region" name={DATA_TYPE_MAP[dataType]} innerRadius={80} labelLine={false} label={renderCustomizedLabel}>
                                                    {dashboardData?.regionWiseSales.map((item, index) => (
                                                        <Cell name={item?.region || ""} key={item?.grade} fill={PIE_CHART_COLORS?.[index]} />
                                                    ))}
                                                </Pie>
                                                <Legend align="right" verticalAlign="middle" layout="vertical" />
                                                <Tooltip labelStyle={{ color: "#94a5c6", fontWeight: 500 }} contentStyle={{ color: "#12203c", fontWeight: 600 }} />
                                            </PieChart>
                                        </ResponsiveContainer>}
                                </div>
                            </div>
                        </div>
                        <div className="flex p-lr-12">
                            <div className='grid w-50 p-rgt-12'>
                                <div className="store-analytics-table-head">
                                    <div className="sath-left">
                                        <h3>Store Grade Contribution - {DATA_TYPE_MAP[dataType]}</h3>
                                    </div>
                                </div>
                                <div className="store-analytics-table-body ars-graph-box">
                                    {dashboardData?.storeGradeContri &&
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={dashboardData?.storeGradeContri} stackOffset="expand">
                                                <XAxis dataKey="grade" padding={{ left: 25, right: 25 }} />
                                                <YAxis type="number" hide={true} />
                                                <CartesianGrid strokeDasharray="2" />
                                                <Legend align="right" verticalAlign="top" />
                                                <Tooltip labelStyle={{ color: "#94a5c6", fontWeight: 500 }} contentStyle={{ color: "#12203c", fontWeight: 600 }} />
                                                {Object.keys(dashboardData?.storeGradeContri?.[0])?.map((key, index) => key !== "grade" &&
                                                    <Bar dataKey={key} fill={PIE_CHART_COLORS?.[index]} stroke="#FFFFFF" stackId="storeGrade" />
                                                )}
                                            </BarChart>
                                        </ResponsiveContainer>}
                                </div>
                            </div>
                            <div className="grid w-50">
                                <div className="store-analytics-table-head">
                                    <div className="sath-left">
                                        <h3>City-wise Contribution %</h3>
                                    </div>
                                </div>
                                <div className="store-analytics-table-body ars-graph-box">
                                    {dashboardData?.cityWiseContriPer &&
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={dashboardData?.cityWiseContriPer} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis type="category" dataKey="city" />
                                                <CartesianGrid strokeDasharray="2" />
                                                <Legend align="right" verticalAlign="top" />
                                                <Tooltip labelStyle={{ color: "#94a5c6", fontWeight: 500 }} contentStyle={{ color: "#12203c", fontWeight: 600 }} />
                                                <Bar dataKey="contri" name="Contribution %" fill={PIE_CHART_COLORS?.[0]} />
                                            </BarChart>
                                        </ResponsiveContainer>}
                                </div>
                            </div>
                        </div>
                        <div className="p-lr-12 m-top-10">
                            <div className="store-analytics-table-head">
                                <div className="sath-left">
                                    <h3>Month-wise ASP & MD%</h3>
                                </div>
                            </div>
                            <div className="store-analytics-table-body ars-graph-box">
                                {dashboardData?.monthWiseASP &&
                                    <ResponsiveContainer width="100%" height={400}>
                                        <LineChart data={dashboardData?.monthWiseASP}>
                                            <XAxis dataKey="month" padding={{ left: 25, right: 25 }} />
                                            <YAxis yAxisId="left" label={{ value: "ASP", angle: -90, position: "insideLeft" }} />
                                            <YAxis yAxisId="right" orientation="right" label={{ value: "MD%", angle: 90, position: "insideRight" }} />
                                            <CartesianGrid strokeDasharray="2" />
                                            <Line type="monotone" dataKey="asp" stroke={PIE_CHART_COLORS?.[0]} name="ASP" yAxisId="left">
                                                <LabelList dataKey="asp" position="top" />
                                            </Line>
                                            <Line type="monotone" dataKey="md" stroke={PIE_CHART_COLORS?.[7]} name="MD%" yAxisId="right">
                                                <LabelList dataKey="md" position="top" />
                                            </Line>
                                            <Line type="monotone" dataKey="ly_asp" stroke="#6bf3e3" name="Last Year ASP" yAxisId="left">
                                                <LabelList dataKey="ly_asp" position="top" />
                                            </Line>
                                            <Line type="monotone" dataKey="ly_md" stroke="#ffaf4f" name="Last Year MD%" yAxisId="right">
                                                <LabelList dataKey="ly_md" position="top" />
                                            </Line>
                                            <Legend align="right" verticalAlign="top" />
                                            <Tooltip labelStyle={{ color: "#94a5c6", fontWeight: 500 }} contentStyle={{ color: "#12203c", fontWeight: 600 }} />
                                        </LineChart>
                                    </ResponsiveContainer>}
                            </div>
                        </div>
                        <div className="p-lr-12 m-top-30">
                            <div className="store-analytics-table-head !relative">
                                <div className="sath-left">
                                    <h3>Region-wise Contribution % - YTD</h3>
                                </div>
                            </div>
                            <div className="store-analytics-table-body ars-graph-box !relative" data-tip="" id="mapMainDiv">
                                {dashboardData?.regionWiseContriPer &&
                                    <ComposableMap
                                        projectionConfig={{
                                            scale: 280,
                                            center: [78.9629, 22.5937]
                                        }}
                                        projection="geoMercator"
                                        width={200}
                                        height={150}
                                        data-tip=""
                                    >
                                        <Geographies geography={INDIA_TOPO_JSON}>
                                            {({ geographies }) =>
                                                geographies.map(geo => {
                                                    const current = dashboardData?.regionWiseContriPer?.find(s => s?.id === geo?.id);
                                                    return (<>
                                                        <AntdTooltip overlayClassName="button-tooltip"
                                                            title={<MapTooltip id={"mapMainDiv"} tooltipContent={current} />}
                                                            color='black'
                                                        >
                                                            <Geography
                                                                key={geo?.rsmKey}
                                                                geography={geo}
                                                                fill={current ? colorScale(current?.value) : "#EFECFE"}
                                                                style={{
                                                                    fill: "#7862F9",
                                                                    hover: {
                                                                        fill: "#7862F9",
                                                                        transition: "all 250ms"
                                                                    }
                                                                }}
                                                            />
                                                        </AntdTooltip>
                                                    </>
                                                    );
                                                })
                                            }
                                        </Geographies>
                                        {dashboardData?.regionWiseContriPer?.map(item =>
                                            item?.coordinates ?
                                                <>
                                                    <Marker coordinates={item?.coordinates}>
                                                        <text textAnchor="middle" fill="#000" fontSize="2">
                                                            {item?.value}%
                                                        </text>
                                                    </Marker>
                                                </> :
                                                null
                                        )}
                                    </ComposableMap>}
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default PANIndia