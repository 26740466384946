import React, { useEffect, useState } from 'react'
import GenericFormHeaderComponent from '../../../genericComponents/forms/GenericFormHeaderComponent'
import LoginPasswordInput from '../LoginPasswordInput'
import GenericFormFooterComponent from '../../../genericComponents/forms/GenericFormFooterComponent'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import { parseJwt } from '../../../helper/genericFunction'
import { useDispatch, useSelector } from 'react-redux'
import { setSessionStorageItems, setLocalStorageItems } from '../LoginApiResponse'
import '../../../styles/stylesheets/home/SessionExpired.scss'

const SessionExpiredModal = (props) => {
    const [password, setPassword ] = useState("");
    const dispatch = useDispatch();
    let tokenData = parseJwt(sessionStorage.getItem("token"))
    const authStore = useSelector(state => state.authStore);

    const loginUser = async(data) =>{
        await setSessionStorageItems(data);
        await setLocalStorageItems(data, dispatch);
    }

    useEffect(()=>{
        if(authStore?.["refreshTokenData"]?.isSuccess){
            loginUser(authStore?.["refreshTokenData"]?.data?.resource || {});
            props.closeModal();
        }
    },[authStore?.["refreshTokenData"]?.isSuccess])

    const handleChange = (type) => {
        switch(type){
            case "submit" : {
                if(password?.trim() != "" && password?.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/)){
                    let loginData = {
                        uType: tokenData?.uType || "",
                        username: btoa(tokenData?.prn),
                        password: btoa(password?.trim()),
                        hash: "true",
                    };
                    dispatchHandler(dispatch, "refreshTokenRequest", loginData);
                }
                break;
            }
        }
    }

    return (
        <>
            <div className='backdrop'></div>
            <div className='session-expired'>
                <GenericFormHeaderComponent
                    label={{
                        contentPrimary: "Your session has expired",
                        contentSecondry: " Please try to relogin by entering password. Don’t worry, we kept all of your filters and breakdowns in place."
                    }}
                    
                    closeModal={props.closeModal}
                />

                <LoginPasswordInput
                    password={password}
                    setPassword={setPassword}
                    submit={() => handleChange("submit")}
                />

                <GenericFormFooterComponent
                    buttons = {
                        [
                            // {type : "default", label :"Close", onClick : props.closeModal},
                            {type: "primary", label :"Relogin", disabled: () => (password?.trim() == "" || !password?.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/)), onClick : () => handleChange("submit")}
                        ]
                    }
                />

            </div>
        </>
    )
}

export default SessionExpiredModal