import React from 'react'

const GenericWizardComponent = (props) => {
    const {wizardSteps, currentStep} = props;


    return (
        <ul class={`flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base`}>
            {Object.keys(wizardSteps || {})?.map((key, index) => {
                let item = wizardSteps[key];
                let mainClass = wizardSteps[key]?.mainClass
                return(
                    <li className= {` ${mainClass}`}>
                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                            {index < currentStep ?  <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.97559" cy="7.66406" r="7.5" fill="#5D5FEF"/>
                            </svg> : <div class="me-2">{index+1}</div>}
                            {typeof(item?.label) == "function" ? item?.label() : item?.label}
                        </span>
                    </li>
                )
            })}
        </ul>

    )
}

export default GenericWizardComponent