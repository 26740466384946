import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import IconsPath from '../assets/icons/index'
import NoDataFound from './NoDataFound'
import { dispatchHandler } from '../helper/apiHelperFunctions.js'
import { useDispatch, useSelector } from 'react-redux';
import ListComponentSkeltonLoader from '../components/skeletons/ListComponentSkeltonLoader.jsx';
import GenericSpinnerLoader from '../loaders/GenericSpinnerLoader.jsx'
import $, { data } from 'jquery'
import GenericCustomButton from './GenericCustomButton.jsx'

const ListComponent = (props) => {
	const OS = navigator.userAgentData && navigator.userAgentData.platform;
	// label: It will contain the props for the label section.
	// search: It will have the props for left, right component of search as well as search options
	// totalRecords: It can be the key to access total records or it can be the function which is going to return the total records.
	// multiselectOptions: Key to identify to show the multiselect type of list options or not.
	// headerKeys are the headers of the table -> min headers will be 1 and each header keys can have 3 itemKeys (i.e three level of item) -> We will not show header is its length is only one 
	// itemKeys are the keys for rendering the list data.
	// hoverKey : To differentiate between the listComponentHover class of multiple list component
	// useKeyboardShortcuts : To disable the keyboard shortcuts
	// updateListdataWithInitialSelectedData : To update the intial list data with the selected data as some date may not be present inside the list data.
	// isListItemDisabled : to check if the item inside the list component is disabled or not
	const { label, search, totalRecords, api, headerKeys, itemKeys, multiselect, rowLeftComponent, rowRightComponent, modalMainClass, viewDataType, hoverKey = "", useKeyboardShortcuts = true, updateListdataWithInitialSelectedData=false, isListItemDisabled } = props;

	let hoverClassName = `listComponentHover${hoverKey}`

	// List Component States
	const [listData, setListData] = useState(props?.api ? [] : props?.getListData?.());
	const [selectedData, setSelectedData] = useState(multiselect ? multiselect.selectedData || {} : {});
	const [currentSearch, setCurrentSearch] = useState("");
	const [focusOn, setFocusOn] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [maxPage, setMaxPage] = useState(0);
	const [searchFilter, setSearchFilter] = useState(props.search?.searchOptions ? Object.keys(props.search?.searchOptions || {})?.[0] : "");
	const [mouseMovement, setMouseMovement] = useState(false);

	// useEffect(() => {
	// 	setSelectedData(multiselect?.selectedData || {});
	// }, [multiselect?.selectedData])

	// State to store the index of the currently focused element
	const [focusedIndex, setFocusedIndex] = useState(null);

	const dispatch = useDispatch();
	let observerTarget = useRef();

	let apiReduxData = useSelector(state => state?.[api?.["reduxState"]]);

	// Function : To get the ref for the last element of the list.
	const lastListElementRef = (node) => {
		if (apiReduxData?.[`${props?.api?.apiName}Data`]?.isLoading) return;
		// console.log("List data", listData);
		if (listData && listData?.length == 0) return;
		if (observerTarget?.current) observerTarget.current.disconnect();
		observerTarget.current = new IntersectionObserver(entries => {
			if (entries?.[0].isIntersecting) {
				// So that the state current page and max page state gets updated
				moveNextPage();
			}
		})
		if (node) observerTarget.current.observe(node);
	}

	useEffect(() => {
		// Set up the keyboard shortcuts 
		$("input#listComponentInputSearch")?.trigger("focus");
	}, [])

	// TO apply tge jeyvoard shortcuts to the list.
	useLayoutEffect(() => {
		document.addEventListener("keydown", keyboardShortcuts);
		return (() => {
			document.removeEventListener("keydown", keyboardShortcuts)
		});
	}, [listData, selectedData, focusedIndex])

	// Function to handle the keyboard shortcuts
	const keyboardShortcuts = (e) => {
		const dataLength = listData?.length || 0;
		if (dataLength) {
			let hoveredElem = $(`.${hoverClassName}.hover`);
			if (e.key === "ArrowDown" && useKeyboardShortcuts) {
				e.preventDefault();
				if (focusedIndex == null) {
					setFocusedIndex(0);
				}
				else {
					if (focusedIndex < dataLength - 1) {
						setFocusedIndex(focusedIndex + 1);
					} else {
						setFocusedIndex(0);
					}
				}
			}
			else if (e.key === "ArrowUp" && useKeyboardShortcuts) {
				e.preventDefault();
				if (focusedIndex == null) {
					setFocusedIndex(dataLength - 1);
				}
				else {
					if (focusedIndex > 0) {
						setFocusedIndex(focusedIndex - 1);
					} else {
						setFocusedIndex(dataLength - 1);;
					}
				}
			}
			else if (e.key === "Enter" && e.shiftKey && useKeyboardShortcuts) {
				hoveredElem.trigger("click");
			} else if (e.keyCode === 70 && (OS === "macOS" ? e.metaKey : e.ctrlKey) && useKeyboardShortcuts) {
				e.preventDefault();
				$("input#listComponentInputSearch")?.trigger("focus");
			}
			else if (e.key === "esc") {
				props?.closeModal();
			}
		}
	}

	useEffect(() => {
		// To move the hovered element to the list
		!mouseMovement && $(`.${hoverClassName}.hover`)[0]?.scrollIntoViewIfNeeded();
		mouseMovement && setMouseMovement(false);
	}, [selectedData, listData, focusedIndex])


	const handleMouseMove = (e, index) => {
		useKeyboardShortcuts && setMouseMovement(true);
		useKeyboardShortcuts && setFocusedIndex(index);
	}

	{
		// Section to handle the useEffect for the api of the component
		useEffect(() => {
			if (apiReduxData?.[`${props?.api?.apiName}Data`]?.isSuccess) {
				let allStates = getComponentStates();
				let allFunctions = getComponentFunctions();
				let response = apiReduxData?.[`${props?.api?.apiName}Data`]?.data;
				setMaxPage(prev => props.api?.getMaxPage ? props?.api?.getMaxPage(allStates, allFunctions, response) : response?.["maxPage"]);
				updateListData(response, currentPage == 1 ? "reset" : "update");
			}
		}, [props?.api ? apiReduxData?.[`${props?.api?.apiName}Data`]?.isSuccess : {}])

		useEffect(() => {
			if (apiReduxData?.[`${props?.api?.apiName}Data`]?.isError) {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
			currentPage > 1 && setCurrentPage(prev => prev - 1);
		}, [props?.api ? apiReduxData?.[`${props?.api?.apiName}Data`]?.isError : {}])

	}
	{
		// Section to handle the refresh triggered from the parent component
		useEffect(() => {
			if (props.refreshData) {
				if (api) {
					if (currentPage == 1) {
						getTableData();
					} else {
						setCurrentPage(1);
					}
				} else {
					updateListData([], "reset")
				}
			}
		}, [props.refreshData])
	}

	{
		// Section to handle all the useEffects of the List Component

		useEffect(() => {
			// If the list is an api type of list then we call the api here otherwise update the list data
			if (api) {
				getTableData();
			} else {
				updateListData([], "reset");
			}

		}, [currentPage])

	}

	const getTableData = (params = {}) => {
		const {
			search = currentSearch || "", // Getting the current search value
		} = params;

		let payload = props?.api?.apiPayload({
			currentSearch: `${search}`,
			currentPage: currentPage,
			searchFilter: searchFilter
		})
		console.log("payload", payload);
		dispatchHandler(dispatch, `${props?.api?.apiName}Request`, payload);
	}

	const moveNextPage = () => {
		if (Number(currentPage || "") < Number(maxPage || "") && listData?.length > 0) {
			let pageNo = currentPage;
			pageNo += 1;
			setCurrentPage(pageNo);
		}
	}


	// Function => To Provide all the states of the component
	const getComponentStates = () => {
		return {
			listData, currentSearch, currentPage, maxPage
		}
	}

	// Function => To Provide all the functions of the list component.
	const getComponentFunctions = () => {
		return {
			isAllListDataSelected,
		}
	}

	// Function => To set the list data from the function provided by the parent component
	const updateListData = (data, key) => {
		let updatedData = props?.getListData?.(data) || [];

		switch (key) {
			case "reset": {
				setListData(updateListdataWithInitialSelectedData ? [...Object.values(selectedData || {}), ...updatedData] : updatedData)
				break;
			}
			case "update": {
				setListData([...listData, ...updatedData]);
				break;
			}
		}
	}

	// Function => To check if all the list data is selected or not
	const isAllListDataSelected = () => {
		// It is going to return a boolean value.
		let id = props?.multiselect?.selectId || "id";
		return listData?.every(item => selectedData?.[item?.[id]]);
	}

	const renderHeaderComponent = () => {
		return (
			// /* The label and header component 
			label && (typeof (label) == "function" ? label() : <div className='modal-header-section'>

				<div className='mhs-left'>
					{/* Render the Left Side component of the label data */}
					{label.leftComponent && <div>{
						typeof (label.leftComponent) == "function" ? label.leftComponent() : label.leftComponent
					}</div>}

					{/* Render the actual labelData  */}
					{label.content && <h4>{typeof (label.content) == "function" ? label?.content() : label?.content}</h4>}
					{label.contentSecondry && <h4>{typeof (label.contentSecondry) == "function" ? label?.contentSecondry?.() : label?.contentSecondry}</h4>}
				</div>

				<div className='mhs-right'>
					{/* Render the right component in the header section */}
					{label.rightComponent && <>
						{typeof (label.rightComponent) == "function" ? label.rightComponent() : label.rightComponent}
					</>}
					{/* Render the close options at the end of the label data */}
					{props.closeModal && <button className='m-lft-10' onClick={() => { props.closeModal() }}><IconsPath.ModalCloseIcon /></button>}
				</div>
			</div>)
		)
	}

	const renderSearchComponent = () => {
		return (
			// The Search Component to render the search with left component with right component.
			search && (
				typeof (search) == "function"
					? search() :
					<div className={`modal-search-area ${!label && 'p-tb-10'}`}>
						{/* Render the left component of the input field */}
						<div className='msa-left'>
							{typeof (search.leftComponent) == "function" ? search.leftComponent() :
								search.leftComponent
							}

							{/* Render the input component to search through the data. */}
							<div className={`msa-input-outer ${focusOn === "searchInput" && "focusOn"}`}>
								{search?.isSearchIconRequire &&
									<span className='msa-search-icon'>
										<IconsPath.SearchIcon />
									</span>}

								<input
									id="listComponentInputSearch"
									placeholder={search.placeholder}
									autoComplete='off'
									type='text' value={currentSearch}
									onFocus={() => { setFocusOn("searchInput") }}
									onBlur={() => { setFocusOn("") }}
									onChange={(e) => { handleChange("listComponentSearch", e.target.value, e) }}
									onKeyDown={(e) => { (!e.shiftKey) && handleChange("listComponentSearch", e.target.value, e) }}
								/>
								{/* The clear option for the input field. */}
								{currentSearch && <button className='msaio-clear'
									onClick={(e) => {
										handleChange("listComponentSearch", "", { ...e, key: "Enter" })
									}}
								><IconsPath.ModalCloseIcon /></button>}
							</div>
						</div>

						{/* Render the custom search options component */}
						{search?.searchOptions && (typeof search?.searchOptions === 'function' ? search?.searchOptions() : (
							<div className='msa-right'>
								<label>{`${props?.search?.searchOptionLabel || "Select Search Type"}`}</label>
								<select value={searchFilter} name="cars" id="cars" onChange={(e) => { handleChange("listComponentSearchType", "", e) }}>
									{Object.keys(search?.searchOptions || {})?.map((key, index) => {
										return <option key={`listDataSearchOption${index}`} value={key}>{search?.searchOptions?.[key]}</option>
									})}
								</select>
							</div>
						))}

						{/* Render the right comoponent of the input field */}
						{typeof (search.rightComponent) == "function" ? search.rightComponent() :
							search.rightComponent
						}
					</div>
			)
		)
	}

	const renderTotalRecords = () => {
		let states = getComponentStates();
		// Render the total Records of the data if the total records keys is provided.
		return props.totalRecords && (
			// In second case the total records is going to be a key.
			<div className='list-modal-total-records'>
				<h3>Total Records :&nbsp;</h3><span>{typeof (totalRecords) == "function" ? totalRecords(states) : totalRecords(states)}</span>
			</div>
		)
	}

	const renderMultiSelectOptions = () => {
		// Render the See Selected Data and Deselect all functionality in case we have multiselect functionality.
		return (
			props.multiselectOptions && (
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

					<div style={{ display: "flex", flexDirection: "row" }} >
						<input type='checkbox' />
						<h4>Show Selected Items</h4>
					</div>

					<button>
						<h4>Deselect All</h4>
					</button>

				</div>
			)
		)
	}

	const renderListData = () => {
		if (props.api) {
			if (currentPage == 1 && apiReduxData?.[`${props?.api?.apiName}Data`]?.isLoading) {
				return false;
			}
		}
		return true;
	}

	const renderListTableData = (config) => {
		const { hideListData = false, hideSelectedData = false, hideSelectAll = false, hideLoader = false } = config || {};

		// Render the table of the list data.
		let id = props.multiselect ? props.multiselect?.selectId : "id";
		let selectedItemsLength = Object.keys(selectedData || {})?.length;
		const allStates = getComponentStates();
		const allFunctions = getComponentFunctions();
		return (
			<div className='lcs-inner'>
				<div className={viewDataType === "table-view" ? `list-component-table-view ${(props?.api ? (!apiReduxData?.[`${api?.apiName}Data`]?.isLoading && listData?.length == 0 && selectedItemsLength == 0) : listData?.length == 0) && "lcgv-data"}` : "list-component-normal-view"}>
					{/* We will render the thead of the table when headerKeys length is > 1 */}
					{Object.keys(headerKeys || {})?.length > 1 &&
						<div id="list_component_main_li" className='lcv-table-head'>
							<ul className="lcvth-row">
								{Object.keys(headerKeys || {})?.map((headerKey, headerIndex) => {
									if (headerKey === "multiSelectCol" && !hideSelectAll) {
										return (
											<>
												{props.multiselect && (listData?.length > 0 || selectedItemsLength > 0) && <li onClick={() => handleChange("selectAll")} key={`mdlr-li listComponentSelectAll ${headerIndex}`}>
													<div className='mdlr-select-all'>
														{isAllListDataSelected() ? <IconsPath.SelectedItemIcon /> : <IconsPath.DeselectedItemIcon />}
														{/* <label>Select All</label> */}
													</div>
												</li>}
											</>
										)
									}
									else {
										return (
											<li key={`${headerKey}_${headerIndex}`}>{typeof (headerKeys?.[headerKey]?.value) == "function" ? headerKeys?.[headerKey]?.value() : <label>{headerKeys?.[headerKey]?.value || ""}</label>}</li>
										)
									}
								})}
							</ul>
						</div>
					}

					{/* Render the body of the table component. */}
					{renderListData() && <div className='modal-data-list-render'>
						{!hideSelectedData && (props.multiselect && Object.values(selectedData || {})?.map((item, index) => {
							// Render the list component row data
							return props?.renderCustomListCompleteItem ? props?.renderCustomListCompleteItem(allStates, allFunctions, item) : <li id="list_component_main_li" onClick={(e) => handleChange("selectItem", item)} onMouseLeave={(e) => handleMouseMove(e, null)} onMouseEnter={(e) => handleMouseMove(e, Number(index))} data-index={index} className={`mdlr-li ${hoverClassName} ${focusedIndex === index ? "hover" : ""}`} key={index}>
								{props?.renderCustomListItem ? props?.renderCustomListItem(allStates, allFunctions, item) : renderTableRowData(item)}
							</li>
						}))}

						{
							!hideListData && <>

								{listData?.length == 0 && <NoDataFound />}

								{listData?.length > 0 && listData?.filter(item => !selectedData?.[item?.[id]])?.map((item, index) => {
									// Render the list component row data
									return props?.renderCustomListCompleteItem ? props?.renderCustomListCompleteItem(allStates, allFunctions, item) : <li id="list_component_main_li" onMouseLeave={(e) => handleMouseMove(e, null)} onMouseEnter={(e) => handleMouseMove(e, Number(index) + Number(selectedItemsLength))} data-index={Number(index) + Number(selectedItemsLength)} className={`mdlr-li ${isListItemDisabled?.({item}) && "mdlr-li-disabled"}  ${hoverClassName} ${focusedIndex === (Number(index) + Number(selectedItemsLength)) ? "hover" : ""}`} onClick={() => !isListItemDisabled?.({item}) && handleChange("selectItem", item)} key={`listDataRow${index}`}>
										{props?.renderCustomListItem ? props?.renderCustomListItem(allStates, allFunctions, item) : renderTableRowData(item)}
									</li>
								})}

								<td colSpan="100" style={{ width: "100%", height: "1px", backgroundColor: "red", visibility: "hidden", opacity: 0 }} ref={lastListElementRef}></td>
							</>
						}
					</div>}

					{!hideLoader &&
						props?.api && apiReduxData?.[`${api?.apiName}Data`]?.isLoading && (
							(currentPage <= 1) ? <ListComponentSkeltonLoader numberOfCols={Object.keys(headerKeys)?.length || 1} numberOfRows={Object.keys(itemKeys)?.some(key => itemKeys?.[key]?.length > 1) ? 2 : 1} /> :
							<tr>
								<td colSpan='100'>
									<GenericSpinnerLoader />
								</td>
							</tr>
						)}
				</div>
			</div>
		)
	}

	const renderTableRowData = (data) => {
		let id = props.multiselect ? props.multiselect?.selectId : "id";
		const allStates = getComponentStates();
		const allFunctions = getComponentFunctions();
		// Function to render all the row level data of the table.
		return (
			<>
				{/* Render the left icon to render the data */}
				{/* In case of multiselect data this is going to be a select icon */}

				{/* {props.multiselect && ((selectedData?.[data?.[id]]) ? <IconsPath.SelectedItemIcon /> : <IconsPath.DeselectedItemIcon />)} */}

				{/* else render the leftComponent */}
				{rowLeftComponent && (typeof (rowLeftComponent) == "function" ? rowLeftComponent(allStates, allFunctions, data) : rowLeftComponent)}
				{Object.keys(headerKeys || {})?.map((headerKey, headerIndex) => {
					if (headerKey === "multiSelectCol") {
						return (
							<>
								{props.multiselect && ((selectedData?.[data?.[id]]) ? <div className='mdlr-col'>
									<IconsPath.SelectedItemIcon />
								</div> :
									<div className='mdlr-col'>
										<IconsPath.DeselectedItemIcon />
									</div>)}
							</>
						)
					}
					else {
						// Render each td according to each header Key item;
						return <div className={`mdlr-col list-col-${Object.keys(headerKeys || {}).length}`} key={headerIndex}>
							{/* Render each itemKey data inside the td */}
							{
								// We need to give the fontsize according to itemIndex 0, 1, 2;
								itemKeys?.[headerKey]?.map((item, itemIndex) => {
									return typeof (item) == "function" ? item(allStates, allFunctions, data) : <span className='mdlr-multiple-row'>{data?.[item]}</span>
								})
							}
						</div>

					}
				})}
				{/* else render the rightComponent */}
				{rowRightComponent && (typeof (rowRightComponent) == "function" ? rowRightComponent(allStates, allFunctions, data) : rowRightComponent)}
			</>
		)
	}

	// Functin: To handle all the changes inside this component
	const handleChange = (type, data, e) => {
		let id = props?.multiselect?.selectId || "id";
		switch (type) {
			case "selectItem": {
				if (props.multiselect) {
					let updatedSelectedData = { ...selectedData };
					if (updatedSelectedData?.[data?.[id]]) {
						delete updatedSelectedData?.[data?.[id]];
					} else {
						updatedSelectedData[data?.[id]] = data;
					}
					setSelectedData(updatedSelectedData);
				} else if (props.submitHandler) {
					props.submitHandler(data);
					props?.closeModal?.();
				}
				break;
			}
			case "selectAll": {
				let isSelectAll = isAllListDataSelected();
				if (isSelectAll) {
					setSelectedData({});
				} else {
					let payload = {};
					listData?.map(item => {
						payload[item[id]] = item;
					})
					setSelectedData(payload);
				}
				break;
			}
			case "listComponentSearch": {
				let states = getComponentStates();
				let functions = getComponentFunctions();
				setCurrentSearch(prev => data);
				if (props.api) {
					if (e.key == "Enter") {
						if (currentPage == 1) {
							getTableData({
								search: data || "",
							});
						} else {
							setCurrentPage(1);
						}
					}
					break;
				} else {
					const updatedData = props?.getListData?.() || [];
					if (props.search?.customSearchFunction)
						setListData(props.search?.customSearchFunction({ ...states, currentSearch: data, listData: updatedData }, functions));
				}
				break;
			}
			case "listComponentSearchType": {
				setSearchFilter(e.target.value);
				break;
			}
			case "finalSubmit": {
				props?.multiselect?.submitHandler(selectedData);
				props?.closeModal?.();
			}
			default:
				break;
		}
	}

	const renderSubmitButtons = () => {
		return (
			<div className='generic-form-footer'>
				<div></div>
				<div className='gff-right'>
					{(props.closeModal || props?.label?.closeModal) && <GenericCustomButton
						className="generic-default-btn"
						type="default"
						disabled={false}
						label="Close"
						onClick={() => props.closeModal()}
					/>}
					<GenericCustomButton
						className="generic-approve-btn"
						type="normal"
						disabled={false}
						label="Done"
						onClick={() => handleChange("finalSubmit")}
					/>
				</div>
			</div>
		)
	}

	return (
		<div className={`list-component-section ${modalMainClass}`} id="listComponent">
			{renderHeaderComponent()}

			{/* Render the custom items at 0 index */}
			{props.renderCustomItems?.["0"] && props.renderCustomItems?.["0"]()}

			{renderSearchComponent()}

			{/* Render the custom items at 1st index */}
			{props.renderCustomItems?.["1"] && props.renderCustomItems?.["1"]()}

			{renderTotalRecords()}

			{/* Render the custom items at 2nd index */}
			{props.renderCustomItems?.["2"] && props.renderCustomItems?.["2"]()}

			{props.multiselect && renderMultiSelectOptions()}

			{/* Render the custom items at 2nd index */}
			{props.renderCustomItems?.["3"] && props.renderCustomItems?.["3"]()}

			{props.type == 2 ? (
				<div className='modal-mapped-data'>
					{renderListTableData({ hideSelectedData: true, hideSelectAll: true })}
					{renderListTableData({ hideListData: true, hideSelectAll: true, hideLoader: true })}
				</div>
			) : renderListTableData()}

			{props.renderCustomItems?.["4"] && props.renderCustomItems?.["4"]()}

			{/* Render the final Submit Buttons */}

			{props.multiselect && renderSubmitButtons()}

		</div>
	)
}

export default ListComponent