import React, { useState } from 'react'
import PerformanceDashFilters from './PerformanceDashFilters'
import { useSelector } from 'react-redux'
import Icons from '../../../assets/icons'
import DSRSummarySkeleton from './skeletons/DSRSummarySkeleton'

const PRIMARY_FILTERS = [{
    entity: "generic_item_master_report",
    key: "hl1_name",
    value: "Product Category"
}]
const currentFilterKeys = ['hl1_name']
const DSR_SUMMARY_HEADERS_MAP = {
    salesQty: "Sales Qty",
    salesValue: "Sales Value",
    wtdSalesQty: "WTD Sales Qty",
    wtdSalesValue: "WTD Sales Value",
    mtdSalesQty: "MTD Sales Qty",
    mtdSalesValue: "MTD Sales Value",
    ytdSalesQty: "YTD Sales Qty",
    ytdSalesValue: "YTD Sales Value",
    ytdUPT: "YTD UPT",
    ytdATV: "YTD ATV"
};

const DSRSummary = (props) => {
    const { dsrSummaryData } = props
    const digivendStore = useSelector(state => state.digivendStore)
    const [isExpand, setIsExpand] = useState(false)
    const [expandedRowId, setExpandedRowId] = useState('');

    const handleExpandRow = (id) => {
        setIsExpand(!isExpand)
        setExpandedRowId(expandedRowId === id ? '' : id);
    };

    // function to get the dashboard data
    const getDashboardData = (filtersData = {}) => {
        props.getDashboardData(filtersData)
    }

    return (
        <div className='ps-dsr-summary'>
            <PerformanceDashFilters
                PRIMARY_FILTERS={PRIMARY_FILTERS}
                applyFilter={getDashboardData}
                currentFilterKeys={currentFilterKeys}
            />

            <div className="psd-table">
                <h3>Daily Sales Report</h3>
                <div className="vendor-gen-table ars-data-table" >
                    <div className="manage-table">
                        <table className="table gen-main-table">
                            <thead>
                                <tr>
                                    <th className="fix-action-btn wid-40">
                                        <ul className="rab-refresh">
                                            <li className="rab-rinner" onClick={() => getDashboardData({})}>
                                                <Icons.RefreshIcon />
                                                <span className="generic-tooltip">Refresh</span>
                                            </li>
                                        </ul>
                                    </th>
                                    <th><label>Client Type</label></th>
                                    {Object.values(DSR_SUMMARY_HEADERS_MAP).map(value =>
                                        <th><label>{value}</label></th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {digivendStore?.getARSDashboardDataData?.isLoading ? 
                                <tr>
                                    <td className='p-0' colSpan='100%'>
                                        <DSRSummarySkeleton />
                                    </td>
                                </tr>
                                 : (
                                    <React.Fragment>
                                        {dsrSummaryData && Object.keys(dsrSummaryData)?.map(key =>
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td className="fix-action-btn wid-40" rowSpan="3">
                                                        <ul className="table-item-list">
                                                            <li className="til-inner til-add-btn" onClick={() => handleExpandRow(key)}>
                                                                {isExpand && expandedRowId === key ?
                                                                    <React.Fragment>
                                                                        <Icons.Minus />
                                                                        <span className="generic-tooltip">Collapse</span>
                                                                    </React.Fragment> :
                                                                    <React.Fragment>
                                                                        <Icons.Plus />
                                                                        <span className="generic-tooltip">Expand</span>
                                                                    </React.Fragment>}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td className="vgtvf-first-col" rowSpan="3">
                                                        <label>{key}</label>
                                                    </td>
                                                    {Object.keys(dsrSummaryData?.[key])?.map(storeType => {
                                                        if (storeType === "null") {
                                                            return Object.keys(DSR_SUMMARY_HEADERS_MAP)?.map(innerKey =>
                                                                <td align="center" style={{ borderBottom: "none" }}>
                                                                    <label className={`${key === "Total" ? "bold" : ""}`}>{dsrSummaryData?.[key]?.[storeType]?.values?.[innerKey]}</label>
                                                                </td>
                                                            )
                                                        }
                                                    })}
                                                </tr>
                                                <tr>
                                                    {Object.keys(dsrSummaryData?.[key])?.map(storeType => {
                                                        if (storeType == "null") {
                                                            return Object.keys(DSR_SUMMARY_HEADERS_MAP)?.map(innerKey =>
                                                                <td className={`${dsrSummaryData?.[key]?.[storeType]?.percentages?.[innerKey] > 0 ? "bl-green adt-text-b" : "bl-red adt-text-b"}`} style={{ borderBottom: "none" }}>
                                                                    <div className="vgtvf-col">
                                                                        <label className={`${storeType == "Total" ? "bold" : ""}`}>{dsrSummaryData?.[key]?.[storeType]?.percentages?.[innerKey]}%</label>
                                                                        {dsrSummaryData?.[key]?.[storeType]?.percentages?.[innerKey] > 0 ?
                                                                            <span className="rprtmt-arrow rotate180">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 14">
                                                                                    <path fill="#00ceb5" fillRule="evenodd" d="M5.142.307V10.54l2.593-2.586 1.018 1.018-4.333 4.334L.086 8.973l1.019-1.018 2.592 2.586V.307h1.445" />
                                                                                </svg>
                                                                            </span> :
                                                                            <span className="rprtmt-arrow">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 14">
                                                                                    <path fill="#fd3757" fillRule="evenodd" d="M5.142.307V10.54l2.593-2.586 1.018 1.018-4.333 4.334L.086 8.973l1.019-1.018 2.592 2.586V.307h1.445" />
                                                                                </svg>
                                                                            </span>}
                                                                    </div>
                                                                </td>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </tr>
                                                <tr>
                                                    {Object.keys(dsrSummaryData?.[key])?.map(storeType => {
                                                        if (storeType == "null") {
                                                            return Object.keys(DSR_SUMMARY_HEADERS_MAP)?.map(innerKey =>
                                                                <td className={`${dsrSummaryData?.[key]?.[storeType]?.percentages?.[innerKey] > 0 ? "bl-green bl-last" : "bl-red bl-last"}`} style={{ borderTop: "none" }}>
                                                                    <label className={`${key == "Total" ? "bold" : ""}`} style={{ color: "#67768e" }}>{dsrSummaryData?.[key]?.[storeType]?.compLabels?.[innerKey]}</label>
                                                                </td>
                                                            )
                                                        }
                                                    })}
                                                </tr>
                                                {isExpand && expandedRowId === key &&
                                                    <React.Fragment>
                                                        {Object.keys(dsrSummaryData?.[key])?.map(storeType => {
                                                            if (storeType !== "null") {
                                                                return <React.Fragment key={storeType}>
                                                                    <tr className='vgt-tr-bg'>
                                                                        <td className="fix-action-btn wid-40" rowSpan="3"></td>
                                                                        <td className="vgtvf-first-col" rowSpan="3">
                                                                            <label>{storeType}</label>
                                                                        </td>
                                                                        {Object.keys(DSR_SUMMARY_HEADERS_MAP)?.map(innerKey =>
                                                                            <td align="center" style={{ borderBottom: "none" }}>
                                                                                <label className={`${key === "Total" ? "bold" : ""}`}>{dsrSummaryData?.[key]?.[storeType]?.values?.[innerKey]}</label>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                    <tr className='vgt-tr-bg'>
                                                                        {Object.keys(DSR_SUMMARY_HEADERS_MAP).map(innerKey =>
                                                                            <td className={`${dsrSummaryData?.[key]?.[storeType]?.percentages?.[innerKey] > 0 ? "bl-green adt-text-b" : "bl-red adt-text-b"}`} style={{ borderBottom: "none" }}>
                                                                                <div className="vgtvf-col">
                                                                                    <label className={`${storeType == "Total" ? "bold" : ""}`}>{dsrSummaryData?.[key]?.[storeType]?.percentages?.[innerKey]}%</label>
                                                                                    {dsrSummaryData?.[key]?.[storeType]?.percentages?.[innerKey] > 0 ?
                                                                                        <span className="rprtmt-arrow rotate180">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 14">
                                                                                                <path fill="#00ceb5" fillRule="evenodd" d="M5.142.307V10.54l2.593-2.586 1.018 1.018-4.333 4.334L.086 8.973l1.019-1.018 2.592 2.586V.307h1.445" />
                                                                                            </svg>
                                                                                        </span> :
                                                                                        <span className="rprtmt-arrow">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 14">
                                                                                                <path fill="#fd3757" fillRule="evenodd" d="M5.142.307V10.54l2.593-2.586 1.018 1.018-4.333 4.334L.086 8.973l1.019-1.018 2.592 2.586V.307h1.445" />
                                                                                            </svg>
                                                                                        </span>}
                                                                                </div>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                    <tr className='vgt-tr-bg'>
                                                                        {Object.keys(DSR_SUMMARY_HEADERS_MAP)?.map(innerKey =>
                                                                            <td className={`${dsrSummaryData?.[key]?.[storeType]?.percentages?.[innerKey] > 0 ? "bl-green bl-last" : "bl-red bl-last"}`} style={{ borderTop: "none" }}>
                                                                                <label className={`${key == "Total" ? "bold" : ""}`} style={{ color: "#67768e" }}>{dsrSummaryData?.[key]?.[storeType]?.compLabels?.[innerKey]}</label>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                </React.Fragment>
                                                            }
                                                        })}
                                                    </React.Fragment>}
                                            </React.Fragment>)}
                                    </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default DSRSummary